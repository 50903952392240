import { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import Axios from 'axios';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRefresh, faTimes, faCheck, faPercent, faEye, faTrash, faTag, faEyeSlash, faRoad, faPercentage, faCube } from '@fortawesome/free-solid-svg-icons';
import { Modal, FloatingLabel, Form, Card, Row, Col, Container, Badge, Button, Table } from 'react-bootstrap';
import { urlEcommerceBackend } from '../recursos/configuracion';
import { reportar } from '../recursos/funcion';
import { Cargador } from '../componentes/Cargador';
import { BarraNavegacion } from '../componentes/BarraNavegacion';
import { useUsuarioContexto } from '../proveedor/usuario';

export default function Promocion() {

    const cuenta = useUsuarioContexto();

    //console.log("cuenta  ", cuenta);

    const [vital, setVital] = useState(false);

    const parametro = useParams();
    const locacion = useLocation();
    const navegar = useNavigate();

    const [modalIncremento, setModalIncremento] = useState(false);

    const mostrarModalIncremento = () => setModalIncremento(true);
    const cerrarModalIncremento = () => setModalIncremento(false);

    const [montoMinimoIncluir, setMontoMinimoIncluir] = useState(0);
    const [montoMinimoRedondeo, setMontoMinimoRedondeo] = useState(0);
    const [porcentajeReduccion, setPorcentajeReduccion] = useState(0);
    const [listaBaseCalculo, setListaBaseCalculo] = useState([]);
    const [listaBaseRecargo, setListaBaseRecargo] = useState([]);
    const [descripcion, setDescripcion] = useState('');

    const [indice, setIndice] = useState(0);
    const [valor, setValor] = useState(0);

    const [carga, setCarga] = useState(false);

    const [ruta, setRuta] = useState("");

    const [elegido, setElegido] = useState(
        {
            tiempo: {
                teaser: "",
                inicio: "",
                fin: ""
            },
            codigo: "",
            nombre: "",
            ruta: "",
            descripcion: "",
            visibilidad: {
                codigo: 0,
                descripcion: ""
            },
            estado: {
                codigo: 0,
                descripcion: ""
            },
            configuracion: {
                cuota: {
                    minimoMontoIncluir: 0,
                    minimoMontoRedondeo: 0,
                    listaBaseCalculo: [],
                    listaBaseRecargo: []
                }
            }
        }
    );

    useEffect(() => {
        if (cuenta.token === '') {
            setVital(true);
        } else {
            setVital(false);
        }
    }, [cuenta]);

    useEffect(() => {
        setRuta(parametro.ruta);
    }, [locacion]);

    useEffect(() => {
        if (ruta !== "") {
            recuperar();
        }
    }, [ruta]);

    useEffect(() => {
        setMontoMinimoIncluir(elegido.configuracion.cuota.minimoMontoIncluir);
        setMontoMinimoRedondeo(elegido.configuracion.cuota.minimoMontoRedondeo);
        setPorcentajeReduccion(elegido.configuracion.porcentajeReduccion);
        setListaBaseCalculo(elegido.configuracion.cuota.listaBaseCalculo);
        setListaBaseRecargo(elegido.configuracion.cuota.listaBaseRecargo);
        setDescripcion(elegido.descripcion);
    }, [elegido]);

    async function recuperar() {
        setCarga(true);
        try {
            let respuesta = await Axios.get(`${urlEcommerceBackend}/promocion/${ruta}`, {
                headers: {
                    authorization: `Bearer ${cuenta.token}`
                }
            });
            if (respuesta.data.codigo < 0) {
                setElegido({
                    tiempo: {
                        teaser: "",
                        inicio: "",
                        fin: ""
                    },
                    codigo: "",
                    nombre: "",
                    ruta: "",
                    descripcion: "",
                    visibilidad: {
                        codigo: 0,
                        descripcion: ""
                    },
                    estado: {
                        codigo: 0,
                        descripcion: ""
                    },
                    configuracion: {
                        cuota: {
                            minimoMontoIncluir: 0,
                            minimoMontoRedondeo: 0,
                            listaBaseCalculo: [],
                            listaBaseRecargo: []
                        }
                    }
                });
            } else {
                setElegido(respuesta.data.objetoJson);
            }
            setCarga(false);
        } catch (error) {
            setCarga(false);
            await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "promocion.jsx", "recuperar", `${error.toString()}`, {
                ruta: ruta,
                error
            }, 1);
        }
    }

    function actualizar() {
        recuperar();
    }

    async function guardar() {
        let teaser = document.getElementById('txtTeaser').value;
        let inicio = document.getElementById('txtInicio').value;
        let fin = document.getElementById('txtFin').value;
        if (teaser === '') {
            Swal.fire(
                'Promoción',
                'El campo fecha teaser esta vacio',
                'info'
            )
        } else if (inicio === '') {
            Swal.fire(
                'Promoción',
                'El campo fecha inicio esta vacio',
                'info'
            )
        } else if (montoMinimoIncluir === '') {
            Swal.fire(
                'Promoción',
                'El campo Monto minimo incluir esta vacio',
                'info'
            )
        } else if (montoMinimoRedondeo === '') {
            Swal.fire(
                'Promoción',
                'El campo Monto minimo redondeo esta vacio',
                'info'
            )
        } else {
            let parametros = {
                descripcion: descripcion,
                codigo: elegido.codigo,
                teaser: teaser,
                inicio: inicio,
                fin: fin,
                minimoMontoIncluir: parseInt(montoMinimoIncluir),
                minimoMontoRedondeo: parseInt(montoMinimoRedondeo),
                listaBaseCalculo: listaBaseCalculo,
                listaBaseRecargo: listaBaseRecargo,
                porcentajeReduccion: parseInt(porcentajeReduccion)
            }
            try {
                let respuesta = await Axios.put(`${urlEcommerceBackend}/promocion/${ruta}`, parametros, {
                    headers: {
                        authorization: `Bearer ${cuenta.token}`
                    }
                });
                await Axios.get(`${urlEcommerceBackend}/recursos/promocionador`);
                recuperar();
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })

                Toast.fire({
                    icon: 'success',
                    title: respuesta.data.descripcion
                })
            } catch (error) {
                await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "promocion.jsx", "guardar", `${error.toString()}`, {
                    parametros,
                    error
                }, 1);
            }
        }
    }

    function eliminar() {
        Swal.fire({
            icon: 'question',
            title: 'Esta seguro/a de eliminar esta promoción?',
            showDenyButton: true,
            confirmButtonText: 'Si',
            denyButtonText: 'No',
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    let respuesta = await Axios.delete(`${urlEcommerceBackend}/promocion/${ruta}`, {
                        headers: {
                            authorization: `Bearer ${cuenta.token}`
                        }
                    });
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'bottom-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer)
                            toast.addEventListener('mouseleave', Swal.resumeTimer)
                        }
                    })

                    Toast.fire({
                        icon: 'success',
                        title: respuesta.data.descripcion
                    })
                    navegar('/publicidad/promociones/1/12');
                } catch (error) {
                    await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "promocion.jsx", "eliminar", `${error.toString()}`, {
                        ruta,
                        error
                    }, 1);
                }
            }
        });
    }

    async function estado() {
        let parametros = {
            estado: 0
        }
        try {
            let respuesta = await Axios.put(`${urlEcommerceBackend}/promocion/${ruta}`, parametros, {
                headers: {
                    authorization: `Bearer ${cuenta.token}`
                }
            });
            recuperar();
            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })

            Toast.fire({
                icon: 'success',
                title: respuesta.data.descripcion
            })
        } catch (error) {
            await reportar((cuenta === null ? cuenta.usuario : cuenta.name), "Ecommerce Admin", "Frontend", "promocion.jsx", "estado", `${error.toString()}`, {
                ruta,
                error
            }, 1);
        }
    }

    //<td><Button variant="outline-danger" size="lg" onClick={() => { quitarItem(llave) }}><FontAwesomeIcon icon={faTrash} /></Button></td>

    function estadoCalculo(indice, valor) {
        let nuevaListaC = [...listaBaseCalculo];
        nuevaListaC[indice].estado = (valor === 1 ? 0 : 1);
        setListaBaseCalculo(nuevaListaC);
        let nuevaListaR = [...listaBaseRecargo];
        nuevaListaR[indice].estado = (valor === 1 ? 0 : 1);
        setListaBaseRecargo(nuevaListaR);
    }

    function cambiarBase(indice, valor) {
        let nuevaLista = [...listaBaseCalculo];
        nuevaLista[indice].base = (valor === 'venta' ? 'costo' : 'venta');
        setListaBaseCalculo(nuevaLista);
    }

    function invocarIncremento(indice, valor) {
        setIndice(indice);
        setValor(valor);
        mostrarModalIncremento();
        /*
        let nuevaLista = [...listaBaseRecargo];
        nuevaLista[indice].base = 0;
        setListaBaseRecargo(nuevaLista);
        */
    }

    function cambiarIncremento() {
        let nuevaLista = [...listaBaseRecargo];
        nuevaLista[indice].incremento = parseInt(valor);
        setListaBaseRecargo(nuevaLista);
        cerrarModalIncremento();
    }

    const eventoEnter = (event) => {
        if (event.key === 'Enter') {
            guardar();
        }
    }

    return (
        <>
            <Container fluid className='mt-3 mb-3'>
                <Row className='mb-3'>
                    <Col md={12}>
                        <h1>
                            {(carga === true ? <Cargador estado={true} /> : <FontAwesomeIcon icon={faPercent} />)} Promoción
                        </h1>
                    </Col>
                    <Col md={12} className='mt-2'>
                        <BarraNavegacion paginas={[
                            {
                                direccion: '/principal',
                                nombre: 'Principal'
                            },
                            {
                                direccion: '/publicidad',
                                nombre: 'Publicidad'
                            }
                        ]} />
                    </Col>
                    <Col md={12} className='mt-2 mb-2'>
                        <Card>
                            <Card.Body>
                                <Card.Title>
                                    <h5>{elegido.nombre} <Badge bg='primary'><FontAwesomeIcon icon={faRoad} />{elegido.ruta}</Badge>
                                        {(elegido.estado.codigo === 0 ? <Badge bg='danger'><FontAwesomeIcon icon={faTimes} />{elegido.estado.descripcion}</Badge> : <Badge bg='success'><FontAwesomeIcon icon={faCheck} />{elegido.estado.descripcion}</Badge>)}
                                        {(elegido.visibilidad.codigo === 0 ? <Badge bg='danger'><FontAwesomeIcon icon={faEyeSlash} />{elegido.visibilidad.descripcion}</Badge> : <Badge bg='success'><FontAwesomeIcon icon={faEye} />{elegido.visibilidad.descripcion}</Badge>)}
                                    </h5>
                                </Card.Title>

                                <Row>
                                    <Col md={12} className='mt-3 mb-3'>
                                        <Button variant="outline-success" onClick={guardar} disabled={vital} size="lg">
                                            <FontAwesomeIcon icon={faCheck} /> Aplicar cambios
                                        </Button>
                                        <Button variant="outline-primary" onClick={() => { navegar(`/promocionado/${elegido.ruta}/1/12`) }} disabled={vital} size='lg'>
                                            <FontAwesomeIcon icon={faCube} /> Productos
                                        </Button>
                                        <Button variant="outline-primary" onClick={actualizar} disabled={vital} size='lg'>
                                            <FontAwesomeIcon icon={faRefresh} /> Actualizar
                                        </Button>
                                        <Button variant="outline-secondary" onClick={estado} disabled={vital} size='lg'>
                                            <FontAwesomeIcon icon={faTag} /> Estado
                                        </Button>
                                        <Button variant="outline-danger" onClick={eliminar} disabled={vital} size='lg'>
                                            <FontAwesomeIcon icon={faTrash} /> Eliminar
                                        </Button>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12} className='mt-1 mb-3'>
                                        <Card>
                                            <Card.Body>
                                                <Row>
                                                    <Col md={12}>
                                                        <Row>
                                                            <Col md={12}>
                                                                <h5>Detalle:</h5>
                                                            </Col>
                                                            <Col md={12} className='mt-1 mb-3'>
                                                                <FloatingLabel controlId="txtDescrip" label="Descripción">
                                                                    <Form.Control as="textarea" placeholder="Describa como funciona la promoción" style={{ height: '230px' }} value={descripcion} onChange={(event) => setDescripcion(event.target.value)} />
                                                                </FloatingLabel>
                                                            </Col>
                                                            <Col md={12}>
                                                                <h5>Tiempos:</h5>
                                                            </Col>
                                                            <Col md={4} className='mt-1 mb-3'>
                                                                <FloatingLabel controlId="txtTeaser" label="Teaser" onKeyUp={eventoEnter}>
                                                                    <Form.Control type="date" placeholder="Teaser" defaultValue={elegido.tiempo.teaser} />
                                                                </FloatingLabel>
                                                            </Col>
                                                            <Col md={4} className='mt-1 mb-3'>
                                                                <FloatingLabel controlId="txtInicio" label="Inicio" onKeyUp={eventoEnter}>
                                                                    <Form.Control type="date" placeholder="Inicio" defaultValue={elegido.tiempo.inicio} />
                                                                </FloatingLabel>
                                                            </Col>
                                                            <Col md={4} className='mt-1 mb-3'>
                                                                <FloatingLabel controlId="txtFin" label="Fin" onKeyUp={eventoEnter}>
                                                                    <Form.Control type="date" placeholder="Fin" defaultValue={elegido.tiempo.fin} />
                                                                </FloatingLabel>
                                                            </Col>
                                                            <Col md={12}>
                                                                <h5>Cuotas:</h5>
                                                            </Col>
                                                            <Col md={4} className='mt-1 mb-3'>
                                                                <FloatingLabel controlId="txtIncluir" label="Minimo monto cuota válida" onKeyUp={eventoEnter}>
                                                                    <Form.Control type="number" placeholder="Minimo monto cuota válida" value={montoMinimoIncluir} onChange={(event) => setMontoMinimoIncluir(event.target.value)} />
                                                                </FloatingLabel>
                                                            </Col>
                                                            <Col md={4} className='mt-1 mb-3'>
                                                                <FloatingLabel controlId="txtRedondeo" label="Minimo monto cuota para redondeo" onKeyUp={eventoEnter}>
                                                                    <Form.Control type="number" placeholder="Minimo monto cuota para redondeo" value={montoMinimoRedondeo} onChange={(event) => setMontoMinimoRedondeo(event.target.value)} />
                                                                </FloatingLabel>
                                                            </Col>
                                                            <Col md={4} className='mt-1 mb-3'>
                                                                <FloatingLabel controlId="txtPorcentajeReduccion" label="Recargo descuento %" onKeyUp={eventoEnter}>
                                                                    <Form.Control type="number" placeholder="Recargo descuento %" value={porcentajeReduccion} onChange={(event) => setPorcentajeReduccion(event.target.value)} />
                                                                </FloatingLabel>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col md={12} className='mt-2'>
                                                        <Row>
                                                            <Col md={6}>
                                                                <center>
                                                                    <h5>Generar</h5>
                                                                </center>
                                                                <Table bordered hover>
                                                                    <thead>
                                                                        <tr className='bg-dark text-white'>
                                                                            <th>Cuota</th>
                                                                            <th>Base</th>
                                                                            <th>Estado</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            listaBaseCalculo.map((item, llave) => (
                                                                                <tr key={llave}>
                                                                                    <td>{item.posicion}</td>
                                                                                    <td><Form.Check type="switch" id={`base${llave}`} label={item.base} checked={(item.base === 'venta' ? true : false)} onChange={() => cambiarBase(llave, item.base)} /></td>
                                                                                    <td><Form.Check type="switch" id={`estadoC${llave}`} checked={(item.estado === 1 ? true : false)} onChange={() => estadoCalculo(llave, item.estado)} /></td>
                                                                                </tr>
                                                                            ))
                                                                        }
                                                                    </tbody>
                                                                </Table>
                                                            </Col>
                                                            <Col md={6}>
                                                                <center>
                                                                    <h5>Recargo</h5>
                                                                </center>
                                                                <Table bordered hover>
                                                                    <thead>
                                                                        <tr className='bg-dark text-white'>
                                                                            <th>Cuota</th>
                                                                            <th>Incremento</th>
                                                                            <th>Estado</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            listaBaseRecargo.map((item, llave) => (
                                                                                <tr key={llave} onClick={() => { invocarIncremento(llave, item.incremento) }}>
                                                                                    <td>{item.cuota}</td>
                                                                                    <td>{item.incremento}</td>
                                                                                    <td><Form.Check type="switch" id={`estadoR${llave}`} label={` `} checked={(item.estado === 1 ? true : false)} onChange={() => { }} /></td>
                                                                                </tr>
                                                                            ))
                                                                        }
                                                                    </tbody>
                                                                </Table>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container >

            <Modal show={modalIncremento} onHide={cerrarModalIncremento} backdrop="static" keyboard={false} >
                <Modal.Header closeButton>
                    <Modal.Title><FontAwesomeIcon icon={faPercentage} />Incremento</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FloatingLabel controlId="txtIncremento" label="Incremento" className="mb-3">
                        <Form.Control type="number" placeholder="Incremento" value={valor} onChange={(event) => setValor(event.target.value)} />
                    </FloatingLabel>
                    <div className="d-grid gap-2">
                        <Button variant="outline-primary" size="lg" onClick={cambiarIncremento}>
                            <FontAwesomeIcon icon={faCheck} />Guardar
                        </Button>
                        <Button variant="outline-secondary" size="lg" onClick={cerrarModalIncremento}>
                            <FontAwesomeIcon icon={faTimes} />Cerrar
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>

        </>
    );
}