import { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import Axios from 'axios';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCogs, faCog, faPlus, faRefresh, faTimes, faCheck, faTrash, faEdit, faMailBulk } from '@fortawesome/free-solid-svg-icons';
import { Dropdown, Table, Modal, Button, FloatingLabel, Form, Pagination } from 'react-bootstrap';
import { urlEcommerceBackend } from '../recursos/configuracion';
import { reportar } from '../recursos/funcion';
import { BarraNavegacion } from '../componentes/BarraNavegacion';
import { Cargador } from '../componentes/Cargador';
import { useUsuarioContexto } from '../proveedor/usuario';

export default function Correo() {
    const cuenta = useUsuarioContexto();
    const parametro = useParams();
    const locacion = useLocation();
    const navegar = useNavigate();

    const [pagina, setPagina] = useState(0);
    const [intervalo, setIntevalo] = useState(0);

    const [datos, setDatos] = useState([]);
    const [paginas, setPaginas] = useState([]);
    const [paginado, setPaginado] = useState(
        {
            inicio: 0,
            fin: 0
        }
    );

    const [carga, setCarga] = useState(false);

    const [elegido, setElegido] = useState(
        {
            nombre: "",
            correo: "",
            tipo: 0,
            estado: 0
        }
    );

    const [codigo, setCodigo] = useState('');

    const [modal, setModal] = useState(false);

    const [operacion, setOperacion] = useState(0);

    const mostrarModal = () => setModal(true);
    const cerrarModal = () => setModal(false);

    useEffect(() => {
        setPagina(parseInt(parametro.pagina));
        setIntevalo(parseInt(parametro.intervalo));
    }, [locacion]);

    useEffect(() => {
        if (pagina !== 0 && intervalo !== 0) {
            listar();
        }
    }, [cuenta, pagina, intervalo]);

    async function listar() {
        setCarga(true);
        try {
            let respuesta = await Axios.get(`${urlEcommerceBackend}/correos?pagina=${pagina}&intervalo=${intervalo}&estado=1`, {
                headers: {
                    authorization: `Bearer ${cuenta.token}`
                }
            });
            setDatos(respuesta.data.arrayJson);
            setPaginas(respuesta.data.objetoJson.paginas);
            setPaginado(respuesta.data.objetoJson.paginado);
            setCarga(false);
        } catch (error) {
            setCarga(false);
            await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "correo.jsx", "listar", `${error.toString()}`, {
                error
            }, 1);
        }
    }

    async function recuperar(codigo) {
        try {
            let respuesta = await Axios.get(`${urlEcommerceBackend}/correos/${codigo}`, {
                headers: {
                    authorization: `Bearer ${cuenta.token}`
                }
            });
            setElegido(respuesta.data.objetoJson);
        } catch (error) {
            setCarga(false);
            await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "correo.jsx", "recuperar", `${error.toString()}`, {
                error
            }, 1);
        }
    }

    function dirigir(paginanueva) {
        if (paginanueva === 0) {

        } else {
            navegar(`/publicidad/correo/${paginanueva}/${intervalo}`);
        }
    }

    function nuevo() {
        setOperacion(1);
        mostrarModal();
    }

    function actualizar() {
        listar();
    }

    async function guardar() {
        let nombre = document.getElementById('txtNombre').value;
        let correos = document.getElementById('txtCorreo').value;
        if (nombre === '') {
            Swal.fire(
                'Promoción',
                'El campo nombre esta vacio',
                'info'
            )
        } else if (correos === '') {
            Swal.fire(
                'Promoción',
                'El campo fecha inicio esta vacio',
                'info'
            )
        } else {
            let parametros = {
                nombre: nombre,
                correos: correos
            }
            if (operacion === 1) {
                try {
                    let respuesta = await Axios.post(`${urlEcommerceBackend}/correos`, parametros, {
                        headers: {
                            authorization: `Bearer ${cuenta.token}`
                        }
                    });
                    setOperacion(0);
                    cerrarModal();
                    actualizar();
                    setElegido(
                        {
                            nombre: "",
                            correo: "",
                            tipo: 0,
                            estado: 0
                        }
                    );
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'bottom-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer)
                            toast.addEventListener('mouseleave', Swal.resumeTimer)
                        }
                    })

                    Toast.fire({
                        icon: 'success',
                        title: respuesta.data.descripcion
                    })
                } catch (error) {
                    await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "correo.jsx", "guardar", `${error.toString()}`, {
                        error
                    }, 1);
                }
            } else if (operacion === 2) {
                try {
                    let respuesta = await Axios.put(`${urlEcommerceBackend}/correos/${codigo}`, parametros, {
                        headers: {
                            authorization: `Bearer ${cuenta.token}`
                        }
                    });
                    setOperacion(0);
                    cerrarModal();
                    actualizar();
                    setElegido(
                        {
                            nombre: "",
                            correo: "",
                            tipo: 0,
                            estado: 0
                        }
                    );
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'bottom-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer)
                            toast.addEventListener('mouseleave', Swal.resumeTimer)
                        }
                    })

                    Toast.fire({
                        icon: 'success',
                        title: respuesta.data.descripcion
                    })
                } catch (error) {
                    await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "correo.jsx", "guardar", `${error.toString()}`, {
                        error
                    }, 1);
                }
            }
        }
    }

    function cancelar() {
        setOperacion(0);
        cerrarModal();
        setElegido(
            {
                nombre: "",
                correo: "",
                tipo: 0,
                estado: 0
            }
        );
    }

    async function modificar(codigo) {
        await recuperar(codigo);
        setOperacion(2);
        setCodigo(codigo);
        mostrarModal();
    }

    async function eliminar(codigo) {
        Swal.fire({
            icon: 'question',
            title: 'Esta seguro/a de eliminar?',
            showDenyButton: true,
            confirmButtonText: 'Si',
            denyButtonText: 'No',
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    let respuesta = await Axios.delete(`${urlEcommerceBackend}/correos/${codigo}`, {
                        headers: {
                            authorization: `Bearer ${cuenta.token}`
                        }
                    });
                    actualizar();
                    setElegido(
                        {
                            nombre: "",
                            correo: "",
                            tipo: 0,
                            estado: 0
                        }
                    );
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'bottom-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer)
                            toast.addEventListener('mouseleave', Swal.resumeTimer)
                        }
                    })
                    Toast.fire({
                        icon: 'success',
                        title: respuesta.data.descripcion
                    })
                } catch (error) {
                    await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "correo.jsx", "eliminar", `${error.toString()}`, {
                        error
                    }, 1);
                }
            }
        });
    }

    async function tipo(codigo) {
        try {
            let respuesta = await Axios.put(`${urlEcommerceBackend}/correos/tipo?codigo=${codigo}`, {

            }, {
                headers: {
                    authorization: `Bearer ${cuenta.token}`
                }
            });
            listar();
            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })
            Toast.fire({
                icon: 'success',
                title: respuesta.data.descripcion
            })
        } catch (error) {
            await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "correo.jsx", "tipo", `${error.toString()}`, {
                error
            }, 1);
        }
    }

    async function estado(codigo) {
        try {
            let respuesta = await Axios.put(`${urlEcommerceBackend}/correos/estado?codigo=${codigo}`, {

            }, {
                headers: {
                    authorization: `Bearer ${cuenta.token}`
                }
            });
            listar();
            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })

            Toast.fire({
                icon: 'success',
                title: respuesta.data.descripcion
            })
        } catch (error) {
            await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "correo.jsx", "estado", `${error.toString()}`, {
                error
            }, 1);
        }
    }

    const eventoEnter = (event) => {
        if (event.key === 'Enter') {
            guardar();
        }
    }

    return (
        <>
            <div className='container-fluid mt-3 mb-3'>
                <div className='row mb-3'>
                    <div className='col-md-12'>
                        <h1>
                            {(carga === true ? <Cargador estado={true} /> : <FontAwesomeIcon icon={faMailBulk} />)} Correo
                        </h1>
                    </div>
                    <div className='col-md-12 mt-2'>
                        <BarraNavegacion paginas={[
                            {
                                direccion: '/principal',
                                nombre: 'Principal'
                            },
                            {
                                direccion: '/correo/1/10',
                                nombre: 'Correo'
                            }
                        ]} />
                    </div>
                    <div className='col-md-6 mt-2 mb-2'>
                        <Dropdown>
                            <Dropdown.Toggle variant="outline-primary" id="cbOpciones">
                                <FontAwesomeIcon icon={faCogs} /> Opciones
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item href="#" onClick={nuevo}>
                                    <FontAwesomeIcon icon={faPlus} /> Nuevo
                                </Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item href="#" onClick={actualizar}>
                                    <FontAwesomeIcon icon={faRefresh} /> Actualizar
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div className='col-md-6 mt-2 mb-2'>
                        <div className="table-responsive">
                            <center>
                                {
                                    (paginas.length === 0 ? '' : <Pagination className='d-flex justify-content-end'>
                                        {(parseInt(pagina) === 1)}
                                        <Pagination.First onClick={() => {
                                            dirigir(paginado.inicio);
                                        }} disabled={parseInt(pagina) === 1} />
                                        <Pagination.Prev onClick={() => {
                                            dirigir(parseInt(pagina) - 1);
                                        }} disabled={parseInt(pagina) === 1} />
                                        {((Math.ceil(pagina / intervalo) * intervalo) === (Math.ceil(paginado.inicio / intervalo) * intervalo) ? '' : <><Pagination.Item onClick={() => {
                                            dirigir(paginado.inicio);
                                        }} disabled={(parseInt(pagina) === paginado.inicio)}>{paginado.inicio}</Pagination.Item><Pagination.Ellipsis onClick={() => {
                                            dirigir(parseInt(pagina) - 10)
                                        }} /></>)}

                                        {
                                            paginas.map((paginaOpcion, llave) => (
                                                <Pagination.Item key={llave} onClick={() => {
                                                    dirigir((paginaOpcion === parseInt(pagina) ? 0 : paginaOpcion))
                                                }} active={paginaOpcion === parseInt(pagina)}>{paginaOpcion}</Pagination.Item>
                                            ))
                                        }

                                        {((Math.ceil(pagina / intervalo) * intervalo) === (Math.ceil(paginado.fin / intervalo) * intervalo) ? '' : <><Pagination.Ellipsis onClick={() => {
                                            dirigir(parseInt(pagina) + 10)
                                        }} /><Pagination.Item onClick={() => {
                                            dirigir(paginado.fin);
                                        }} disabled={(parseInt(pagina) === paginado.fin)}>{paginado.fin}</Pagination.Item></>)}
                                        <Pagination.Next onClick={() => {
                                            dirigir(parseInt(pagina) + 1);
                                        }} disabled={parseInt(pagina) === paginado.fin} />
                                        <Pagination.Last onClick={() => {
                                            dirigir(paginado.fin);
                                        }} disabled={parseInt(pagina) === paginado.fin} />
                                    </Pagination>)
                                }
                            </center>
                        </div>
                    </div>
                    <div className='col-md-12'>
                        <div className='table-responsive'>
                            <Table bordered hover size="lg" className='table-dark'>
                                <thead>
                                    <tr>
                                        <th>Acción</th>
                                        <th>Nombre</th>
                                        <th>Correo</th>
                                        <th>Tipo</th>
                                        <th>Estado</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {datos.map((dato) => (
                                        <tr key={dato.codigo} className={`align-middle ${(dato.estado === 1 ? 'text-success' : 'text-danger')}`}>
                                            <td className='text-center'>
                                                <Dropdown>
                                                    <Dropdown.Toggle variant={(dato.estado === 1 ? 'outline-success' : 'outline-danger')} id="cbOpcion">
                                                        <FontAwesomeIcon icon={faCog} />
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item href="#" onClick={() => {
                                                            modificar(dato.codigo)
                                                        }}>
                                                            <FontAwesomeIcon icon={faEdit} /> Modificar
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#" onClick={() => {
                                                            tipo(dato.codigo)
                                                        }}>
                                                            <FontAwesomeIcon icon={faRefresh} /> Tipo
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#" onClick={() => {
                                                            estado(dato.codigo)
                                                        }}>
                                                            <FontAwesomeIcon icon={faRefresh} /> Estado
                                                        </Dropdown.Item>
                                                        <Dropdown.Divider />
                                                        <Dropdown.Item href="#" onClick={() => {
                                                            eliminar(dato.codigo)
                                                        }}>
                                                            <FontAwesomeIcon icon={faTrash} /> Eliminar
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </td>
                                            <td>{dato.nombre}</td>
                                            <td>{dato.correo}</td>
                                            <td className='text-center'>{(dato.tipo === 1 ? <>Notificación</> : <>Prueba</>)}</td>
                                            <td className='text-center'>{(dato.estado === 1 ? <><FontAwesomeIcon icon={faCheck} size='xl' /></> : <><FontAwesomeIcon icon={faTimes} size='xl' /></>)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={modal} onHide={cancelar} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <FontAwesomeIcon icon={faMailBulk} /> Correo
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FloatingLabel controlId="txtNombre" label="Nombre" className="mb-3" onKeyUp={eventoEnter}>
                        <Form.Control type="text" placeholder="Nombre" defaultValue={elegido.nombre} />
                    </FloatingLabel>
                    <FloatingLabel controlId="txtCorreo" label="Correo" className="mb-3" onKeyUp={eventoEnter}>
                        <Form.Control type="text" placeholder="Correo" defaultValue={elegido.correo} />
                    </FloatingLabel>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-secondary" onClick={cancelar} size="lg">
                        <FontAwesomeIcon icon={faTimes} /> Cancelar
                    </Button>
                    <Button variant="outline-primary" onClick={guardar} size="lg">
                        <FontAwesomeIcon icon={faCheck} /> Guardar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}