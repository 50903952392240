import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Breadcrumb } from 'react-bootstrap';

export function BarraNavegacion(props) {
    const navegar = useNavigate();
    const locacion = useLocation();
    const [historicos, setHistoricos] = useState([]);

    useEffect(() => {

    }, []);

    useEffect(() => {
        //console.log(locacion.pathname, props.paginas);
        if (props.paginas === undefined) {
            setHistoricos([]);
        } else {
            setHistoricos(props.paginas);
        }
    }, [props]);

    function retrocederhistorico(direccion) {
        navegar(direccion);
    }

    return (
        <Breadcrumb className='mt-2 mb-2'>
            {
                historicos.map((historico, llave) => (
                    <Breadcrumb.Item onClick={() => {
                        retrocederhistorico(historico.direccion);
                    }} key={llave} active={historico.direccion === locacion.pathname}>
                        {historico.nombre}
                    </Breadcrumb.Item>
                ))
            }
        </Breadcrumb>
    );
}