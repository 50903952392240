import { useState, useEffect } from 'react';
import Axios from 'axios';
import Swal from 'sweetalert2';
import { urlEcommerceBackend } from '../recursos/configuracion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Container, Row, Col, Button, Form, ListGroup, FloatingLabel } from 'react-bootstrap';
import { reportar } from '../recursos/funcion';
import { useUsuarioContexto } from '../proveedor/usuario';

export function Caracteristicas(props) {

    const usuario = useUsuarioContexto();

    const [datos, setDatos] = useState([]);
    const [caracteristicas, setCaracteristicas] = useState([]);

    useEffect(() => {
        cargar();
        actualizar(props.codigo);
    }, []);

    function agregar() {
        //let tipo = document.getElementById('txtTipo').value;
        let tipo = document.getElementById('cbTipo').value;
        let dato = document.getElementById('txtDato').value;
        if (tipo === '') {
            Swal.fire(
                'Atención',
                'El campo Tipo esta vacio',
                'info'
            )
        } else if (dato === '') {
            Swal.fire(
                'Atención',
                'El campo Dato esta vacio',
                'info'
            )
        } else {
            Axios.post(urlEcommerceBackend + '/productocaracteristicas/' + props.codigo + '?tipo=' + tipo.trim() + '&dato=' + dato.trim())
                .then(function (respuesta) {
                    cargar();
                    actualizar(props.codigo);
                    //document.getElementById('txtTipo').value = '';
                    document.getElementById('txtDato').value = '';
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'bottom-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer)
                            toast.addEventListener('mouseleave', Swal.resumeTimer)
                        }
                    })

                    Toast.fire({
                        icon: 'success',
                        title: respuesta.data.descripcion
                    })
                })
                .catch(async function (error) {
                    await reportar((usuario === null ? usuario.usuario : usuario.name), "Ecommerce Admin", "Frontend", "Caracteristicas.jsx", "agregar", `${error.toString()}`, {
                        codigo: props.codigo,
                        tipo: tipo.trim(),
                        dato: dato.trim(),
                        error: error.response.data
                    }, 1);
                });
        }
    }

    function eliminar(tipo, dato) {
        Swal.fire({
            title: 'Desea eliminar la etiqueta?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.isConfirmed) {
                Axios.delete(urlEcommerceBackend + '/productocaracteristicas/' + props.codigo + '?tipo=' + tipo + '&dato=' + dato)
                    .then(function (respuesta) {
                        cargar();
                        actualizar(props.codigo);
                        const Toast = Swal.mixin({
                            toast: true,
                            position: 'bottom-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', Swal.stopTimer)
                                toast.addEventListener('mouseleave', Swal.resumeTimer)
                            }
                        })

                        Toast.fire({
                            icon: 'success',
                            title: respuesta.data.descripcion
                        })
                    })
                    .catch(async function (error) {
                        await reportar((usuario === null ? usuario.usuario : usuario.name), "Ecommerce Admin", "Frontend", "Caracteristicas.jsx", "eliminar", `${error.toString()}`, {
                            codigo: props.codigo,
                            tipo: tipo,
                            dato: dato,
                            error: error.response.data
                        }, 1);
                    });
            }
        })
    }

    function actualizar(codigo) {
        Axios.get(urlEcommerceBackend + '/productocaracteristicas/' + codigo)
            .then(function (respuesta) {
                setDatos(respuesta.data.arrayJson);
            })
            .catch(async function (error) {
                await reportar((usuario === null ? usuario.usuario : usuario.name), "Ecommerce Admin", "Frontend", "Caracteristicas.jsx", "actualizar", `${error.toString()}`, {
                    codigo: codigo,
                    error: error.response.data
                }, 1);
            });
    }

    function cargar() {
        Axios.get(`${urlEcommerceBackend}/caracteristicas?estado=1`)
            .then(function (respuesta) {
                setCaracteristicas(respuesta.data.arrayJson);
            })
            .catch(async function (error) {
                await reportar((usuario === null ? usuario.usuario : usuario.name), "Ecommerce Admin", "Frontend", "Caracteristicas.jsx", "cargar", `${error.toString()}`, {
                    error: error.response.data
                }, 1);
            });
    }

    const eventoGuardar = (event) => {
        if (event.key === 'Enter') {
            agregar();
        }
    }

    return (
        <Container>
            <Row>
                <Col>
                    <FloatingLabel controlId="cbTipo" label="Tipo" className="mb-3">
                        <Form.Select aria-label="Floating label select example">
                            {
                                caracteristicas.map((caracteristica, llave) => (
                                    <option key={llave} value={caracteristica.nombre}>{caracteristica.nombre}</option>
                                ))
                            }
                        </Form.Select>
                    </FloatingLabel>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Floating className="mb-3" onKeyUp={eventoGuardar}>
                        <Form.Control id="txtDato" type="text" placeholder="Dato" />
                        <label htmlFor="txtDato">Dato</label>
                    </Form.Floating>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="d-grid gap-2 mb-3">
                        <Button variant="outline-primary" size="lg" onClick={agregar}>
                            <FontAwesomeIcon icon={faPlus} />Agregar
                        </Button>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <ListGroup as="ol" numbered>
                        {
                            datos.map((dato, key) => (
                                <ListGroup.Item key={key} action onClick={() => { eliminar(dato.tipo, dato.dato) }}><b>{dato.tipo}</b>: {dato.dato}</ListGroup.Item>
                            ))
                        }
                    </ListGroup>
                </Col>
            </Row>
        </Container>
    );
}