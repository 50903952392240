import {
    useState,
    useEffect,
    useRef
} from 'react';
import {
    useParams,
    useNavigate,
    useLocation
} from 'react-router-dom';
import {
    FontAwesomeIcon
} from '@fortawesome/react-fontawesome';
import {
    faCheck,
    faTableList,
    faPlus,
    faRefresh,
    faEdit,
    faTimes,
    faTrash,
    faListCheck,
    faCheckSquare,
    faTimesSquare,
    faCogs,
    faBroom
} from '@fortawesome/free-solid-svg-icons';
import {
    Modal,
    Card,
    Row,
    Col,
    Container,
    ButtonGroup,
    Button,
    FloatingLabel,
    Form,
    Pagination,
    InputGroup,
    Table,
    Badge,
    Dropdown,
    Spinner
} from 'react-bootstrap';
import {
    urlEcommerceBackend
} from '../recursos/configuracion';
import {
    reportar
} from '../recursos/funcion';
import {
    Cargador
} from '../componentes/Cargador';
import {
    BarraNavegacion
} from '../componentes/BarraNavegacion';
import {
    useUsuarioContexto
} from '../proveedor/usuario';
import Axios from 'axios';
import Swal from 'sweetalert2';
import ImportarExcel from '../componentes/promocionado/ImportarExcel';

export default function Promocionado() {

    const cuenta = useUsuarioContexto();

    const parametro = useParams();
    const locacion = useLocation();
    const navegar = useNavigate();

    const [ruta, setRuta] = useState((parametro.ruta !== undefined ? parametro.ruta : ''));
    const [pagina, setPagina] = useState((parametro.pagina !== undefined ? parametro.pagina : 1));
    const [intervalo, setIntervalo] = useState((parametro.intervalo !== undefined ? parametro.intervalo : 10));
    const [filterProduct, setFilterProduct] = useState('')

    const [vital, setVital] = useState(false);

    const [carga, setCarga] = useState(false);

    const [proceso, setProceso] = useState(false);

    const [posicionDatos, setPosicionDatos] = useState(-1);

    const [producto, setProducto] = useState({
        codigo: "",
        nombre: "",
        imagenes: [],
        subcategoria: '',
        productos: [],
        isCombo: false
    });

    const [contado, setContado] = useState({
        diferencia: 0,
        valor: 0
    });
    const [credito, setCredito] = useState([]);

    const [datosMarca, setDatosMarca] = useState([]);
    const [datosCategoria, setDatosCategoria] = useState([]);
    const [posicionCategoria, setPosicionCategoria] = useState('sc');
    const [datosSubCategoria, setDatosSubCategoria] = useState([]);

    const [dato, setDato] = useState('');
    const [marca, setMarca] = useState('sm');
    const [categoria, setCategoria] = useState('sc');
    const [subcategoria, setSubcategoria] = useState('ssc');

    const [datos, setDatos] = useState([]);
    const [paginas, setPaginas] = useState([]);
    const [paginado, setPaginado] = useState(
        {
            inicio: 0,
            fin: 0
        }
    );

    const [datosN, setDatosN] = useState([]);

    const [datosP, setDatosP] = useState([]);
    const [intervaloP, setIntervaloP] = useState(12);
    const [paginaP, setPaginaP] = useState(1);
    const [paginasP, setPaginasP] = useState([]);
    const [paginadoP, setPaginadoP] = useState(
        {
            inicio: 0,
            fin: 0
        }
    );

    const [modalAgregar, setModalAgregar] = useState(false);

    const cerrarModalAgregar = () => setModalAgregar(false);
    const mostrarModalAgregar = () => setModalAgregar(true);

    const [modalEditar, setModalEditar] = useState(false);

    const cerrarModalEditar = () => setModalEditar(false);
    const mostrarModalEditar = () => setModalEditar(true);

    const [modalLimpiar, setModalLimpiar] = useState(false);

    const cerrarModalLimpiar = () => setModalLimpiar(false);
    const mostrarModalLimpiar = () => setModalLimpiar(true);

    const [interaccion, setInteraccion] = useState([]);

    const [prioridad, setPrioridad] = useState(0);

    useEffect(() => {
        cargarListas();
        recuperar();
    }, []);

    useEffect(() => {
        if (ruta !== "") {
            if (cuenta.token === '') {
                setVital(true);
            } else {
                setVital(false);
            }
        }
    }, [cuenta]);

    useEffect(() => {
        setRuta(parametro.ruta);
        setPagina(parametro.pagina);
        setIntervalo(parametro.intervalo);
    }, [locacion]);

    useEffect(() => {
        recuperar();
    }, [ruta, pagina, intervalo, filterProduct]);

    useEffect(() => {
        //console.log(datos);
        buscar();
    }, [categoria, subcategoria, marca, dato, paginaP]);

    useEffect(() => {
        //console.log(datos);
    }, [datos]);


    useEffect(() => {
        //console.log(contado);
    }, [contado.valor, contado.diferencia]);

    useEffect(() => {
        //console.log(credito);
    }, [credito]);

    useEffect(() => {
        if (producto.codigo !== '') {
            mostrarModalEditar();
        } else {
            cerrarModalEditar();
        }
        //console.log("valores de producto", producto);
    }, [producto]);

    useEffect(() => {
        if (datosCategoria.length > 0) {
            if (posicionCategoria === 'sc') {
                setSubcategoria('ssc');
            } else {
                setDatosSubCategoria(datosCategoria[posicionCategoria].subcategoria);
            }
        }
    }, [datosCategoria]);

    useEffect(() => {
        if (datosCategoria.length > 0) {
            if (posicionCategoria === 'sc') {
                setSubcategoria('ssc');
            } else {
                setCategoria(datosCategoria[posicionCategoria].ruta);
                setDatosSubCategoria(datosCategoria[posicionCategoria].subcategoria);
            }
        }
    }, [posicionCategoria]);
    //ralentizar las peticiones a la api
    const timeoutRef = useRef(null);

    const handleSelectChange = (event) => {
        const value = event.target.value;

        // Limpia el timeout anterior
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        // Establece un nuevo timeout
        timeoutRef.current = setTimeout(() => {
            setDato(value);
        }, 1500); // 2000 ms = 2 segundos
    };

    async function buscar() {
        try {
            let respuesta = await Axios.post(`${urlEcommerceBackend}/consultas/buscador?pagina=${paginaP}&intervalo=${intervaloP}&dato=${(dato === 'sd' ? '' : dato)}&marca=${(marca === 'sm' ? '' : marca)}&categoria=${(categoria === 'sc' ? '' : categoria)}&subcategoria=${(subcategoria === 'ssc' ? '' : subcategoria)}&clasificacion=`, {

            }, {
                headers: {
                    authorization: `Bearer ${cuenta.token}`
                }
            });
            // console.log('buscar()', respuesta.data.arrayJson);

            setDatosP(respuesta.data.arrayJson);
            
          const marcasDatoOrigen =  respuesta.data.objetoJson.filtro.marcas.map(marca => ({nombre:marca.nombre,ruta:marca.ruta}))
          setDatosMarca(marcasDatoOrigen)

            setPaginasP(respuesta.data.objetoJson.paginas);
            setPaginadoP(respuesta.data.objetoJson.paginado);
        } catch (error) {
            await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "promocionado.jsx", "buscar", `${error.toString()}`, error, 1);
        }
    }
    // console.log(datosMarca,'datosMarca')

    function dirigir(paginanueva) {
        if (paginanueva === 0) {

        } else {
            navegar(`/promocionado/${ruta}/${paginanueva}/${intervalo}`);
        }
    }

    async function recuperar() {
        setCarga(true);
        try {
            let respuesta = await Axios.get(`${urlEcommerceBackend}/consultas/promocionesSinImagen?ruta=${ruta}&pagina=${pagina}&intervalo=${intervalo}&tipo=&nombre=${filterProduct}`, {
                headers: {
                    authorization: `Bearer ${cuenta.token}`
                }
            });
            console.log("XDDDDDDDDD", respuesta.data.arrayJson);

            setDatos(respuesta.data.arrayJson);
            setPaginas(respuesta.data.objetoJson.paginas);
            setPaginado(respuesta.data.objetoJson.paginado);
            setCarga(false);
        } catch (error) {
            setCarga(false);
            await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "promocionado.jsx", "recuperar", `${error.toString()}`, {
                ruta: ruta,
                error: error.response.data
            }, 1);
        }
    }

    async function cargarListas() {
        try {
            let marca = await Axios.post(`${urlEcommerceBackend}/recursos/marcas?dato=&categoria=&subcategoria=`, {

            }, {
                headers: {
                    authorization: `Bearer ${cuenta.token}`
                }
            });

            setDatosMarca(marca.data.arrayJson);
            let categoria = await Axios.get(`${urlEcommerceBackend}/recursos/categorias?dato=&marca=&categoria=`, {
                headers: {
                    authorization: `Bearer ${cuenta.token}`
                }
            });
            setDatosCategoria(categoria.data.arrayJson);
        } catch (error) {
            await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "promocionado.jsx", "cargarListas", `${error.toString()}`, {
                ruta: ruta,
                error: error.response.data
            }, 1);
        }
    }

    function modificar(producto) {
        // console.log(producto);
        setProducto(
            {
                codigo: producto.codigo,
                nombre: producto.nombre,
                imagenes: producto.imagenes,
                subcategoria: producto.subcategorias[0].nombre,
                isCombo: producto.isCombo
            }
        );
        setContado(producto.contado);
        setCredito(producto.credito);
        setPrioridad(producto.prioridad);
    }

    function quitar(codigo) {
        Swal.fire({
            title: 'Desea quitar el producto de la promoción?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si',
            cancelButtonText: 'No'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    let respuesta = await Axios.delete(`${urlEcommerceBackend}/productopromociones/${codigo}?dato=${ruta}`, {
                        headers: {
                            authorization: `Bearer ${cuenta.token}`
                        }
                    });
                    await recuperar();
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'bottom-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer)
                            toast.addEventListener('mouseleave', Swal.resumeTimer)
                        }
                    })

                    Toast.fire({
                        icon: 'success',
                        title: respuesta.data.descripcion
                    })
                } catch (error) {
                    await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "promocionado.jsx", "opcionesProducto", `${error.toString()}`, {
                        error
                    }, 1);
                }
            }
        })
    }

    function modificarAntesContado(valor) {
        let modificarContado = {
            valor: contado.valor,
            diferencia: contado.diferencia
        };
        modificarContado.diferencia = valor;
        setContado(modificarContado);
    }

    function modificarAhoraContado(valor) {
        let modificarContado = {
            valor: contado.valor,
            diferencia: contado.diferencia
        };
        modificarContado.valor = valor;
        setContado(modificarContado);
    }

    function modificarNumeroCuota(posicion, valor) {
        let modificarCuotas = [...credito];
        modificarCuotas[posicion].numero_cuota = valor;
        setCredito(modificarCuotas);
    }

    function modificarAntesCredito(posicion, valor) {
        let modificarCuotas = [...credito];
        modificarCuotas[posicion].diferencia = valor;
        setCredito(modificarCuotas);
    }

    function modificarAhoraCredito(posicion, valor) {
        let modificarCuotas = [...credito];
        modificarCuotas[posicion].valor = valor;
        setCredito(modificarCuotas);
    }

    function agregarCuota() {
        let agregarCuota = [...credito];
        agregarCuota.push(
            {
                numero_cuota: (credito.length === 0 ? 0 : credito[credito.length - 1].numero_cuota) + 1,
                valor: (credito.length === 0 ? 0 : credito[credito.length - 1].valor),
                diferencia: (credito.length === 0 ? 0 : credito[credito.length - 1].diferencia)
            }
        );
        setCredito(agregarCuota);
    }

    function eliminarCuotas() {
        let quitarCuota = [...credito];
        for (let x = 0; x <= credito.length - 1; x++) {
            if (document.getElementById(`marcar${x}`).checked) {
                document.getElementById(`marcar${x}`).checked = false;
                quitarCuota = quitarCuota.filter((cuota) => cuota.numero_cuota !== credito[x].numero_cuota);
            }
        }
        setCredito(quitarCuota);
        setInteraccion([]);
    }

    function listaInteraccion(llave, estado) {
        let lista = [...interaccion];
        if (estado) {
            lista.push(llave);
            setInteraccion(lista);
        } else {
            setInteraccion(lista.filter((indice) => indice !== llave));
        }
    }

    function marcarCuotas() {
        for (let x = 0; x <= credito.length - 1; x++) {
            document.getElementById(`marcar${x}`).checked = true;
        }
    }

    function desmarcarCuotas() {
        for (let x = 0; x <= credito.length - 1; x++) {
            document.getElementById(`marcar${x}`).checked = false;
        }
    }

    async function guardarEditar() {
        var parametros = {
            producto: {
                codigo: producto.codigo,
                contado: contado,
                credito: credito,
                subcategoria: producto.subcategoria,
                prioridad: prioridad,
                usuario_received: `${cuenta.nombre} ${cuenta.apellido}`
            }
        };
        // console.log("XDDDD",parametros.producto);
        let respuesta = await Axios.put(`${urlEcommerceBackend}/productopromociones/${ruta}`, parametros, {
            headers: {
                authorization: `Bearer ${cuenta.token}`
            }
        });
        // console.log(respuesta);
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })
        Toast.fire({
            icon: (respuesta.data.codigo === 0 ? 'success' : 'error'),
            title: respuesta.data.descripcion
        })
        await recuperar();
        setProducto(
            {
                codigo: "",
                nombre: "",
                imagenes: []
            }
        );
        setContado(
            {
                valor: 0,
                diferencia: 0
            }
        );
        setCredito([]);
    }

    function cancelarEditar() {
        setProducto(
            {
                codigo: "",
                nombre: "",
                imagenes: []
            }
        );
        setContado(
            {
                valor: 0,
                diferencia: 0
            }
        );
        setCredito([]);
    }

    function cargarSubCategoria(posicion) {
        if (posicion === 'sc') {
            setCategoria('sc');
            setSubcategoria('ssc');
            setDatosSubCategoria([]);
        } else {
            setPosicionCategoria(posicion);
        }
    }

    function manejarProducto(codigo, precio) {
        if (comprobarEditable(codigo)) {
            let agregarDatosN = [...datosN];
            agregarDatosN.push(
                {
                    codigo: codigo,
                    contado: precio,
                    credito: []
                }
            )
            setDatosN(agregarDatosN);
        } else {
            let quitarDatosN = [...datosN];
            setDatosN(quitarDatosN.filter((producto) => producto.codigo !== codigo));
        }
    }

    function comprobarEditable(codigo) {
        let comprobar = datosN.find((producto) => producto.codigo === codigo);
        return comprobar === undefined;
    }

    async function guardarGrupo() {
        setProceso(true);
        Swal.fire({
            title: 'Desea agregar todo el grupo de productos a la promoción?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si',
            cancelButtonText: 'No'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    if (paginadoP.inicio === 0 || paginadoP.fin === 0) {
                        const Toast = Swal.mixin({
                            toast: true,
                            position: 'bottom-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', Swal.stopTimer)
                                toast.addEventListener('mouseleave', Swal.resumeTimer)
                            }
                        })

                        Toast.fire({
                            icon: 'info',
                            title: 'No se encuentran productos'
                        })
                    } else {
                        for (var x = paginadoP.inicio; x <= paginadoP.fin; x++) {
                            let respuesta1 = await Axios.post(`${urlEcommerceBackend}/consultas/buscador?pagina=${x}&intervalo=${intervaloP}&dato=${(dato === 'sd' ? '' : dato)}&marca=${(marca === 'sm' ? '' : marca)}&categoria=${(categoria === 'sc' ? '' : categoria)}&subcategoria=${(subcategoria === 'ssc' ? '' : subcategoria)}&clasificacion=`, {

                            }, {
                                headers: {
                                    authorization: `Bearer ${cuenta.token}`
                                }
                            });
                            // console.log("respuesta1", respuesta1);
                            for (var y = 0; y <= (respuesta1.data.arrayJson.length - 1); y++) {
                                const respuestaApi = await Axios.post(`${urlEcommerceBackend}/productopromociones/${respuesta1.data.arrayJson[y].codigo}?dato=${ruta}`, {
                                    // Parámetros en el body
                                    usuario_received: `${cuenta.nombre} ${cuenta.apellido}`

                                }, {
                                    headers: {
                                        authorization: `Bearer ${cuenta.token}`
                                    }
                                });
                            }
                        }
                        const Toast = Swal.mixin({
                            toast: true,
                            position: 'bottom-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', Swal.stopTimer)
                                toast.addEventListener('mouseleave', Swal.resumeTimer)
                            }
                        })

                        Toast.fire({
                            icon: 'info',
                            title: 'Proceso finalizado'
                        })
                        cerrarModalAgregar();
                        await recuperar();
                    }
                } catch (error) {
                    console.log(error);
                }
            }
        })
        setProceso(false);
    }
    //FUNCION para guardar productos seleccionados al presionar el boton de aplicar seleccionados.
    async function guardarSeleccionados() {
        setProceso(true);
        cerrarModalAgregar();
        for (let x = 0; x <= datosN.length - 1; x++) {
            try {
                if (comprobarOriginal(datosN[x].codigo)) {

                    // let parametros = {
                    //     usuario: `${cuenta.nombre} ${cuenta.apellido}`,
                    // }

                    let respuesta = await Axios.post(`${urlEcommerceBackend}/productopromociones/${datosN[x].codigo}?dato=${ruta}`, {
                        usuario_received: `${cuenta.nombre} ${cuenta.apellido}`
                    }, {
                        headers: {
                            authorization: `Bearer ${cuenta.token}`
                        }
                    });

                    // console.log(respuesta);
                    
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'bottom-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer)
                            toast.addEventListener('mouseleave', Swal.resumeTimer)
                        }
                    })

                    Toast.fire({
                        icon: (respuesta.data.codigo === 0 ? 'success' : 'info'),
                        title: respuesta.data.descripcion
                    })
                    setDatosN([]);
                }
            } catch (error) {
                console.log(error);
            }
        }
        await recuperar();
        setProceso(false);
    }

    function cancelarAgregar() {
        cerrarModalAgregar();
    }

    function comprobarOriginal(codigo) {
        let comprobar = undefined /*elegido.producto.find((producto) => producto.codigo === codigo)*/;
        return comprobar === undefined;
    }

    function iniciarAgregar() {
        mostrarModalAgregar();
    }

    const handleFiltro = (e) => {
        if (e.key === 'Enter') {
            // console.log('e.target.value', e.target.value);
            
            setFilterProduct(e.target.value)
        }
    }

    async function limpiarPromocion() {

        console.log('Limpiar promo');

        console.log('ruta', ruta);

        console.log('datos', datos);
        
        
        try {

            let respuesta = await Axios.post(`${urlEcommerceBackend}/promocion/limpiarPromo/${ruta}`, {datos}, {
                headers: {
                    authorization: `Bearer ${cuenta.token}`
                }
            });


            console.log(respuesta.data);

            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })

            Toast.fire({
                icon: 'success',
                title: 'Promoción vaciada'
            });

            // navegar(`/promocion/${ruta}`);
            cerrarModalLimpiar();


        } catch (error) {

            let parametros = {};
            
            await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "promociones.jsx", "guardar", `${error.toString()}`, {
                parametros,
                error
            }, 1);
            
            recuperar();

            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })

            Toast.fire({
                icon: 'error',
                title: 'Error al limpiar promoción'
            });
            
        }

        recuperar();

        // cerrarModalLimpiar();

    }

    return (
        <>
            <Container fluid className='mt-3 mb-3'>
                <Row>
                    <Col md={12}>
                        <h1>
                            {(carga === true ? <Cargador estado={true} /> : <FontAwesomeIcon icon={faTableList} />)} Promocionado
                        </h1>
                    </Col>
                    <Col md={12} className='mt-2'>
                        <BarraNavegacion formulario={'Promocionado'} />
                    </Col>
                    <Col md={6} className='mt-2 mb-2'>
                        <Dropdown>
                            <Dropdown.Toggle variant="outline-primary" id="cbOpciones">
                                <FontAwesomeIcon icon={faCogs} /> Opciones
                            </Dropdown.Toggle>
                            <Dropdown.Menu>

                                
                                <Dropdown.Item href="#" onClick={() => {
                                    iniciarAgregar()
                                }}>
                                    <FontAwesomeIcon icon={faPlus} /> Agregar
                                </Dropdown.Item>

                                <Dropdown.Divider />

                                <Dropdown.Item href="#" onClick={() => {
                                    mostrarModalLimpiar()
                                }}>
                                    <FontAwesomeIcon icon={faBroom} /> Limpiar promo
                                </Dropdown.Item>

                                <Dropdown.Divider />


                                <ImportarExcel ruta={ruta} refresh={recuperar}/>

                                <Dropdown.Divider />

                                <Dropdown.Item href="#" onClick={() => {
                                    recuperar()
                                }}>
                                    <FontAwesomeIcon icon={faRefresh} /> Actualizar
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                    <Col md={6}>
                        <div className="table-responsive">
                            <center>
                                {
                                    (paginas.length === 0 ? '' : <Pagination className='d-flex justify-content-end'>
                                        {(parseInt(pagina) === 1)}
                                        <Pagination.First onClick={() => {
                                            dirigir(paginado.inicio);
                                        }} disabled={parseInt(pagina) === 1} />
                                        <Pagination.Prev onClick={() => {
                                            dirigir(parseInt(pagina) - 1);
                                        }} disabled={parseInt(pagina) === 1} />
                                        {((Math.ceil(pagina / intervalo) * intervalo) === (Math.ceil(paginado.inicio / intervalo) * intervalo) ? '' : <><Pagination.Item onClick={() => {
                                            dirigir(paginado.inicio);
                                        }} disabled={(parseInt(pagina) === paginado.inicio)}>{paginado.inicio}</Pagination.Item><Pagination.Ellipsis onClick={() => {
                                            dirigir(parseInt(pagina) - 10)
                                        }} /></>)}

                                        {
                                            paginas.map((paginaOpcion, llave) => (
                                                <Pagination.Item key={llave} onClick={() => {
                                                    dirigir((paginaOpcion === parseInt(pagina) ? 0 : paginaOpcion))
                                                }} active={paginaOpcion === parseInt(pagina)}>{paginaOpcion}</Pagination.Item>
                                            ))
                                        }

                                        {((Math.ceil(pagina / intervalo) * intervalo) === (Math.ceil(paginado.fin / intervalo) * intervalo) ? '' : <><Pagination.Ellipsis onClick={() => {
                                            dirigir(parseInt(pagina) + 10)
                                        }} /><Pagination.Item onClick={() => {
                                            dirigir(paginado.fin);
                                        }} disabled={(parseInt(pagina) === paginado.fin)}>{paginado.fin}</Pagination.Item></>)}
                                        <Pagination.Next onClick={() => {
                                            dirigir(parseInt(pagina) + 1);
                                        }} disabled={parseInt(pagina) === paginado.fin} />
                                        <Pagination.Last onClick={() => {
                                            dirigir(paginado.fin);
                                        }} disabled={parseInt(pagina) === paginado.fin} />
                                    </Pagination>)
                                }
                            </center>
                        </div>
                    </Col>
                    <Form onSubmit={(e) => e.preventDefault()}>
                        <Form.Group className="my-3" >
                            <Form.Label>Filtrar</Form.Label>
                            <Form.Control type="text" name='filtro' placeholder="Nombre, Marca y/o código." onKeyDown={
                                e => handleFiltro(e)
                            } />
                        </Form.Group>
                    </Form>
                    <Col md={12}>
                        {/*<Table bordered hover responsive size="lg" className='table-dark'>
                            <thead>
                                <tr>
                                    <th>Acción</th>
                                    <th>Imagen</th>
                                    <th>Código</th>
                                    <th>Marca</th>
                                    <th>Nombre</th>
                                    <th>Cantidad</th>
                                    <th>Precio</th>
                                    <th>Cuotas</th>
                                </tr>
                            </thead>
                            <tbody>
                                {datos.map((dato, llave) => (
                                    <tr key={llave} className={`align-middle`}>
                                        <td className='text-center'>
                                            <Button variant='outline-danger' size='lg' onClick={() => {
                                                quitar(dato.codigo);
                                            }}>
                                                <FontAwesomeIcon icon={faTrash} />
                                            </Button>
                                            <Button variant='outline-primary' size='lg' onClick={() => {
                                                modificar(dato);
                                            }}>
                                                <FontAwesomeIcon icon={faEdit} />
                                            </Button>
                                        </td>
                                        <td className='text-center'>
                                            <img src={
                                                dato.imagenes === undefined ? '' : dato.imagenes.length > 0 ? dato.imagenes[0].url['300'] : "https://csdigitalizacion.nyc3.cdn.digitaloceanspaces.com/ecommerce/logos/store/lcng/100.png"
                                            } alt="" height='50%' width='50%' />
                                        </td>
                                        <td className='text-center'>{dato.codigo}</td>
                                        <td>{dato.marca.nombre}</td>
                                        <td>{dato.nombre}</td>
                                        <td className='derecha'>{parseInt(dato.cantidad).toLocaleString('es-PY')}</td>
                                        <td className='derecha'>{parseInt(dato.contado.valor).toLocaleString('es-PY')}</td>
                                        <td className='derecha'>{parseInt(dato.credito.length).toLocaleString('es-PY')}</td>
                                    </tr>
                                ))}
                            </tbody>
                            </Table>*/}
                        <Row>
                            {
                                datos.map((dato, llave) => (

                                    <Col xxl={2} xl={3} lg={4} md={6} sm={12} xs={12} key={llave} className='mt-3 mb-3'>
                                        <Card bg={'dark'} text={'light'} onClick={() => { }}>
                                            <Card.Img variant="top" src={dato.imagenes.length > 0 ? dato.imagenes[0].url['1000'] : "https://csdigitalizacion.nyc3.cdn.digitaloceanspaces.com/ecommerce/logos/store/lcng/1000.png"} />
                                            <Card.Body>
                                                <Card.Title>{dato.nombre} <Badge bg='primary'>{dato.codigo}</Badge></Card.Title>
                                                <Card.Text>
                                                    Marca:{dato.marca.nombre}<br />
                                                    Cantidad: {parseInt(dato.cantidad).toLocaleString('es-PY')}<br />
                                                    Precio Gs: {parseInt(dato.contado.valor).toLocaleString('es-PY')}<br />
                                                    Cuotas: {dato.credito.length}
                                                </Card.Text>
                                                <div className="d-grid gap-2">
                                                    <Button variant='primary' size="lg" onClick={async () => {
                                                        modificar(dato);
                                                    }}>
                                                        <FontAwesomeIcon icon={faEdit} />
                                                    </Button>
                                                    <Button variant="danger" size="lg" onClick={async () => {
                                                        quitar(dato.codigo);
                                                    }}>
                                                        <FontAwesomeIcon icon={faTrash} />
                                                    </Button>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                ))
                            }
                        </Row>
                    </Col>
                </Row>
            </Container>

            <Modal show={modalAgregar} onHide={cerrarModalAgregar} backdrop="static" keyboard={false} size='xl' >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <FontAwesomeIcon icon={faPlus} />Agregar
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col md={6} className='mt-2 mb-2'>
                                    <FloatingLabel controlId="txtCategoria" label="Categoria">
                                        <Form.Select aria-label="Categoria" onChange={(event) => {
                                            cargarSubCategoria(event.target.value);
                                        }}>
                                            <option value="sc">Sin categoria</option>
                                            {
                                                datosCategoria.map((categoria, key) => (
                                                    <option value={key} key={key}>{categoria.nombre}</option>
                                                ))
                                            }
                                        </Form.Select>
                                    </FloatingLabel>
                                </Col>
                                <Col md={6} className='mt-2 mb-2'>
                                    <FloatingLabel controlId="txtSubCategoria" label="SubCategoria">
                                        <Form.Select aria-label="SubCategoria" onChange={(event) => {
                                            setSubcategoria(event.target.value);
                                        }}>
                                            <option value="ssc">Sin subcategoria</option>
                                            {
                                                datosSubCategoria.map((subcategoria, key) => (
                                                    <option value={subcategoria.ruta} key={key}>{subcategoria.nombre}</option>
                                                ))
                                            }
                                        </Form.Select>
                                    </FloatingLabel>
                                </Col>
                                <Col md={6} className='mt-2 mb-2'>
                                    <FloatingLabel controlId="txtMarca" label="Marca">
                                        <Form.Select aria-label="Marca" onChange={(event) => {
                                            setMarca(event.target.value);
                                        }}>
                                            <option value="sm">Sin marca</option>
                                            {
                                                datosMarca.map((marca, key) => (
                                                    <option value={marca.ruta} key={key}>{marca.nombre}</option>
                                                ))
                                            }
                                        </Form.Select>
                                    </FloatingLabel>
                                </Col>
                                <Col md={6} className='mt-2 mb-2'>
                                    <FloatingLabel controlId="txtDato" label="Código/Nombre" >
                                        <Form.Control type="text" placeholder="Código/Nombre" onKeyUp={handleSelectChange} />
                                    </FloatingLabel>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Row className='mt-4'>
                        <Col md={12} className='table-responsive'>
                            {
                                (paginasP.length === 0 ? '' : <Pagination className='d-flex justify-content-center' size="lg">
                                    {(parseInt(paginaP) === 1)}
                                    <Pagination.First onClick={() => {
                                        setPaginaP(paginadoP.inicio);
                                    }} disabled={parseInt(paginaP) === 1} />
                                    <Pagination.Prev onClick={() => {
                                        setPaginaP(parseInt(paginaP) - 1);
                                    }} disabled={parseInt(paginaP) === 1} />
                                    {((Math.ceil(paginaP / intervaloP) * intervaloP) === (Math.ceil(paginadoP.inicio / intervaloP) * intervaloP) ? '' : <><Pagination.Item onClick={() => {
                                        setPaginaP(paginadoP.inicio);
                                    }} disabled={(parseInt(paginaP) === paginadoP.inicio)}>{paginadoP.inicio}</Pagination.Item><Pagination.Ellipsis onClick={() => {
                                        setPaginaP(parseInt(paginaP) - 10)
                                    }} /></>)}

                                    {
                                        paginasP.map((paginaOpcion, llave) => (
                                            <Pagination.Item key={llave} onClick={() => {
                                                setPaginaP((paginaOpcion === parseInt(paginaP) ? 0 : paginaOpcion))
                                            }} active={paginaOpcion === parseInt(paginaP)}>{paginaOpcion}</Pagination.Item>
                                        ))
                                    }

                                    {((Math.ceil(paginaP / intervaloP) * intervaloP) === (Math.ceil(paginadoP.fin / intervaloP) * intervaloP) ? '' : <><Pagination.Ellipsis onClick={() => {
                                        setPaginaP(parseInt(paginaP) + 10)
                                    }} /><Pagination.Item onClick={() => {
                                        setPaginaP(paginadoP.fin);
                                    }} disabled={(parseInt(paginaP) === paginadoP.fin)}>{paginadoP.fin}</Pagination.Item></>)}
                                    <Pagination.Next onClick={() => {
                                        setPaginaP(parseInt(paginaP) + 1);
                                    }} disabled={parseInt(paginaP) === paginadoP.fin} />
                                    <Pagination.Last onClick={() => {
                                        setPaginaP(paginadoP.fin);
                                    }} disabled={parseInt(paginaP) === paginadoP.fin} />
                                </Pagination>)
                            }
                        </Col>
                        {
                            datosP.map((dato, llave) => (
                                <Col md={3} key={llave} className='mt-3 mb-3'>
                                    <Card bg={(comprobarEditable(dato.codigo) ? 'dark' : 'success')} text={'light'} onClick={() => {
                                        manejarProducto(dato.codigo, dato.precio);
                                    }}>
                                        <Card.Img variant="top" src={dato.imagenes.length > 0 ? dato.imagenes[0].url['1000'] : "https://csdigitalizacion.nyc3.cdn.digitaloceanspaces.com/ecommerce/logos/store/lcng/1000.png"} />
                                        <Card.Body>
                                            <Card.Title>{dato.nombre}</Card.Title>
                                            <Card.Text>
                                                Código: {dato.codigo}<br />
                                                {/* Código: {parseInt(dato.codigo).toLocaleString('es-PY')}<br /> */}
                                                Marca: {dato.marca.nombre}<br />
                                                Modelo: {dato.modelo}<br />
                                                Precio Gs: {parseInt(dato.precio).toLocaleString('es-PY')}<br />
                                                Cantidad: {parseInt(dato.cantidad).toLocaleString('es-PY')}
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))
                        }
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" size='lg' onClick={guardarGrupo}>
                        {(proceso ? <Spinner animation="border" size="sm" /> : <FontAwesomeIcon icon={faCheck} />)}Aplicar Grupo
                    </Button>
                    <Button variant="outline-primary" size='lg' onClick={guardarSeleccionados}>
                        {(proceso ? <Spinner animation="border" size="sm" /> : <FontAwesomeIcon icon={faListCheck} />)}Aplicar Seleccionados
                    </Button>
                    <Button variant="outline-danger" size='lg' onClick={cancelarAgregar}>
                        <FontAwesomeIcon icon={faTimes} />Cancelar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={modalEditar} onHide={cerrarModalEditar} backdrop="static" keyboard={false} size='lg' className='dark' >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <FontAwesomeIcon icon={faEdit} />Editar
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h3>{producto.nombre} <Badge bg='primary'>{producto.codigo}</Badge></h3>
                    <br />
                    <center>
                        <img src={(producto.imagenes.length > 0 ? producto.imagenes[0].url['1000'] : '')} width='60%' height='60%' />
                    </center>
                    <br />
                    <h4>Precio Contado:</h4>
                    <Table bordered hover responsive>
                        <thead>
                            <tr className='bg-dark text-light'>
                                <th>Antes Gs:</th>
                                <th>Ahora Gs:</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <Form.Control type="number" disabled={producto.isCombo} value={contado.diferencia} onChange={(event) => {
                                        modificarAntesContado(parseInt((event.target.value === '' ? 0 : event.target.value)));
                                    }} />
                                </td>
                                <td>
                                    <Form.Control type="number" disabled={producto.isCombo} value={contado.valor} onChange={(event) => {
                                        modificarAhoraContado(parseInt((event.target.value === '' ? 0 : event.target.value)));
                                    }} />
                                </td>
                            </tr>
                        </tbody>
                    </Table>

                    <h4>Precio Crédito:</h4>
                    <ButtonGroup aria-label="Basic example">
                        <Button variant="outline-primary" size='lg' onClick={() => { agregarCuota(); }}>
                            <FontAwesomeIcon icon={faPlus} />
                        </Button>
                        <Button variant="outline-danger" size='lg' onClick={() => { eliminarCuotas(); }}>
                            <FontAwesomeIcon icon={faTrash} />
                        </Button>
                        <Button variant="outline-success" size='lg' onClick={() => { marcarCuotas(); }}>
                            <FontAwesomeIcon icon={faCheckSquare} />
                        </Button>
                        <Button variant="outline-secondary" size='lg' onClick={() => { desmarcarCuotas(); }}>
                            <FontAwesomeIcon icon={faTimesSquare} />
                        </Button>
                    </ButtonGroup>
                    <br />
                    <br />
                    <div className="w-50">
                        <h4>Prioridad:</h4>
                        <Form.Group controlId="formPrioridad">
                            <Form.Control
                                type="number"
                                placeholder="Ingresa la prioridad"
                                value={prioridad}
                                onChange={(event) => setPrioridad(Number(event.target.value))}
                            />
                        </Form.Group>
                    </div>


                    <br />
                    <br />
                    <Table bordered hover responsive>
                        <thead>
                            <tr className='bg-dark text-light'>
                                <th>Marca</th>
                                <th>Cuota</th>
                                <th>Antes Gs</th>
                                <th>Ahora Gs</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                credito.map((cuota, llave) => (
                                    <tr key={llave}>
                                        <td>
                                            <InputGroup.Checkbox id={`marcar${llave}`} onChange={(event) => {
                                                listaInteraccion(llave, event.target.checked);
                                            }} />
                                        </td>
                                        <td>
                                            <Form.Control type='number' id={`numeroCuota${llave}`} value={cuota.numero_cuota} onChange={(event) => {
                                                modificarNumeroCuota(llave, parseInt((event.target.value === '' ? 0 : event.target.value)));
                                            }} />
                                        </td>
                                        <td>
                                            <Form.Control type='number' value={cuota.diferencia} onChange={(event) => {
                                                modificarAntesCredito(llave, parseInt((event.target.value === '' ? 0 : event.target.value)));
                                            }} />
                                        </td>
                                        <td>
                                            <Form.Control type='number' value={cuota.valor} onChange={(event) => {
                                                modificarAhoraCredito(llave, parseInt((event.target.value === '' ? 0 : event.target.value)));
                                            }} />
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </Table>
                    <div className="d-grid gap-2">
                        <Button variant="outline-primary" size="lg" onClick={guardarEditar}>
                            <FontAwesomeIcon icon={faCheck} />Guardar
                        </Button>
                        <Button variant="outline-secondary" size="lg" onClick={cancelarEditar}>
                            <FontAwesomeIcon icon={faTimes} />Cancelar
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={modalLimpiar} onHide={cerrarModalLimpiar} backdrop="static" keyboard={false} size='lg' className='dark' >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <FontAwesomeIcon icon={faBroom} />Limpiar Promoción
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>Está seguro de limpiar la promoción?</h4>
                    
                    <div className="d-grid gap-2">
                        <Button variant="outline-primary" size="lg" onClick={limpiarPromocion}>
                            <FontAwesomeIcon icon={faCheck} />Limpiar
                        </Button>
                        <Button variant="outline-secondary" size="lg" onClick={cerrarModalLimpiar}>
                            <FontAwesomeIcon icon={faTimes} />Cancelar
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}