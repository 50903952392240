import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { useState } from 'react';
import { Modal, Button, Card, Form, Row, Col, FloatingLabel, Spinner } from 'react-bootstrap';
import { urlEcommerceBackend } from '../../recursos/configuracion';
import { useParams } from 'react-router-dom';
import { useUsuarioContexto } from '../../proveedor/usuario';
import Swal from 'sweetalert2';

export default function AgregarProductoModal({ recuperar }) {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const cuenta = useUsuarioContexto();
  const parametro = useParams();
  const [productos, setProductos] = useState([]);

  const cargarProductos = async (codigo) => {
    setLoading(true);
    const respuesta = await axios.post(
      `${urlEcommerceBackend}/productoCombo/consulta/${
        parametro.id
      }?pagina=${1}&intervalo=12&dato=${codigo}&marca=&categoria=&subcategoria=&clasificacion=`,
      {},
      {
        headers: {
          authorization: `Bearer ${cuenta.token}`
        }
      }
    );
    setProductos(respuesta.data.arrayJson);
    setLoading(false);
  };

  const handleSelectChange = (event) => {
    if (event.key === 'Enter') {
      cargarProductos(event.target.value);
    }
  };

  const handleAgregar = async (producto) => {
    setLoading(true);
    const respuesta = await axios.post(
      `${urlEcommerceBackend}/productoCombo/agregar/${parametro.id}`,
      {
        productosCombo: [producto]
      },
      {
        headers: {
          authorization: `Bearer ${cuenta.token}`
        }
      }
    );
    console.log(respuesta.data);
    Swal.fire({
      title: respuesta.data.descripcion
    }).then(() => {
      setLoading(false);
    });
    await recuperar();
  };

  return (
    <>
      <Button onClick={() => setShow(true)}>Agregar</Button>
      <Modal show={show} onHide={() => setShow(false)} backdrop='static' keyboard={false} size='xl'>
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faPlus} />
            Agregar
            {loading && <Spinner animation='border' size='sm' />}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card>
            <Card.Body>
              <Row>
                <Col md={6} className='mt-2 mb-2'>
                  <FloatingLabel controlId='txtDato' label='Código/Nombre'>
                    <Form.Control type='text' placeholder='Código/Nombre' onKeyDown={handleSelectChange} />
                  </FloatingLabel>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Row className='mt-4'>
            {productos.map((producto, llave) => (
              <Col md={3} key={llave} className='mt-3 mb-3'>
                <Card>
                  <Card.Img
                    variant='top'
                    src={
                      producto.imagenes.length > 0
                        ? producto.imagenes[0].url['1000']
                        : 'https://csdigitalizacion.nyc3.cdn.digitaloceanspaces.com/ecommerce/logos/store/lcng/1000.png'
                    }
                  />
                  <Card.Body>
                    <Card.Title>{producto.nombre}</Card.Title>
                    <Card.Text>{producto.codigo}</Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <Row>
                      <Col md={6} className='mt-2 mb-2'>
                        <Button onClick={() => handleAgregar(producto)} disabled={loading}>
                          {loading ? <Spinner animation='border' size='sm' /> : 'Agregar'}
                        </Button>
                      </Col>
                    </Row>
                  </Card.Footer>
                </Card>
              </Col>
            ))}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='outline-danger' size='lg' onClick={() => setShow(false)}>
            <FontAwesomeIcon icon={faTimes} />
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
