/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCode,
  faFileCode,
  faCogs,
  faPlus,
  faCheck,
  faRefresh,
  faTimes,
  faToolbox,
  faCube,
  faTrash,
  faEdit,
  faEye,
  faCog,
  faIcons,
  faPager,
  faFileCircleCheck,
  faScrewdriverWrench
} from '@fortawesome/free-solid-svg-icons';
import {
  Card,
  Modal,
  Button,
  Offcanvas,
  Dropdown,
  ListGroup,
  Badge,
  FloatingLabel,
  Form,
  Row,
  Col,
  InputGroup
} from 'react-bootstrap';
import { BarraNavegacion } from '../componentes/BarraNavegacion';
import Axios from 'axios';
import Swal from 'sweetalert2';
import { urlEcommerceBackend, urlEcommerceFrontend } from '../recursos/configuracion';
import { obtenerPermiso, reportar } from '../recursos/funcion';
import { useUsuarioContexto } from '../proveedor/usuario';
import { Cargador } from '../componentes/Cargador';
import ScrollCategorias from '../componentes/editor/ScrollCategorias';

export default function Editor() {
  const cuenta = useUsuarioContexto();

  const [vital, setVital] = useState(false);

  const navegar = useNavigate();
  const parametros = useParams();
  const [codigopagina, setCodigoPagina] = useState(0);

  const [datos, setDatos] = useState([]);
  const [elegido, setElegido] = useState({
    nombre: '',
    estado: -1
  });
  const [estructura, setEstructura] = useState([]);

  const [contenidos, setContenidos] = useState({
    portadas: [],
    banners: [],
    sellos: [],
    contadores: [],
    promociones: [],
    productos: [],
    videos: []
  });

  const [promociones, setPromociones] = useState([]);

  const [marcas, setMarcas] = useState([]);

  const [categorias, setCategorias] = useState([]);

  const [posicionS, setPosicionS] = useState(0);

  const [posicionE, setPosicionE] = useState(0);

  const [seleccionPlantilla, setSeleccionPlantilla] = useState(-1);

  const [tipo, setTipo] = useState('');

  const [origenContenido, setOrigenContenido] = useState([]);

  const [tipoContenido, setTipoContenido] = useState([]);

  const [valorContenido, setValorContenido] = useState([]);

  const [cantidadContenido, setCantidadContenido] = useState([]);

  const [accion, setAccion] = useState(1);

  const [actividad, setActividad] = useState(true);

  const [izquierdo, setIzquierdo] = useState(false);

  const cerrarIzquierdo = () => setIzquierdo(false);
  const mostrarIzquierdo = () => setIzquierdo(true);

  const [inferior, setInferior] = useState(false);

  const cerrarInferior = () => setInferior(false);
  const mostrarInferior = () => setInferior(true);

  const [derecho, setDerecho] = useState(false);

  const cerrarDerecho = () => setDerecho(false);
  const mostrarDerecho = () => setDerecho(true);

  const [modalPagina, setModalPagina] = useState(false);

  const cerrarModalPagina = () => setModalPagina(false);
  const mostrarModalPagina = () => setModalPagina(true);

  const [modalNE, setModalNE] = useState(false);

  const cerrarModalNE = () => setModalNE(false);
  const mostrarModalNE = () => setModalNE(true);

  const [modalConfig, setModalConfig] = useState(false);

  const cerrarModalConfig = () => setModalConfig(false);
  const mostrarModalConfig = () => setModalConfig(true);

  const [carga, setCarga] = useState(false);

  const [configDefecto] = useState({
    elemento: {
      titulo: {
        tamano: 20,
        color: '#000000'
      },
      fondo: {
        color: '#f8f9fa'
      },
      borde: {
        tamano: 1,
        redondeo: 5,
        color: '#c3c7cb'
      },
      efecto: {
        comportamiento: {
          animacion: '',
          repeticion: '',
          duracion: '',
          retraso: ''
        },
        entrada: {
          animacion: '',
          repeticion: '',
          duracion: '',
          retraso: ''
        },
        salida: {
          animacion: '',
          repeticion: '',
          duracion: '',
          retraso: ''
        }
      }
    },
    contenido: {
      fondo: {
        color: '#f8f9fa'
      },
      borde: {
        tamano: 1,
        redondeo: 5,
        color: '#c3c7cb'
      },
      texto: {
        tamano: 2,
        color: '#EC650A'
      },
      icono: {
        tamano: 25,
        color: '#f8f9fa'
      },
      sello: {
        tamano: 12
      },
      efecto: {
        comportamiento: {
          animacion: '',
          repeticion: '',
          duracion: '',
          retraso: ''
        },
        entrada: {
          animacion: '',
          repeticion: '',
          duracion: '',
          retraso: ''
        },
        salida: {
          animacion: '',
          repeticion: '',
          duracion: '',
          retraso: ''
        }
      }
    }
  });

  const [configuracion, setConfiguracion] = useState(configDefecto);

  const [listaAnimacionComportamiento] = useState([
    {
      clase: 'animate__bounce',
      nombre: 'Salto'
    },
    {
      clase: 'animate__pulse',
      nombre: 'Pulso'
    },
    {
      clase: 'animate__shakeX',
      nombre: 'Sacudida Horizontal'
    },
    {
      clase: 'animate__shakeY',
      nombre: 'Sacudida Vertical'
    },
    {
      clase: 'animate__headShake',
      nombre: 'Vibración'
    }
  ]);

  const [listaAnimacionEntrada] = useState([
    {
      clase: 'animate__backInUp',
      nombre: 'Subida'
    },

    {
      clase: 'animate__bounceIn',
      nombre: 'Aparición'
    }
  ]);

  const [listaAnimacionSalida] = useState([
    {
      clase: 'animate__backOutDown',
      nombre: 'Bajada'
    },
    {
      clase: 'animate__bounceOut',
      nombre: 'Desaparición'
    }
  ]);

  const [listaRepeticion] = useState([
    {
      clase: 'animate__repeat-1',
      nombre: '1'
    },
    {
      clase: 'animate__repeat-2',
      nombre: '2'
    },
    {
      clase: 'animate__repeat-3',
      nombre: '3'
    },
    {
      clase: 'animate__infinite',
      nombre: 'Infinito'
    }
  ]);

  const [listaDuracion] = useState([
    {
      clase: 'animate__delay-2s',
      nombre: '2 Segundos'
    },
    {
      clase: 'animate__delay-3s',
      nombre: '3 Segundos'
    },
    {
      clase: 'animate__delay-4s',
      nombre: '4 Segundos'
    },
    {
      clase: 'animate__delay-5s',
      nombre: '5 Segundos'
    }
  ]);

  const [listaRetraso] = useState([
    {
      clase: 'animate__slow',
      nombre: 'Lento (2 Segundos)'
    },
    {
      clase: 'animate__slower',
      nombre: 'Medio (3 Segundos)'
    },
    {
      clase: 'animate__fast',
      nombre: 'Rápido (800 MiliSegundos)'
    },
    {
      clase: 'animate__faster',
      nombre: 'Más rápido (500 MiliSegundos)'
    }
  ]);

  const Interfaz = 'editor';

  useEffect(() => {
    if (cuenta.token !== '') {
      setCodigoPagina(parametros.codigo);
    } else {
      setElegido({
        nombre: '',
        estado: -1
      });
    }
  }, [cuenta.token, parametros.codigo]);

  useEffect(() => {
    if (codigopagina === '0') {
      setElegido({
        nombre: '',
        estado: -1
      });
    } else {
      recuperarPagina();
    }
  }, [codigopagina]);

  useEffect(() => {
    if (cuenta.token === '') {
      setVital(true);
    } else {
      setVital(false);
      recuperarPaginas();
      obtenerContenido();
      obtenerPromociones();
      obtenerMarcas();
      obtenerCategorias();
    }
  }, [cuenta]);

  useEffect(() => {
    if (elegido.nombre !== undefined) {
      if (elegido.nombre === '') {
        setActividad(true);
      } else {
        setActividad(false);
      }
    }
    if (elegido.secciones !== undefined) {
      if (elegido.secciones.length === 0) {
        setEstructura([]);
      } else {
        setEstructura(elegido.secciones);
      }
    } else {
      setEstructura([]);
    }
  }, [elegido]);

  useEffect(() => {
    if (tipo !== '') {
      formularioContenidos();
    }
  }, [tipo]);

  useEffect(() => {
    if (tipoContenido.length > 0) {
      cargarContenidos();
    }
  }, [tipoContenido]);

  useEffect(() => {
    if (derecho) {
      formularioContenidos();
    }
  }, [derecho]);

  function elegir(codigo) {
    setCodigoPagina(codigo);
    navegar(`/editor/${codigo}`);
    cerrarIzquierdo();
  }

  async function recuperarPaginas() {
    try {
      let respuesta = await Axios.get(`${urlEcommerceBackend}/paginas`, {
        headers: {
          authorization: `Bearer ${cuenta.token}`
        }
      });
      setDatos(respuesta.data.arrayJson);
    } catch (error) {
      await reportar(
        `${cuenta.nombre} ${cuenta.apellido}`,
        'Ecommerce Admin',
        'Frontend',
        'editor.jsx',
        'recuperarPaginas',
        `${error.toString()}`,
        {
          error
        },
        1
      );
    }
  }

  async function recuperarPagina() {
    setCarga(true);
    try {
      let respuesta = await Axios.get(`${urlEcommerceBackend}/paginas/${codigopagina}?estado=0`, {
        headers: {
          authorization: `Bearer ${cuenta.token}`
        }
      });
      setElegido(respuesta.data.objetoJson);
      //console.log(respuesta.data.objetoJson);
    } catch (error) {
      await reportar(
        `${cuenta.nombre} ${cuenta.apellido}`,
        'Ecommerce Admin',
        'Frontend',
        'editor.jsx',
        'recuperarPagina',
        `${error.toString()}`,
        {
          codigopagina,
          error
        },
        1
      );
    }
    setCarga(false);
  }

  async function obtenerContenido() {
    try {
      let respuesta = await Axios.get(`${urlEcommerceBackend}/recursos/contenido`, {
        headers: {
          authorization: `Bearer ${cuenta.token}`
        }
      });
      setContenidos(respuesta.data.objetoJson);
    } catch (error) {
      await reportar(
        `${cuenta.nombre} ${cuenta.apellido}`,
        'Ecommerce Admin',
        'Frontend',
        'editor.jsx',
        'obtenerContenido',
        `${error.toString()}`,
        {
          error
        },
        1
      );
    }
  }

  async function obtenerPromociones() {
    try {
      let respuesta = await Axios.get(`${urlEcommerceBackend}/consultas/promos?estado=`);
      setPromociones(respuesta.data.arrayJson);
    } catch (error) {
      await reportar(
        `${cuenta.nombre} ${cuenta.apellido}`,
        'Ecommerce Admin',
        'Frontend',
        'editor.jsx',
        'obtenerPromociones',
        `${error.toString()}`,
        {
          error
        },
        1
      );
    }
  }

  async function obtenerMarcas() {
    try {
      let respuesta = await Axios.post(`${urlEcommerceBackend}/recursos/marcas?dato=&categoria=&subcategoria=`);
      setMarcas(respuesta.data.arrayJson);
    } catch (error) {
      await reportar(
        `${cuenta.nombre} ${cuenta.apellido}`,
        'Ecommerce Admin',
        'Frontend',
        'editor.jsx',
        'obtenerMarcas',
        `${error.toString()}`,
        {
          error
        },
        1
      );
    }
  }

  async function obtenerCategorias() {
    try {
      let respuesta = await Axios.get(`${urlEcommerceBackend}/categorias`);
      setCategorias(respuesta.data.arrayJson);
    } catch (error) {
      await reportar(
        `${cuenta.nombre} ${cuenta.apellido}`,
        'Ecommerce Admin',
        'Frontend',
        'editor.jsx',
        'obtenerCategorias',
        `${error.toString()}`,
        {
          error
        },
        1
      );
    }
  }

  function nuevo() {
    setAccion(1);
    mostrarModalPagina();
  }

  function modificar() {
    setAccion(2);
    mostrarModalPagina();
  }

  function actualizar() {
    recuperarPaginas();
    recuperarPagina(parametros.codigo);
  }

  function agregarSeccion() {
    if (elegido.nombre === undefined) {
      Swal.fire('Editor', 'Debe seleccionar una plantilla de Página para continuar', 'warning');
    } else {
      let informacion = [...estructura];
      informacion.push({
        id: informacion.length,
        nombre: '',
        ruta: '',
        tamano: 12,
        descrip: '',
        elementos: []
      });
      setEstructura(informacion);
    }
  }

  function agregarElemento(tipo) {
    if (elegido.nombre === undefined) {
      cerrarInferior();
      Swal.fire('Editor', 'Debe seleccionar una plantilla de Página para continuar', 'warning');
    } else {
      if (tipo === 'Carta Simple') {
        let informacion = [...estructura];
        informacion[posicionS].elementos.push({
          id: informacion[posicionS].elementos.length,
          nombre: '',
          ruta: '',
          tipo: tipo,
          tamano: 12,
          descrip: '',
          configuracion: configDefecto,
          descriptivo: {
            nombre: false,
            contado: false,
            cuota: false
          },
          filtro: {
            origen: '',
            tipo: '',
            valor: '',
            dato: '',
            cantidad: 0
          },
          contenidos: []
        });
        setEstructura(informacion);
      } else if (tipo === 'Carta Multiple') {
        let informacion = [...estructura];
        informacion[posicionS].elementos.push({
          id: informacion[posicionS].elementos.length,
          nombre: '',
          ruta: '',
          tipo: tipo,
          tamano: 12,
          descrip: '',
          configuracion: configDefecto,
          descriptivo: {
            nombre: false,
            contado: false,
            cuota: false
          },
          filtro: {
            origen: '',
            tipo: '',
            valor: '',
            dato: '',
            cantidad: 0
          },
          contenidos: []
        });
        setEstructura(informacion);
      } else if (tipo === 'Videos') {
        let informacion = [...estructura];
        informacion[posicionS].elementos.push({
          id: informacion[posicionS].elementos.length,
          nombre: '',
          ruta: '',
          tipo: tipo,
          tamano: 12,
          descrip: '',
          configuracion: configDefecto,
          descriptivo: {
            nombre: false,
            contado: false,
            cuota: false
          },
          filtro: {
            origen: '',
            tipo: '',
            valor: '',
            dato: '',
            cantidad: 0
          },
          contenidos: []
        });
        setEstructura(informacion);
      } else {
        let informacion = [...estructura];
        informacion[posicionS].elementos.push({
          id: informacion[posicionS].elementos.length,
          nombre: '',
          ruta: '',
          tipo: tipo,
          tamano: 12,
          descrip: '',
          configuracion: configDefecto,
          descriptivo: {
            nombre: false,
            contado: false,
            cuota: false
          },
          filtro: {
            origen: '',
            tipo: '',
            valor: '',
            dato: '',
            cantidad: 0
          },
          contenidos: []
        });
        setEstructura(informacion);
      }
      cerrarInferior();
    }
  }

  function cerrarPagina() {
    cerrarModalPagina();
  }

  async function guardarPagina() {
    if (document.getElementById('txtNombre').value === '') {
      Swal.fire('Atención', 'El campo Nombre esta vacio', 'info');
    } else {
      if (accion === 1) {
        let parametros = {
          nombre: document.getElementById('txtNombre').value
        };
        try {
          let respuesta = await Axios.post(`${urlEcommerceBackend}/paginas`, parametros, {
            headers: {
              authorization: `Bearer ${cuenta.token}`
            }
          });
          document.getElementById('txtNombre').value = '';
          actualizar();
          cerrarModalPagina();
          const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer);
              toast.addEventListener('mouseleave', Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: 'success',
            title: respuesta.data.descripcion
          });
        } catch (error) {
          await reportar(
            `${cuenta.nombre} ${cuenta.apellido}`,
            'Ecommerce Admin',
            'Frontend',
            'editor.jsx',
            'guardarPagina',
            `${error.toString()}`,
            {
              error
            },
            1
          );
        }
      } else if (accion === 2) {
        let parametros = {
          nombre: document.getElementById('txtNombre').value
        };
        try {
          let respuesta = await Axios.put(`${urlEcommerceBackend}/paginas/${elegido.codigo}`, parametros, {
            headers: {
              authorization: `Bearer ${cuenta.token}`
            }
          });
          document.getElementById('txtNombre').value = '';
          actualizar();
          elegir(codigopagina);
          cerrarModalPagina();
          const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer);
              toast.addEventListener('mouseleave', Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: 'success',
            title: respuesta.data.descripcion
          });
        } catch (error) {
          await reportar(
            `${cuenta.nombre} ${cuenta.apellido}`,
            'Ecommerce Admin',
            'Frontend',
            'editor.jsx',
            'guardarPagina',
            `${error.toString()}`,
            {
              error
            },
            1
          );
        }
      }
    }
  }

  function indiceElemento(posicionS, tamano, valor, descripcion, posicionE) {
    if (tamano === 1 && valor === -1) {
    } else if (tamano === 12 && valor === 1) {
    } else {
      let informacion = [...estructura];
      informacion[posicionS].elementos[posicionE].descrip = descripcion;
      informacion[posicionS].elementos[posicionE].tamano = valor;
      setEstructura(informacion);
    }
  }

  function quitarSeccion(idS) {
    Swal.fire({
      title: 'Desea eliminar la Sección?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {
        setEstructura(estructura.filter((a) => a.id !== idS));
      }
    });
  }

  function quitarElemento(posicion, id) {
    Swal.fire({
      title: 'Desea eliminar el Elemento?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {
        let informacion = [...estructura];
        informacion[posicion].elementos = informacion[posicion].elementos.filter((a) => a.id !== id);
        setEstructura(informacion);
      }
    });
  }

  async function aplicar() {
    if (elegido.codigo !== undefined) {
      if (validarSitio(estructura)) {
        let parametros = {
          secciones: estructura
        };
        try {
          let respuesta = await Axios.put(`${urlEcommerceBackend}/paginas/${elegido.codigo}`, parametros, {
            headers: {
              authorization: `Bearer ${cuenta.token}`
            }
          });
          actualizar();
          const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer);
              toast.addEventListener('mouseleave', Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: 'success',
            title: respuesta.data.descripcion
          });
        } catch (error) {
          await reportar(
            `${cuenta.nombre} ${cuenta.apellido}`,
            'Ecommerce Admin',
            'Frontend',
            'editor.jsx',
            'aplicar',
            `${error.toString()}`,
            {
              error
            },
            1
          );
        }
      }
    } else {
      Swal.fire('Editor', 'No se detectan cambios', 'error');
    }
  }

  function validarSitio(sitio) {
    let envio = false;
    let seccionTamano = 0;
    let elementoTamano = 0;
    let elementoInterno = 0;
    sitio.forEach((seccion) => {
      seccionTamano = seccionTamano + seccion.tamano;
      if (seccionTamano === 12) {
        seccionTamano = 0;
      }
      if (seccion.elementos.length === 0) {
        elementoInterno++;
      }
      seccion.elementos.forEach((elemento) => {
        elementoTamano = elementoTamano + elemento.tamano;
        if (elementoTamano === 12) {
          elementoTamano = 0;
        }
      });
    });
    if (seccionTamano > 0) {
      envio = false;
      Swal.fire('Editor', 'Se detectan espacios vacios entre Secciones', 'warning');
    } else if (elementoTamano > 0) {
      envio = false;
      Swal.fire('Editor', 'Se detectan espacios vacios entre Elementos', 'warning');
    } else if (elementoInterno > 0) {
      envio = false;
      Swal.fire('Editor', 'Se detectan Secciones sin Elementos', 'warning');
    } else {
      envio = true;
    }
    return envio;
  }

  function previa() {
    window.open(urlEcommerceFrontend + '/preview/' + codigopagina, '_blank');
  }

  function eliminar() {
    Swal.fire({
      icon: 'question',
      title: 'Esta seguro/a de eliminar esta Página?',
      showDenyButton: true,
      confirmButtonText: 'Si',
      denyButtonText: 'No'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          let respuesta = await Axios.delete(`${urlEcommerceBackend}/paginas/${elegido.codigo}`, {
            headers: {
              authorization: `Bearer ${cuenta.token}`
            }
          });
          setElegido({
            nombre: '',
            estado: -1
          });
          setEstructura([]);
          navegar('/editor/0');
          recuperarPaginas();
          const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer);
              toast.addEventListener('mouseleave', Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: 'success',
            title: respuesta.data.descripcion
          });
        } catch (error) {
          await reportar(
            `${cuenta.nombre} ${cuenta.apellido}`,
            'Ecommerce Admin',
            'Frontend',
            'editor.jsx',
            'eliminar',
            `${error.toString()}`,
            {
              error
            },
            1
          );
        }
      }
    });
  }

  function establecerPrincipal() {
    if (elegido.codigo === undefined) {
      Swal.fire('Editor', 'Seleccione una plantilla para realizar esta acción', 'error');
    } else {
      Swal.fire({
        icon: 'question',
        title: 'Esta seguro/a de habilitar esta página como la principal?',
        showDenyButton: true,
        confirmButtonText: 'Si',
        denyButtonText: 'No'
      }).then(async (result) => {
        if (result.isConfirmed) {
          let parametros = {
            estado: 1
          };
          try {
            let respuesta = await Axios.put(`${urlEcommerceBackend}/paginas/${elegido.codigo}`, parametros, {
              headers: {
                authorization: `Bearer ${cuenta.token}`
              }
            });
            actualizar();
            elegir(elegido.codigo);
            const Toast = Swal.mixin({
              toast: true,
              position: 'bottom-end',
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: 'success',
              title: respuesta.data.descripcion
            });
          } catch (error) {
            await reportar(
              `${cuenta.nombre} ${cuenta.apellido}`,
              'Ecommerce Admin',
              'Frontend',
              'editor.jsx',
              'establecerPrincipal',
              `${error.toString()}`,
              {
                error
              },
              1
            );
          }
        }
      });
    }
  }

  function cambiarNE(posicion1, posicion2) {
    setPosicionS(posicion1);
    setPosicionE(posicion2);
    mostrarModalNE();
  }

  function cerrarNE() {
    cerrarModalNE();
  }

  function guardarNE() {
    estructura[posicionS].elementos[posicionE].nombre = document.getElementById('txtNombreE').value;
    cerrarModalNE();
  }

  const eventoEnterNE = (event) => {
    if (event.key === 'Enter') {
      guardarNE();
    }
  };

  const eventoEnterNP = (event) => {
    if (event.key === 'Enter') {
      guardarPagina();
    }
  };

  function establecerContenido(posicion1, posicion2, tipos) {
    setPosicionS(posicion1);
    setPosicionE(posicion2);
    setTipo(tipos);
    mostrarDerecho();
  }

 async function formularioContenidos() {
    if (tipo === 'Carta Simple') {
      setOrigenContenido(['Producto']);
      setTipoContenido(['Producto', 'Promoción', 'Marca', 'Categoria', 'Subcategoria']);
      setCantidadContenido(['1']);
    } else if (tipo === 'Carta Multiple') {
      setOrigenContenido(['Producto', 'Más vendidos']);
      setTipoContenido(['Promoción', 'Marca', 'Categoria', 'Subcategoria']);
      setCantidadContenido(['4']);
    } else if (tipo === 'Carrusel Regular') {
      setOrigenContenido(['Producto', 'Más vendidos']);
      setTipoContenido(['Promoción', 'Marca', 'Categoria', 'Subcategoria']);
      setCantidadContenido(['6', '7', '8', '9', '10', '11', '12', '13', '14', '15']);
    } else if (tipo === 'Banner') {
      setOrigenContenido(['Banner']);
      setTipoContenido([]);
      setValorContenido([]);
      setCantidadContenido([]);
    } else if (tipo === 'Videos') {
    
      const {data} =  await Axios.get(`${urlEcommerceBackend}/videos`, {
        headers: {
          authorization: `Bearer ${cuenta.token}`
        }
      });
      const nombres = data.arrayJson.map(video => video.nombre)
    
      setOrigenContenido(['Videos']);
      setTipoContenido([]);
      setValorContenido(nombres);
      setCantidadContenido([]);
    }
  }

  function cargarContenidos() {
    if (document.getElementById('cbOrigen').value === 'producto') {
      if (document.getElementById('cbTipo').value === 'marca') {
        setValorContenido(marcas);
      } else if (document.getElementById('cbTipo').value === 'promoción') {
        setValorContenido(promociones);
      } else if (document.getElementById('cbTipo').value === 'categoria') {
        setValorContenido(categorias);
      } else if (document.getElementById('cbTipo').value === 'subcategoria') {
        let subcategorias = [];
        categorias.forEach((categoria) => {
          categoria.subcategoria.forEach((sub) => {
            subcategorias.push(sub);
          });
        });
        setValorContenido(subcategorias);
      } else if (document.getElementById('cbTipo').value === 'producto') {
        setValorContenido(contenidos.productos);
      }
    } else if (document.getElementById('cbOrigen').value === 'promoción') {
      if (document.getElementById('cbTipo').value === 'promoción') {
        setValorContenido(contenidos.promociones);
      }
    } else if (document.getElementById('cbOrigen').value === 'banner') {
      if (document.getElementById('cbTipo').value === 'promoción') {
        setValorContenido(contenidos.banners);
      }
    } else if (document.getElementById('cbOrigen').value === 'videos') {
      if (document.getElementById('cbTipo').value === 'promoción') {
        setValorContenido(contenidos.videos);
      }
    }
  }

  function guardarContenido() {
    estructura[posicionS].elementos[posicionE].filtro.origen = document.getElementById('cbOrigen').value;
    estructura[posicionS].elementos[posicionE].filtro.tipo = document.getElementById('cbTipo').value;
    estructura[posicionS].elementos[posicionE].filtro.valor = document.getElementById('cbValor').value;
    estructura[posicionS].elementos[posicionE].filtro.dato = '';
    estructura[posicionS].elementos[posicionE].filtro.cantidad = document.getElementById('cbCantidad').value;
    cerrarDerecho();
  }

  function descriptivo(tipo, posS, posE) {
    if (tipo === 1) {
      if (estructura[posS].elementos[posE].descriptivo.nombre) {
        estructura[posS].elementos[posE].descriptivo.nombre = false;
      } else {
        estructura[posS].elementos[posE].descriptivo.nombre = true;
      }
    } else if (tipo === 2) {
      if (estructura[posS].elementos[posE].descriptivo.contado) {
        estructura[posS].elementos[posE].descriptivo.contado = false;
      } else {
        estructura[posS].elementos[posE].descriptivo.contado = true;
      }
    } else if (tipo === 3) {
      if (estructura[posS].elementos[posE].descriptivo.cuota) {
        estructura[posS].elementos[posE].descriptivo.cuota = false;
      } else {
        estructura[posS].elementos[posE].descriptivo.cuota = true;
      }
    }
  }

  function configurar(llaveSeccion, llaveElemento) {
    setPosicionS(llaveSeccion);
    setPosicionE(llaveElemento);
    //console.log();
    //setTipoConfig(1);
    setConfiguracion(estructura[llaveSeccion].elementos[llaveElemento].configuracion);
    mostrarModalConfig();
  }

  function cerrarConfig() {
    cerrarModalConfig();
  }

  function guardarConfig() {
    let nuevaEstructura = [...estructura];
    nuevaEstructura[posicionS].elementos[posicionE].configuracion = {
      elemento: {
        titulo: {
          tamano: parseInt(document.getElementById('tituloTamanoElemento').value),
          color: document.getElementById('tituloColorElemento').value
        },
        fondo: {
          color: document.getElementById('fondoColorElemento').value
        },
        borde: {
          tamano: parseInt(document.getElementById('bordeTamanoElemento').value),
          redondeo: parseInt(document.getElementById('bordeRedondeoElemento').value),
          color: document.getElementById('bordeColorElemento').value
        },
        efecto: {
          comportamiento: {
            animacion: document.getElementById('efectoAnimacionComportamientoElemento').value,
            repeticion: document.getElementById('efectoRepeticionComportamientoElemento').value,
            duracion: document.getElementById('efectoDuracionComportamientoElemento').value,
            retraso: document.getElementById('efectoRetrasoComportamientoElemento').value
          },
          entrada: {
            animacion: document.getElementById('efectoAnimacionEntradaElemento').value,
            repeticion: document.getElementById('efectoRepeticionEntradaElemento').value,
            duracion: document.getElementById('efectoDuracionEntradaElemento').value,
            retraso: document.getElementById('efectoDuracionEntradaElemento').value
          },
          salida: {
            animacion: document.getElementById('efectoAnimacionSalidaElemento').value,
            repeticion: document.getElementById('efectoRepeticionSalidaElemento').value,
            duracion: document.getElementById('efectoDuracionSalidaElemento').value,
            retraso: document.getElementById('efectoRetrasoSalidaElemento').value
          }
        }
      },
      contenido: {
        fondo: {
          color: document.getElementById('fondoColorContenido').value
        },
        borde: {
          tamano: parseInt(document.getElementById('bordeTamanoContenido').value),
          redondeo: parseInt(document.getElementById('bordeRedondeoContenido').value),
          color: document.getElementById('bordeColorContenido').value
        },
        texto: {
          tamano: parseInt(document.getElementById('textoTamanoContenido').value),
          color: document.getElementById('textoColorContenido').value
        },
        icono: {
          tamano: parseInt(document.getElementById('iconoTamanoContenido').value),
          color: document.getElementById('iconoColorContenido').value
        },
        sello: {
          tamano: parseInt(document.getElementById('selloTamanoContenido').value)
        },
        efecto: {
          comportamiento: {
            animacion: document.getElementById('efectoAnimacionComportamientoContenido').value,
            repeticion: document.getElementById('efectoRepeticionComportamientoContenido').value,
            duracion: document.getElementById('efectoDuracionComportamientoContenido').value,
            retraso: document.getElementById('efectoRetrasoComportamientoContenido').value
          },
          entrada: {
            animacion: document.getElementById('efectoAnimacionEntradaContenido').value,
            repeticion: document.getElementById('efectoRepeticionEntradaContenido').value,
            duracion: document.getElementById('efectoDuracionEntradaContenido').value,
            retraso: document.getElementById('efectoRetrasoEntradaContenido').value
          },
          salida: {
            animacion: document.getElementById('efectoAnimacionSalidaContenido').value,
            repeticion: document.getElementById('efectoRepeticionSalidaContenido').value,
            duracion: document.getElementById('efectoDuracionSalidaContenido').value,
            retraso: document.getElementById('efectoRetrasoSalidaContenido').value
          }
        }
      }
    };
    setEstructura(nuevaEstructura);
    cerrarModalConfig();
  }

  return (
    <>
      <div className='container-fluid mt-3 mb-3'>
        <div className='row mb-3'>
          <div className='col-md-12'>
            <h1>{carga === true ? <Cargador estado={true} /> : <FontAwesomeIcon icon={faCode} />}Editor</h1>
          </div>
          <div className='col-md-12 mt-2'>
            <BarraNavegacion
              paginas={[
                {
                  direccion: '/principal',
                  nombre: 'Principal'
                },
                {
                  direccion: '/editor/0',
                  nombre: 'Editor'
                }
              ]}
            />
          </div>
          <div className='col-md-12 mt-3 mb-3'>
            <Card border='dark'>
              <Card.Body>
                <Card.Title>
                  <h5>
                    <FontAwesomeIcon icon={faFileCode} />
                    <u>Plantilla:</u>{' '}
                    {elegido.nombre === undefined || elegido.nombre === '' ? 'Seleccione plantilla' : elegido.nombre}
                    {elegido.estado === 1 ? (
                      <Badge bg='success'>
                        <FontAwesomeIcon icon={faCheck} />
                        Principal
                      </Badge>
                    ) : (
                      ''
                    )}
                  </h5>
                </Card.Title>
                <Dropdown>
                  <Dropdown.Toggle variant='outline-dark' id='dropdown-basic'>
                    <FontAwesomeIcon icon={faCogs} />
                    Opciones
                  </Dropdown.Toggle>
                  <Dropdown.Menu variant='dark'>
                    <Dropdown.Item onClick={mostrarIzquierdo} disabled={vital}>
                      <FontAwesomeIcon icon={faFileCode} />
                      Plantillas <Badge bg='primary'>{datos.length}</Badge>
                    </Dropdown.Item>
                    {obtenerPermiso(cuenta.interfaces, Interfaz, 'modificar') ? (
                      <>
                        <Dropdown.Item onClick={agregarSeccion} disabled={actividad}>
                          <FontAwesomeIcon icon={faPager} />
                          Agregar Sección
                        </Dropdown.Item>
                        <Dropdown.Item onClick={modificar} disabled={actividad}>
                          <FontAwesomeIcon icon={faEdit} />
                          Modificar Nombre
                        </Dropdown.Item>
                        <Dropdown.Item onClick={aplicar} disabled={actividad}>
                          <FontAwesomeIcon icon={faCheck} />
                          Aplicar cambios
                        </Dropdown.Item>
                      </>
                    ) : (
                      ''
                    )}
                    <Dropdown.Item onClick={previa} disabled={actividad}>
                      <FontAwesomeIcon icon={faEye} />
                      Vista Previa
                    </Dropdown.Item>
                    {obtenerPermiso(cuenta.interfaces, Interfaz, 'modificar') ? (
                      elegido.estado !== undefined && elegido.estado === 0 ? (
                        <>
                          <Dropdown.Divider></Dropdown.Divider>
                          <Dropdown.Item onClick={establecerPrincipal} disabled={vital}>
                            <FontAwesomeIcon icon={faFileCircleCheck} />
                            Establecer como Principal
                          </Dropdown.Item>
                        </>
                      ) : (
                        ''
                      )
                    ) : (
                      ''
                    )}
                    {obtenerPermiso(cuenta.interfaces, Interfaz, 'eliminar') ? (
                      <>
                        <Dropdown.Divider></Dropdown.Divider>
                        <Dropdown.Item onClick={eliminar} disabled={actividad}>
                          <FontAwesomeIcon icon={faTrash} />
                          Eliminar Página
                        </Dropdown.Item>
                      </>
                    ) : (
                      ''
                    )}
                  </Dropdown.Menu>
                </Dropdown>
                <Row className='mt-2 mb-2'>
                  {estructura.map((seccion, keys) => (
                    <Col md={seccion.tamano} key={keys} className='mb-2 mt-1'>
                      <Card bg='light' border='primary' text='primary'>
                        <Card.Body>
                          <Card.Title>
                            <h6>
                              <FontAwesomeIcon icon={faPager} />
                              <u>
                                Sección {keys + 1} {seccion.descuento !== undefined ? '(Promoción)' : ''}
                              </u>{' '}
                              {seccion.nombre}
                            </h6>
                            {seccion.descuento !== undefined ? (
                              ''
                            ) : (
                              <Dropdown>
                                <Dropdown.Toggle variant='outline-primary'>
                                  <FontAwesomeIcon icon={faCog} />
                                </Dropdown.Toggle>
                                <Dropdown.Menu variant='dark'>
                                  <Dropdown.Item
                                    onClick={() => {
                                      setPosicionS(keys);
                                      mostrarInferior();
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faToolbox} /> Elemento
                                  </Dropdown.Item>
                                  <Dropdown.Divider />
                                  <Dropdown.Item
                                    onClick={() => {
                                      quitarSeccion(seccion.id);
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faTrash} />
                                    Eliminar
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            )}
                          </Card.Title>
                          <Row>
                            {seccion.elementos.map((elemento, keye) => (
                              <Col md={elemento.tamano} key={keye} className='mb-2'>
                                <Card bg='light' border='success' text='success'>
                                  <Card.Body>
                                    <Card.Title>
                                      <h6>
                                        <FontAwesomeIcon icon={faToolbox} />
                                        <u>Elemento {keye + 1}</u>: {elemento.nombre}{' '}
                                        <Badge bg='success'>{elemento.tipo}</Badge>
                                      </h6>
                                      {seccion.descuento !== undefined ? (
                                        ''
                                      ) : (
                                        <Dropdown>
                                          <Dropdown.Toggle variant='outline-success'>
                                            <FontAwesomeIcon icon={faCog} />
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu variant='dark'>
                                            <Dropdown.Item
                                              onClick={() => {
                                                configurar(keys, keye);
                                              }}
                                            >
                                              <FontAwesomeIcon icon={faScrewdriverWrench} /> Configuración
                                            </Dropdown.Item>
                                            <Dropdown.Divider />
                                            <Dropdown.Item
                                              onClick={() => {
                                                establecerContenido(keys, keye, elemento.tipo);
                                              }}
                                            >
                                              <FontAwesomeIcon icon={faIcons} /> Contenido
                                            </Dropdown.Item>
                                            <Dropdown.Divider />
                                            <Dropdown.Item
                                              onClick={() => {
                                                cambiarNE(keys, keye);
                                              }}
                                            >
                                              <FontAwesomeIcon icon={faEdit} /> Modificar Nombre
                                            </Dropdown.Item>
                                            <Dropdown.Divider />
                                            <Dropdown.Divider />
                                            <Dropdown.Item
                                              onClick={() => {
                                                indiceElemento(keys, elemento.tamano, 3, 'Extra Pequeño', keye);
                                              }}
                                            >
                                              <FontAwesomeIcon icon={faCube} size={'2xs'} /> Extra Pequeño
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                              onClick={() => {
                                                indiceElemento(keys, elemento.tamano, 4, 'Pequeño', keye);
                                              }}
                                            >
                                              <FontAwesomeIcon icon={faCube} size={'xs'} />
                                              Pequeño
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                              onClick={() => {
                                                indiceElemento(keys, elemento.tamano, 6, 'Mediano', keye);
                                              }}
                                            >
                                              <FontAwesomeIcon icon={faCube} size={'sm'} />
                                              Mediano
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                              onClick={() => {
                                                indiceElemento(keys, elemento.tamano, 8, 'Grande', keye);
                                              }}
                                            >
                                              <FontAwesomeIcon icon={faCube} />
                                              Grande
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                              onClick={() => {
                                                indiceElemento(keys, elemento.tamano, 9, 'Extra Grande', keye);
                                              }}
                                            >
                                              <FontAwesomeIcon icon={faCube} />
                                              Extra Grande
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                              onClick={() => {
                                                indiceElemento(keys, elemento.tamano, 12, 'Completo', keye);
                                              }}
                                            >
                                              <FontAwesomeIcon icon={faCube} size={'lg'} />
                                              Completo
                                            </Dropdown.Item>
                                            <Dropdown.Divider />
                                            <Dropdown.Item
                                              onClick={() => {
                                                quitarElemento(keys, elemento.id);
                                              }}
                                            >
                                              <FontAwesomeIcon icon={faTrash} />
                                              Eliminar
                                            </Dropdown.Item>
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      )}
                                    </Card.Title>
                                    <Card.Body>
                                      <Row>
                                        <Col md={6}>
                                          {seccion.descuento !== undefined ? (
                                            ''
                                          ) : (
                                            <>
                                              {elemento.filtro.origen === '' ? (
                                                <></>
                                              ) : (
                                                <Card.Text>
                                                  <u>Origen:</u> {elemento.filtro.origen}
                                                </Card.Text>
                                              )}
                                              {elemento.filtro.tipo === '' ? (
                                                <></>
                                              ) : (
                                                <Card.Text>
                                                  <u>Tipo:</u> {elemento.filtro.tipo}
                                                </Card.Text>
                                              )}
                                              {elemento.filtro.valor === '' ? (
                                                <></>
                                              ) : (
                                                <Card.Text>
                                                  <u>Valor:</u> {elemento.filtro.valor}
                                                </Card.Text>
                                              )}
                                            </>
                                          )}
                                          <Card.Text>
                                            <u>Cantidad:</u> {elemento.contenidos.length}/{elemento.filtro.cantidad}{' '}
                                            {parseInt(elemento.contenidos.length) <
                                            parseInt(elemento.filtro.cantidad) ? (
                                              <Badge bg='danger'>
                                                <FontAwesomeIcon icon={faTimes} />
                                              </Badge>
                                            ) : (
                                              <Badge bg='success'>
                                                <FontAwesomeIcon icon={faCheck} />
                                              </Badge>
                                            )}
                                          </Card.Text>
                                        </Col>
                                        {elemento.tipo === 'Banner' ? (
                                          <></>
                                        ) : (
                                          <Col md={6}>
                                            <Form.Check
                                              type='switch'
                                              id={`nombre${keys}${keye}`}
                                              label='Nombre'
                                              defaultChecked={estructura[keys].elementos[keye].descriptivo.nombre}
                                              onChange={() => {
                                                descriptivo(1, keys, keye);
                                              }}
                                            />
                                            <Form.Check
                                              type='switch'
                                              id={`contado${keys}${keye}`}
                                              label='Contado'
                                              defaultChecked={estructura[keys].elementos[keye].descriptivo.contado}
                                              onChange={() => {
                                                descriptivo(2, keys, keye);
                                              }}
                                            />
                                            <Form.Check
                                              type='switch'
                                              id={`cuota${keys}${keye}`}
                                              label='Cuota'
                                              defaultChecked={estructura[keys].elementos[keye].descriptivo.cuota}
                                              onChange={() => {
                                                descriptivo(3, keys, keye);
                                              }}
                                            />
                                          </Col>
                                        )}
                                      </Row>
                                    </Card.Body>
                                  </Card.Body>
                                </Card>
                              </Col>
                            ))}
                          </Row>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
                </Row>
                {!!elegido.nombre && <ScrollCategorias categorias={categorias}  elegido={elegido}/>}
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>

      <Offcanvas
        show={izquierdo}
        onHide={cerrarIzquierdo}
        backdrop='static'
        placement='start'
        className='bg-dark text-white'
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <FontAwesomeIcon icon={faFileCode} />
            Plantillas
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Dropdown>
            <Dropdown.Toggle variant='outline-primary'>
              <FontAwesomeIcon icon={faCogs} />
              Opciones
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {obtenerPermiso(cuenta.interfaces, Interfaz, 'crear') ? (
                <>
                  <Dropdown.Item onClick={nuevo}>
                    <FontAwesomeIcon icon={faPlus} />
                    Nuevo
                  </Dropdown.Item>
                </>
              ) : (
                ''
              )}
              <Dropdown.Item onClick={actualizar}>
                <FontAwesomeIcon icon={faRefresh} />
                Actualizar
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <ListGroup as='ol' numbered className='mt-3 mb-3'>
            {datos.map((dato, key) => (
              <ListGroup.Item
                as='li'
                action
                active={key === seleccionPlantilla}
                key={key}
                onClick={() => {
                  elegir(dato.codigo);
                  setSeleccionPlantilla(key);
                }}
              >
                {dato.nombre}{' '}
                {dato.estado === 1 ? (
                  <Badge bg='success'>
                    <FontAwesomeIcon icon={faCheck} />
                    Principal
                  </Badge>
                ) : (
                  ''
                )}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas
        show={inferior}
        onHide={cerrarInferior}
        backdrop='static'
        placement='bottom'
        className='bg-dark text-white'
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <FontAwesomeIcon icon={faToolbox} />
            Elementos
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Button
            variant='outline-primary'
            onClick={() => {
              agregarElemento('Carta Simple');
            }}
          >
            <FontAwesomeIcon icon={faPlus} />
            Carta Simple
          </Button>
          <Button
            variant='outline-primary'
            onClick={() => {
              agregarElemento('Carta Multiple');
            }}
          >
            <FontAwesomeIcon icon={faPlus} />
            Carta Multiple
          </Button>
          <Button
            variant='outline-primary'
            onClick={() => {
              agregarElemento('Carrusel Regular');
            }}
          >
            <FontAwesomeIcon icon={faPlus} />
            Carrusel Regular
          </Button>
          <Button
            variant='outline-primary'
            onClick={() => {
              agregarElemento('Banner');
            }}
          >
            <FontAwesomeIcon icon={faPlus} />
            Banner
          </Button>
          <Button
            variant='outline-primary'
            onClick={() => {
              agregarElemento('Videos');
            }}
          >
            <FontAwesomeIcon icon={faPlus} />
            Videos
          </Button>
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas show={derecho} onHide={cerrarDerecho} backdrop='static' placement='end' className='bg-dark text-white'>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            {/* checkpoint */}
            <FontAwesomeIcon icon={faIcons} />
            Contenido
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Row>
            <Col md={12}>
              <FloatingLabel controlId='cbOrigen' label='Origen' className='text-dark mb-2'>
                <Form.Select aria-label='Origen' disabled={false}>
                  {origenContenido.map((oc, llave) => (
                    <option value={oc.toLowerCase()} key={llave}>
                      {oc}
                    </option>
                  ))}
                </Form.Select>
              </FloatingLabel>

              <FloatingLabel controlId='cbTipo' label='Tipo' className='text-dark mb-2'>
                <Form.Select aria-label='Tipo' disabled={false} onChange={cargarContenidos}>
                  {tipoContenido.map((tc, llave) => (
                    <option value={tc.toLowerCase()} key={llave}>
                      {tc}
                    </option>
                  ))}
                </Form.Select>
              </FloatingLabel>

              <FloatingLabel controlId='cbValor' label='Valor' className='text-dark mb-2'>
                <Form.Select aria-label='Valor' disabled={false}>
                  {valorContenido.map((vc, llave) => (
                    <option value={vc.ruta === undefined ? vc : vc.ruta} key={llave}>
                      {vc.nombre === undefined ? vc : vc.nombre}
                    </option>
                  ))}
                </Form.Select>
              </FloatingLabel>

              <FloatingLabel controlId='cbCantidad' label='Cantidad' className='text-dark mb-2'>
                <Form.Select aria-label='Cantidad' disabled={false}>
                  {cantidadContenido.map((cc, llave) => (
                    <option value={cc.toLowerCase()} key={llave}>
                      {cc}
                    </option>
                  ))}
                </Form.Select>
              </FloatingLabel>

              <div className='d-grid gap-2'>
                <Button variant='outline-primary' size='lg' onClick={guardarContenido}>
                  <FontAwesomeIcon icon={faCheck} />
                  Guardar
                </Button>
              </div>
            </Col>
          </Row>
        </Offcanvas.Body>
      </Offcanvas>

      <Modal show={modalPagina} onHide={cerrarModalPagina} backdrop='static' keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faFileCode} />
            Página
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FloatingLabel controlId='txtNombre' label='Nombre' onKeyUp={eventoEnterNP}>
            <Form.Control type='text' placeholder='Nombre' defaultValue={''} />
          </FloatingLabel>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='outline-secondary' onClick={cerrarPagina} size='lg'>
            <FontAwesomeIcon icon={faTimes} />
            Cerrar
          </Button>
          <Button variant='outline-primary' onClick={guardarPagina} size='lg'>
            <FontAwesomeIcon icon={faCheck} />
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={modalNE} onHide={cerrarModalNE} backdrop='static' keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faToolbox} />
            Elemento
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FloatingLabel controlId='txtNombreE' label='Nombre' onKeyUp={eventoEnterNE}>
            <Form.Control type='text' placeholder='Nombre' />
          </FloatingLabel>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='outline-secondary' onClick={cerrarNE} size='lg'>
            <FontAwesomeIcon icon={faTimes} />
            Cerrar
          </Button>
          <Button variant='outline-primary' onClick={guardarNE} size='lg'>
            <FontAwesomeIcon icon={faCheck} />
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={modalConfig} onHide={cerrarModalConfig} backdrop='static' keyboard={false} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faScrewdriverWrench} />
            Configuración
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={12}>
              <Card border='dark' className='mt-2 mb-2'>
                <Card.Body>
                  <Card.Title>
                    <FontAwesomeIcon icon={faToolbox} />
                    Elemento
                  </Card.Title>
                  <Row>
                    <Col md={12}>
                      <Card className='mt-2 mb-2'>
                        <Card.Body>
                          <Card.Title>
                            <center>Titulo</center>
                          </Card.Title>
                          <InputGroup size='sm' className='mb-3'>
                            <InputGroup.Text>Tamaño</InputGroup.Text>
                            <Form.Select id='tituloTamanoElemento' defaultValue={configuracion.elemento.titulo.tamano}>
                              <option value='1'>1</option>
                              <option value='2'>2</option>
                              <option value='3'>3</option>
                              <option value='4'>4</option>
                              <option value='5'>5</option>
                              <option value='6'>6</option>
                              <option value='7'>7</option>
                              <option value='8'>8</option>
                              <option value='9'>9</option>
                              <option value='10'>10</option>
                              <option value='11'>11</option>
                              <option value='12'>12</option>
                              <option value='13'>13</option>
                              <option value='14'>14</option>
                              <option value='15'>15</option>
                              <option value='16'>16</option>
                              <option value='17'>17</option>
                              <option value='18'>18</option>
                              <option value='19'>19</option>
                              <option value='20'>20</option>
                              <option value='21'>21</option>
                              <option value='22'>22</option>
                              <option value='23'>23</option>
                              <option value='24'>24</option>
                              <option value='25'>25</option>
                              <option value='26'>26</option>
                              <option value='27'>27</option>
                              <option value='28'>28</option>
                              <option value='29'>29</option>
                              <option value='30'>30</option>
                            </Form.Select>
                            <Button
                              variant='outline-secondary'
                              onClick={() => {
                                document.getElementById('tituloTamanoElemento').value =
                                  configDefecto.elemento.titulo.tamano;
                              }}
                            >
                              <FontAwesomeIcon icon={faRefresh} />
                              Reestablecer
                            </Button>
                          </InputGroup>
                          <InputGroup size='sm'>
                            <InputGroup.Text>Color</InputGroup.Text>
                            <Form.Control
                              type='color'
                              id='tituloColorElemento'
                              defaultValue={
                                configuracion.elemento.titulo.color === ''
                                  ? '#EC650A'
                                  : configuracion.elemento.titulo.color
                              }
                              title='Selector de color'
                            />
                            <Button
                              variant='outline-secondary'
                              onClick={() => {
                                document.getElementById('tituloColorElemento').value =
                                  configDefecto.elemento.titulo.color;
                              }}
                            >
                              <FontAwesomeIcon icon={faRefresh} />
                              Reestablecer
                            </Button>
                          </InputGroup>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col md={12}>
                      <Card className='mt-2 mb-2'>
                        <Card.Body>
                          <Card.Title>
                            <center>Fondo</center>
                          </Card.Title>
                          <InputGroup size='sm'>
                            <InputGroup.Text>Color</InputGroup.Text>
                            <Form.Control
                              type='color'
                              id='fondoColorElemento'
                              defaultValue={
                                configuracion.elemento.fondo.color === ''
                                  ? '#EC650A'
                                  : configuracion.elemento.fondo.color
                              }
                              title='Selector de color'
                            />
                            <Button
                              variant='outline-secondary'
                              onClick={() => {
                                document.getElementById('fondoColorElemento').value =
                                  configDefecto.elemento.fondo.color;
                              }}
                            >
                              <FontAwesomeIcon icon={faRefresh} />
                              Reestablecer
                            </Button>
                          </InputGroup>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col md={12}>
                      <Card className='mt-2 mb-2'>
                        <Card.Body>
                          <Card.Title>
                            <center>Borde</center>
                          </Card.Title>
                          <InputGroup size='sm' className='mb-3'>
                            <InputGroup.Text>Grosor</InputGroup.Text>
                            <Form.Select id='bordeTamanoElemento' defaultValue={configuracion.elemento.borde.tamano}>
                              <option value='0'>0</option>
                              <option value='1'>1</option>
                              <option value='2'>2</option>
                              <option value='3'>3</option>
                              <option value='4'>4</option>
                              <option value='5'>5</option>
                              <option value='6'>6</option>
                              <option value='7'>7</option>
                              <option value='8'>8</option>
                              <option value='9'>9</option>
                              <option value='10'>10</option>
                              <option value='11'>11</option>
                              <option value='12'>12</option>
                              <option value='13'>13</option>
                              <option value='14'>14</option>
                              <option value='15'>15</option>
                            </Form.Select>
                            <Button
                              variant='outline-secondary'
                              onClick={() => {
                                document.getElementById('bordeTamanoElemento').value =
                                  configDefecto.elemento.borde.tamano;
                              }}
                            >
                              <FontAwesomeIcon icon={faRefresh} />
                              Reestablecer
                            </Button>
                          </InputGroup>
                          <InputGroup size='sm' className='mb-3'>
                            <InputGroup.Text>Redondeo</InputGroup.Text>
                            <Form.Select
                              id='bordeRedondeoElemento'
                              defaultValue={configuracion.elemento.borde.redondeo}
                            >
                              <option value='0'>0</option>
                              <option value='1'>1</option>
                              <option value='2'>2</option>
                              <option value='3'>3</option>
                              <option value='4'>4</option>
                              <option value='5'>5</option>
                            </Form.Select>
                            <Button
                              variant='outline-secondary'
                              onClick={() => {
                                document.getElementById('bordeRedondeoElemento').value =
                                  configDefecto.elemento.borde.redondeo;
                              }}
                            >
                              <FontAwesomeIcon icon={faRefresh} />
                              Reestablecer
                            </Button>
                          </InputGroup>
                          <InputGroup size='sm' className='mb-3'>
                            <InputGroup.Text>Color</InputGroup.Text>
                            <Form.Control
                              type='color'
                              id='bordeColorElemento'
                              defaultValue={
                                configuracion.elemento.borde.color === ''
                                  ? '#EC650A'
                                  : configuracion.elemento.borde.color
                              }
                              title='Selector de color'
                            />
                            <Button
                              variant='outline-secondary'
                              onClick={() => {
                                document.getElementById('bordeColorElemento').value =
                                  configDefecto.elemento.fondo.color;
                              }}
                            >
                              <FontAwesomeIcon icon={faRefresh} />
                              Reestablecer
                            </Button>
                          </InputGroup>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col md={12}>
                      <Card className='mt-2 mb-2'>
                        <Card.Body>
                          <Card.Title>
                            <center>Efectos</center>
                          </Card.Title>
                          <Row>
                            <Col md={12}>
                              <Card className='mt-2 mb-2'>
                                <Card.Body>
                                  <Card.Title>
                                    <center>Comportamiento</center>
                                  </Card.Title>
                                  <Row>
                                    <Col md={12}>
                                      <InputGroup size='sm' className='mb-3'>
                                        <InputGroup.Text>Animación</InputGroup.Text>
                                        <Form.Select
                                          id='efectoAnimacionComportamientoElemento'
                                          defaultValue={configuracion.elemento.efecto.comportamiento.animacion}
                                        >
                                          <option value=''>Sin definir</option>
                                          {listaAnimacionComportamiento.map((animacion, llave) => (
                                            <option value={animacion.clase} key={llave}>
                                              {animacion.nombre}
                                            </option>
                                          ))}
                                        </Form.Select>
                                        <Button
                                          variant='outline-secondary'
                                          onClick={() => {
                                            document.getElementById('efectoAnimacionComportamientoElemento').value =
                                              configDefecto.elemento.efecto.comportamiento.animacion;
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faRefresh} />
                                          Reestablecer
                                        </Button>
                                      </InputGroup>
                                    </Col>
                                    <Col md={12}>
                                      <InputGroup size='sm' className='mb-3'>
                                        <InputGroup.Text>Repetición</InputGroup.Text>
                                        <Form.Select
                                          id='efectoRepeticionComportamientoElemento'
                                          defaultValue={configuracion.elemento.efecto.comportamiento.repeticion}
                                        >
                                          <option value=''>Sin definir</option>
                                          {listaRepeticion.map((repeticion, llave) => (
                                            <option value={repeticion.clase} key={llave}>
                                              {repeticion.nombre}
                                            </option>
                                          ))}
                                        </Form.Select>
                                        <Button
                                          variant='outline-secondary'
                                          onClick={() => {
                                            document.getElementById('efectoRepeticionComportamientoElemento').value =
                                              configDefecto.elemento.efecto.comportamiento.repeticion;
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faRefresh} />
                                          Reestablecer
                                        </Button>
                                      </InputGroup>
                                    </Col>
                                    <Col md={12}>
                                      <InputGroup size='sm' className='mb-3'>
                                        <InputGroup.Text>Duración</InputGroup.Text>
                                        <Form.Select
                                          id='efectoDuracionComportamientoElemento'
                                          defaultValue={configuracion.elemento.efecto.comportamiento.duracion}
                                        >
                                          <option value=''>Sin definir</option>
                                          {listaDuracion.map((duracion, llave) => (
                                            <option value={duracion.clase} key={llave}>
                                              {duracion.nombre}
                                            </option>
                                          ))}
                                        </Form.Select>
                                        <Button
                                          variant='outline-secondary'
                                          onClick={() => {
                                            document.getElementById('efectoDuracionComportamientoElemento').value =
                                              configDefecto.elemento.efecto.comportamiento.duracion;
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faRefresh} />
                                          Reestablecer
                                        </Button>
                                      </InputGroup>
                                    </Col>
                                    <Col md={12}>
                                      <InputGroup size='sm' className='mb-3'>
                                        <InputGroup.Text>Retraso</InputGroup.Text>
                                        <Form.Select
                                          id='efectoRetrasoComportamientoElemento'
                                          defaultValue={configuracion.elemento.efecto.comportamiento.retraso}
                                        >
                                          <option value=''>Sin definir</option>
                                          {listaRetraso.map((retraso, llave) => (
                                            <option value={retraso.clase} key={llave}>
                                              {retraso.nombre}
                                            </option>
                                          ))}
                                        </Form.Select>
                                        <Button
                                          variant='outline-secondary'
                                          onClick={() => {
                                            document.getElementById('efectoRetrasoComportamientoElemento').value =
                                              configDefecto.elemento.efecto.comportamiento.retraso;
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faRefresh} />
                                          Reestablecer
                                        </Button>
                                      </InputGroup>
                                    </Col>
                                  </Row>
                                </Card.Body>
                              </Card>
                            </Col>
                            <Col md={12}>
                              <Card className='mt-2 mb-2'>
                                <Card.Body>
                                  <Card.Title>
                                    <center>Entrada</center>
                                  </Card.Title>
                                  <Row>
                                    <Col md={12}>
                                      <InputGroup size='sm' className='mb-3'>
                                        <InputGroup.Text>Animación</InputGroup.Text>
                                        <Form.Select
                                          id='efectoAnimacionEntradaElemento'
                                          defaultValue={configuracion.elemento.efecto.entrada.animacion}
                                        >
                                          <option value=''>Sin definir</option>
                                          {listaAnimacionEntrada.map((animacion, llave) => (
                                            <option value={animacion.clase} key={llave}>
                                              {animacion.nombre}
                                            </option>
                                          ))}
                                        </Form.Select>
                                        <Button
                                          variant='outline-secondary'
                                          onClick={() => {
                                            document.getElementById('efectoAnimacionEntradaElemento').value =
                                              configDefecto.elemento.efecto.entrada.animacion;
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faRefresh} />
                                          Reestablecer
                                        </Button>
                                      </InputGroup>
                                    </Col>
                                    <Col md={12}>
                                      <InputGroup size='sm' className='mb-3'>
                                        <InputGroup.Text>Repetición</InputGroup.Text>
                                        <Form.Select
                                          id='efectoRepeticionEntradaElemento'
                                          defaultValue={configuracion.elemento.efecto.entrada.repeticion}
                                        >
                                          <option value=''>Sin definir</option>
                                          {listaRepeticion.map((repeticion, llave) => (
                                            <option value={repeticion.clase} key={llave}>
                                              {repeticion.nombre}
                                            </option>
                                          ))}
                                        </Form.Select>
                                        <Button
                                          variant='outline-secondary'
                                          onClick={() => {
                                            document.getElementById('efectoRepeticionEntradaElemento').value =
                                              configDefecto.elemento.efecto.entrada.repeticion;
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faRefresh} />
                                          Reestablecer
                                        </Button>
                                      </InputGroup>
                                    </Col>
                                    <Col md={12}>
                                      <InputGroup size='sm' className='mb-3'>
                                        <InputGroup.Text>Duración</InputGroup.Text>
                                        <Form.Select
                                          id='efectoDuracionEntradaElemento'
                                          defaultValue={configuracion.elemento.efecto.entrada.duracion}
                                        >
                                          <option value=''>Sin definir</option>
                                          {listaDuracion.map((duracion, llave) => (
                                            <option value={duracion.clase} key={llave}>
                                              {duracion.nombre}
                                            </option>
                                          ))}
                                        </Form.Select>
                                        <Button
                                          variant='outline-secondary'
                                          onClick={() => {
                                            document.getElementById('efectoDuracionEntradaElemento').value =
                                              configDefecto.elemento.efecto.entrada.duracion;
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faRefresh} />
                                          Reestablecer
                                        </Button>
                                      </InputGroup>
                                    </Col>
                                    <Col md={12}>
                                      <InputGroup size='sm' className='mb-3'>
                                        <InputGroup.Text>Retraso</InputGroup.Text>
                                        <Form.Select
                                          id='efectoRetrasoEntradaElemento'
                                          defaultValue={configuracion.elemento.efecto.entrada.retraso}
                                        >
                                          <option value=''>Sin definir</option>
                                          {listaRetraso.map((retraso, llave) => (
                                            <option value={retraso.clase} key={llave}>
                                              {retraso.nombre}
                                            </option>
                                          ))}
                                        </Form.Select>
                                        <Button
                                          variant='outline-secondary'
                                          onClick={() => {
                                            document.getElementById('efectoRetrasoEntradaElemento').value =
                                              configDefecto.elemento.efecto.entrada.retraso;
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faRefresh} />
                                          Reestablecer
                                        </Button>
                                      </InputGroup>
                                    </Col>
                                  </Row>
                                </Card.Body>
                              </Card>
                            </Col>
                            <Col md={12}>
                              <Card className='mt-2 mb-2'>
                                <Card.Body>
                                  <Card.Title>
                                    <center>Salida</center>
                                  </Card.Title>
                                  <Row>
                                    <Col md={12}>
                                      <InputGroup size='sm' className='mb-3'>
                                        <InputGroup.Text>Animación</InputGroup.Text>
                                        <Form.Select
                                          id='efectoAnimacionSalidaElemento'
                                          defaultValue={configuracion.elemento.efecto.salida.animacion}
                                        >
                                          <option value=''>Sin definir</option>
                                          {listaAnimacionSalida.map((animacion, llave) => (
                                            <option value={animacion.clase} key={llave}>
                                              {animacion.nombre}
                                            </option>
                                          ))}
                                        </Form.Select>
                                        <Button
                                          variant='outline-secondary'
                                          onClick={() => {
                                            document.getElementById('efectoAnimacionSalidaElemento').value =
                                              configDefecto.elemento.efecto.salida.animacion;
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faRefresh} />
                                          Reestablecer
                                        </Button>
                                      </InputGroup>
                                    </Col>
                                    <Col md={12}>
                                      <InputGroup size='sm' className='mb-3'>
                                        <InputGroup.Text>Repetición</InputGroup.Text>
                                        <Form.Select
                                          id='efectoRepeticionSalidaElemento'
                                          defaultValue={configuracion.elemento.efecto.salida.repeticion}
                                        >
                                          <option value=''>Sin definir</option>
                                          {listaRepeticion.map((repeticion, llave) => (
                                            <option value={repeticion.clase} key={llave}>
                                              {repeticion.nombre}
                                            </option>
                                          ))}
                                        </Form.Select>
                                        <Button
                                          variant='outline-secondary'
                                          onClick={() => {
                                            document.getElementById('efectoRepeticionSalidaElemento').value =
                                              configDefecto.elemento.efecto.salida.repeticion;
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faRefresh} />
                                          Reestablecer
                                        </Button>
                                      </InputGroup>
                                    </Col>
                                    <Col md={12}>
                                      <InputGroup size='sm' className='mb-3'>
                                        <InputGroup.Text>Duración</InputGroup.Text>
                                        <Form.Select
                                          id='efectoDuracionSalidaElemento'
                                          defaultValue={configuracion.elemento.efecto.salida.duracion}
                                        >
                                          <option value=''>Sin definir</option>
                                          {listaDuracion.map((duracion, llave) => (
                                            <option value={duracion.clase} key={llave}>
                                              {duracion.nombre}
                                            </option>
                                          ))}
                                        </Form.Select>
                                        <Button
                                          variant='outline-secondary'
                                          onClick={() => {
                                            document.getElementById('efectoDuracionSalidaElemento').value =
                                              configDefecto.elemento.efecto.salida.duracion;
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faRefresh} />
                                          Reestablecer
                                        </Button>
                                      </InputGroup>
                                    </Col>
                                    <Col md={12}>
                                      <InputGroup size='sm' className='mb-3'>
                                        <InputGroup.Text>Retraso</InputGroup.Text>
                                        <Form.Select
                                          id='efectoRetrasoSalidaElemento'
                                          defaultValue={configuracion.elemento.efecto.salida.retraso}
                                        >
                                          <option value=''>Sin definir</option>
                                          {listaRetraso.map((retraso, llave) => (
                                            <option value={retraso.clase} key={llave}>
                                              {retraso.nombre}
                                            </option>
                                          ))}
                                        </Form.Select>
                                        <Button
                                          variant='outline-secondary'
                                          onClick={() => {
                                            document.getElementById('efectoRetrasoSalidaElemento').value =
                                              configDefecto.elemento.efecto.salida.retraso;
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faRefresh} />
                                          Reestablecer
                                        </Button>
                                      </InputGroup>
                                    </Col>
                                  </Row>
                                </Card.Body>
                              </Card>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>

            <Col md={12}>
              <Card border='dark' className='mt-2 mb-2'>
                <Card.Body>
                  <Card.Title>
                    <FontAwesomeIcon icon={faIcons} />
                    Contenido
                  </Card.Title>
                  <Row>
                    <Col md={12}>
                      <Card className='mt-2 mb-2'>
                        <Card.Body>
                          <Card.Title>
                            <center>Fondo</center>
                          </Card.Title>
                          <InputGroup size='sm'>
                            <InputGroup.Text>Color</InputGroup.Text>
                            <Form.Control
                              type='color'
                              id='fondoColorContenido'
                              defaultValue={
                                configuracion.contenido.fondo.color === ''
                                  ? '#EC650A'
                                  : configuracion.contenido.fondo.color
                              }
                              title='Selector de color'
                            />
                            <Button
                              variant='outline-secondary'
                              onClick={() => {
                                document.getElementById('fondoColorContenido').value =
                                  configDefecto.contenido.fondo.color;
                              }}
                            >
                              <FontAwesomeIcon icon={faRefresh} />
                              Reestablecer
                            </Button>
                          </InputGroup>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col md={12}>
                      <Card className='mt-2 mb-2'>
                        <Card.Body>
                          <Card.Title>
                            <center>Borde</center>
                          </Card.Title>
                          <InputGroup size='sm' className='mb-3'>
                            <InputGroup.Text>Grosor</InputGroup.Text>
                            <Form.Select id='bordeTamanoContenido' defaultValue={configuracion.contenido.borde.tamano}>
                              <option value='0'>0</option>
                              <option value='1'>1</option>
                              <option value='2'>2</option>
                              <option value='3'>3</option>
                              <option value='4'>4</option>
                              <option value='5'>5</option>
                              <option value='6'>6</option>
                              <option value='7'>7</option>
                              <option value='8'>8</option>
                              <option value='9'>9</option>
                              <option value='10'>10</option>
                              <option value='11'>11</option>
                              <option value='12'>12</option>
                              <option value='13'>13</option>
                              <option value='14'>14</option>
                              <option value='15'>15</option>
                            </Form.Select>
                            <Button
                              variant='outline-secondary'
                              onClick={() => {
                                document.getElementById('bordeTamanoContenido').value =
                                  configDefecto.contenido.borde.tamano;
                              }}
                            >
                              <FontAwesomeIcon icon={faRefresh} />
                              Reestablecer
                            </Button>
                          </InputGroup>
                          <InputGroup size='sm' className='mb-3'>
                            <InputGroup.Text>Redondeo</InputGroup.Text>
                            <Form.Select
                              id='bordeRedondeoContenido'
                              defaultValue={configuracion.contenido.borde.redondeo}
                            >
                              <option value='0'>0</option>
                              <option value='1'>1</option>
                              <option value='2'>2</option>
                              <option value='3'>3</option>
                              <option value='4'>4</option>
                              <option value='5'>5</option>
                            </Form.Select>
                            <Button
                              variant='outline-secondary'
                              onClick={() => {
                                document.getElementById('bordeRedondeoContenido').value =
                                  configDefecto.contenido.borde.redondeo;
                              }}
                            >
                              <FontAwesomeIcon icon={faRefresh} />
                              Reestablecer
                            </Button>
                          </InputGroup>
                          <InputGroup size='sm' className='mb-3'>
                            <InputGroup.Text>Color</InputGroup.Text>
                            <Form.Control
                              type='color'
                              id='bordeColorContenido'
                              defaultValue={
                                configuracion.contenido.borde.color === ''
                                  ? '#EC650A'
                                  : configuracion.contenido.borde.color
                              }
                              title='Selector de color'
                            />
                            <Button
                              variant='outline-secondary'
                              onClick={() => {
                                document.getElementById('bordeColorContenido').value =
                                  configDefecto.contenido.borde.color;
                              }}
                            >
                              <FontAwesomeIcon icon={faRefresh} />
                              Reestablecer
                            </Button>
                          </InputGroup>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col md={12}>
                      <Card className='mt-2 mb-2'>
                        <Card.Body>
                          <Card.Title>
                            <center>Texto</center>
                          </Card.Title>
                          <InputGroup size='sm' className='mb-3'>
                            <InputGroup.Text>Tamaño</InputGroup.Text>
                            <Form.Select id='textoTamanoContenido' defaultValue={configuracion.contenido.texto.tamano}>
                              <option value='1'>1</option>
                              <option value='2'>2</option>
                              <option value='3'>3</option>
                              <option value='4'>4</option>
                              <option value='5'>5</option>
                              <option value='6'>6</option>
                              <option value='7'>7</option>
                              <option value='8'>8</option>
                              <option value='9'>9</option>
                              <option value='10'>10</option>
                              <option value='11'>11</option>
                              <option value='12'>12</option>
                              <option value='13'>13</option>
                              <option value='14'>14</option>
                              <option value='15'>15</option>
                              <option value='16'>16</option>
                              <option value='17'>17</option>
                              <option value='18'>18</option>
                              <option value='19'>19</option>
                              <option value='20'>20</option>
                              <option value='21'>21</option>
                              <option value='22'>22</option>
                              <option value='23'>23</option>
                              <option value='24'>24</option>
                              <option value='25'>25</option>
                              <option value='26'>26</option>
                              <option value='27'>27</option>
                              <option value='28'>28</option>
                              <option value='29'>29</option>
                              <option value='30'>30</option>
                            </Form.Select>
                            <Button
                              variant='outline-secondary'
                              onClick={() => {
                                document.getElementById('textoTamanoContenido').value =
                                  configDefecto.contenido.texto.tamano;
                              }}
                            >
                              <FontAwesomeIcon icon={faRefresh} />
                              Reestablecer
                            </Button>
                          </InputGroup>
                          <InputGroup size='sm'>
                            <InputGroup.Text>Color</InputGroup.Text>
                            <Form.Control
                              type='color'
                              id='textoColorContenido'
                              defaultValue={
                                configuracion.contenido.texto.color === ''
                                  ? '#EC650A'
                                  : configuracion.contenido.texto.color
                              }
                              title='Selector de color'
                            />
                            <Button
                              variant='outline-secondary'
                              onClick={() => {
                                document.getElementById('textoColorContenido').value =
                                  configDefecto.contenido.texto.color;
                              }}
                            >
                              <FontAwesomeIcon icon={faRefresh} />
                              Reestablecer
                            </Button>
                          </InputGroup>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col md={12}>
                      <Card className='mt-2 mb-2'>
                        <Card.Body>
                          <Card.Title>
                            <center>Icono</center>
                          </Card.Title>
                          <InputGroup size='sm' className='mb-3'>
                            <InputGroup.Text>Tamaño</InputGroup.Text>
                            <Form.Select id='iconoTamanoContenido' defaultValue={configuracion.contenido.icono.tamano}>
                              <option value='1'>1</option>
                              <option value='2'>2</option>
                              <option value='3'>3</option>
                              <option value='4'>4</option>
                              <option value='5'>5</option>
                              <option value='6'>6</option>
                              <option value='7'>7</option>
                              <option value='8'>8</option>
                              <option value='9'>9</option>
                              <option value='10'>10</option>
                              <option value='11'>11</option>
                              <option value='12'>12</option>
                              <option value='13'>13</option>
                              <option value='14'>14</option>
                              <option value='15'>15</option>
                              <option value='16'>16</option>
                              <option value='17'>17</option>
                              <option value='18'>18</option>
                              <option value='19'>19</option>
                              <option value='20'>20</option>
                              <option value='21'>21</option>
                              <option value='22'>22</option>
                              <option value='23'>23</option>
                              <option value='24'>24</option>
                              <option value='25'>25</option>
                              <option value='26'>26</option>
                              <option value='27'>27</option>
                              <option value='28'>28</option>
                              <option value='29'>29</option>
                              <option value='30'>30</option>
                            </Form.Select>
                            <Button
                              variant='outline-secondary'
                              onClick={() => {
                                document.getElementById('iconoTamanoContenido').value =
                                  configDefecto.contenido.icono.tamano;
                              }}
                            >
                              <FontAwesomeIcon icon={faRefresh} />
                              Reestablecer
                            </Button>
                          </InputGroup>
                          <InputGroup size='sm'>
                            <InputGroup.Text>Color</InputGroup.Text>
                            <Form.Control
                              type='color'
                              id='iconoColorContenido'
                              defaultValue={
                                configuracion.contenido.icono.color === ''
                                  ? '#EC650A'
                                  : configuracion.contenido.icono.color
                              }
                              title='Selector de color'
                            />
                            <Button
                              variant='outline-secondary'
                              onClick={() => {
                                document.getElementById('iconoColorContenido').value =
                                  configDefecto.contenido.icono.color;
                              }}
                            >
                              <FontAwesomeIcon icon={faRefresh} />
                              Reestablecer
                            </Button>
                          </InputGroup>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col md={12}>
                      <Card className='mt-2 mb-2'>
                        <Card.Body>
                          <Card.Title>
                            <center>Sello</center>
                          </Card.Title>
                          <InputGroup size='sm' className='mb-3'>
                            <InputGroup.Text>Tamaño</InputGroup.Text>
                            <Form.Select id='selloTamanoContenido' defaultValue={configuracion.contenido.sello.tamano}>
                              <option value='1'>1</option>
                              <option value='2'>2</option>
                              <option value='3'>3</option>
                              <option value='4'>4</option>
                              <option value='5'>5</option>
                              <option value='6'>6</option>
                              <option value='7'>7</option>
                              <option value='8'>8</option>
                              <option value='9'>9</option>
                              <option value='10'>10</option>
                              <option value='11'>11</option>
                              <option value='12'>12</option>
                              <option value='13'>13</option>
                              <option value='14'>14</option>
                              <option value='15'>15</option>
                              <option value='16'>16</option>
                              <option value='17'>17</option>
                              <option value='18'>18</option>
                              <option value='19'>19</option>
                              <option value='20'>20</option>
                              <option value='21'>21</option>
                              <option value='22'>22</option>
                              <option value='23'>23</option>
                              <option value='24'>24</option>
                              <option value='25'>25</option>
                              <option value='26'>26</option>
                              <option value='27'>27</option>
                              <option value='28'>28</option>
                              <option value='29'>29</option>
                              <option value='30'>30</option>
                            </Form.Select>
                            <Button
                              variant='outline-secondary'
                              onClick={() => {
                                document.getElementById('selloTamanoContenido').value =
                                  configDefecto.contenido.sello.tamano;
                              }}
                            >
                              <FontAwesomeIcon icon={faRefresh} />
                              Reestablecer
                            </Button>
                          </InputGroup>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col md={12}>
                      <Card className='mt-2 mb-2'>
                        <Card.Body>
                          <Card.Title>
                            <center>Efectos</center>
                          </Card.Title>
                          <Row>
                            <Col md={12}>
                              <Card className='mt-2 mb-2'>
                                <Card.Body>
                                  <Card.Title>
                                    <center>Comportamiento</center>
                                  </Card.Title>
                                  <Row>
                                    <Col md={12}>
                                      <InputGroup size='sm' className='mb-3'>
                                        <InputGroup.Text>Animación</InputGroup.Text>
                                        <Form.Select
                                          id='efectoAnimacionComportamientoContenido'
                                          defaultValue={configuracion.contenido.efecto.comportamiento.animacion}
                                        >
                                          <option value=''>Sin definir</option>
                                          {listaAnimacionComportamiento.map((animacion, llave) => (
                                            <option value={animacion.clase} key={llave}>
                                              {animacion.nombre}
                                            </option>
                                          ))}
                                        </Form.Select>
                                        <Button
                                          variant='outline-secondary'
                                          onClick={() => {
                                            document.getElementById('efectoAnimacionComportamientoContenido').value =
                                              configDefecto.contenido.efecto.comportamiento.animacion;
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faRefresh} />
                                          Reestablecer
                                        </Button>
                                      </InputGroup>
                                    </Col>
                                    <Col md={12}>
                                      <InputGroup size='sm' className='mb-3'>
                                        <InputGroup.Text>Repetición</InputGroup.Text>
                                        <Form.Select
                                          id='efectoRepeticionComportamientoContenido'
                                          defaultValue={configuracion.contenido.efecto.comportamiento.repeticion}
                                        >
                                          <option value=''>Sin definir</option>
                                          {listaRepeticion.map((repeticion, llave) => (
                                            <option value={repeticion.clase} key={llave}>
                                              {repeticion.nombre}
                                            </option>
                                          ))}
                                        </Form.Select>
                                        <Button
                                          variant='outline-secondary'
                                          onClick={() => {
                                            document.getElementById('efectoRepeticionComportamientoContenido').value =
                                              configDefecto.contenido.efecto.comportamiento.repeticion;
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faRefresh} />
                                          Reestablecer
                                        </Button>
                                      </InputGroup>
                                    </Col>
                                    <Col md={12}>
                                      <InputGroup size='sm' className='mb-3'>
                                        <InputGroup.Text>Duración</InputGroup.Text>
                                        <Form.Select
                                          id='efectoDuracionComportamientoContenido'
                                          defaultValue={configuracion.contenido.efecto.comportamiento.duracion}
                                        >
                                          <option value=''>Sin definir</option>
                                          {listaDuracion.map((duracion, llave) => (
                                            <option value={duracion.clase} key={llave}>
                                              {duracion.nombre}
                                            </option>
                                          ))}
                                        </Form.Select>
                                        <Button
                                          variant='outline-secondary'
                                          onClick={() => {
                                            document.getElementById('efectoDuracionComportamientoContenido').value =
                                              configDefecto.contenido.efecto.comportamiento.duracion;
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faRefresh} />
                                          Reestablecer
                                        </Button>
                                      </InputGroup>
                                    </Col>
                                    <Col md={12}>
                                      <InputGroup size='sm' className='mb-3'>
                                        <InputGroup.Text>Retraso</InputGroup.Text>
                                        <Form.Select
                                          id='efectoRetrasoComportamientoContenido'
                                          defaultValue={configuracion.contenido.efecto.comportamiento.retraso}
                                        >
                                          <option value=''>Sin definir</option>
                                          {listaRetraso.map((retraso, llave) => (
                                            <option value={retraso.clase} key={llave}>
                                              {retraso.nombre}
                                            </option>
                                          ))}
                                        </Form.Select>
                                        <Button
                                          variant='outline-secondary'
                                          onClick={() => {
                                            document.getElementById('efectoRetrasoComportamientoContenido').value =
                                              configDefecto.contenido.efecto.comportamiento.retraso;
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faRefresh} />
                                          Reestablecer
                                        </Button>
                                      </InputGroup>
                                    </Col>
                                  </Row>
                                </Card.Body>
                              </Card>
                            </Col>
                            <Col md={12}>
                              <Card className='mt-2 mb-2'>
                                <Card.Body>
                                  <Card.Title>
                                    <center>Entrada</center>
                                  </Card.Title>
                                  <Row>
                                    <Col md={12}>
                                      <InputGroup size='sm' className='mb-3'>
                                        <InputGroup.Text>Animación</InputGroup.Text>
                                        <Form.Select
                                          id='efectoAnimacionEntradaContenido'
                                          defaultValue={configuracion.contenido.efecto.entrada.animacion}
                                        >
                                          <option value=''>Sin definir</option>
                                          {listaAnimacionEntrada.map((animacion, llave) => (
                                            <option value={animacion.clase} key={llave}>
                                              {animacion.nombre}
                                            </option>
                                          ))}
                                        </Form.Select>
                                        <Button
                                          variant='outline-secondary'
                                          onClick={() => {
                                            document.getElementById('efectoAnimacionEntradaContenido').value =
                                              configDefecto.contenido.efecto.entrada.animacion;
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faRefresh} />
                                          Reestablecer
                                        </Button>
                                      </InputGroup>
                                    </Col>
                                    <Col md={12}>
                                      <InputGroup size='sm' className='mb-3'>
                                        <InputGroup.Text>Repetición</InputGroup.Text>
                                        <Form.Select
                                          id='efectoRepeticionEntradaContenido'
                                          defaultValue={configuracion.contenido.efecto.entrada.repeticion}
                                        >
                                          <option value=''>Sin definir</option>
                                          {listaRepeticion.map((repeticion, llave) => (
                                            <option value={repeticion.clase} key={llave}>
                                              {repeticion.nombre}
                                            </option>
                                          ))}
                                        </Form.Select>
                                        <Button
                                          variant='outline-secondary'
                                          onClick={() => {
                                            document.getElementById('efectoRepeticionEntradaContenido').value =
                                              configDefecto.contenido.efecto.entrada.repeticion;
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faRefresh} />
                                          Reestablecer
                                        </Button>
                                      </InputGroup>
                                    </Col>
                                    <Col md={12}>
                                      <InputGroup size='sm' className='mb-3'>
                                        <InputGroup.Text>Duración</InputGroup.Text>
                                        <Form.Select
                                          id='efectoDuracionEntradaContenido'
                                          defaultValue={configuracion.contenido.efecto.entrada.duracion}
                                        >
                                          <option value=''>Sin definir</option>
                                          {listaDuracion.map((duracion, llave) => (
                                            <option value={duracion.clase} key={llave}>
                                              {duracion.nombre}
                                            </option>
                                          ))}
                                        </Form.Select>
                                        <Button
                                          variant='outline-secondary'
                                          onClick={() => {
                                            document.getElementById('efectoDuracionEntradaContenido').value =
                                              configDefecto.contenido.efecto.entrada.duracion;
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faRefresh} />
                                          Reestablecer
                                        </Button>
                                      </InputGroup>
                                    </Col>
                                    <Col md={12}>
                                      <InputGroup size='sm' className='mb-3'>
                                        <InputGroup.Text>Retraso</InputGroup.Text>
                                        <Form.Select
                                          id='efectoRetrasoEntradaContenido'
                                          defaultValue={configuracion.contenido.efecto.entrada.retraso}
                                        >
                                          <option value=''>Sin definir</option>
                                          {listaRetraso.map((retraso, llave) => (
                                            <option value={retraso.clase} key={llave}>
                                              {retraso.nombre}
                                            </option>
                                          ))}
                                        </Form.Select>
                                        <Button
                                          variant='outline-secondary'
                                          onClick={() => {
                                            document.getElementById('efectoRetrasoEntradaContenido').value =
                                              configDefecto.contenido.efecto.entrada.retraso;
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faRefresh} />
                                          Reestablecer
                                        </Button>
                                      </InputGroup>
                                    </Col>
                                  </Row>
                                </Card.Body>
                              </Card>
                            </Col>
                            <Col md={12}>
                              <Card className='mt-2 mb-2'>
                                <Card.Body>
                                  <Card.Title>
                                    <center>Salida</center>
                                  </Card.Title>
                                  <Row>
                                    <Col md={12}>
                                      <InputGroup size='sm' className='mb-3'>
                                        <InputGroup.Text>Animación</InputGroup.Text>
                                        <Form.Select
                                          id='efectoAnimacionSalidaContenido'
                                          defaultValue={configuracion.contenido.efecto.salida.animacion}
                                        >
                                          <option value=''>Sin definir</option>
                                          {listaAnimacionSalida.map((animacion, llave) => (
                                            <option value={animacion.clase} key={llave}>
                                              {animacion.nombre}
                                            </option>
                                          ))}
                                        </Form.Select>
                                        <Button
                                          variant='outline-secondary'
                                          onClick={() => {
                                            document.getElementById('efectoAnimacionSalidaContenido').value =
                                              configDefecto.contenido.efecto.salida.animacion;
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faRefresh} />
                                          Reestablecer
                                        </Button>
                                      </InputGroup>
                                    </Col>
                                    <Col md={12}>
                                      <InputGroup size='sm' className='mb-3'>
                                        <InputGroup.Text>Repetición</InputGroup.Text>
                                        <Form.Select
                                          id='efectoRepeticionSalidaContenido'
                                          defaultValue={configuracion.contenido.efecto.salida.repeticion}
                                        >
                                          <option value=''>Sin definir</option>
                                          {listaRepeticion.map((repeticion, llave) => (
                                            <option value={repeticion.clase} key={llave}>
                                              {repeticion.nombre}
                                            </option>
                                          ))}
                                        </Form.Select>
                                        <Button
                                          variant='outline-secondary'
                                          onClick={() => {
                                            document.getElementById('efectoRepeticionSalidaContenido').value =
                                              configDefecto.contenido.efecto.salida.repeticion;
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faRefresh} />
                                          Reestablecer
                                        </Button>
                                      </InputGroup>
                                    </Col>
                                    <Col md={12}>
                                      <InputGroup size='sm' className='mb-3'>
                                        <InputGroup.Text>Duración</InputGroup.Text>
                                        <Form.Select
                                          id='efectoDuracionSalidaContenido'
                                          defaultValue={configuracion.contenido.efecto.salida.duracion}
                                        >
                                          <option value=''>Sin definir</option>
                                          {listaDuracion.map((duracion, llave) => (
                                            <option value={duracion.clase} key={llave}>
                                              {duracion.nombre}
                                            </option>
                                          ))}
                                        </Form.Select>
                                        <Button
                                          variant='outline-secondary'
                                          onClick={() => {
                                            document.getElementById('efectoDuracionSalidaContenido').value =
                                              configDefecto.contenido.efecto.salida.duracion;
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faRefresh} />
                                          Reestablecer
                                        </Button>
                                      </InputGroup>
                                    </Col>
                                    <Col md={12}>
                                      <InputGroup size='sm' className='mb-3'>
                                        <InputGroup.Text>Retraso</InputGroup.Text>
                                        <Form.Select
                                          id='efectoRetrasoSalidaContenido'
                                          defaultValue={configuracion.contenido.efecto.salida.retraso}
                                        >
                                          <option value=''>Sin definir</option>
                                          {listaRetraso.map((retraso, llave) => (
                                            <option value={retraso.clase} key={llave}>
                                              {retraso.nombre}
                                            </option>
                                          ))}
                                        </Form.Select>
                                        <Button
                                          variant='outline-secondary'
                                          onClick={() => {
                                            document.getElementById('efectoRetrasoSalidaContenido').value =
                                              configDefecto.contenido.efecto.salida.retraso;
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faRefresh} />
                                          Reestablecer
                                        </Button>
                                      </InputGroup>
                                    </Col>
                                  </Row>
                                </Card.Body>
                              </Card>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <div className='d-grid gap-2'>
            <Button variant='outline-primary' onClick={guardarConfig} size='lg'>
              <FontAwesomeIcon icon={faCheck} />
              Guardar
            </Button>
            <Button variant='outline-secondary' onClick={cerrarConfig} size='lg'>
              <FontAwesomeIcon icon={faTimes} />
              Cerrar
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
