import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faArrowRightToBracket } from '@fortawesome/free-solid-svg-icons';
import { Container, Row, Button, FloatingLabel, Form, Card, Col } from 'react-bootstrap';
import { useSetUsuarioContexto } from '../proveedor/usuario';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { reportar } from '../recursos/funcion';
import { urlLoginBackend } from '../recursos/configuracion';
import Axios from 'axios';

export default function Acceso() {

    const navegar = useNavigate();

    const [usuario, setUsuario] = useState('')
    const [contrasena, setContrasena] = useState('')

    async function ingresar() {
        if (usuario === '') {
            Swal.fire(
                'Atención',
                'El campo usuario esta vacio',
                'info'
            )
        } else if (contrasena === '') {
            Swal.fire(
                'Atención',
                'El campo contraseña esta vacio',
                'info'
            )
        } else {
            let parametros = {
                usuario,
                contrasena
            }
            try {
                let respuesta = await Axios.post(`${urlLoginBackend}/acceso`, parametros,
                    {
                        headers: {
                            Authorization: 'Bearer 0'
                        }
                    });
                if (respuesta.data.codigo === 1) {
                    sessionStorage.setItem('auth-token', respuesta.headers['authorization'].split(' ')[1]);
                    navegar('/principal');
                } else {
                    sessionStorage.removeItem('auth-token');
                }
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })

                Toast.fire({
                    icon: 'success',
                    title: respuesta.data.descripcion
                })
            } catch (error) {
                await reportar(usuario.usuario, "Ecommerce Admin", "Frontend", "acceso.jsx", "ingresar", `${error.response.data.message}: ${usuario}`, {
                    error: error.response.data
                }, 1);
            }
            vaciartxt();
        }
    }

    function vaciartxt() {
        setUsuario('');
        setContrasena('');
    }

    const eventoEnter = (event) => {
        if (event.key === 'Enter') {
            ingresar();
        }
    }

    return (
        <>
            <Container>
                <Row>

                </Row>
                <Row>
                    <Col md='4'>

                    </Col>
                    <Col md='4'>
                        <Card className='mt-3 mb-3'>
                            <Card.Body>
                                <Card.Title>
                                    <h5 className='mb-3'>
                                        <FontAwesomeIcon icon={faUser} /> Acceso
                                    </h5>
                                </Card.Title>
                                <FloatingLabel controlId="txtUsuario" label="Usuario" className="mb-3" >
                                    <Form.Control type="text" placeholder="nombre.apellido" value={usuario} onChange={event => setUsuario(event.target.value)} onKeyUp={eventoEnter} />
                                </FloatingLabel>
                                <FloatingLabel controlId="txtPass" label="Contraseña" className='mb-3'>
                                    <Form.Control type="password" placeholder="Contraseña" value={contrasena} onChange={event => setContrasena(event.target.value)} onKeyUp={eventoEnter} />
                                </FloatingLabel>
                                <div className="d-grid gap-2">
                                    <Button variant="outline-primary" size="lg" onClick={ingresar}>
                                        <FontAwesomeIcon icon={faArrowRightToBracket} />Ingresar
                                    </Button>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md='4'>

                    </Col>
                </Row>
                <Row>

                </Row>
            </Container>
        </>
    );
}