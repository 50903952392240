import { useState, useEffect } from 'react';
import { Pie } from 'react-chartjs-2';

export function EstadisticaTorta(props) {
    const [estructura, setEstructura] = useState(
        {
            labels: [],
            datasets: [
                {
                    label: '',
                    data: [],
                    backgroundColor: [],
                    borderColor: [],
                    borderWidth: 1,
                }
            ]
        }
    );
    const [titulos, setTitulos] = useState([]);
    const [datos, setDatos] = useState([]);
    const [coloresPintura, setColoresPintura] = useState([]);
    const [coloresBorde, setColoresBorde] = useState([]);

    useEffect(() => {
        setTitulos(props.titulos);
        setDatos(props.datos);
        setColoresPintura(generarColores(props.colores, '0.2'));
        setColoresBorde(generarColores(props.colores, '1'));
    }, [props.titulos, props.datos, props.colores]);

    useEffect(() => {
        setEstructura({
            labels: titulos,
            datasets: [
                {
                    label: props.titulo,
                    data: datos,
                    backgroundColor: coloresPintura,
                    borderColor: coloresBorde,
                    borderWidth: 1,
                },
            ]
        });
    }, [titulos, datos]);

    function generarColores(colores, opacidad) {
        let envio = [];
        for (const color of colores) {
            envio.push(`rgba(${color}, ${opacidad})`);
        }
        return envio;
    }

    return (
        <>
            <Pie data={estructura} />
        </>
    );
}