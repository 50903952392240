import { faCog, faToolbox } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Button, Card, Col, Form, Table } from 'react-bootstrap';
import { usePrioridadStore } from './categoriasStore';
import axios from 'axios';
import { urlEcommerceBackend } from '../../recursos/configuracion';
import { useUsuarioContexto } from '../../proveedor/usuario';
import useSWR from 'swr';
import authFetcher from '../../utils/db/fetcher';
import { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import ConfiguracionModal from './ConfiguracionModal';

export default function ScrollCategorias({ categorias, elegido }) {
  const {
    active,
    setActive,
    addPrioridad,
    prioridades,
    editPrioridad,
    removePrioridad,
    initPrioridad,
    resetStore,
    setConfiguracion,
    configuracion
  } = usePrioridadStore();

  const [subCategorias, setSubCategorias] = useState([]);
  const [modalConfing, setModalConfing] = useState(false);
  const cuenta = useUsuarioContexto();
  const fetcher = authFetcher(cuenta.token);
  const { data: pagina } = useSWR(`${urlEcommerceBackend}/paginas/${elegido.codigo}/scroll`, fetcher);

  const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    showCloseButton: true,
    timer: 3000,
    timerProgressBar: true
  });

  useEffect(() => {
    if (!pagina?.scrollCategorias) {
      resetStore();
      return;
    }
    initPrioridad(pagina.scrollCategorias);
    if (!!pagina.scrollCategorias.configuracion?.elemento) setConfiguracion(pagina.scrollCategorias.configuracion);
  }, [pagina, initPrioridad, resetStore, setConfiguracion]);

  useEffect(() => {
    const newSub = [];
    categorias.forEach((categoria) => {
      categoria.subcategoria.forEach((sub) => {
        newSub.push(sub);
      });
    });

    setSubCategorias(newSub);
  }, [categorias]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!e.target[0].value) return;
    addPrioridad(e.target[0].value);
  };

  const handleAplicar = async () => {
    try {
      await axios.put(
        `${urlEcommerceBackend}/paginas/${elegido.codigo}/scroll`,
        { elegido, active, prioridades, configuracion },
        {
          headers: {
            authorization: `Bearer ${cuenta.token}`
          }
        }
      );

      Toast.fire({
        icon: 'success',
        title: 'Guardado correctamente'
      });
    } catch (err) {
      Toast.fire({
        icon: 'error',
        title: 'Problemas al realizar el guardado!'
      });
    }
  };

  return (
    <>
      <Col className='mb-2'>
        <Card bg='light' border='success' className='p-3'>
          <Card.Title className='d-flex justify-content-between'>
            <h6>
              <FontAwesomeIcon icon={faToolbox} />
              <u>
                Subcategorias
                <Badge bg='warning' className='ms-2'>
                  Scroll Infinito
                </Badge>
              </u>
            </h6>
            <Button onClick={handleAplicar}>Aplicar cambios</Button>
          </Card.Title>
          <div>
            <Button
              onClick={() => {
                setModalConfing(true);
              }}
            >
              <FontAwesomeIcon icon={faCog} />
            </Button>
            <Form.Check
              type='switch'
              id='categorias-activo'
              label='Mostrar'
              checked={active}
              onChange={(e) => setActive(e.target.checked)}
            />
          </div>

          <h5 className='mt-3'>Prioridades</h5>
          <form onSubmit={handleSubmit}>
            <Form.Group className='mb-3 w-50 d-flex gap-2'>
              <Form.Select>
                <option value=''>Definir subcategoria</option>
                {subCategorias
                  .filter((categoria) => {
                    const isValid = !!categoria.nombre && !!categoria.ruta;
                    const isNotInPrioridades = !prioridades.some((prioridad) => prioridad.ruta === categoria.ruta);
                    return isValid && isNotInPrioridades;
                  })
                  .map((categoria, i) => (
                    <option key={i + categoria.ruta} value={`${categoria.ruta}?${categoria.nombre}`}>
                      {categoria.nombre}
                    </option>
                  ))}
              </Form.Select>
              <Button type='submit'>Agregar</Button>
            </Form.Group>
          </form>

          <div>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th> Nombre</th>
                  <th> Ruta</th>
                  <th> Prioridad</th>
                  <th> </th>
                </tr>
              </thead>
              <tbody>
                {prioridades.map((prioridad, i) => (
                  <tr key={i + prioridad.ruta}>
                    <td>{prioridad.nombre}</td>
                    <td>{prioridad.ruta}</td>
                    <td>
                      <input
                        type='number'
                        className='form-control w-25'
                        defaultValue={prioridad.prioridad}
                        onChange={(e) => editPrioridad(prioridad.ruta, e.target.value)}
                      />
                    </td>
                    <td>
                      <Button variant='danger' onClick={() => removePrioridad(prioridad.ruta)}>
                        Eliminar
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Card>
      </Col>

      <ConfiguracionModal
        show={modalConfing}
        onHide={setModalConfing}
        onSave={(config) => {
          setConfiguracion(config);
        }}
        configuracion={configuracion}
      />
    </>
  );
}
