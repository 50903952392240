import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Axios from 'axios';
import { urlEcommerceBackend } from '../recursos/configuracion';
import { Card, Accordion, ListGroup, ButtonToolbar, ButtonGroup, Button, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark, faCube, faCogs, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { reportar } from '../recursos/funcion';
import { Cargador } from '../componentes/Cargador';
import { useUsuarioContexto } from '../proveedor/usuario';

export default function Coleccion() {
    const navegar = useNavigate();

    const cuenta = useUsuarioContexto();

    const [datos, setDatos] = useState([]);

    const [carga, setCarga] = useState(false);

    useEffect(() => {
        recuperar();
    }, [cuenta]);

    async function recuperar() {
        setCarga(true);
        try {
            let respuesta = await Axios.post(`${urlEcommerceBackend}/recursos/caracteristicas?marca=&categoria=&subcategoria=`, {

            }, {
                headers: {
                    authorization: `Bearer ${cuenta.token}`
                }
            });
            setCarga(false);
            setDatos(respuesta.data.arrayJson);
        } catch (error) {
            setCarga(false);
            await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "caracteristicas.jsx", "recuperar", `${error.toString()}`, {
                error
            }, 1);
        }
    }

    function actualizar() {
        recuperar();
    }

    function dirigir(codigo) {
        navegar(`/producto/${codigo}`);
    }

    return (
        <>
            <div className='container-fluid mt-3 mb-3'>
                <div className='row mb-3'>
                    <div className='col-md-12 mt-2 mb-2'>
                        <center>
                            <h1>
                                {(carga === true ? <Cargador estado={true} /> : <FontAwesomeIcon icon={faBookmark} />)}Característica
                            </h1>
                        </center>
                    </div>
                    <div className='col-md-12 mt-2 mb-2'>
                        <Dropdown>
                            <Dropdown.Toggle variant="outline-primary" id="cbOpciones">
                                <FontAwesomeIcon icon={faCogs} /> Opciones
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item href="#" onClick={actualizar}>
                                    <FontAwesomeIcon icon={faRefresh} /> Actualizar
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div className='col-md-12 mt-2 mb-2'>
                        <Card>
                            <Card.Body>
                                <Accordion>
                                    {
                                        datos.map((dato, llave) => (
                                            <Accordion.Item eventKey={llave} key={llave}>
                                                <Accordion.Header>{(llave + 1) + ' -'} {dato.tipo} ({dato.dato.length})</Accordion.Header>
                                                <Accordion.Body className='bg-secondary'>
                                                    <ListGroup as="ol" numbered>
                                                        {
                                                            dato.dato.map((item, clave) => (
                                                                <ListGroup.Item as="li" key={clave} action>{item.caracter}
                                                                    <ButtonToolbar aria-label="Toolbar with button groups">
                                                                        {
                                                                            item.codigo.map((codigo, key) => (
                                                                                <ButtonGroup key={key} size="md">
                                                                                    <Button variant="outline-success" onClick={() => {
                                                                                        dirigir(codigo);
                                                                                    }}><FontAwesomeIcon icon={faCube} /> {codigo}</Button>
                                                                                </ButtonGroup>
                                                                            ))
                                                                        }
                                                                    </ButtonToolbar>
                                                                </ListGroup.Item>
                                                            ))
                                                        }
                                                    </ListGroup>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        ))
                                    }
                                </Accordion>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className='col-md-12'>
                        <div className='row'></div>
                    </div>
                </div>
            </div>
        </>
    );
}