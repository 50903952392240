import {
    useState,
    useEffect
} from 'react';
import {
    FontAwesomeIcon
} from '@fortawesome/react-fontawesome';
import {
    faMicrochip,
    faArrowRight
} from '@fortawesome/free-solid-svg-icons';
import {
    ListGroup,
    Badge,
    Card,
    Form
} from 'react-bootstrap';
import {
    BarraNavegacion
} from '../componentes/BarraNavegacion';
import {
    urlProccessesBackend
} from '../recursos/configuracion';
import {
    reportar
} from '../recursos/funcion';
import {
    useProcesosContexto
} from '../proveedor/procesos';
import {
    useUsuarioContexto
} from '../proveedor/usuario';
import {
    obtenerPermiso
} from '../recursos/funcion';
import Axios from 'axios';

export default function Panel() {

    const cuenta = useUsuarioContexto();

    const Interfaz = 'panel';

    const procesosContexto = useProcesosContexto();

    const [datos, setDatos] = useState([]);

    useEffect(() => {
        if (procesosContexto !== null) {
            setDatos(procesosContexto);
        } else {
            setDatos([]);
        }
    }, [procesosContexto]);

    async function estado(instancia) {
        try {
            Axios.put(`${urlProccessesBackend}/procesos?instancia=${instancia}`);
        } catch (error) {
            await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "panel.jsx", "estado", `${error.toString()}`, {
                error
            }, 1);
        }
    }

    return (
        <>
            <div className='container-fluid mt-3 mb-3'>
                <div className='row mb-3'>
                    <div className='col-md-12'>
                        <h1>
                            <FontAwesomeIcon icon={faMicrochip} />Panel
                        </h1>
                    </div>
                    <div className='col-md-12 mt-2'>
                        <BarraNavegacion paginas={[
                            {
                                direccion: '/principal',
                                nombre: 'Principal'
                            },
                            {
                                direccion: '/panel',
                                nombre: 'Panel'
                            }
                        ]} />
                    </div>
                    <div className='col-md-12'>
                        {
                            (obtenerPermiso(cuenta.interfaces, Interfaz, 'procesos') ? <Card>
                                <Card.Body>
                                    <ListGroup as="ol" numbered>
                                        {
                                            datos.map((dato, llave) => (
                                                <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start" key={llave} action>
                                                    <div className="ms-2 me-auto">
                                                        <div className="fw-bold">
                                                            <Form.Check type="switch" id={`p${dato.instancia}`} label={dato.nombre} defaultChecked={(dato.estado === 1 ? true : false)} onChange={() => {
                                                                estado(dato.instancia);
                                                            }} />
                                                        </div>
                                                        <Badge bg="primary" pill>{dato.origen} <FontAwesomeIcon icon={faArrowRight} /> {dato.destino}</Badge> {dato.descrip}
                                                    </div>
                                                    {(dato.ejecucion === 1 ? <Badge bg="success" pill>En ejecución</Badge> : <Badge bg="danger" pill>Detenido</Badge>)}
                                                </ListGroup.Item>
                                            ))
                                        }
                                    </ListGroup>
                                </Card.Body>
                            </Card> : '')
                        }
                    </div>
                </div>
            </div>
        </>
    );
}