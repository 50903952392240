import { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faCartShopping, faClock, faCheck, faTimes, faMoneyBillTransfer, faCoins, faRotate, faShoppingCart, faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons';
import { Card, ListGroup, Tooltip, OverlayTrigger, Offcanvas, Row, Col, Button, Container, Badge, Modal } from 'react-bootstrap';
import { BarraNavegacion } from '../componentes/BarraNavegacion';
import { urlEcommerceBackend } from '../recursos/configuracion';
import { obtenerFechaDeTiempo, obtenerHoraDeTiempo, obtenerPermiso, reportar } from '../recursos/funcion';
import { Cargador } from '../componentes/Cargador';
import { Estado } from '../componentes/Estado';
import { Transaccion } from '../componentes/Transaccion';
import { useUsuarioContexto } from '../proveedor/usuario';
import Axios from 'axios';
import Swal from 'sweetalert2';
import Ubicacion from '../componentes/Ubicacion';

export default function Solicitud() {

    const cuenta = useUsuarioContexto();
    const Interfaz = 'solicitud';

    const parametro = useParams();
    const locacion = useLocation();
    const [vital, setVital] = useState(false);

    const [codigo, setCodigo] = useState(0);

    const [dato, setDato] = useState(
        {
            codigo: 0,
            proceso: '',
            tiempo: '',
            cliente: {
                razonsocial: '',
                documento: '',
                correo: '',
                telefono: ''
            },
            envio: {
                direccion: '',
                numerocasa: '',
                barrio: '',
                ciudad: '',
                observacion: '',
                ubicacion: {
                    lat: 0,
                    lng: 0
                }
            },
            pago: {
                condicion: '',
                moneda: '',
                monto: '',
                periodicidad: '',
                tipo: '',
                cuotas: [],
                entregainicial: 0,
                primerpago: ''
            },
            articulos: {
                contado: [],
                credito: []
            },
            transaccion: [],
            estado: 2
        }
    );

    const [carga, setCarga] = useState(false);

    const [modalCuota, setModalCuota] = useState(false);

    const cerrarModalCuota = () => setModalCuota(false);
    const mostrarModalCuota = () => setModalCuota(true);

    const [modalTransaccion, setModalTransaccion] = useState(false);

    const cerrarModalTransaccion = () => setModalTransaccion(false);
    const mostrarModalTransaccion = () => setModalTransaccion(true);

    const [modal, setModal] = useState(false);

    const mostrarModal = () => setModal(true);
    const cerrarModal = () => setModal(false);

    const [ubicacion, setUbicacion] = useState(
        {
            lat: 0,
            lng: 0
        }
    );

    useEffect(() => {
        if (cuenta === null) {
            setVital(true);
        } else {
            setVital(false);
            if (codigo !== 0) {
                recuperar();
            }
        }
    }, [cuenta]);

    useEffect(() => {
        setCodigo(parseInt(parametro.codigo));
    }, [locacion]);

    useEffect(() => {
        if (codigo !== 0) {
            recuperar();
        }
    }, [codigo]);

    useEffect(() => {
        if (dato.envio.ubicacion !== undefined && dato.envio.ubicacion !== null) {
            setUbicacion(dato.envio.ubicacion);
        } else {
            setUbicacion(
                {
                    lat: 0,
                    lng: 0
                }
            );
        }
    }, [dato.envio.ubicacion]);

    async function recuperar() {
        setCarga(true);
        try {
            let respuesta = await Axios.get(`${urlEcommerceBackend}/solicitud/${codigo}`, {
                headers: {
                    authorization: `Bearer ${cuenta.token}`
                }
            });
            if (respuesta.data.codigo === 0) {
                setDato(respuesta.data.objetoJson);
            } else if (respuesta.data.codigo < 0) {
                setDato({
                    codigo: 0,
                    proceso: '',
                    tiempo: '',
                    cliente: {
                        razonsocial: '',
                        documento: '',
                        correo: '',
                        telefono: ''
                    },
                    envio: {
                        direccion: '',
                        numerocasa: '',
                        barrio: '',
                        ciudad: '',
                        observacion: ''
                    },
                    pago: {
                        condicion: '',
                        moneda: '',
                        monto: '',
                        periodicidad: '',
                        tipo: '',
                        cuotas: [],
                        entregainicial: 0,
                        primerpago: ''
                    },
                    articulos: {
                        contado: [],
                        credito: []
                    },
                    transaccion: [],
                    estado: 2
                });
            }
            setCarga(false);
        } catch (error) {
            setCarga(false);
            await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "solicitud.jsx", "recuperar", `${error.toString()}`, {
                error
            }, 1);
        }
    }

    function manjearEstado(estado) {
        let valor = {};
        if (estado === 0) {
            valor['descripcion'] = 'Pago confirmado';
            valor['estilo'] = 'success';
        } else if (estado === 1) {
            valor['descripcion'] = 'Pago sin confirmar';
            valor['estilo'] = 'warning';
        } else if (estado === 2) {
            valor['descripcion'] = 'Sin pagar';
            valor['estilo'] = 'danger';
        }
        return valor;
    }

    async function confirmar() {
        Swal.fire({
            icon: 'question',
            title: 'Esta seguro/a de confirmar esta solicitud?',
            showDenyButton: true,
            confirmButtonText: 'Si',
            denyButtonText: 'No',
        }).then(async (result) => {
            if (result.isConfirmed) {
                /*try {
                    let respuesta = await Axios.put(`${urlEcommerceBackend}/vpos?usuario=${cuenta.nombre}.${cuenta.apellido}&codigo=${dato.codigo}`, {

                    }, {
                        headers: {
                            authorization: `Bearer ${cuenta.token}`
                        }
                    });
                    console.log(respuesta.data);
                } catch (error) {
                    await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "solicitud.jsx", "confirmar", `${error.toString()}`, {
                        error
                    }, 1);
                }*/
            }
        });
    }

    async function cancelar() {
        Swal.fire({
            icon: 'question',
            title: 'Esta seguro/a de cancelar este solicitud?',
            showDenyButton: true,
            confirmButtonText: 'Si',
            denyButtonText: 'No',
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await Axios.delete(`${urlEcommerceBackend}/vpos?usuario=${cuenta.nombre}.${cuenta.apellido}&codigo=${dato.codigo}`, {
                        headers: {
                            authorization: `Bearer ${cuenta.token}`
                        }
                    });
                    recuperar();
                } catch (error) {
                    await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "solicitud.jsx", "cancelar", `${error.toString()}`, {
                        error
                    }, 1);
                }
            }
        });
    }

    return (
        <>
            <Container fluid className='mt-3 mb-3'>
                <Row className='mb-3'>
                    <Col md={12}>
                        <h1>
                            {(carga === true ? <Cargador estado={true} /> : <FontAwesomeIcon icon={faCartShopping} />)}Solicitud: {dato.codigo} <Estado descripcion={manjearEstado(dato.estado).descripcion} formato={1} estado={dato.estado} tipo='1' />
                        </h1>
                    </Col>
                    <Col md={12} className='mt-2'>
                        <BarraNavegacion paginas={[
                            {
                                direccion: '/principal',
                                nombre: 'Principal'
                            },
                            {
                                direccion: '/solicitudes/1/10',
                                nombre: 'Solicitudes'
                            },
                            {
                                direccion: `/solicitud/${codigo}`,
                                nombre: 'Solicitud'
                            }
                        ]} />
                    </Col>
                    <Col md={12} className='mt-2 mb-2'>
                        <Card>
                            <Card.Body>
                                <Card.Title>
                                    <h5>Creado: <FontAwesomeIcon icon={faCalendarAlt} />{obtenerFechaDeTiempo(dato.tiempo)} <FontAwesomeIcon icon={faClock} />{obtenerHoraDeTiempo(dato.tiempo)}</h5>
                                </Card.Title>
                                <Row>
                                    <Col md={12} className='mt-3 mb-3'>
                                        {
                                            (obtenerPermiso(cuenta.interfaces, Interfaz, 'confirmar') ? (dato.proceso !== '' ? <Button variant='outline-success' onClick={confirmar} disabled={vital}><FontAwesomeIcon icon={faCheck} /><FontAwesomeIcon icon={faShoppingCart} /> Confirmar</Button> : '') : '')
                                        }
                                        {
                                            (obtenerPermiso(cuenta.interfaces, Interfaz, 'cancelar') ? (dato.proceso !== '' ? <Button variant='outline-danger' onClick={async () => { await cancelar() }} disabled={vital}><FontAwesomeIcon icon={faTimes} /><FontAwesomeIcon icon={faShoppingCart} /> Cancelar</Button> : '') : '')
                                        }
                                        <Button variant='outline-primary' onClick={recuperar} disabled={vital}>
                                            <FontAwesomeIcon icon={faRotate} /> Actualizar
                                        </Button>
                                        {
                                            (obtenerPermiso(cuenta.interfaces, Interfaz, 'transacciones') ? <Button variant='outline-dark' onClick={mostrarModalTransaccion} disabled={vital}>
                                                <FontAwesomeIcon icon={faMoneyBillTransfer} /> Transacciones <Badge bg='primary'>{dato.transaccion.length}</Badge>
                                            </Button> : '')
                                        }
                                        <Button variant='outline-secondary' onClick={mostrarModalCuota} disabled={vital}>
                                            <FontAwesomeIcon icon={faCoins} /> Cuotas <Badge bg='primary'>{dato.pago.cuotas.length}</Badge>
                                        </Button>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={3} className='mt-2 mb-2'>
                                        <Card>
                                            <Card.Body>
                                                <Card.Title>
                                                    <h5>Cliente</h5>
                                                </Card.Title>
                                                <Card.Text>
                                                    <b>Nombre:</b> {dato.cliente.razonsocial}
                                                </Card.Text>
                                                <Card.Text>
                                                    <b>Documento:</b> {dato.cliente.documento}
                                                </Card.Text>
                                                <Card.Text>
                                                    <b>Correo:</b> {dato.cliente.correo}
                                                </Card.Text>
                                                <Card.Text>
                                                    <b>Teléfono:</b> {dato.cliente.telefono}
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col md={3} className='mt-2 mb-2'>
                                        <Card>
                                            <Card.Body>
                                                <Card.Title>
                                                    <h5>{(dato.envio.ubicacion !== undefined && dato.envio.ubicacion !== null ? <Button variant='primary' onClick={mostrarModal}><FontAwesomeIcon icon={faMapMarkedAlt} /></Button> : <></>)}Envio</h5>
                                                </Card.Title>
                                                <Card.Text>
                                                    <b>Dirección:</b> {dato.envio.direccion} ({dato.envio.numerocasa})
                                                </Card.Text>
                                                <Card.Text>
                                                    <b>Barrio:</b> {dato.envio.barrio}
                                                </Card.Text>
                                                <Card.Text>
                                                    <b>Ciudad:</b> {dato.envio.ciudad}
                                                </Card.Text>
                                                <Card.Text>
                                                    <b>Observación:</b> {dato.envio.observacion}
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col md={6} className='mt-2 mb-2'>
                                        <Card>
                                            <Card.Body>
                                                <Card.Title>
                                                    <h5>Pago</h5>
                                                </Card.Title>
                                                <Row>
                                                    <Col md={6}>
                                                        <Card.Text>
                                                            <b>Tipo:</b> {dato.pago.tipo} {(dato.proceso === '' ? '' : <FontAwesomeIcon icon={faCoins} />)}
                                                        </Card.Text>
                                                        <Card.Text>
                                                            <b>Condición:</b> {dato.pago.condicion}
                                                        </Card.Text>
                                                        <Card.Text>
                                                            <b>Periodicidad:</b> {dato.pago.periodicidad}
                                                        </Card.Text>
                                                        <Card.Text>
                                                            <b>Monto:</b> {dato.pago.monto} {dato.pago.moneda}
                                                        </Card.Text>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Card.Text>
                                                            <b>Primer pago:</b> {dato.pago.primerpago}
                                                        </Card.Text>
                                                        <Card.Text>
                                                            <b>Entrega Inicial:</b> {dato.pago.entregainicial}
                                                        </Card.Text>
                                                        <Card.Text>
                                                            <b>Cantidad cuotas:</b> {dato.pago.cuotas.length}
                                                        </Card.Text>
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col md={12} className='mt-2 mb-2'>
                                        <Card>
                                            <Card.Body>
                                                <Card.Title>Crédito: {dato.articulos.credito.length}</Card.Title>
                                                <Row>
                                                    {
                                                        dato.articulos.credito.map((articulo, llave) => (
                                                            <Col md={2} key={llave} className='mt-2 mb-2'>
                                                                <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={<Tooltip>{articulo.nombre}</Tooltip>}>
                                                                    <Card>
                                                                        <Card.Img variant="top" src={articulo.imagen} />
                                                                        <Card.Body>
                                                                            <Card.Text>
                                                                                <b>Código:</b> {articulo.codigo}
                                                                            </Card.Text>
                                                                            <Card.Text>
                                                                                <b>Precio:</b> {(articulo.precio).toLocaleString('es-PY')} Gs
                                                                            </Card.Text>
                                                                            <Card.Text>
                                                                                <b>Cuotas:</b> {articulo.cuota}
                                                                            </Card.Text>
                                                                            <Card.Text>
                                                                                <b>Cantidad:</b> {articulo.cantidad}
                                                                            </Card.Text>
                                                                        </Card.Body>
                                                                    </Card>
                                                                </OverlayTrigger>
                                                            </Col>
                                                        ))
                                                    }
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>

            <Offcanvas show={modalTransaccion} onHide={cerrarModalTransaccion} placement='start' className='bg-dark text-white'>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>
                        <FontAwesomeIcon icon={faMoneyBillTransfer} />Transacciones
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Row>
                        <Col md={12}>
                            <ListGroup as="ol" numbered>
                                {
                                    dato.transaccion.map((informacion, llave) => (
                                        <Transaccion informacion={informacion} key={llave} />
                                    ))
                                }
                            </ListGroup>
                        </Col>
                    </Row>
                </Offcanvas.Body>
            </Offcanvas>

            <Offcanvas show={modalCuota} onHide={cerrarModalCuota} placement='end' className='bg-dark text-white'>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>
                        <FontAwesomeIcon icon={faCoins} />Cuotas
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Row>
                        <Col md={12}>
                            <ListGroup as="ol" numbered>
                                {
                                    dato.pago.cuotas.map((cuota, llave) => (
                                        <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start" key={llave} >
                                            <div className="ms-2 me-auto">
                                                <div className="fw-bold">Monto: {parseInt(cuota.importe).toLocaleString('es-PY')} Gs</div>
                                                Vencimiento: {(cuota.fechavencimiento)}
                                            </div>
                                        </ListGroup.Item>
                                    ))
                                }
                            </ListGroup>
                        </Col>
                    </Row>
                </Offcanvas.Body>
            </Offcanvas>

            <Modal show={modal} onHide={cerrarModal} backdrop="static" keyboard={false} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <FontAwesomeIcon icon={faMapMarkedAlt} /> Ubicación
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Ubicacion latitud={ubicacion.lat} longitud={ubicacion.lng} />
                </Modal.Body>
            </Modal>

        </>
    );
}