import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown } from 'react-bootstrap';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import { useUsuarioContexto } from '../../proveedor/usuario';
import { urlEcommerceBackend } from '../../recursos/configuracion';
import Swal from 'sweetalert2';

export default function ImportarExcel({ ruta, refresh }) {
  const [show, setShow] = useState(false);
  const cuenta = useUsuarioContexto();
  console.log(cuenta)
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const fileInput = e.target.elements.file;
    const file = fileInput.files?.[0];

    if (file) {
      setLoading(true);
      const formData = new FormData();
      formData.append('excelFile', file);
      formData.append('ruta', ruta);
      formData.append('usuario', cuenta.nombre + " " + cuenta.apellido);

      try {
        const { data } = await axios.post(`${urlEcommerceBackend}/excelPromociones/nuevo `, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            authorization: `Bearer ${cuenta.token}`
          }
        });
        Swal.fire({
          title: data.descripcion,
          html: `
          Los siguientes items se encuentran en una promo activa y no se insertaron:
          <br/><br/>
          ${
            data.arrayJson[0].cod_productos.length > 0
              ? `
            <b>productos:</b>
            ${data.arrayJson[0].cod_productos.join(',')} 
            `
              : ''
          }
          <br/><br/>
         
            ${
              data.arrayJson[0].cod_combos.length > 0
                ? `
            <b>combos:</b>
            ${data.arrayJson[0].cod_combos.join(',')}
            `
                : ''
            }
          `,
          icon: 'success'
        });
      } catch (error) {
        if (error.response.status === 500) return Swal.fire('Error al cargar las promociones.', 'Error 500', 'error');

        const errorBody = error.response.data;

        Swal.fire({
          title: errorBody.descripcion,
          html: `
          corrija los siguientes campos y reintente:
          <br/><br/>
          ${
            errorBody.arrayJson[0].cod_productos.length > 0
              ? `
            <b>productos:</b>
            ${errorBody.arrayJson[0].cod_productos.join(',')}
            `
              : ''
          }
         
            ${
              errorBody.arrayJson[0].cod_combos.length > 0
                ? `
            <b>productos:</b>
            ${errorBody.arrayJson[0].cod_combos.join(',')}
            `
                : ''
            }
          `,
          icon: 'error'
        });
      setLoading(false);

      }
      setLoading(false);
      setShow(false);
      refresh();
    }
  };

  return (
    <>
      <Dropdown.Item href='#' onClick={() => setShow(true)}>
        <FontAwesomeIcon icon={faFileExcel} /> Importar Excel
      </Dropdown.Item>

      <Modal show={show} onHide={() => setShow(false)}>
        <form onSubmit={handleSubmit} encType='multipart/form-data'>
          <Modal.Header closeButton>
            <Modal.Title>Importar Promociones</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <label className='mb-2'>Seleccionar archivo</label>
            <input type='file' name='file' accept='.xlsx, .xls' />
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={() => setShow(false)}>
              Cancelar
            </Button>
            <Button variant='primary' type='submit' disabled={loading}>
              {loading ? 'Guardando...' : 'Guardar Cambios'}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}
