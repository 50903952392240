export const listaAnimacionComportamiento = [
  {
    clase: 'animate__bounce',
    nombre: 'Salto'
  },
  {
    clase: 'animate__pulse',
    nombre: 'Pulso'
  },
  {
    clase: 'animate__shakeX',
    nombre: 'Sacudida Horizontal'
  },
  {
    clase: 'animate__shakeY',
    nombre: 'Sacudida Vertical'
  },
  {
    clase: 'animate__headShake',
    nombre: 'Vibración'
  }
];

export const listaAnimacionEntrada = [
  {
    clase: 'animate__backInUp',
    nombre: 'Subida'
  },

  {
    clase: 'animate__bounceIn',
    nombre: 'Aparición'
  }
];

export const listaAnimacionSalida = [
  {
    clase: 'animate__backOutDown',
    nombre: 'Bajada'
  },
  {
    clase: 'animate__bounceOut',
    nombre: 'Desaparición'
  }
];

export const listaRepeticion = [
  {
    clase: 'animate__repeat-1',
    nombre: '1'
  },
  {
    clase: 'animate__repeat-2',
    nombre: '2'
  },
  {
    clase: 'animate__repeat-3',
    nombre: '3'
  },
  {
    clase: 'animate__infinite',
    nombre: 'Infinito'
  }
];

export const listaDuracion = [
  {
    clase: 'animate__delay-2s',
    nombre: '2 Segundos'
  },
  {
    clase: 'animate__delay-3s',
    nombre: '3 Segundos'
  },
  {
    clase: 'animate__delay-4s',
    nombre: '4 Segundos'
  },
  {
    clase: 'animate__delay-5s',
    nombre: '5 Segundos'
  }
];

export const listaRetraso = [
  {
    clase: 'animate__slow',
    nombre: 'Lento (2 Segundos)'
  },
  {
    clase: 'animate__slower',
    nombre: 'Medio (3 Segundos)'
  },
  {
    clase: 'animate__fast',
    nombre: 'Rápido (800 MiliSegundos)'
  },
  {
    clase: 'animate__faster',
    nombre: 'Más rápido (500 MiliSegundos)'
  }
];

export const configDefecto = {
  elemento: {
    titulo: {
      tamano: 20,
      color: '#000000'
    },
    fondo: {
      color: '#f8f9fa'
    },
    borde: {
      tamano: 1,
      redondeo: 5,
      color: '#c3c7cb'
    },
    efecto: {
      comportamiento: {
        animacion: '',
        repeticion: '',
        duracion: '',
        retraso: ''
      },
      entrada: {
        animacion: '',
        repeticion: '',
        duracion: '',
        retraso: ''
      },
      salida: {
        animacion: '',
        repeticion: '',
        duracion: '',
        retraso: ''
      }
    }
  },
  contenido: {
    fondo: {
      color: '#f8f9fa'
    },
    borde: {
      tamano: 1,
      redondeo: 5,
      color: '#c3c7cb'
    },
    texto: {
      tamano: 2,
      color: '#EC650A'
    },
    icono: {
      tamano: 25,
      color: '#f8f9fa'
    },
    sello: {
      tamano: 12
    },
    efecto: {
      comportamiento: {
        animacion: '',
        repeticion: '',
        duracion: '',
        retraso: ''
      },
      entrada: {
        animacion: '',
        repeticion: '',
        duracion: '',
        retraso: ''
      },
      salida: {
        animacion: '',
        repeticion: '',
        duracion: '',
        retraso: ''
      }
    }
  }
};

