import axios from 'axios';

export default function authFetcher(token) {
  return async (url) => {
    const { data } = await axios.get(url, {
      headers: {
        authorization: `Bearer ${token}`
      }
    });
    return data;
  };
}
