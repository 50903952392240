import { useState, useEffect } from "react";
import Axios from 'axios';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faTrash, faPager, faTimes, faCheck, faImage, faEye, faEyeSlash, faCog, faRefresh, faEdit } from '@fortawesome/free-solid-svg-icons';
import { Card, Button, Form, Image, Modal, FloatingLabel, Dropdown } from 'react-bootstrap';
import { urlEcommerceBackend } from '../recursos/configuracion';
import { conseguirFormato, conseguirBase64, reportar } from '../recursos/funcion';
import { useUsuarioContexto } from '../proveedor/usuario';

export default function Banner() {

    const cuenta = useUsuarioContexto();

    const [vital, setVital] = useState(false);

    const [nombreDato, setNombreDato] = useState("");
    const [rutaDato, setRutaDato] = useState("");

    const [banner, setBanner] = useState({});

    const [operacion, setOperacion] = useState(0);

    const [datos, setDatos] = useState(
        {
            formato: '',
            file: ''
        }
    );
    const [imagen, setImagen] = useState("");
    const [imagenes, setImagenes] = useState([]);

    const [estadoSubir, setEstadoSubir] = useState(true);

    const [modal, setModal] = useState(false);

    const mostrarModal = () => setModal(true);
    const cerrarModal = () => setModal(false);

    useEffect(() => {
        if (cuenta === null) {
            setVital(true);
        } else {
            setVital(false);
            recuperar();
            cargarLista();
        }
    }, [cuenta]);

    async function recuperar() {
        try {
            let respuesta = await Axios.get(`${urlEcommerceBackend}/banners?estado=1`, {
                headers: {
                    authorization: `Bearer ${cuenta.token}`
                }
            });
            setImagenes(respuesta.data.arrayJson);
            setBanner(respuesta.data.arrayJson);
        } catch (error) {
            await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "banner.jsx", "recuperar", `${error.toString()}`, {
                error
            }, 1);
        }
    }

    function elegirArchivo() {
        let informacion = document.getElementById('formImagen').files[0];
        let reader = new FileReader();
        reader.readAsDataURL(informacion);
        reader.onloadend = () => {
            setImagen(reader.result);
            setEstadoSubir(false);
            setDatos(
                {
                    formato: conseguirFormato(informacion.name),
                    file: conseguirBase64(reader.result)
                }
            );
        };
    }

    async function cargarLista() {
        try {

        } catch (error) {
            await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "banner.jsx", "cargarLista", `${error.toString()}`, {
                error
            }, 1);
        }
    }

    function subir() {
        setOperacion(0);
        mostrarModal();
    }

    async function terminar() {
        if (document.getElementById('txtNombre').value === '') {
            Swal.fire(
                'Subida de imágenes',
                'No se ha establecido un nombre',
                'info'
            )
        } else {
            cerrarModal();
            setEstadoSubir(true);
            if (operacion === 0) {
                if (document.getElementById('formImagen').value === '') {
                    Swal.fire(
                        'Subida de imágenes',
                        'No se ha seleccionado imagen',
                        'info'
                    )
                } else {
                    let parametros = {
                        nombre: document.getElementById('txtNombre').value,
                        formato: datos.formato,
                        ruta: document.getElementById('cbRuta').value,
                        file: datos.file
                    }
                    try {
                        let respuesta = await Axios.post(`${urlEcommerceBackend}/banners`, parametros, {
                            headers: {
                                "Content-Type": "multipart/form-data",
                                authorization: `Bearer ${cuenta.token}`
                            }
                        });
                        document.getElementById('formImagen').value = '';
                        setImagen("https://csdigitalizacion.nyc3.cdn.digitaloceanspaces.com/ecommerce/logos/830x425.png");
                        recuperar();
                        const Toast = Swal.mixin({
                            toast: true,
                            position: 'bottom-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', Swal.stopTimer)
                                toast.addEventListener('mouseleave', Swal.resumeTimer)
                            }
                        })

                        Toast.fire({
                            icon: 'success',
                            title: respuesta.data.descripcion
                        })
                    } catch (error) {
                        await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "banner.jsx", "terminar", `${error.toString()}`, {
                            error
                        }, 1);
                    }
                }
            } else if (operacion === 1) {
                try {
                    let respuesta = await Axios.put(`${urlEcommerceBackend}/banners?id=${banner.id}&nombre=${document.getElementById('txtNombre').value}&ruta=${document.getElementById('cbRuta').value}`, {

                    }, {
                        headers: {
                            authorization: `Bearer ${cuenta.token}`
                        }
                    });
                    recuperar();
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'bottom-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer)
                            toast.addEventListener('mouseleave', Swal.resumeTimer)
                        }
                    })
                    Toast.fire({
                        icon: 'success',
                        title: respuesta.data.descripcion
                    })
                } catch (error) {
                    await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "banner.jsx", "terminar", `${error.toString()}`, {
                        id: banner.id,
                        nombre: document.getElementById('txtNombre').value,
                        ruta: document.getElementById('cbRuta').value,
                        error
                    }, 1);
                }
            }
        }
    }

    function cancelar() {
        cerrarModal();
        document.getElementById('formImagen').value = '';
        setImagen("https://csdigitalizacion.nyc3.cdn.digitaloceanspaces.com/ecommerce/logos/830x425.png");
        setEstadoSubir(true);
    }

    function modificar(id, nombre, ruta) {
        setNombreDato(nombre);
        setRutaDato(ruta);
        setBanner(
            {
                id: id,
                nombre: nombre,
                ruta: ruta
            }
        );
        setOperacion(1);
        mostrarModal();
    }

    async function eliminar(id, variante, formato) {
        Swal.fire({
            icon: 'question',
            title: 'Esta seguro/a de eliminar esta imagen?',
            showDenyButton: true,
            confirmButtonText: 'Si',
            denyButtonText: 'No',
        }).then(async (result) => {
            if (result.isConfirmed) {
                let parametros = {
                    id: id,
                    formato: formato,
                    variante: variante
                }
                try {
                    let respuesta = await Axios.delete(`${urlEcommerceBackend}/banners`, {
                        data: parametros,
                        headers: {
                            authorization: `Bearer ${cuenta.token}`
                        }
                    });
                    recuperar();
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'bottom-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer)
                            toast.addEventListener('mouseleave', Swal.resumeTimer)
                        }
                    })

                    Toast.fire({
                        icon: 'success',
                        title: respuesta.data.descripcion
                    })
                } catch (error) {
                    await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "banner.jsx", "eliminar", `${error.toString()}`, {
                        id,
                        formato,
                        variante,
                        error
                    }, 1);
                }
            }
        });
    }

    async function estado(id, estado) {
        try {
            let respuesta = await Axios.put(`${urlEcommerceBackend}/banners?id=${id}&estado=${estado}`, {

            }, {
                headers: {
                    authorization: `Bearer ${cuenta.token}`
                }
            });
            recuperar();
            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })

            Toast.fire({
                icon: 'success',
                title: respuesta.data.descripcion
            })
        } catch (error) {
            await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "banner.jsx", "estado", `${error.toString()}`, {
                id,
                estado,
                error
            }, 1);
        }
    }

    return (
        <>
            <div className='container-fluid mt-3 mb-3'>
                <div className='row mb-3'>
                    <div className='col-md-12'>
                        <center>
                            <h1>
                                <FontAwesomeIcon icon={faImage} /> Banner (2800x180)
                            </h1>
                        </center>
                    </div>
                    <div className='col-md-12'>
                        <Card border="secondary">
                            <Card.Body>
                                <div className="row">
                                    <div className="col-md-12 mb-3">
                                        <center>
                                            <Image src={(imagen === "" ? "https://csdigitalizacion.nyc3.cdn.digitaloceanspaces.com/ecommerce/logos/830x425.png" : imagen)} height='830px' width='471px' fluid thumbnail alt="" />
                                        </center>
                                    </div>
                                    <div className="col-md-12">
                                        <center>
                                            <Form.Group controlId="formImagen">
                                                <Form.Control type="file" size="lg" onChange={elegirArchivo} accept='image/*' disabled={vital} />
                                            </Form.Group>
                                            <div className="d-grid gap-2 mt-3 mb-3">
                                                <Button variant="outline-success" size="lg" onClick={subir} disabled={estadoSubir}>
                                                    <FontAwesomeIcon icon={faUpload} />Subir
                                                </Button>
                                            </div>
                                        </center>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className='col-md-12 mt-3 mb-3'>
                        <Card border="secondary">
                            <Card.Body>
                                <div className='row'>
                                    {
                                        imagenes.map((imagen, key) => (
                                            <div className='col-md-12' key={key}>
                                                <Card border={(imagen.estado === 1 ? 'success' : 'danger')} className="mt-2 mb-2">
                                                    <Card.Header className={(imagen.estado === 1 ? 'text-success' : 'text-danger')}>
                                                        <Card.Title>{(imagen.estado === 1 ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />)} {imagen.nombre}</Card.Title>
                                                        <Card.Text>Ruta: {imagen.ruta}</Card.Text>
                                                    </Card.Header>
                                                    <Card.Img variant="top" src={imagen.imagen} />
                                                    <Card.Footer>
                                                        <div className="d-grid gap-2">
                                                            <Dropdown drop="end">
                                                                <Dropdown.Toggle variant={(imagen.estado === 1 ? 'outline-success' : 'outline-danger')} id="cbEdit">
                                                                    <FontAwesomeIcon icon={faCog} /> Opción
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item href="#" onClick={() => {
                                                                        modificar(imagen._id, imagen.nombre, imagen.ruta)
                                                                    }}><FontAwesomeIcon icon={faEdit} />Modificar</Dropdown.Item>
                                                                    <Dropdown.Item href="#" onClick={() => {
                                                                        estado(imagen._id, imagen.estado)
                                                                    }}><FontAwesomeIcon icon={faRefresh} />Estado</Dropdown.Item>
                                                                    <Dropdown.Divider />
                                                                    <Dropdown.Item href="#" onClick={() => {
                                                                        eliminar(imagen._id, imagen.variante, imagen.formato)
                                                                    }}><FontAwesomeIcon icon={faTrash} />Eliminar</Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                    </Card.Footer>
                                                </Card>
                                            </div>
                                        ))
                                    }
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </div>

            <Modal show={modal} onHide={cancelar} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <FontAwesomeIcon icon={faPager} /> Banner
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FloatingLabel controlId="txtNombre" label="Nombre" className="mb-3">
                        <Form.Control type="text" placeholder="Nombre" defaultValue={nombreDato} />
                    </FloatingLabel>
                    <FloatingLabel controlId="cbRuta" label="Rutas">
                        <Form.Select aria-label="Floating label select example" defaultValue={rutaDato}>
                            <option value="">Sin ruta</option>
                            <option value="/masvendidos">Más vendidos</option>
                            <option value="/promociones">Promociones</option>
                        </Form.Select>
                    </FloatingLabel>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-secondary" onClick={cancelar} size="lg">
                        <FontAwesomeIcon icon={faTimes} /> Cancelar
                    </Button>
                    <Button variant="outline-primary" onClick={terminar} size="lg">
                        <FontAwesomeIcon icon={faCheck} /> Terminar
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    );
}