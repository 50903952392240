import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Axios from 'axios';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCube, faImages, faCheck, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { Image, Button, FloatingLabel, Form, Tabs, Tab, Card, Carousel, Badge } from 'react-bootstrap';
import { urlEcommerceBackend, urlEcommerceFrontend } from '../recursos/configuracion';
import { separarDigitos, nl2br, reportar, obtenerInterfaz, obtenerPermiso } from '../recursos/funcion';
import { Promocion } from '../componentes/Promocion';
import { Clasificacion } from '../componentes/Clasificacion';
import { Caracteristicas } from '../componentes/Caracteristicas';
import { Relacion } from "../componentes/Relacion";
import { LinkContainer } from 'react-router-bootstrap';
import { BarraNavegacion } from '../componentes/BarraNavegacion';
import { useUsuarioContexto } from '../proveedor/usuario';

export default function Producto() {

    const cuenta = useUsuarioContexto();
    const Interfaz = 'producto';

    const [vital, setVital] = useState(false);

    const parametro = useParams();

    const [codigo, setCodigo] = useState(0);

    const [producto, setProducto] = useState({});
    const [imagen, setImagen] = useState([]);

    useEffect(() => {
        if (cuenta.token === '') {
            setVital(true);
        } else {
            setVital(false);
            setCodigo(parametro.codigo);
        }
    }, [cuenta]);

    useEffect(() => {
        recuperar();
    }, [codigo]);

    async function recuperar() {
        try {
            let respuesta = await Axios.get(`${urlEcommerceBackend}/productos/${codigo}`, {
                headers: {
                    authorization: `Bearer ${cuenta.token}`
                }
            });
            if (respuesta.data.arrayJson.length > 0) {
                if (respuesta.data.arrayJson[0].imagenes !== undefined) {

                    if (respuesta.data.arrayJson[0].imagenes.length > 0) {
                        setImagen(respuesta.data.arrayJson[0].imagenes);
                    }
                }
                setProducto(respuesta.data.arrayJson[0]);
            }
        } catch (error) {
            await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "producto.jsx", "recuperar", `${error.toString()}`, {
                error
            }, 1);
        }
    }

    async function guardar() {
        let marca = document.getElementById('txtMarca').value;
        let modelo = document.getElementById('txtModelo').value;
        let descrip = nl2br(document.getElementById('txtDescrip').value);
        let tipo = document.getElementById('cbPrecio').value;
        var parametros = {
            codarticulo: producto.codigo,
            marca: marca.trim(),
            modelo: modelo.trim(),
            descrip: descrip.trim(),
            //precio: (parseInt(tipo) === 0 ? producto.costo : producto.venta),
            precio: producto.venta,
            //tipo: parseInt(tipo)
            tipo: 1
        }
        try {
            let respuesta = await Axios.put(urlEcommerceBackend + '/productos', parametros, {
                headers: {
                    authorization: `Bearer ${cuenta.token}`
                }
            });
            await recuperar(codigo);
            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })
            Toast.fire({
                icon: 'success',
                title: respuesta.data.descripcion
            })
        } catch (error) {
            await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "producto.jsx", "guardar", `${error.toString()}`, {
                error
            }, 1);
        }
    }

    const eventoGuardar = (event) => {
        if (event.key === 'Enter') {
            guardar();
        }
    }

    return (
        <>
            <div className="container-fluid mt-3 mb-3">
                <div className="row">
                    <div className="col-md-12">
                        <h1>
                            <FontAwesomeIcon icon={faCube} />Producto ({separarDigitos(producto.codigo)}) <Badge bg={(producto.estado === 0 ? 'danger' : 'success')}><FontAwesomeIcon icon={(producto.estado === 0 ? faEyeSlash : faEye)} /> {(producto.estado === 0 ? 'No visible' : 'Visible')}</Badge>
                        </h1>
                    </div>
                    <div className='col-md-12 mt-2'>
                        <BarraNavegacion paginas={[
                            {
                                direccion: '/principal',
                                nombre: 'Principal'
                            },
                            {
                                direccion: '/productos/1/10/tm/tc',
                                nombre: 'Productos'
                            },
                            {
                                direccion: `/producto/${codigo}`,
                                nombre: 'Producto'
                            }
                        ]} />
                    </div>
                    <div className={`col-md-${(obtenerPermiso(cuenta.interfaces, Interfaz, 'etiquetas') ? '8' : '12')} mb-3`}>
                        <Card border="secondary">
                            <Card.Body>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="row">

                                            {
                                                (obtenerInterfaz(cuenta.interfaces, 'galeria') ? <>
                                                    <div className="col-md-6 mt-1 mb-1">
                                                        <div className="d-grid gap-2">
                                                            <LinkContainer to={'/galeria/' + parametro.codigo}>
                                                                <Button variant="outline-primary" size="lg">
                                                                    <FontAwesomeIcon icon={faImages} />Galeria
                                                                </Button>
                                                            </LinkContainer>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 mt-1 mb-1">
                                                        <div className="d-grid gap-2">
                                                            <Button variant="outline-success" size="lg" onClick={() => window.open(urlEcommerceFrontend + '/producto/' + producto.ruta, "_blank")} disabled={vital}>
                                                                <FontAwesomeIcon icon={faEye} /> Previsualizar
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </> : <>
                                                    <div className="col-md-12 mt-1 mb-1">
                                                        <div className="d-grid gap-2">
                                                            <Button variant="outline-success" size="lg" onClick={() => window.open(urlEcommerceFrontend + '/producto/' + producto.ruta, "_blank")} disabled={vital}>
                                                                <FontAwesomeIcon icon={faEye} /> Previsualizar
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </>)
                                            }

                                            <div className="col-md-12">
                                                <center className='mb-3 mt-3'>
                                                    <Carousel>
                                                        {
                                                            imagen.map((img, key) => (
                                                                <Carousel.Item key={key}>
                                                                    <Image
                                                                        className="d-block w-100"
                                                                        src={img.url['1000']}
                                                                        height='600px'
                                                                        width='600px'
                                                                        fluid
                                                                        thumbnail
                                                                        alt=""
                                                                    />
                                                                </Carousel.Item>
                                                            ))
                                                        }
                                                    </Carousel>
                                                </center>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <FloatingLabel controlId="txtMarca" label="Marca" className="mb-3 mt-3" >
                                                    <Form.Control type="text" placeholder="Marca" defaultValue={(producto.marca === undefined ? '' : producto.marca.nombre)} onKeyUp={eventoGuardar} />
                                                </FloatingLabel>
                                            </div>
                                            <div className="col-md-12">
                                                <FloatingLabel controlId="txtModelo" label="Modelo" className="mb-3" >
                                                    <Form.Control type="text" placeholder="Modelo" defaultValue={producto.modelo} onKeyUp={eventoGuardar} />
                                                </FloatingLabel>
                                            </div>
                                            <div className="col-md-12">
                                                <FloatingLabel controlId="txtNombre" label="Nombre" className="mb-3" >
                                                    <Form.Control type="text" placeholder="Nombre" defaultValue={producto.nombre} disabled={true} />
                                                </FloatingLabel>
                                            </div>
                                            <div className="col-md-12">
                                                <FloatingLabel controlId="txtRuta" label="Ruta" className="mb-3" >
                                                    <Form.Control type="text" placeholder="Ruta" defaultValue={producto.ruta} disabled={true} />
                                                </FloatingLabel>
                                            </div>
                                            <div className="col-md-6">
                                                <FloatingLabel controlId="txtPrecio" label={"Precio " + (producto.tipo === 0 ? 'Costo' : 'Venta')} className="mb-3" >
                                                    <Form.Control type="number" placeholder="Precio" defaultValue={producto.precio} disabled={true} />
                                                </FloatingLabel>
                                            </div>
                                            <div className="col-md-6">
                                                <FloatingLabel controlId="cbPrecio" label="Tipo Precio">
                                                    <Form.Select aria-label="Floating label select example" defaultValue={'' + producto.tipo}>
                                                        <option value={'0'}>{separarDigitos(producto.costo)} {'(Costo)'}</option>
                                                        <option value={'1'}>{separarDigitos(producto.venta)} {'(Venta)'}</option>
                                                    </Form.Select>
                                                </FloatingLabel>
                                            </div>
                                            <div className="col-md-12">
                                                <FloatingLabel controlId="txtCantidad" label="Cantidad" className="mb-3" >
                                                    <Form.Control type="number" placeholder="Cantidad" defaultValue={producto.cantidad} disabled={true} />
                                                </FloatingLabel>
                                            </div>
                                            <div className="col-md-12">
                                                <FloatingLabel controlId="txtDescrip" label="Descripcion" className="mb-3">
                                                    <Form.Control as="textarea" placeholder="Descripcion" defaultValue={producto.descripcion} style={{ height: '280px' }} />
                                                </FloatingLabel>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="d-grid gap-2">
                                                    <Button variant="outline-primary" size="lg" onClick={guardar} disabled={vital}>
                                                        <FontAwesomeIcon icon={faCheck} />Guardar
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                    {
                        (obtenerPermiso(cuenta.interfaces, Interfaz, 'etiquetas') ? <div className="col-md-4">
                            <Tabs defaultActiveKey="caracteristica" id="tabs" className="mb-3" >
                                {
                                    (obtenerPermiso(cuenta.interfaces, Interfaz, 'caracteristica') ? <Tab eventKey="caracteristica" title="Caracteristica">
                                        {
                                            (
                                                cuenta === null ? '' : <Caracteristicas codigo={parametro.codigo} />
                                            )
                                        }
                                    </Tab> : '')
                                }
                                {
                                    (obtenerPermiso(cuenta.interfaces, Interfaz, 'promocion') ? <Tab eventKey="promocion" title="Promoción">
                                        {
                                            (
                                                cuenta === null ? '' : <Promocion codigo={parametro.codigo} />
                                            )
                                        }
                                    </Tab> : '')
                                }
                                {
                                    (obtenerPermiso(cuenta.interfaces, Interfaz, 'clasificacion') ? <Tab eventKey="clasificacion" title="Clasificación">
                                        {
                                            (
                                                cuenta === null ? '' : <Clasificacion codigo={parametro.codigo} />
                                            )
                                        }
                                    </Tab> : '')
                                }
                                {
                                    (obtenerPermiso(cuenta.interfaces, Interfaz, 'relacion') ? <Tab eventKey="relacion" title="Relación">
                                        {
                                            (
                                                cuenta === null ? '' : <Relacion codigo={parametro.codigo} />
                                            )
                                        }
                                    </Tab> : '')
                                }
                            </Tabs>
                        </div> : '')
                    }

                </div>
            </div>
        </>
    );
}