import { createContext, useState, useContext } from 'react';

const contadoresContexto = createContext();
const setContadoresContexto = createContext();

export function useContadoresContexto() {
    return useContext(contadoresContexto);
}

export function useSetContadoresContexto() {
    return useContext(setContadoresContexto);
}

export function ContadoresProveedor(props) {
    const [contadores, setContadores] = useState(null);

    function establecerContadores(contadoresActual) {
        setContadores(contadoresActual);
    }

    return (
        <contadoresContexto.Provider value={contadores}>
            <setContadoresContexto.Provider value={establecerContadores}>
                {props.children}
            </setContadoresContexto.Provider>
        </contadoresContexto.Provider>
    );
}