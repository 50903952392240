import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { configDefecto } from './constantes';

export const usePrioridadStore = create(
  immer((set) => ({
    active: false,
    prioridades: [],
    configuracion:configDefecto,
    setActive: (value) =>
      set((state) => {
        state.active = value;
      }),
    addPrioridad: (value) =>
      set((state) => {
        const [ruta, nombre] = value.split('?');
        state.prioridades.push({ ruta, nombre, prioridad: 0 });
      }),
    editPrioridad: (ruta, nuevaPrioridad) =>
      set((state) => {
        const prioridad = state.prioridades.find((p) => p.ruta === ruta);
        if (prioridad) {
          prioridad.prioridad = nuevaPrioridad;
        }
      }),
    removePrioridad: (ruta) =>
      set((state) => {
        state.prioridades = state.prioridades.filter((prioridad) => prioridad.ruta !== ruta);
      }),
    initPrioridad: (scroll) =>
      set((state) => {
        state.active = scroll.activo;
        state.prioridades = scroll.prioridades;
      }),
    resetStore: () =>
      set((state) => {
        state.active = false;
        state.prioridades = [];
        state.configuracion = configDefecto
      }),
      setConfiguracion: (value) =>
        set((state) => {
          state.configuracion = value;
        }),
  }))
);
