import Axios from 'axios';
import moment from 'moment';
import {
    urlSyslogBackend,
    ambienteAdmin
} from './configuracion';

export function separarDigitos(dato) {
    let conteo = 0;
    let proceso = "";
    let resultado = "";
    let cadena = dato + "";
    for (var x = (cadena.length - 1); x >= 0; x--) {
        if (conteo === 2) {
            if (x !== 0) {
                proceso = proceso + cadena[x] + '.';

            } else {
                proceso = proceso + cadena[x];
            }
            conteo = -1;
        } else {
            proceso = proceso + cadena[x];
        }
        conteo++;
    }
    conteo = 0;
    for (var y = (proceso.length - 1); y >= 0; y--) {
        resultado = resultado + proceso[y];
    }
    return resultado;
}

export function conseguirFormato(nombre) {
    let resultado = nombre.split(".");
    return resultado[resultado.length - 1]
}

export function conseguirBase64(archivo) {
    let resultado = archivo.split(",");
    return resultado[resultado.length - 1]
}

export function convertirFecha(fecha) {
    let tiempo = moment(fecha).format("DD-MM-YYYY");
    return tiempo
}

export function convertirFechaInvertido(fecha) {
    let tiempo = moment(fecha).format("YYYY-MM-DD");
    return tiempo
}

export function obtenerFechaDeTiempo(tiempo) {
    let envio = moment(tiempo).format("DD-MM-YYYY");
    return envio
}

export function obtenerHoraDeTiempo(tiempo) {
    let envio = moment(tiempo).format("HH:mm:ss");
    return envio
}

export function diferencia(fecha1, fecha2) {
    let fe1 = moment(fecha1);
    let fe2 = moment(fecha2);
    return (fe1.diff(fe2, 'days'));
}

export function nl2br(str, is_xhtml) {
    if (typeof str === 'undefined' || str === null) {
        return '';
    }
    var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '' : '';
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
}

export async function reportar(usuario, programa, lado, archivo, funcion, descripcion, detalle, tipo) {
    try {
        var parametros = {
            ambiente: ambienteAdmin,
            usuario: usuario,
            programa: programa,
            lado: lado,
            archivo: archivo,
            funcion: funcion,
            descripcion: descripcion,
            detalle: detalle,
            tipo: tipo
        }
         await Axios.post(`${urlSyslogBackend}/registros`, parametros);
    } catch (error) {
    }
}

export function obtenerInterfaz(interfaces, interfazRequerida) {
    return (interfaces.findIndex((interfaz) => interfaz.nombre === interfazRequerida) >= 0 ? true : false);
}

export function obtenerPermiso(interfaces, interfazRequerida, permisoRequerido) {
    let envio = false;
    let posicionInterfaz = interfaces.findIndex((interfaz) => interfaz.nombre === interfazRequerida);
    if (posicionInterfaz >= 0) {
        envio = (interfaces[posicionInterfaz].permiso.findIndex((permiso) => permiso === permisoRequerido) >= 0 ? true : false);
    } else {
        envio = false;
    }
    return envio;
}

export function obtenerCuenta(token) {
    const cargaUtil = token.split('.')[1];
    const descifrado = JSON.parse(atob(cargaUtil));
    return descifrado;
}