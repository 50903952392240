import {
    useState,
    useEffect
} from 'react';
import {
    useParams,
    useNavigate,
    useLocation
} from 'react-router-dom';
import Axios from 'axios';
import Swal from 'sweetalert2';
import {
    FontAwesomeIcon
} from '@fortawesome/react-fontawesome';
import {
    faTrash,
    faCog,
    faPlus,
    faTimes,
    faCheck,
    faCogs,
    faRefresh,
    faLayerGroup,
    faThumbTack
} from '@fortawesome/free-solid-svg-icons';
import {
    FloatingLabel,
    Button,
    Form,
    Modal,
    Table,
    Dropdown,
    Pagination,
    Row,
    Col,
    Stack
} from 'react-bootstrap';
import {
    urlEcommerceBackend
} from '../recursos/configuracion';
import {
    obtenerInterfaz,
    obtenerPermiso,
    reportar
} from '../recursos/funcion';
import {
    Cargador
} from '../componentes/Cargador';
import {
    useUsuarioContexto
} from '../proveedor/usuario';

export default function Clasificacion() {

    const cuenta = useUsuarioContexto();
    const Interfaz = 'clasificacion';

    const parametro = useParams();
    const locacion = useLocation();
    const navegar = useNavigate();

    const [pagina, setPagina] = useState(0);
    const [intervalo, setIntevalo] = useState(0);

    const [datos, setDatos] = useState([]);

    const [miembros, setMiembros] = useState([]);

    const [clasificacionElegida, setClasificacionElegida] = useState("");

    const [clasificacionPosicion, setClasificacionPosicion] = useState(-1);

    const [categoriasControl, setCategoriasControl] = useState(true);
    const [subcategoriasControl, setSubCategoriasControl] = useState(true);

    const [categorias, setCategorias] = useState([]);
    const [categoriasPosicion, setCategoriasPosicion] = useState(-1);

    const [subcategorias, setSubCategorias] = useState([]);
    const [subcategoriasPosicion, setSubCategoriasPosicion] = useState(-1);

    const [paginas, setPaginas] = useState([]);
    const [paginado, setPaginado] = useState(
        {
            inicio: 0,
            fin: 0
        }
    );

    const [carga, setCarga] = useState(false);

    const [modalClasificacion, setModalClasificacion] = useState(false);

    const cerrarModalClasificacion = () => setModalClasificacion(false);
    const mostrarModalClasificacion = () => setModalClasificacion(true);

    const [modalMiembro, setModalMiembro] = useState(false);

    const cerrarModalMiembro = () => setModalMiembro(false);
    const mostrarModalMiembro = () => setModalMiembro(true);

    useEffect(() => {
        obtenerCategorias();
    }, []);

    useEffect(() => {
        setPagina(parseInt(parametro.pagina));
        setIntevalo(parseInt(parametro.intervalo));
    }, [locacion]);

    useEffect(() => {
        if (pagina !== 0 && intervalo !== 0) {
            recuperar();
        }
    }, [pagina, intervalo]);

    useEffect(() => {
        if (categoriasPosicion === -1) {
            setCategoriasControl(true);
        } else {
            setSubCategorias(categorias[document.getElementById('cbCategorias').value].subcategoria);
            setCategoriasControl(false);
        }
    }, [categoriasPosicion]);

    useEffect(() => {
        if (subcategoriasPosicion === -1) {
            setSubCategoriasControl(true);
        } else {
            setSubCategoriasControl(false);
        }
    }, [subcategoriasPosicion]);

    useEffect(() => {
        if (clasificacionPosicion !== -1) {
            setMiembros(datos[clasificacionPosicion].miembro);
        }
    }, [clasificacionPosicion]);

    useEffect(() => {
        if (datos.length > 0) {
            setMiembros((datos[clasificacionPosicion] === undefined ? [] : datos[clasificacionPosicion].miembro));
        }
    }, [datos]);

    async function recuperar() {
        setCarga(true);
        try {
            let respuesta = await Axios.get(`${urlEcommerceBackend}/clasificaciones?pagina=${pagina}&intervalo=${intervalo}`, {
                headers: {
                    authorization: `Bearer ${cuenta.token}`
                }
            });
            setDatos(respuesta.data.arrayJson);
            setPaginas(respuesta.data.objetoJson.paginas);
            setPaginado(respuesta.data.objetoJson.paginado);
            setCarga(false);
        } catch (error) {
            setCarga(false);
            await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "clasificacion.jsx", "recuperar", `${error.toString()}`, {
                error
            }, 1);
        }
    }

    function obtenerCategorias() {
        Axios.get(`${urlEcommerceBackend}/categorias`)
            .then(function (resultado) {
                setCategorias(resultado.data.arrayJson);
            })
            .catch(async function (error) {
                await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "clasificacion.jsx", "obtenerCategorias", `${error.toString()}`, {
                    error: error.response
                }, 1);
            });
    }

    function dirigir(paginanueva) {
        if (paginanueva === 0) {

        } else {
            navegar(`/etiquetas/clasificacion/${paginanueva}/${intervalo}`);
        }
    }

    function actualizar() {
        recuperar();
    }

    function nuevo() {
        mostrarModalClasificacion();
    }

    async function guardar() {
        let nombre = document.getElementById('txtNombre').value;
        if (nombre === '') {
            Swal.fire(
                'Atención',
                'El campo Nombre esta vacio',
                'info'
            )
        } else {
            let parametros = {
                nombre: nombre
            }
            try {
                let respuesta = await Axios.post(`${urlEcommerceBackend}/clasificaciones`, parametros, {
                    headers: {
                        authorization: `Bearer ${cuenta.token}`
                    }
                });
                cerrarModalClasificacion();
                actualizar();
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })

                Toast.fire({
                    icon: 'success',
                    title: respuesta.data.descripcion
                })
            } catch (error) {
                await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "clasificacion.jsx", "guardar", `${error.toString()}`, {
                    nombre: nombre.trim(),
                    error
                }, 1);
            }
        }
    }

    function cerrarClasificacion() {
        cerrarModalClasificacion();
    }

    async function eliminar(nombre, cantidad) {
        if (cantidad > 0) {
            Swal.fire(
                'No se puede eliminar el registro',
                'Contiene miembros asignados',
                'info'
            )
        } else {
            Swal.fire({
                title: 'Desea eliminar la etiqueta?',
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si',
                cancelButtonText: 'No'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    let parametros = {
                        nombre: nombre
                    }
                    try {
                        let respuesta = await Axios.put(`${urlEcommerceBackend}/clasificaciones`, parametros, {
                            headers: {
                                authorization: `Bearer ${cuenta.token}`
                            }
                        });
                        actualizar();
                        const Toast = Swal.mixin({
                            toast: true,
                            position: 'bottom-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', Swal.stopTimer)
                                toast.addEventListener('mouseleave', Swal.resumeTimer)
                            }
                        })
                        Toast.fire({
                            icon: 'success',
                            title: respuesta.data.descripcion
                        })
                    } catch (error) {
                        await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "clasificacion.jsx", "eliminar", `${error.toString()}`, {
                            nombre,
                            error: error.response.data
                        }, 1);
                    }
                }
            })
        }
    }

    function miembro(nombre, posicion) {
        setClasificacionElegida(nombre);
        setClasificacionPosicion(posicion);
        mostrarModalMiembro();
    }

    function terminar() {
        recuperar();
        cerrarModalMiembro();
    }

    async function agregarCategorias() {
        let parametros = {
            nombre: clasificacionElegida,
            tipo: 'Categoria',
            valor: categorias[document.getElementById('cbCategorias').value].ruta
        }
        try {
            let respuesta = await Axios.post(`${urlEcommerceBackend}/clasificar`, parametros, {
                headers: {
                    authorization: `Bearer ${cuenta.token}`
                }
            });
            recuperar();
            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })
            Toast.fire({
                icon: 'success',
                title: respuesta.data.descripcion
            })
        } catch (error) {
            await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "clasificacion.jsx", "agregarCategorias", `${error.toString()}`, {
                parametros,
                error
            }, 1);
        }
    }

    async function agregarSubCategorias() {
        let parametros = {
            nombre: clasificacionElegida,
            tipo: 'Subcategoria',
            valor: subcategorias[document.getElementById('cbSubCategorias').value].ruta
        }
        try {
            let respuesta = await Axios.post(`${urlEcommerceBackend}/clasificar`, parametros, {
                headers: {
                    authorization: `Bearer ${cuenta.token}`
                }
            });
            recuperar();
            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })
            Toast.fire({
                icon: 'success',
                title: respuesta.data.descripcion
            })
        } catch (error) {
            await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "clasificacion.jsx", "agregarSubCategorias", `${error.toString()}`, {
                parametros,
                error
            }, 1);
        }
    }

    async function eliminarMiembro(tipo, valor) {
        let parametros = {
            nombre: clasificacionElegida,
            tipo: tipo,
            valor: valor
        }
        try {
            let respuesta = await Axios.put(`${urlEcommerceBackend}/clasificar`, parametros, {
                headers: {
                    authorization: `Bearer ${cuenta.token}`
                }
            });
            recuperar();
            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })
            Toast.fire({
                icon: 'success',
                title: respuesta.data.descripcion
            })
        } catch (error) {
            await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "clasificacion.jsx", "eliminarMiembro", `${error.toString()}`, {
                parametros,
                error
            }, 1);
        }
    }

    const eventoEnter = (event) => {
        if (event.key === 'Enter') {
            guardar();
        }
    }

    return (
        <>
            <div className='container-fluid mt-3 mb-3'>
                <div className='row mb-3'>
                    <div className='col-md-12 mt-2 mb-2'>
                        <center>
                            <h1>
                                {(carga === true ? <Cargador estado={carga} /> : <FontAwesomeIcon icon={faThumbTack} />)} Clasificación
                            </h1>
                        </center>
                    </div>
                    <div className='col-md-6 mt-2 mb-2'>
                        <Dropdown>
                            <Dropdown.Toggle variant="outline-primary" id="cbOpciones">
                                <FontAwesomeIcon icon={faCogs} /> Opciones
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item href="#" onClick={nuevo}>
                                    <FontAwesomeIcon icon={faPlus} /> Nuevo
                                </Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item href="#" onClick={actualizar}>
                                    <FontAwesomeIcon icon={faRefresh} /> Actualizar
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div className='col-md-6 mt-2 mb-2'>
                        <div className="table-responsive">
                            <center>
                                {
                                    (paginas.length === 0 ? '' : <Pagination className='d-flex justify-content-end'>
                                        {(parseInt(pagina) === 1)}
                                        <Pagination.First onClick={() => {
                                            dirigir(paginado.inicio);
                                        }} disabled={parseInt(pagina) === 1} />
                                        <Pagination.Prev onClick={() => {
                                            dirigir(parseInt(pagina) - 1);
                                        }} disabled={parseInt(pagina) === 1} />
                                        {((Math.ceil(pagina / intervalo) * intervalo) === (Math.ceil(paginado.inicio / intervalo) * intervalo) ? '' : <><Pagination.Item onClick={() => {
                                            dirigir(paginado.inicio);
                                        }} disabled={(parseInt(pagina) === paginado.inicio)}>{paginado.inicio}</Pagination.Item><Pagination.Ellipsis onClick={() => {
                                            dirigir(parseInt(pagina) - 10)
                                        }} /></>)}

                                        {
                                            paginas.map((paginaOpcion, llave) => (
                                                <Pagination.Item key={llave} onClick={() => {
                                                    dirigir((paginaOpcion === parseInt(pagina) ? 0 : paginaOpcion))
                                                }} active={paginaOpcion === parseInt(pagina)}>{paginaOpcion}</Pagination.Item>
                                            ))
                                        }

                                        {((Math.ceil(pagina / intervalo) * intervalo) === (Math.ceil(paginado.fin / intervalo) * intervalo) ? '' : <><Pagination.Ellipsis onClick={() => {
                                            dirigir(parseInt(pagina) + 10)
                                        }} /><Pagination.Item onClick={() => {
                                            dirigir(paginado.fin);
                                        }} disabled={(parseInt(pagina) === paginado.fin)}>{paginado.fin}</Pagination.Item></>)}
                                        <Pagination.Next onClick={() => {
                                            dirigir(parseInt(pagina) + 1);
                                        }} disabled={parseInt(pagina) === paginado.fin} />
                                        <Pagination.Last onClick={() => {
                                            dirigir(paginado.fin);
                                        }} disabled={parseInt(pagina) === paginado.fin} />
                                    </Pagination>)
                                }
                            </center>
                        </div>
                    </div>
                    <div className='col-md-12'>
                        <div className='table-responsive'>
                            <Table bordered hover size='lg' className='table-dark'>
                                <thead>
                                    <tr>
                                        <th>Acción</th>
                                        <th>Nombre</th>
                                        <th>Miembros</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        datos.map((dato, llave) => (
                                            <tr key={llave}>
                                                <td>
                                                    <Dropdown>
                                                        <Dropdown.Toggle variant="outline-primary" id="cbOpcion">
                                                            <FontAwesomeIcon icon={faCog} />
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            {
                                                                (obtenerInterfaz(cuenta.interfaces, 'clasificar') ? <><Dropdown.Item href="#" onClick={() => {
                                                                    miembro(dato.nombre, llave)
                                                                }}>
                                                                    <FontAwesomeIcon icon={faLayerGroup} /> Miembros
                                                                </Dropdown.Item>
                                                                    <Dropdown.Divider /></> : '')
                                                            }

                                                            <Dropdown.Item href="#" onClick={() => {
                                                                eliminar(dato.nombre, dato.miembro.length)
                                                            }}>
                                                                <FontAwesomeIcon icon={faTrash} /> Eliminar
                                                            </Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </td>
                                                <td>{dato.nombre}</td>
                                                <td>{dato.miembro.length}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </div>
                    <Modal show={modalClasificacion} onHide={cerrarModalClasificacion} backdrop="static" keyboard={false} >
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <FontAwesomeIcon icon={faThumbTack} />Clasificación
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <FloatingLabel controlId="txtNombre" label="Nombre" className="mb-3" onKeyUp={eventoEnter} >
                                <Form.Control type="text" placeholder="Nombre" />
                            </FloatingLabel>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="outline-secondary" onClick={cerrarClasificacion}>
                                <FontAwesomeIcon icon={faTimes} /> Cerrar
                            </Button>
                            <Button variant="outline-primary" onClick={guardar}>
                                <FontAwesomeIcon icon={faCheck} /> Guardar
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={modalMiembro} onHide={cerrarModalMiembro} backdrop="static" size='lg' keyboard={false} >
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <FontAwesomeIcon icon={faLayerGroup} />Miembros ({clasificacionElegida})
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

                            <Row>
                                <Col md={8} className='mt-3 mb-3'>
                                    <Form.Select id='cbCategorias' onChange={(event) => {
                                        setCategoriasPosicion(parseInt(event.target.value));
                                    }}>
                                        <option value={-1}>Seleccione para continuar</option>
                                        {
                                            categorias.map((categoria, llave) => (
                                                <option value={llave} key={llave}>{categoria.nombre}</option>
                                            ))
                                        }
                                    </Form.Select>
                                </Col>
                                <Col md={4} className='mt-3 mb-3'>
                                    <Stack gap={2} className="mx-auto">
                                        <Button variant='outline-primary' onClick={() => {
                                            agregarCategorias();
                                        }} disabled={categoriasControl}>
                                            <FontAwesomeIcon icon={faPlus} />Categorias
                                        </Button>
                                    </Stack>
                                </Col>
                                <Col md={8} className='mt-3 mb-3'>
                                    <Form.Select id='cbSubCategorias' onChange={(event) => {
                                        setSubCategoriasPosicion(parseInt(event.target.value));
                                    }}>
                                        <option value={-1}>Seleccione para continuar</option>
                                        {
                                            subcategorias.map((subcategoria, llave) => (
                                                <option value={llave} key={llave}>{subcategoria.nombre}</option>
                                            ))
                                        }
                                    </Form.Select>
                                </Col>
                                <Col md={4} className='mt-3 mb-3'>
                                    <Stack gap={2} className="mx-auto">
                                        <Button variant='outline-primary' onClick={() => {
                                            agregarSubCategorias();
                                        }} disabled={subcategoriasControl}>
                                            <FontAwesomeIcon icon={faPlus} />SubCategorias
                                        </Button>
                                    </Stack>
                                </Col>
                                <Col md={12}>
                                    <div className='table-responsive'>
                                        <Table bordered hover size='lg' className='table-dark'>
                                            <thead>
                                                <tr>
                                                    <th>Acción</th>
                                                    <th>Tipo</th>
                                                    <th>Valor</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    miembros.map((miembro, key) => (
                                                        <tr key={key}>
                                                            <td>
                                                                <Button variant='outline-danger' onClick={() => {
                                                                    eliminarMiembro(miembro.tipo, miembro.valor);
                                                                }}>
                                                                    <FontAwesomeIcon icon={faTrash} />
                                                                </Button>
                                                            </td>
                                                            <td>{miembro.tipo}</td>
                                                            <td>{miembro.valor}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </Table>
                                    </div>
                                </Col>
                            </Row>

                        </Modal.Body>
                        <Modal.Footer>
                            <Stack gap={2} className="mx-auto">
                                <Button variant="outline-primary" onClick={terminar}>
                                    <FontAwesomeIcon icon={faCheck} /> Terminar
                                </Button>
                            </Stack>
                        </Modal.Footer>
                    </Modal>

                </div>
            </div>
        </>
    );
}