import { useState, useEffect } from "react";
import Axios from 'axios';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faTrash, faRefresh, faVideo } from '@fortawesome/free-solid-svg-icons';
import { Card, Button, Form, Modal, FloatingLabel, Dropdown, Container, Row, Col, InputGroup, Toast } from 'react-bootstrap';
import { urlEcommerceBackend } from '../recursos/configuracion';
import { reportar } from '../recursos/funcion';
import { useUsuarioContexto } from '../proveedor/usuario';
import ReactPlayer from "react-player";

export default function Videos() {

    const cuenta = useUsuarioContexto();

    const [vital, setVital] = useState(false);

    const [modalVideo, setModalVideo] = useState(false);

    const mostrarModalVideo = () => setModalVideo(true);
    const cerrarModalVideo = () => setModalVideo(false);
    const [videos, setVideos] = useState([]);

    const [rutaVideo, setRutaVideo] = useState("");

    useEffect(() => {
        if (cuenta === null) {
            setVital(true);
        } else {
            setVital(false);
            recuperarVideo();
            cargarLista();
        }
    }, [cuenta]);

    function cancelar() {
        cerrarModalVideo();
    }
    async function recuperarVideo() {
        try {
            let respuesta = await Axios.get(`${urlEcommerceBackend}/videos`, {
                headers: {
                    authorization: `Bearer ${cuenta.token}`
                }
            });
            setVideos(respuesta.data.arrayJson);
        } catch (error) {
            await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "banner.jsx", "recuperar", `${error.toString()}`, {
                error
            }, 1);
        }
    }

    async function cargarLista() {
        try {

        } catch (error) {
            await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "banner.jsx", "cargarLista", `${error.toString()}`, {
                error
            }, 1);
        }
    }

    function subirVideos() {
        try {
            mostrarModalVideo();
        } catch (error) {
            console.log(error);
        }
    }

    const handleAgregarVideo = async (e) => {
        e.preventDefault()
      const form = e.target
        try {

            if(form.nombre.value ===''){
                return Swal.fire(
                    'Subida de videos',
                    'No se ha seleccionado nombre del video',
                    'info'
                )
            }

            if (form.ruta.value === '') {
                return Swal.fire(
                    'Subida de videos',
                    'No se ha seleccionado ruta de video',
                    'info'
                )
            } 

          
            let parametrosVideo = {
                nombre:form.nombre.value,
                url: form.ruta.value
            }

            let respuesta = await Axios.post(`${urlEcommerceBackend}/videos`, parametrosVideo, {
                headers: {
                    authorization: `Bearer ${cuenta.token}`
                }
            });
            recuperarVideo();
            cerrarModalVideo();
            Toast.fire({
                icon: 'success',
                title: respuesta.data.descripcion
            })
            
        } catch (error) {
            await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "galeria.jsx", "subirVideo", `${error.toString()}`, {
                error
            }, 1);
        }
    }

    async function modificarVideo(video,llave) {
        try {
            let parametros = {
                url: document.getElementById(`txtUrlVideoModificar${llave}`).value,
                id:video._id
            }
            let respuesta = await Axios.put(`${urlEcommerceBackend}/videos`, parametros,{
                headers: {
                    authorization: `Bearer ${cuenta.token}`
                }
            });
            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })
            recuperarVideo();
            Toast.fire({
                icon: 'success',
                title: respuesta.data.descripcion
            })
        } catch (error) {
            console.log(error);
        }
    }

    async function bajarVideo(video) {
        Swal.fire({
            icon: 'question',
            title: 'Esta seguro/a de eliminar este video?',
            showDenyButton: true,
            confirmButtonText: 'Si',
            denyButtonText: 'No',
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    let parametros = {
                        id:video._id
                    }
                    let respuesta = await Axios.delete(`${urlEcommerceBackend}/videos`, {
                        headers: {
                            authorization: `Bearer ${cuenta.token}`
                        },
                        data: parametros
                    });
                    recuperarVideo();
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'bottom-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer)
                            toast.addEventListener('mouseleave', Swal.resumeTimer)
                        }
                    })

                    Toast.fire({
                        icon: (respuesta.data.codigo === 0 ? 'success' : 'info'),
                        title: respuesta.data.descripcion
                    })

                } catch (error) {
                    console.log(error);
                }
            }

        });
    }

    return (
        <>
            <Container fluid >
                <Row className=' mb-3'>
                    <Col className='col-md-12'>
                        <center>
                            <h1>
                                <FontAwesomeIcon icon={faVideo} /> Videos
                            </h1>
                        </center>
                    </Col>

                    <Col md={12} className='mt-2 mb-3'>
                        <Dropdown>
                            <Dropdown.Toggle variant="outline-primary" id="cbOpciones">
                                <FontAwesomeIcon icon={faVideo} /> Agregar videos
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item href="#" onClick={subirVideos} disabled={vital}>
                                    <FontAwesomeIcon icon={faVideo} /> Subir video
                                </Dropdown.Item>

                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                    <Col md={12} className='mt-1 mb-3'>
                        <Card>
                            <Card.Body>
                                <Card.Title>
                                    <FontAwesomeIcon icon={faVideo} /> Videos
                                </Card.Title>
                                <Row>
                                    {videos.map((video, llave) => <Col md={4} key={llave}>
                                        <Card>
                                            <Card.Body>
                                                <Card.Title>
                                                    {video.nombre}
                                                </Card.Title>
                                                <InputGroup>
                                                    <Form.Control type="text" id={`txtUrlVideoModificar${llave}`} placeholder="Ruta" defaultValue={video.url} className='mb-2' onChange={(event) => setRutaVideo(event.target.value)} />
                                                    <Button variant="outline-primary" onClick={() => {
                                                        modificarVideo(video,llave);
                                                    }}><FontAwesomeIcon icon={faRefresh} /></Button>
                                                    <Button variant="outline-danger" onClick={() => {
                                                        bajarVideo(video);
                                                    }}><FontAwesomeIcon icon={faTrash} /></Button>
                                                </InputGroup>
                                              
                                                <ReactPlayer controls={false} url={video.url} width={"100%"}>
                                                </ReactPlayer>
                                            </Card.Body>
                                        </Card>
                                    </Col>)}
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Modal show={modalVideo} onHide={cancelar} backdrop="static" keyboard={false} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <FontAwesomeIcon icon={faVideo} /> Video
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <center>
                                <form onSubmit={handleAgregarVideo}>
                            <FloatingLabel label="Nombre" className="mb-3" >

                                    <Form.Control name="nombre" type="text" size="lg" placeholder="Nombre" defaultValue={rutaVideo}  />
                                </FloatingLabel>
                                <FloatingLabel label="Ruta" className="mb-3" >
                                    <Form.Control name="ruta" type="text" size="lg" placeholder="Ruta" defaultValue={rutaVideo}  />
                                </FloatingLabel>
                               
                                <div className="d-grid gap-2 mt-3 mb-3">
                                    <Button variant="outline-success" size="lg" type="submit">
                                        <FontAwesomeIcon icon={faUpload} />Guardar
                                    </Button>
                                </div>
                                </form>
                            </center>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    );
}