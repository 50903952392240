import { createContext, useState, useContext } from 'react';

const editorContexto = createContext();
const setEditorContexto = createContext();

export function useEditorContexto() {
    return useContext(editorContexto);
}

export function useSetEditorContexto() {
    return useContext(setEditorContexto);
}

export function EditorProveedor(props) {
    const [editor, setEditor] = useState(null);

    function establecerEditor(editorActual) {
        setEditor(editorActual);
    }

    return (
        <editorContexto.Provider value={editor}>
            <setEditorContexto.Provider value={establecerEditor}>
                {props.children}
            </setEditorContexto.Provider>
        </editorContexto.Provider>
    );
}