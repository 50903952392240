import { useState, useEffect } from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import { urlGoogleMapsAPIKEY } from '../recursos/configuracion'

const Ubicacion = ({ google, latitud, longitud }) => {
    const [mapa, setMapa] = useState({ lat: latitud, lng: longitud });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setMapa({ lat: latitud, lng: longitud });
    }, [latitud, longitud]);

    const onMapReady = () => {
        setLoading(false);
    };

    const mapaEstilo = {
        width: '100%',
        height: '70vh',
        overflow: 'hidden'
    };

    return (
        <div style={{ position: 'relative', width: '100%', height: '70vh' }}>
            {loading && <div>Loading...</div>}
            <Map
                google={google}
                zoom={14}
                style={mapaEstilo}
                initialCenter={mapa}
                center={mapa}
                onReady={onMapReady}
            >
                <Marker position={mapa} />
            </Map>
        </div>
    );
}

export default GoogleApiWrapper({
    apiKey: urlGoogleMapsAPIKEY
})(Ubicacion);