import { useState } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTag, faBookmark, faThumbtack, faCodeBranch } from '@fortawesome/free-solid-svg-icons';
import { ButtonGroup, Card, ToggleButton } from 'react-bootstrap';
import { BarraNavegacion } from '../componentes/BarraNavegacion';
import { useUsuarioContexto } from '../proveedor/usuario';
import { obtenerPermiso } from '../recursos/funcion';

export default function Etiquetas() {
    const cuenta = useUsuarioContexto();
    const Interfaz = 'etiquetas';
    const navegar = useNavigate();
    const [ValorRadio, setValorRadio] = useState('0');

    return (
        <>
            <div className='container-fluid mt-3 mb-3'>
                <div className='row mb-3'>
                    <div className='col-md-12'>
                        <h1>
                            <FontAwesomeIcon icon={faTag} />Etiquetas
                        </h1>
                    </div>
                    <div className='col-md-12 mt-2'>
                        <BarraNavegacion paginas={[
                            {
                                direccion: '/principal',
                                nombre: 'Principal'
                            },
                            {
                                direccion: '/etiquetas',
                                nombre: 'Etiquetas'
                            }
                        ]} />
                    </div>
                    <div className='col-md-12 mt-2'>
                        <ButtonGroup>
                            {
                                (obtenerPermiso(cuenta.interfaces, Interfaz, 'caracteristica') ? <ToggleButton id='radio1' variant='outline-success' name='radio' type='radio' value={'1'} onChange={(e) => {
                                    navegar('caracteristica/1/10');
                                    setValorRadio(e.currentTarget.value);
                                }} checked={ValorRadio === '1'}>
                                    <FontAwesomeIcon icon={faBookmark} /> Característica
                                </ToggleButton> : '')
                            }
                            {
                                (obtenerPermiso(cuenta.interfaces, Interfaz, 'clasificacion') ? <ToggleButton id='radio2' variant='outline-success' name='radio' type='radio' value={'2'} onChange={(e) => {
                                    navegar('clasificacion/1/10');
                                    setValorRadio(e.currentTarget.value);
                                }} checked={ValorRadio === '2'}>
                                    <FontAwesomeIcon icon={faThumbtack} /> Clasificación
                                </ToggleButton> : '')
                            }
                            {
                                (obtenerPermiso(cuenta.interfaces, Interfaz, 'relacion') ? <ToggleButton id='radio3' variant='outline-success' name='radio' type='radio' value={'3'} onChange={(e) => {
                                    navegar('relacion/1/10');
                                    setValorRadio(e.currentTarget.value);
                                }} checked={ValorRadio === '3'}>
                                    <FontAwesomeIcon icon={faCodeBranch} /> Relación
                                </ToggleButton> : '')
                            }

                        </ButtonGroup>
                    </div>
                    <div className='col-md-12 mt-3 mb-3'>
                        <Card>
                            <Outlet />
                        </Card>
                    </div>
                </div>
            </div>
        </>
    );
}