import { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import Axios from 'axios';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRefresh, faCheck, faTrash, faWindowRestore, faTimes, faCogs, faImage, faUpload, faUnlock, faLock, faVideo, faVideoCamera, faDatabase } from '@fortawesome/free-solid-svg-icons';
import { FloatingLabel, InputGroup, Form, Card, Row, Col, Container, Badge, Dropdown, Modal, Button, Toast, FormLabel } from 'react-bootstrap';
import { urlEcommerceBackend } from '../recursos/configuracion';
import { conseguirBase64, conseguirFormato, reportar } from '../recursos/funcion';
import { Cargador } from '../componentes/Cargador';
import { useUsuarioContexto } from '../proveedor/usuario';
import ReactPlayer from 'react-player';

export default function Emergente() {

    const cuenta = useUsuarioContexto();

    const [vital, setVital] = useState(false);

    const parametro = useParams();
    const locacion = useLocation();
    const navegar = useNavigate();

    const [carga, setCarga] = useState(false);

    const [ruta, setRuta] = useState("");

    const [titulo, setTitulo] = useState("");
    const [descripcion, setDescripcion] = useState("");
    const [formato, setFormato] = useState("");
    const [archivo, setArchivo] = useState("");
    const [tiempo, setTiempo] = useState(0);
    const [imagenes, setImagenes] = useState([]);
    const [videos, setVideos] = useState([]);
    const [rutaImagen, setRutaImagen] = useState("");
    const [rutaVideo, setRutaVideo] = useState("");
    const [tituloVideo, setTituloVideo] = useState("");
    const [paginaUrl, setPaginaUrl] = useState("");
    const inicioRef = useRef(null)
    const finRef = useRef(null)

    const [elegido, setElegido] = useState(
        {
            codigo: "",
            nombre: "",
            ruta: "",
            tiempo: "",
            contenido: {
                texto: {
                    titulo: "",
                    descripcion: ""
                },
                imagenes: [],
                videos: []
            },
            configuracion: {
                programado: {
                    inicio: "",
                    fin: ""
                },
                persistente: false,
                tiempo: 0
            },
            estado: 0
        }
    );

    const [estadoSubir, setEstadoSubir] = useState(true);

    const [modal, setModal] = useState(false);

    const mostrarModal = () => setModal(true);
    const cerrarModal = () => setModal(false);

    const [modalVideo, setModalVideo] = useState(false);

    const mostrarModalVideo = () => setModalVideo(true);
    const cerrarModalVideo = () => setModalVideo(false);

    useEffect(() => {
        if (cuenta.token === '') {
            setVital(true);
        } else {
            setVital(false);
        }
    }, [cuenta]);

    useEffect(() => {
        setRuta(parametro.ruta);
    }, [locacion]);

    useEffect(() => {
        if (ruta !== "") {
            recuperar();
        }
    }, [ruta]);

    useEffect(() => {
        if (elegido !== '') {
            setTitulo(elegido.contenido.texto.titulo);
            setDescripcion(elegido.contenido.texto.descripcion);
            setImagenes(elegido.contenido.imagenes);
            setVideos(elegido.contenido.videos);
            setTiempo(elegido.configuracion.tiempo);
        }
    }, [elegido]);


    async function recuperar() {
        setCarga(true);
        try {
            let respuesta = await Axios.get(`${urlEcommerceBackend}/emergente/${ruta}`, {
                headers: {
                    authorization: `Bearer ${cuenta.token}`
                }
            });
            if (respuesta.data.codigo < 0) {
                setElegido(
                    {
                        codigo: "",
                        nombre: "",
                        ruta: "",
                        tiempo: "",
                        contenido: {
                            texto: {
                                titulo: "",
                                descripcion: ""
                            },
                            imagenes: [],
                            videos: []
                        },
                        configuracion: {
                            programado: {
                                inicio: "",
                                fin: ""
                            },
                            persistente: false
                        },
                        estado: 0
                    }
                );
            } else {
                setElegido(respuesta.data.objetoJson);
            }
            setCarga(false);
        } catch (error) {
            setCarga(false);
            await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "emergente.jsx", "recuperar", `${error.toString()}`, {
                ruta: ruta,
                error
            }, 1);
        }
    }

    function actualizar() {
        recuperar();
    }

    async function aplicar() {
        let parametros = {
            titulo: titulo,
            descripcion: descripcion,
            tiempo: tiempo,
            inicio:inicioRef.current.value,
            fin:finRef.current.value,

        }

        try {
            let respuesta = await Axios.put(`${urlEcommerceBackend}/emergente/${ruta}`, parametros, {
                headers: {
                    authorization: `Bearer ${cuenta.token}`
                }
            });
            setRutaImagen("");
            recuperar();
            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })
            Toast.fire({
                icon: 'success',
                title: respuesta.data.descripcion
            })
        } catch (error) {
            await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "emergente.jsx", "guardar", `${error.toString()}`, {
                parametros,
                error
            }, 1);
        }
    }

    function eliminar() {
        Swal.fire({
            icon: 'question',
            title: 'Esta seguro/a de eliminar el registro?',
            showDenyButton: true,
            confirmButtonText: 'Si',
            denyButtonText: 'No',
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    let respuesta = await Axios.delete(`${urlEcommerceBackend}/emergente/${ruta}`, {
                        headers: {
                            authorization: `Bearer ${cuenta.token}`
                        }
                    });
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'bottom-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer)
                            toast.addEventListener('mouseleave', Swal.resumeTimer)
                        }
                    })

                    Toast.fire({
                        icon: 'success',
                        title: respuesta.data.descripcion
                    })
                    navegar('/publicidad/emergentes/1/12');
                } catch (error) {
                    await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "emergente.jsx", "eliminar", `${error.toString()}`, {
                        ruta,
                        error
                    }, 1);
                }
            }
        });
    }

    async function persistencia() {
        let parametros = {
            persistente: elegido.configuracion.persistente
        }
        try {
            let respuesta = await Axios.put(`${urlEcommerceBackend}/emergente/${ruta}`, parametros, {
                headers: {
                    authorization: `Bearer ${cuenta.token}`
                }
            });
            recuperar();
            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })

            Toast.fire({
                icon: 'success',
                title: respuesta.data.descripcion
            })
        } catch (error) {
            await reportar((cuenta === null ? cuenta.usuario : cuenta.name), "Ecommerce Admin", "Frontend", "emergente.jsx", "persistencia", `${error.toString()}`, {
                ruta,
                error
            }, 1);
        }
    }

    async function estado() {
        let parametros = {
            estado: elegido.estado
        }
        try {
            let respuesta = await Axios.put(`${urlEcommerceBackend}/emergente/${ruta}`, parametros, {
                headers: {
                    authorization: `Bearer ${cuenta.token}`
                }
            });
            recuperar();
            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })

            Toast.fire({
                icon: 'success',
                title: respuesta.data.descripcion
            })
        } catch (error) {
            await reportar((cuenta === null ? cuenta.usuario : cuenta.name), "Ecommerce Admin", "Frontend", "emergente.jsx", "estado", `${error.toString()}`, {
                ruta,
                error
            }, 1);
        }
    }

    function subirImagen() {
        try {
            mostrarModal();
        } catch (error) {
         
        }
    }

    function subirVideos() {
        try {
            mostrarModalVideo();
        } catch (error) {
       
        }
    }

    function elegirArchivo() {
        let informacion = document.getElementById('formImagen').files[0];
        let reader = new FileReader();
        reader.readAsDataURL(informacion);
        reader.onloadend = () => {
            setEstadoSubir(false);
            setFormato(conseguirFormato(informacion.name));
            setArchivo(conseguirBase64(reader.result));
        };
    }

    async function guardar() {
        
        if (document.getElementById('formImagen').value === '') {
            Swal.fire(
                'Subida de imágenes',
                'No se ha seleccionado imagen',
                'info'
            )
        } else {
            setEstadoSubir(true);
            try {
                const posicion = document.getElementById('txtPosicionImg').value
                let parametros = {
                    formato: formato,
                    archivo: archivo,
                    codigo: elegido.codigo,
                    almacen: 'emergente',
                    posicion,
                    tamanos: [
                        {
                            ancho: 800,
                            alto: 600
                        }
                    ],
                    adicional: {
                        ruta: rutaImagen
                    }
                }
                let respuesta = await Axios.post(`${urlEcommerceBackend}/contenidos`, parametros, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        authorization: `Bearer ${cuenta.token}`
                    }
                });
                document.getElementById('formImagen').value = '';
                recuperar();
                cerrarModal();
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })

                Toast.fire({
                    icon: 'success',
                    title: respuesta.data.descripcion
                })
            } catch (error) {
                await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "galeria.jsx", "subir", `${error.toString()}`, {
                    error
                }, 1);
            }
        }
    }

    async function guardarVideo() {
        try {
            if (document.getElementById('txtTituloVideoGuardar').value === '') {
                Swal.fire(
                    'Subida de videos',
                    'No se ha seleccionado ruta de video',
                    'info'
                )

                return
            } 

            let parametrosVideo = {
                tituloVideo: document.getElementById('txtTituloVideoGuardar').value,
                rutaVideo: document.getElementById('txtUrlVideoGuardar').value,
                paginaUrl: document.getElementById('txtUrlPaginaGuardar').value,
                posicion: document.getElementById('txtPosicion').value,
                ruta: elegido.ruta,
            }
            let respuesta = await Axios.post(`${urlEcommerceBackend}/contenidos/videos`, parametrosVideo, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    authorization: `Bearer ${cuenta.token}`
                }
            });
            recuperar();
            cerrarModalVideo();
            Toast.fire({
                icon: 'success',
                title: respuesta.data.descripcion
            })
            
        } catch (error) {
            await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "galeria.jsx", "subirVideo", `${error.toString()}`, {
                error
            }, 1);
        }
    }

    function cancelar() {
        cerrarModal();
        cerrarModalVideo();
    }

    async function modificar(codigo, variante, ruta,llave) {
        try {
            let parametros = {
                ruta: document.getElementById(ruta).value,
                posicion:document.getElementById(`txtPosicionImgModificar${llave}`).value
            }
            let respuesta = await Axios.put(`${urlEcommerceBackend}/emergente/${codigo}/${variante}`, parametros);
            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })

            Toast.fire({
                icon: 'success',
                title: respuesta.data.descripcion
            })
        } catch (error) {
        }
    }

    async function modificarVideo(video, llave) {
        try {
            let parametros = {
                ruta: elegido.ruta,
                tituloVideo: document.getElementById(`txtTituloVideoModificar${llave}`).value,
                rutaVideo: document.getElementById(`txtRutaVideoModificar${llave}`).value,
                paginaUrl: document.getElementById(`txtUrlPaginaModificar${llave}`).value,
                posicion: document.getElementById(`txtPosicionModificar${llave}`).value,
                llave
            }
            let respuesta = await Axios.put(`${urlEcommerceBackend}/contenidos/videos`, parametros);
            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })
            recuperar();
            Toast.fire({
                icon: 'success',
                title: respuesta.data.descripcion
            })
        } catch (error) {
        }
    }

    async function bajar(variante, formato) {
        Swal.fire({
            icon: 'question',
            title: 'Esta seguro/a de eliminar esta imagen?',
            showDenyButton: true,
            confirmButtonText: 'Si',
            denyButtonText: 'No',
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    let parametros = {
                        codigo: elegido.codigo,
                        variante: variante,
                        formato: formato,
                        almacen: 'emergente'
                    }
                    let respuesta = await Axios.delete(`${urlEcommerceBackend}/contenidos`, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            authorization: `Bearer ${cuenta.token}`
                        },
                        data: parametros
                    });
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'bottom-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer)
                            toast.addEventListener('mouseleave', Swal.resumeTimer)
                        }
                    })

                    Toast.fire({
                        icon: (respuesta.data.codigo === 0 ? 'success' : 'info'),
                        title: respuesta.data.descripcion
                    })
                    actualizar();
                } catch (error) {
     
                }
            }
        });
    }

    async function bajarVideo(video, llave) {
        Swal.fire({
            icon: 'question',
            title: 'Esta seguro/a de eliminar este video?',
            showDenyButton: true,
            confirmButtonText: 'Si',
            denyButtonText: 'No',
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    let parametros = {
                        ruta: elegido.ruta,
                        llave
                        //rutaVideo: video
                        //ruta: get
                    }
                    let respuesta = await Axios.delete(`${urlEcommerceBackend}/contenidos/videos`, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            authorization: `Bearer ${cuenta.token}`
                        },
                        data: parametros
                    });
                    recuperar();
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'bottom-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer)
                            toast.addEventListener('mouseleave', Swal.resumeTimer)
                        }
                    })

                    Toast.fire({
                        icon: (respuesta.data.codigo === 0 ? 'success' : 'info'),
                        title: respuesta.data.descripcion
                    })

                } catch (error) {
    
                }
            }

        });

    }

    return (
        <>
            <Container fluid className='mt-3 mb-3'>
                <Row className='mb-3'>
                    <Col md={12}>
                        <h1 className='d-flex gap-2'>
                            {(carga === true ? <Cargador estado={true} /> : <FontAwesomeIcon icon={faWindowRestore} />)} Emergente
                             {elegido.estado === 1 ? 
                            <Badge bg='success'><FontAwesomeIcon icon={faCheck} />Activo</Badge> 
                            : <Badge bg='danger'><FontAwesomeIcon icon={faTimes} />Inactivo</Badge>}
                            {elegido.configuracion.persistente ? 
                            <Badge bg='success'><FontAwesomeIcon icon={faLock} />Persistir</Badge> 
                            : <Badge bg='danger'><FontAwesomeIcon icon={faUnlock} />No persistir</Badge>}
                        </h1>
                    </Col>

                    <Col md={12} className='mt-2 mb-2'>
                        <Card>
                            <Card.Body>
                                <Row>
                                    <Col md={12} className='mt-2 mb-3'>
                                        <Dropdown>
                                            <Dropdown.Toggle variant="outline-primary" id="cbOpciones">
                                                <FontAwesomeIcon icon={faCogs} /> Opciones
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item href="#" onClick={aplicar} disabled={vital}>
                                                    <FontAwesomeIcon icon={faCheck} /> Aplicar cambios
                                                </Dropdown.Item>
                                                <Dropdown.Item href="#" onClick={subirImagen} disabled={vital}>
                                                    <FontAwesomeIcon icon={faImage} /> Subir imagen
                                                </Dropdown.Item>
                                                <Dropdown.Item href="#" onClick={subirVideos} disabled={vital}>
                                                    <FontAwesomeIcon icon={faVideo} /> Subir video
                                                </Dropdown.Item>
                                                <Dropdown.Item href="#" onClick={persistencia} disabled={vital}>
                                                    <FontAwesomeIcon icon={elegido.configuracion.persistente ? faLock : faUnlock} /> Persistencia
                                                </Dropdown.Item>
                                                <Dropdown.Item href="#" onClick={estado} disabled={vital}>
                                                    <FontAwesomeIcon icon={elegido.estado === 1 ? faCheck : faTimes} /> Estado
                                                </Dropdown.Item>
                                                <Dropdown.Item href="#" onClick={actualizar} disabled={vital}>
                                                    <FontAwesomeIcon icon={faRefresh} /> Actualizar
                                                </Dropdown.Item>
                                                <Dropdown.Divider />
                                                <Dropdown.Item href="#" onClick={eliminar} disabled={vital}>
                                                    <FontAwesomeIcon icon={faTrash} /> Eliminar
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Col>
                                    <Col md={12} className='mt-1 mb-3'>
                                        <Card>
                                            <Card.Body>
                                                <Card.Title>
                                                    <FontAwesomeIcon icon={faDatabase} className='text-dark'></FontAwesomeIcon> Datos
                                                </Card.Title>
                                                <Row>
                                                    <Col md={12}>
                                                        <Row>
                                                            <Col md={12} className='mt-1 mb-3'>
                                                                <FloatingLabel controlId="cbTiempo" label="Tiempo para el cierre (Segundos)">
                                                                    <Form.Select value={tiempo} onChange={(event) => setTiempo(parseInt(event.target.value))}>
                                                                        <option value="0">0</option>
                                                                        <option value="1000">1</option>
                                                                        <option value="2000">2</option>
                                                                        <option value="3000">3</option>
                                                                        <option value="4000">4</option>
                                                                        <option value="5000">5</option>
                                                                        <option value="6000">6</option>
                                                                        <option value="7000">7</option>
                                                                        <option value="8000">8</option>
                                                                        <option value="9000">9</option>
                                                                        <option value="10000">10</option>
                                                                        <option value="11000">11</option>
                                                                        <option value="12000">12</option>
                                                                        <option value="13000">13</option>
                                                                        <option value="14000">14</option>
                                                                        <option value="15000">15</option>
                                                                        <option value="16000">16</option>
                                                                        <option value="17000">17</option>
                                                                        <option value="18000">18</option>
                                                                        <option value="19000">19</option>
                                                                        <option value="20000">20</option>
                                                                    </Form.Select>
                                                                </FloatingLabel>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    {/* checkpoint */}
                                                    <Col md={6}>
                                                        <FloatingLabel controlId='txtInicio' label='Inicio'>
                                                        <Form.Control type='date' placeholder='Inicio' ref={inicioRef} defaultValue={elegido.configuracion.programado.inicio}/>
                                                        </FloatingLabel>
                                                    </Col>
                                                    <Col md={6}>
                                                        <FloatingLabel controlId='txtFin' label='Fin'>
                                                        <Form.Control type='date' placeholder='Final' ref={finRef} defaultValue={elegido.configuracion.programado.fin}/>
                                                        </FloatingLabel>
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col md={12} className='mt-1 mb-3'>
                                        <Card>
                                            <Card.Body>
                                                <Card.Title >
                                                    <FontAwesomeIcon icon={faImage}></FontAwesomeIcon> Imágenes
                                                </Card.Title>
                                                <Row>
                                                    {
                                                        imagenes.map((imagen, llave) => (<Col md={4} key={llave}>
                                                            <Card>
                                                                <Card.Body>
                                                                <InputGroup className="mb-1 d-flex align-items-center justify-content-between">
                                                                    <FormLabel className='d-flex p-1'>Posicion:</FormLabel>
                                                                    <Form.Control type="number" id={`txtPosicionImgModificar${llave}`} placeholder="Posicion" defaultValue={imagen.posicion} className='mb-2 d-inline-flex' />
                                                                </InputGroup>
                                                                    <InputGroup>
                                                                        <Form.Control type="text" id={`txtRuta${llave}`} placeholder="Ruta" defaultValue={imagen.ruta} />
                                                                        <Button variant="outline-primary" onClick={() => {
                                                                            modificar(elegido.codigo, imagen.variante, `txtRuta${llave}`,llave);
                                                                        }}><FontAwesomeIcon icon={faRefresh} /></Button>
                                                                        <Button variant="outline-danger" onClick={() => {
                                                                            bajar(imagen.variante, imagen.formato);
                                                                        }}><FontAwesomeIcon icon={faTrash} /></Button>
                                                                    </InputGroup>

                                                                    <Card.Img src={imagen.imagen} className='mt-3'></Card.Img>
                                                                </Card.Body>
                                                            </Card>
                                                        </Col>))
                                                    }
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col md={12} className='mt-1 mb-3'>
                                        <Card>
                                            <Card.Body>
                                                <Card.Title>
                                                    <FontAwesomeIcon icon={faVideoCamera}></FontAwesomeIcon>  Videos
                                                </Card.Title>
                                                <Row>
                                                    {videos.map((video, llave) => <Col md={4} key={llave}>
                                                        <Card>
                                                            <Card.Body>
                                                                <InputGroup className="mb-1 d-flex align-items-center justify-content-between">
                                                                    <FormLabel className='d-flex p-1'>{`Titulo:`}</FormLabel>
                                                                    <Form.Control type="text" id={`txtTituloVideoModificar${llave}`} placeholder="Titulo" defaultValue={video.titulo} className='mb-2 d-inline-flex' onChange={(event) => setTituloVideo(event.target.value)} />
                                                                </InputGroup>
                                                                <InputGroup className="mb-1 d-flex align-items-center justify-content-between">
                                                                    <FormLabel className='d-flex p-1'>{`Video:`}</FormLabel>
                                                                    <Form.Control type="text" id={`txtRutaVideoModificar${llave}`} placeholder="Ruta" defaultValue={video.link} className='mb-2' onChange={(event) => setRutaVideo(event.target.value)} />
                                                                    <Button variant="outline-primary" onClick={() => {
                                                                        modificarVideo(video, llave);
                                                                    }}><FontAwesomeIcon icon={faRefresh} /></Button>
                                                                    <Button variant="outline-danger" onClick={() => {
                                                                        bajarVideo(video, llave);
                                                                    }}><FontAwesomeIcon icon={faTrash} /></Button>
                                                                </InputGroup>
                                                                <InputGroup className="mb-1 d-flex align-items-center justify-content-between">
                                                                    <FormLabel className='d-flex p-1'>Posicion:</FormLabel>
                                                                    <Form.Control type="number" id={`txtPosicionModificar${llave}`} placeholder="Posicion" defaultValue={video.posicion} className='mb-2 d-inline-flex' />
                                                                </InputGroup>
                                                                <InputGroup className='mb-2'>
                                                                    <ReactPlayer controls url={video.link} width={"100%"}>
                                                                    </ReactPlayer>
                                                                </InputGroup>
                                                                <InputGroup className="mb-1 d-flex align-items-center justify-content-between">
                                                                    <FormLabel className='d-flex p-1'>{`URL de boton:`}</FormLabel>
                                                                    <Form.Control type="text" id={`txtUrlPaginaModificar${llave}`} placeholder="Enlacepagina" defaultValue={video.urlPagina} className='mb-2' onChange={(event) => setPaginaUrl(event.target.value)} />
                                                                </InputGroup>
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>)}
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>

            <Modal show={modal} onHide={cancelar} backdrop="static" keyboard={false} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <FontAwesomeIcon icon={faImage} /> Imagen (800x600)
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <center>
                                <FloatingLabel controlId="txtUrl" label="Ruta" className="mb-3" >
                                    <Form.Control type="text" size="lg" placeholder="Ruta" value={rutaImagen} onChange={(event) => setRutaImagen(event.target.value)} />
                                </FloatingLabel>
                                <FloatingLabel label="Posicion" className="mb-3" >
                                    <Form.Control id="txtPosicionImg" type="number" size="lg" placeholder="Posicion" />
                                </FloatingLabel>
                                <Form.Group controlId="formImagen">
                                    <Form.Control type="file" size="lg" onChange={elegirArchivo} accept='image/*' />
                                </Form.Group>
                                <div className="d-grid gap-2 mt-3 mb-3">
                                    <Button variant="outline-success" size="lg" onClick={guardar} disabled={estadoSubir}>
                                        <FontAwesomeIcon icon={faUpload} />Guardar
                                    </Button>
                                </div>
                            </center>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>

            <Modal show={modalVideo} onHide={cancelar} backdrop="static" keyboard={false} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <FontAwesomeIcon icon={faVideo} /> Video
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <center>
                                <FloatingLabel label="Titulo" className="mb-3" >
                                    <Form.Control id="txtTituloVideoGuardar" type="text" size="lg" placeholder="Titulo" />
                                </FloatingLabel>
                                <FloatingLabel label="Ruta" className="mb-3" >
                                    <Form.Control id="txtUrlVideoGuardar" type="text" size="lg" placeholder="Ruta" />
                                </FloatingLabel>
                                <FloatingLabel label="Enlace Pagina" className="mb-3" >
                                    <Form.Control id="txtUrlPaginaGuardar" type="text" size="lg" placeholder="Enlace Pagina" />
                                </FloatingLabel>
                                <FloatingLabel label="Posicion" className="mb-3" >
                                    <Form.Control id="txtPosicion" type="number" size="lg" placeholder="Posicion" />
                                </FloatingLabel>
                                <div className="d-grid gap-2 mt-3 mb-3">
                                    <Button variant="outline-success" size="lg" onClick={guardarVideo}>
                                        <FontAwesomeIcon icon={faUpload} />Guardar
                                    </Button>
                                </div>
                            </center>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    );
}