import { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { obtenerInterfaz, reportar, separarDigitos } from '../recursos/funcion';
import { urlEcommerceBackend } from '../recursos/configuracion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faCubes, faEye, faEyeSlash, faFilter, faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'
import { Card, InputGroup, Form, Pagination, Button, FloatingLabel, Offcanvas, Table } from 'react-bootstrap';
import Axios from 'axios';
import { BarraNavegacion } from '../componentes/BarraNavegacion';
import { Cargador } from '../componentes/Cargador';
import { useUsuarioContexto } from '../proveedor/usuario';

export default function Productos() {

    const cuenta = useUsuarioContexto();
    const Interfaz = 'producto';

    const parametro = useParams();
    const locacion = useLocation();
    const navegar = useNavigate();

    const [pagina, setPagina] = useState(0);
    const [intervalo, setIntevalo] = useState(0);

    const [datos, setDatos] = useState([]);
    const [paginas, setPaginas] = useState([]);
    const [paginado, setPaginado] = useState(
        {
            inicio: 0,
            fin: 0
        }
    );

    const [carga, setCarga] = useState(false);

    const [codigo, setCodigo] = useState(0);

    const establecerIntervalo = () => {
        navegar(`/productos/${pagina}/${document.getElementById('cbInterval').value}/${marca}/${categoria}`);
    }

    const [marcas, setMarcas] = useState([]);
    const [marca, setMarca] = useState("tm");
    const establecerMarca = () => {
        navegar(`/productos/${pagina}/${intervalo}/${document.getElementById('cbMarca').value}/${categoria}`);
    }

    const [categorias, setCategorias] = useState([]);
    const [categoria, setCategoria] = useState("tc");
    const establecerCategoria = () => {
        navegar(`/productos/${pagina}/${intervalo}/${marca}/${document.getElementById('cbCategoria').value}`);
    }

    const [filtros, setFiltros] = useState(false);

    const cerrarFiltros = () => setFiltros(false);
    const mostrarFiltros = () => setFiltros(true);


    useEffect(() => {
        cargarSeleccion();
        setCodigo(0);
    }, []);

    useEffect(() => {
        setPagina(parseInt(parametro.pagina));
        setIntevalo(parseInt(parametro.intervalo));
        setMarca(parametro.marca === undefined ? 'tm' : parametro.marca);
        setCategoria(parametro.categoria === undefined ? 'tc' : parametro.categoria);
    }, [locacion]);

    useEffect(() => {
        if (pagina !== 0 && intervalo !== 0) {
            recuperar();
        }
    }, [cuenta, pagina, intervalo, marca, categoria]);

    /*async function recolector() {
        if (codigo === '') {
            codigo = 0
        }
        navegar(`/productos/${pagina}/${intervalo}/${codigo}/${marca}/${categoria}`);
    }*/

    async function cargarSeleccion() {
        try {
            let respuesta = await Axios.get(urlEcommerceBackend + '/consultas/marca');
            setMarcas(respuesta.data.arrayJson);
        } catch (error) {
            await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "productos.jsx", "cargarSeleccion", `${error.toString()}`, {
                error
            }, 1);
        }
        try {
            let respuesta = await Axios.get(urlEcommerceBackend + '/categorias');
            setCategorias(respuesta.data.arrayJson);
        } catch (error) {
            await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "productos.jsx", "cargarSeleccion", `${error.toString()}`, {
                error
            }, 1);
        }
    }

    async function recuperar() {
        setCarga(true);
        try {
            let respuesta = await Axios.get(`${urlEcommerceBackend}/productos?pagina=${pagina}&intervalo=${intervalo}&marca=${marca}&categoria=${categoria}`, {
                headers: {
                    authorization: `Bearer ${cuenta.token}`
                }
            });
            setDatos(respuesta.data.arrayJson);
            setPaginas(respuesta.data.objetoJson.paginas);
            setPaginado(respuesta.data.objetoJson.paginado);
            setCarga(false);
        } catch (error) {
            setCarga(false);
            await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "productos.jsx", "recuperar", `${error.toString()}`, {
                error
            }, 1);
        }
    }

    async function buscarCodigo() {
        if (codigo === 0) {
            recuperar();
        } else {
            let respuesta = [];
            setCarga(true);
            try {
                respuesta = await Axios.get(`${urlEcommerceBackend}/productos/${codigo}`, {
                    headers: {
                        authorization: `Bearer ${cuenta.token}`
                    }
                });
                setDatos(respuesta.data.arrayJson);
                setPaginas(respuesta.data.objetoJson.paginas);
                setPaginado(respuesta.data.objetoJson.paginado);
                setCarga(false);
            } catch (error) {
                await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "productos.jsx", "buscarCodigo", `${error.toString()}`, {
                    error
                }, 1);
            }
        }
    }

    function dirigir(paginanueva) {
        if (paginanueva === 0) {

        } else {
            navegar(`/productos/${paginanueva}/${intervalo}/${marca}/${categoria}`);
        }
    }

    function dirigirProducto(codigo) {
        navegar(`/producto/${codigo}`);
    }

    const eventoEnter = (event) => {
        if (document.getElementById('txtCodigo').value === '') {
            setCodigo(0);
        } else {
            setCodigo(document.getElementById('txtCodigo').value);
        }
        if (event.key === 'Enter') {
            buscarCodigo();
        }
    }

    return (
        <>
            <div className='container-fluid mt-3 mb-3'>
                <div className='row mb-3'>
                    <div className='col-md-12'>
                        <h1>
                            {(carga === true ? <Cargador estado={true} /> : <FontAwesomeIcon icon={faCubes} />)}Productos
                        </h1>
                    </div>
                    <div className='col-md-12 mt-2'>
                        <BarraNavegacion paginas={[
                            {
                                direccion: '/principal',
                                nombre: 'Principal'
                            },
                            {
                                direccion: '/productos/1/10/tm/tc',
                                nombre: 'Productos'
                            }
                        ]} />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <Card border="secondary">
                            <Card.Body>
                                <div className='row'>
                                    <div className='col-md-6 mt-2 mb-2'>
                                        <Button variant='outline-secondary' onClick={mostrarFiltros}>
                                            <FontAwesomeIcon icon={faFilter} />Filtro
                                        </Button>
                                    </div>
                                    <div className='col-md-6 mt-2 mb-2'>
                                        <div className="table-responsive">
                                            {
                                                (paginas.length === 0 ? '' : <Pagination className='d-flex justify-content-end'>
                                                    {(parseInt(pagina) === 1)}
                                                    <Pagination.First onClick={() => {
                                                        dirigir(paginado.inicio);
                                                    }} disabled={parseInt(pagina) === 1} />
                                                    <Pagination.Prev onClick={() => {
                                                        dirigir(parseInt(pagina) - 1);
                                                    }} disabled={parseInt(pagina) === 1} />
                                                    {((Math.ceil(pagina / intervalo) * intervalo) === (Math.ceil(paginado.inicio / intervalo) * intervalo) ? '' : <><Pagination.Item onClick={() => {
                                                        dirigir(paginado.inicio);
                                                    }} disabled={(parseInt(pagina) === paginado.inicio)}>{paginado.inicio}</Pagination.Item><Pagination.Ellipsis onClick={() => {
                                                        dirigir(parseInt(pagina) - 10)
                                                    }} /></>)}

                                                    {
                                                        paginas.map((paginaOpcion, llave) => (
                                                            <Pagination.Item key={llave} onClick={() => {
                                                                dirigir((paginaOpcion === parseInt(pagina) ? 0 : paginaOpcion))
                                                            }} active={paginaOpcion === parseInt(pagina)}>{paginaOpcion}</Pagination.Item>
                                                        ))
                                                    }

                                                    {((Math.ceil(pagina / intervalo) * intervalo) === (Math.ceil(paginado.fin / intervalo) * intervalo) ? '' : <><Pagination.Ellipsis onClick={() => {
                                                        dirigir(parseInt(pagina) + 10)
                                                    }} /><Pagination.Item onClick={() => {
                                                        dirigir(paginado.fin);
                                                    }} disabled={(parseInt(pagina) === paginado.fin)}>{paginado.fin}</Pagination.Item></>)}
                                                    <Pagination.Next onClick={() => {
                                                        dirigir(parseInt(pagina) + 1);
                                                    }} disabled={parseInt(pagina) === paginado.fin} />
                                                    <Pagination.Last onClick={() => {
                                                        dirigir(paginado.fin);
                                                    }} disabled={parseInt(pagina) === paginado.fin} />
                                                </Pagination>)
                                            }
                                        </div>
                                    </div>
                                    <div className='col-md-12 table-responsive'>
                                        <Table bordered hover size="lg" className='table-dark'>
                                            <thead>
                                                <tr>
                                                    {
                                                        (obtenerInterfaz(cuenta.interfaces, Interfaz) ? <th>Acción</th> : '')
                                                    }
                                                    <th>Imagen</th>
                                                    <th>Código</th>
                                                    <th>Marca</th>
                                                    <th>Modelo</th>
                                                    <th>Nombre</th>
                                                    <th>Ruta</th>
                                                    <th>Precio Gs</th>
                                                    <th>Cantidad</th>
                                                    <th>Deposito</th>
                                                    <th>Estado</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {datos.map((dato, llave) => (
                                                    <tr key={llave} className={`align-middle ${(dato.estado === 1 ? 'text-success' : 'text-danger')}`}>
                                                        {
                                                            (obtenerInterfaz(cuenta.interfaces, Interfaz) ? <td className='text-center'>
                                                                <Button variant={(dato.estado === 1 ? 'success' : 'danger')} onClick={() => {
                                                                    dirigirProducto(dato.codigo)
                                                                }}>
                                                                    <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                                                                </Button>
                                                            </td> : '')
                                                        }
                                                        <td className='text-center'>
                                                            <img src={
                                                                dato.imagenes.length > 0 ? dato.imagenes[0].url['100'] : "https://csdigitalizacion.nyc3.cdn.digitaloceanspaces.com/ecommerce/logos/store/lcng/100.png"
                                                            } alt="" height='' width='' />
                                                        </td>
                                                        <td className='text-end'>{separarDigitos(dato.codigo)}</td>
                                                        <td>{dato.marca.nombre}</td>
                                                        <td>{dato.modelo}</td>
                                                        <td>{dato.nombre}</td>
                                                        <td>{dato.ruta}</td>
                                                        <td className='text-end'>{parseInt(dato.precio).toLocaleString('es-PY')}</td>
                                                        <td className='text-end'>{parseInt(dato.cantidad).toLocaleString('es-PY')}</td>
                                                        <td>{dato.deposito}</td>
                                                        <td>{<FontAwesomeIcon icon={(dato.estado === 0 ? faEyeSlash : faEye)} />}{(dato.estado === 1 ? 'Visible' : 'No visible')}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </div>
            <Offcanvas show={filtros} onHide={cerrarFiltros} placement='bottom' className='bg-dark text-white'>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title><FontAwesomeIcon icon={faFilter} />Filtros</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className='row'>
                        <div className='col-md-12 mt-2'>
                            <InputGroup className="mb-3">
                                <Button variant="outline-success" onClick={buscarCodigo}>
                                    <FontAwesomeIcon icon={faSearch} /> Consultar
                                </Button>
                                <Form.Control type='text' aria-label="Example text with button addon" aria-describedby="basic-addon1" placeholder="Busqueda por Código" id='txtCodigo' onKeyUp={eventoEnter} />
                            </InputGroup>
                        </div>
                        <div className='col-md-5 mt-2'>
                            <FloatingLabel controlId="cbMarca" label="Marca" className="text-dark">
                                <Form.Select aria-label="Marca" defaultValue={marca} onChange={() => {
                                    establecerMarca();
                                }}>
                                    <option value="sm">Mostrar sin marca</option>
                                    <option value="tm">Mostrar todas las marcas</option>
                                    {
                                        marcas.map((marca, key) => (
                                            <option value={marca.ruta} key={key}>{marca.nombre}</option>
                                        ))
                                    }
                                </Form.Select>
                            </FloatingLabel>
                        </div>
                        <div className='col-md-5 mt-2'>
                            <FloatingLabel controlId="cbCategoria" label="Categoria" className="text-dark">
                                <Form.Select aria-label="Categoria" defaultValue={categoria} onChange={() => {
                                    establecerCategoria();
                                }}>
                                    <option value="sc">Mostrar sin categoria</option>
                                    <option value="tc">Mostrar todas las categoria</option>
                                    {
                                        categorias.map((categoria, key) => (
                                            <option value={categoria.ruta} key={key}>{categoria.nombre}</option>
                                        ))
                                    }
                                </Form.Select>
                            </FloatingLabel>
                        </div>
                        <div className='col-md-2 mt-2'>
                            <FloatingLabel controlId="cbInterval" label="Cantidad de registros" className="text-dark">
                                <Form.Select aria-label="Floating label select example" defaultValue={intervalo} onChange={() => {
                                    establecerIntervalo();
                                }}>
                                    <option value="4">4</option>
                                    <option value="6">6</option>
                                    <option value="8">8</option>
                                    <option value="10">10</option>
                                    <option value="12">12</option>
                                    <option value="20">20</option>
                                    <option value="30">30</option>
                                    <option value="40">40</option>
                                    <option value="50">50</option>
                                </Form.Select>
                            </FloatingLabel>
                        </div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}