import { useState, useEffect } from 'react';
import Axios from 'axios';
import { urlEcommerceBackend } from '../recursos/configuracion';
import { Card, Accordion, ListGroup, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbtack, faCogs, faRefresh, faCube } from '@fortawesome/free-solid-svg-icons';
import { reportar } from '../recursos/funcion';
import { Cargador } from '../componentes/Cargador';
import { useUsuarioContexto } from '../proveedor/usuario';

export default function Categorias() {

    const cuenta = useUsuarioContexto();

    const [datos, setDatos] = useState([]);

    const [carga, setCarga] = useState(false);

    useEffect(() => {
        recuperar();
    }, [cuenta]);

    async function recuperar() {
        setCarga(true);
        try {
            let respuesta = await Axios.get(`${urlEcommerceBackend}/categorias`, {
                headers: {
                    authorization: `Bearer ${cuenta.token}`
                }
            });
            setCarga(false);
            setDatos(respuesta.data.arrayJson);
        } catch (error) {
            setCarga(false);
            await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "categorias.jsx", "recuperar", `${error.toString()}`, {
                error
            }, 1);
        }
    }

    function actualizar() {
        recuperar();
    }

    return (
        <>
            <div className='container-fluid mt-3 mb-3'>
                <div className='row mb-3'>
                    <div className='col-md-12 mt-2 mb-2'>
                        <center>
                            <h1>
                                {(carga === true ? <Cargador estado={true} /> : <FontAwesomeIcon icon={faThumbtack} />)}Categoria
                            </h1>
                        </center>
                    </div>
                    <div className='col-md-12 mt-2 mb-2'>
                        <Dropdown>
                            <Dropdown.Toggle variant="outline-primary" id="cbOpciones">
                                <FontAwesomeIcon icon={faCogs} /> Opciones
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item href="#" onClick={actualizar}>
                                    <FontAwesomeIcon icon={faRefresh} /> Actualizar
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div className='col-md-12 mt-2 mb-2'>
                        <Card>
                            <Card.Body>
                                <Accordion>
                                    {
                                        datos.map((dato, llave) => (
                                            <Accordion.Item eventKey={llave} key={llave}>
                                                <Accordion.Header>{(llave + 1) + ' -'} {dato.nombre} ({dato.cantidad})</Accordion.Header>
                                                <Accordion.Body className='bg-secondary'>
                                                    <ListGroup as="ol" numbered>
                                                        {
                                                            dato.subcategoria.map((item, clave) => (
                                                                <ListGroup.Item as="li" key={clave} action>{item.nombre} ({item.cantidad})</ListGroup.Item>
                                                            ))
                                                        }
                                                    </ListGroup>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        ))
                                    }
                                </Accordion>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className='col-md-12'>
                        <div className='row'></div>
                    </div>
                </div>
            </div>
        </>
    );
}