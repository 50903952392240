import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping, faCircleCheck, faCircleExclamation, faMoneyCheck, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { Badge } from 'react-bootstrap';

export function Estado(props) {
    let estado = parseInt(props.estado);
    let tipo = parseInt(props.tipo);
    let formato = parseInt(props.formato);
    if (tipo === 0) {            // Carrito
        if (formato === 0) {            // Información
            if (estado === 0) {
                return (
                    <>
                        <FontAwesomeIcon icon={faCartShopping} /><FontAwesomeIcon icon={faCircleCheck} /> {props.descripcion}
                    </>
                );
            } else if (estado === 1) {
                return (
                    <>
                        <FontAwesomeIcon icon={faCartShopping} /><FontAwesomeIcon icon={faCircleExclamation} /> {props.descripcion}
                    </>
                );
            } else if (estado === 2) {
                return (
                    <>
                        <FontAwesomeIcon icon={faCartShopping} /><FontAwesomeIcon icon={faXmarkCircle} /> {props.descripcion}
                    </>
                );
            }
        } else if (formato === 1) {     // Etiqueta
            if (estado === 0) {
                return (
                    <>
                        <Badge bg='success'>
                            <FontAwesomeIcon icon={faCircleCheck} /> {props.descripcion}
                        </Badge>
                    </>
                );

            } else if (estado === 1) {
                return (
                    <>
                        <Badge bg='warning' text='black'>
                            <FontAwesomeIcon icon={faCircleExclamation} /> {props.descripcion}
                        </Badge>
                    </>
                );
            } else if (estado === 2) {
                return (
                    <>
                        <Badge bg='danger'>
                            <FontAwesomeIcon icon={faXmarkCircle} /> {props.descripcion}
                        </Badge>
                    </>
                );
            }
        }
    } else if (tipo === 1) {     // Solicitud
        if (formato === 0) {            // Información
            if (estado === 0) {
                return (
                    <>
                        <FontAwesomeIcon icon={faMoneyCheck} /><FontAwesomeIcon icon={faCircleCheck} /> {props.descripcion}
                    </>
                );
            } else if (estado === 1) {
                return (
                    <>
                        <FontAwesomeIcon icon={faMoneyCheck} /><FontAwesomeIcon icon={faCircleExclamation} /> {props.descripcion}
                    </>
                );
            } else if (estado === 2) {
                return (
                    <>
                        <FontAwesomeIcon icon={faMoneyCheck} /><FontAwesomeIcon icon={faXmarkCircle} /> {props.descripcion}
                    </>
                );
            }
        } else if (formato === 1) {     // Etiqueta
            if (estado === 0) {
                return (
                    <>
                        <Badge bg='success'>
                            <FontAwesomeIcon icon={faCircleCheck} /> {props.descripcion}
                        </Badge>
                    </>
                );

            } else if (estado === 1) {
                return (
                    <>
                        <Badge bg='warning' text='black'>
                            <FontAwesomeIcon icon={faCircleExclamation} /> {props.descripcion}
                        </Badge>
                    </>
                );
            } else if (estado === 2) {
                return (
                    <>
                        <Badge bg='danger'>
                            <FontAwesomeIcon icon={faXmarkCircle} /> {props.descripcion}
                        </Badge>
                    </>
                );
            }
        }
    }
}