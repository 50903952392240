import { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { obtenerFechaDeTiempo, obtenerHoraDeTiempo } from '../recursos/funcion';
import { urlEcommerceBackend } from '../recursos/configuracion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faFilter, faArrowUpRightFromSquare, faUsersGear, faCheck, faTimes, faRefresh, faCogs } from '@fortawesome/free-solid-svg-icons'
import { Card, InputGroup, Form, Pagination, Button, FloatingLabel, Offcanvas, Table, Dropdown } from 'react-bootstrap';
import Axios from 'axios';
import { BarraNavegacion } from '../componentes/BarraNavegacion';
import { Cargador } from '../componentes/Cargador';
import { useUsuarioContexto } from '../proveedor/usuario';
import { reportar } from '../recursos/funcion';

export default function Cuentas() {

    const cuenta = useUsuarioContexto();

    const Interfaz = 'cuentas';

    const parametro = useParams();
    const locacion = useLocation();
    const navegar = useNavigate();

    const [pagina, setPagina] = useState(0);
    const [intervalo, setIntevalo] = useState(0);

    const [datos, setDatos] = useState([]);
    const [paginas, setPaginas] = useState([]);
    const [paginado, setPaginado] = useState(
        {
            inicio: 0,
            fin: 0
        }
    );

    const [carga, setCarga] = useState(false);

    const [codigo, setCodigo] = useState(0);

    const establecerIntervalo = () => {
        navegar(`/cuentas/${pagina}/${document.getElementById('cbInterval').value}`);
    }

    const [filtros, setFiltros] = useState(false);

    const cerrarFiltros = () => setFiltros(false);
    const mostrarFiltros = () => setFiltros(true);

    useEffect(() => {
        setPagina(parseInt(parametro.pagina));
        setIntevalo(parseInt(parametro.intervalo));
    }, [locacion]);

    useEffect(() => {
        if (pagina !== 0 && intervalo !== 0) {
            actualizar();
        }
    }, [cuenta, pagina, intervalo]);

    async function actualizar() {
        setCarga(true);
        try {
            let respuesta = await Axios.get(`${urlEcommerceBackend}/cuentas?pagina=${pagina}&intervalo=${intervalo}`, {
                headers: {
                    authorization: `Bearer ${cuenta.token}`
                }
            });
            setDatos(respuesta.data.arrayJson);
            setPaginas(respuesta.data.objetoJson.paginas);
            setPaginado(respuesta.data.objetoJson.paginado);
            setCarga(false);
        } catch (error) {
            setCarga(false);
            await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "cuentas.jsx", "recuperar", `${error.toString()}`, {
                error
            }, 1);
        }
    }

    async function buscarCodigo() {
        //if (codigo === 0) {
        actualizar();
        /*} else {
            let respuesta = [];
            setCarga(true);
            try {
                respuesta = await Axios.get(`${urlEcommerceBackend}/cuentas/${codigo}`);
                setDatos(respuesta.data.arrayJson);
                setPaginas(respuesta.data.objetoJson.paginas);
                setPaginado(respuesta.data.objetoJson.paginado);
                setCarga(false);
            } catch (error) {
                setCarga(false);
                await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "cuentas.jsx", "buscarCodigo", `${error.toString()}`, {
                    error
                }, 1);
            }
        }*/
    }

    function dirigir(paginanueva) {
        if (paginanueva === 0) {

        } else {
            navegar(`/cuentas/${paginanueva}/${intervalo}`);
        }
    }

    function dirigirCuenta(codigo) {
        navegar(`/cuenta/${codigo}`);
    }

    const eventoEnter = (event) => {
        if (document.getElementById('txtCodigo').value === '') {
            setCodigo(0);
        } else {
            setCodigo(parseInt(document.getElementById('txtCodigo').value));
        }
        if (event.key === 'Enter') {
            buscarCodigo();
        }
    }

    return (
        <>
            <div className='container-fluid mt-3 mb-3'>
                <div className='row mb-3'>
                    <div className='col-md-12'>
                        <h1>
                            {(carga === true ? <Cargador estado={true} /> : <FontAwesomeIcon icon={faUsersGear} />)}Cuentas
                        </h1>
                    </div>
                    <div className='col-md-12 mt-2'>
                        <BarraNavegacion paginas={[
                            {
                                direccion: '/principal',
                                nombre: 'Principal'
                            },
                            {
                                direccion: '/cuentas/1/10',
                                nombre: 'Cuentas'
                            }
                        ]} />
                        {/*<BarraNavegacion formulario={[{ titulo: 'Principal', ruta: '/principal' }, { titulo: 'Cuentas', ruta: '/cuentas' }]} />*/}
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <Card border="secondary">
                            <Card.Body>
                                <div className='row'>
                                    <div className='col-md-6 mt-2 mb-2'>
                                        <Dropdown>
                                            <Dropdown.Toggle variant="outline-primary" id="cbOpciones">
                                                <FontAwesomeIcon icon={faCogs} /> Opciones
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item href="#" onClick={mostrarFiltros}>
                                                    <FontAwesomeIcon icon={faFilter} /> Filtro
                                                </Dropdown.Item>
                                                <Dropdown.Item href="#" onClick={actualizar}>
                                                    <FontAwesomeIcon icon={faRefresh} /> Actualizar
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                    <div className='col-md-6 mt-2 mb-2'>
                                        <div className="table-responsive">
                                            {
                                                (paginas.length === 0 ? '' : <Pagination className='d-flex justify-content-end'>
                                                    {(parseInt(pagina) === 1)}
                                                    <Pagination.First onClick={() => {
                                                        dirigir(paginado.inicio);
                                                    }} disabled={parseInt(pagina) === 1} />
                                                    <Pagination.Prev onClick={() => {
                                                        dirigir(parseInt(pagina) - 1);
                                                    }} disabled={parseInt(pagina) === 1} />
                                                    {((Math.ceil(pagina / intervalo) * intervalo) === (Math.ceil(paginado.inicio / intervalo) * intervalo) ? '' : <><Pagination.Item onClick={() => {
                                                        dirigir(paginado.inicio);
                                                    }} disabled={(parseInt(pagina) === paginado.inicio)}>{paginado.inicio}</Pagination.Item><Pagination.Ellipsis onClick={() => {
                                                        dirigir(parseInt(pagina) - 10)
                                                    }} /></>)}

                                                    {
                                                        paginas.map((paginaOpcion, llave) => (
                                                            <Pagination.Item key={llave} onClick={() => {
                                                                dirigir((paginaOpcion === parseInt(pagina) ? 0 : paginaOpcion))
                                                            }} active={paginaOpcion === parseInt(pagina)}>{paginaOpcion}</Pagination.Item>
                                                        ))
                                                    }

                                                    {((Math.ceil(pagina / intervalo) * intervalo) === (Math.ceil(paginado.fin / intervalo) * intervalo) ? '' : <><Pagination.Ellipsis onClick={() => {
                                                        dirigir(parseInt(pagina) + 10)
                                                    }} /><Pagination.Item onClick={() => {
                                                        dirigir(paginado.fin);
                                                    }} disabled={(parseInt(pagina) === paginado.fin)}>{paginado.fin}</Pagination.Item></>)}
                                                    <Pagination.Next onClick={() => {
                                                        dirigir(parseInt(pagina) + 1);
                                                    }} disabled={parseInt(pagina) === paginado.fin} />
                                                    <Pagination.Last onClick={() => {
                                                        dirigir(paginado.fin);
                                                    }} disabled={parseInt(pagina) === paginado.fin} />
                                                </Pagination>)
                                            }
                                        </div>
                                    </div>
                                    <div className='col-md-12 table-responsive'>
                                        <Table bordered hover size="lg" className='table-dark'>
                                            <thead>
                                                <tr>
                                                    <th>Acción</th>
                                                    <th>Imagen</th>
                                                    <th>Documento</th>
                                                    <th>Nombre</th>
                                                    <th>Apellido</th>
                                                    <th>Correo</th>
                                                    <th>Fecha</th>
                                                    <th>Hora</th>
                                                    <th>Estado</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {datos.map((dato, llave) => (
                                                    <tr key={llave} className={`align-middle ${(dato.estado === 1 ? 'text-success' : 'text-danger')} `}>
                                                        <td className='text-center'>
                                                            <Button variant={(dato.estado === 1 ? 'success' : 'danger')} onClick={() => {
                                                                dirigirCuenta(dato.codigo)
                                                            }}>
                                                                <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                                                            </Button>
                                                        </td>
                                                        <td className='text-center'>
                                                            <img src={
                                                                (dato.foto !== undefined ? dato.foto : "https://csdigitalizacion.nyc3.cdn.digitaloceanspaces.com/ecommerce/logos/store/lcng/100.png")
                                                            } alt="" height='' width='' />
                                                        </td>
                                                        <td className='text-end'>{dato.documento}</td>
                                                        <td>{dato.nombre}</td>
                                                        <td>{dato.apellido}</td>
                                                        <td>{dato.correo}</td>
                                                        <td className='text-end'>{obtenerFechaDeTiempo(dato.tiempo)}</td>
                                                        <td className='text-end'>{obtenerHoraDeTiempo(dato.tiempo)}</td>
                                                        <td>{<FontAwesomeIcon icon={(dato.estado === 0 ? faTimes : faCheck)} />}{(dato.estado === 1 ? 'Habilitado' : 'No habilitado')}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </div>
            <Offcanvas show={filtros} onHide={cerrarFiltros} placement='bottom' className='bg-dark text-white'>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title><FontAwesomeIcon icon={faFilter} />Filtros</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className='row'>
                        <div className='col-md-12 mt-2'>
                            <InputGroup className="mb-3">
                                <Button variant="outline-success" onClick={buscarCodigo}>
                                    <FontAwesomeIcon icon={faSearch} /> Consultar
                                </Button>
                                <Form.Control type='number' aria-label="Example text with button addon" aria-describedby="basic-addon1" placeholder="Busqueda por Código" id='txtCodigo' defaultValue={codigo} onKeyUp={eventoEnter} />
                            </InputGroup>
                        </div>
                        <div className='col-md-2 mt-2'>
                            <FloatingLabel controlId="cbInterval" label="Cantidad de registros" className="text-dark">
                                <Form.Select aria-label="Floating label select example" defaultValue={intervalo} onChange={() => {
                                    establecerIntervalo();
                                }}>
                                    <option value="4">4</option>
                                    <option value="6">6</option>
                                    <option value="8">8</option>
                                    <option value="10">10</option>
                                    <option value="12">12</option>
                                    <option value="20">20</option>
                                    <option value="30">30</option>
                                    <option value="40">40</option>
                                    <option value="50">50</option>
                                </Form.Select>
                            </FloatingLabel>
                        </div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}