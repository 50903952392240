import {
    useEffect,
    useState
} from 'react';
import {
    useNavigate
} from 'react-router-dom';
import {
    Card,
    ListGroup,
    Badge,
    Offcanvas,
    Button,
    Row,
    Col,
    Tab,
    Tabs
} from 'react-bootstrap';
import {
    FontAwesomeIcon
} from '@fortawesome/react-fontawesome';
import {
    faChevronRight,
    faClipboard,
    faCheck,
    faTimes,
    faCube,
    fa0,
    faList,
    faImage,
    faCoins,
    faMinus,
    faChartPie,
    faFilter,
    faUsers,
    faShoppingCart,
    faEye,
    faEyeSlash,
    faTag,
    faMapMarker
} from '@fortawesome/free-solid-svg-icons';
import {
    BarraNavegacion
} from '../componentes/BarraNavegacion';
import {
    Exportar
} from '../componentes/Exportar';
import {
    useContadoresContexto
} from '../proveedor/contadores';
import {
    useUsuarioContexto
} from '../proveedor/usuario';
import {
    obtenerPermiso
} from '../recursos/funcion';
import Ubicacion from '../componentes/Ubicacion';
import { Estadistica } from '../componentes/Estadistica';
import { EstadisticaTorta } from '../componentes/EstadisticaTorta';
import { EstadisticaDona } from '../componentes/EstadisticaDona';

export default function Principal() {

    const cuenta = useUsuarioContexto();

    const Interfaz = 'principal';

    const contadoresContexto = useContadoresContexto();

    const [contadores, setContadores] = useState(
        {
            cuenta: {
                general: {
                    total: [],
                    hoy: []
                }
            },
            carrito: {
                general: {
                    total: [],
                    hoy: []
                },
                estado: {
                    pagoSinRealizar: [],
                    pagoSinConfirmar: [],
                    pagoConfirmado: []
                }
            },
            producto: { // Sin filtro
                general: {
                    total: [],
                    modelo: [],
                    descripcion: []
                },
                estado: {
                    positivo: [],
                    negativo: []
                },
                imagen: {
                    con: [],
                    sin: []
                },
                visible: { // Filtro base
                    positivo: [],
                    negativo: []
                },
                cantidad: {
                    negativo: [],
                    cero: [],
                    positivo: []
                },
                precio: {
                    negativo: [],
                    cero: [],
                    positivo: []
                },
                movimiento: {
                    limite30: [],
                    limite90: []
                },
                caracteristicas: {
                    con: [],
                    sin: []
                },
                clasificacion: {
                    con: [],
                    sin: []
                },
                promocion: {
                    con: [],
                    sin: []
                }
            },
            publicidad: {
                general: {
                    total: []
                },
                visible: {
                    si: [],
                    no: []
                },
                estado: {
                    proximamente: [],
                    vigente: [],
                    vencido: []
                }
            }
        }
    );

    const navegar = useNavigate();

    const [tipo, setTipo] = useState("");

    const [datos, setDatos] = useState([]);

    const [panel, setPanel] = useState(false);

    const ocultarPanel = () => setPanel(false);
    const mostrarPanel = () => setPanel(true);

    const [nombre, setNombre] = useState('');

    useEffect(() => {
        if (contadoresContexto !== null) {
            setContadores(contadoresContexto);
        }
    }, [contadoresContexto]);

    function interaccion(cantidad, tipo, espacio, bloque) {
        if (cantidad > 0) {
            setTipo(tipo);
            setDatos(contadores[tipo][espacio][bloque]);
            mostrarPanel();
        }
    }

    function dirigir(codigo) {
        if (tipo === "producto") {
            navegar('/producto/' + codigo);
        } else if (tipo === "carrito") {
            navegar('/carrito/' + codigo);
        } else if (tipo === "publicidad") {
            //navegar('/carrito/' + codigo);
        } else if (tipo === "cuenta") {
            //navegar('/carrito/' + codigo);
        }
    }

    return (
        <>
            <div className='container-fluid mt-3 mb-3'>
                <div className='row mb-3'>
                    <div className='col-md-12'>
                        <h1>
                            <FontAwesomeIcon icon={faChartPie} />Principal
                        </h1>
                    </div>
                    <div className='col-md-12 mt-2'>
                        <BarraNavegacion paginas={[
                            {
                                direccion: '/principal',
                                nombre: 'Principal'
                            }
                        ]} />
                    </div>
                    <div className='col-md-12'>
                        <div className='row'>
                            <Col md={12}>
                                <Tabs defaultActiveKey="producto" id="dashboard" className="mb-3" justify>
                                    {
                                        (obtenerPermiso(cuenta.interfaces, Interfaz, 'producto') ? <Tab eventKey="producto" title={`Producto: ${contadores.producto.general.total.length}`}>
                                            <Row>

                                                <Col md={12}>
                                                    <Card border='primary' text='primary' className='mt-2 mb-2'>
                                                        <Card.Body>
                                                            <Card.Title>
                                                                <FontAwesomeIcon icon={faCube} /> Nivel 1 - Todos los productos en la base de datos del ecommerce.
                                                            </Card.Title>
                                                            <center>
                                                                <h1 className='display-1'>
                                                                    <strong>{contadores.producto.general.total.length}</strong>
                                                                </h1>
                                                            </center>
                                                            <br />
                                                            <div className="d-grid gap-2">
                                                                <Button variant="outline-primary" size="lg" onClick={() => {
                                                                    setNombre('Productos Total');
                                                                    interaccion(contadores.producto.general.total.length, 'producto', 'general', 'total');
                                                                }}>
                                                                    <FontAwesomeIcon icon={faList} />Listar
                                                                </Button>
                                                            </div>
                                                            <br />
                                                            <Row>
                                                                <Col md={12}>
                                                                    <Card border='danger' text='danger' className='mt-2 mb-2'>
                                                                        <Card.Body>
                                                                            <Card.Title>
                                                                                <FontAwesomeIcon icon={faCube} /> Nivel 2 -  Desmarcados en el ECont (Enviar a web).
                                                                            </Card.Title>
                                                                            <center>
                                                                                <h1 className='display-1'>
                                                                                    <strong>{contadores.producto.estado.negativo.length}</strong>
                                                                                </h1>
                                                                            </center>
                                                                            <Card.Text>
                                                                                <FontAwesomeIcon icon={faFilter} /> Deshabilitados por el ETL.
                                                                            </Card.Text>
                                                                            <ListGroup numbered>
                                                                                <ListGroup.Item variant='danger'>
                                                                                    <FontAwesomeIcon icon={faTimes} /> Deshabilitado
                                                                                </ListGroup.Item>
                                                                            </ListGroup>
                                                                            <br />
                                                                            <div className="d-grid gap-2">
                                                                                <Button variant="outline-danger" size="lg" onClick={() => {
                                                                                    setNombre('Productos Deshabilitados');
                                                                                    interaccion(contadores.producto.estado.negativo.length, 'producto', 'estado', 'negativo');
                                                                                }}>
                                                                                    <FontAwesomeIcon icon={faList} />Listar
                                                                                </Button>
                                                                            </div>
                                                                        </Card.Body>
                                                                    </Card>
                                                                </Col>
                                                                <Col md={12}>
                                                                    <Card border='success' text='success' className='mt-2 mb-2'>
                                                                        <Card.Body>
                                                                            <Card.Title>
                                                                                <FontAwesomeIcon icon={faCube} /> Nivel 2 - Marcados en el ECont (Enviar a web).
                                                                            </Card.Title>
                                                                            <center>
                                                                                <h1 className='display-1'>
                                                                                    <strong>{contadores.producto.estado.positivo.length}</strong>
                                                                                </h1>
                                                                            </center>
                                                                            <Card.Text>
                                                                                <FontAwesomeIcon icon={faFilter} /> Habilitados por el ETL.
                                                                            </Card.Text>
                                                                            <ListGroup numbered>
                                                                                <ListGroup.Item variant='success'>
                                                                                    <FontAwesomeIcon icon={faCheck} /> Habilitado
                                                                                </ListGroup.Item>
                                                                            </ListGroup>
                                                                            <br />
                                                                            <div className="d-grid gap-2">
                                                                                <Button variant="outline-success" size="lg" onClick={() => {
                                                                                    setNombre('Productos Habilitados');
                                                                                    interaccion(contadores.producto.estado.positivo.length, 'producto', 'estado', 'positivo');
                                                                                }}>
                                                                                    <FontAwesomeIcon icon={faList} />Listar
                                                                                </Button>
                                                                            </div>
                                                                            <br />
                                                                            <Row>
                                                                                <Col md={12}>
                                                                                    <Card border='danger' text='danger' className='mt-2 mb-2'>
                                                                                        <Card.Body>
                                                                                            <Card.Title>
                                                                                                <FontAwesomeIcon icon={faImage} /> Nivel 3 - Sin imagen
                                                                                            </Card.Title>
                                                                                            <center>
                                                                                                <h1 className='display-1'>
                                                                                                    <strong>{contadores.producto.imagen.sin.length}</strong>
                                                                                                </h1>
                                                                                            </center>
                                                                                            <Card.Text>
                                                                                                <FontAwesomeIcon icon={faFilter} /> Cantidad con filtro.
                                                                                            </Card.Text>
                                                                                            <ListGroup numbered>
                                                                                                <ListGroup.Item variant='danger'>
                                                                                                    <FontAwesomeIcon icon={faImage} /> No tiene imagen
                                                                                                </ListGroup.Item>
                                                                                                <ListGroup.Item variant='danger'>
                                                                                                    <FontAwesomeIcon icon={faCheck} /> Habilitado
                                                                                                </ListGroup.Item>
                                                                                            </ListGroup>
                                                                                            <br />
                                                                                            <div className="d-grid gap-2">
                                                                                                <Button variant="outline-danger" size="lg" onClick={() => {
                                                                                                    setNombre('Productos sin imagen');
                                                                                                    interaccion(contadores.producto.imagen.sin.length, 'producto', 'imagen', 'sin');
                                                                                                }}>
                                                                                                    <FontAwesomeIcon icon={faList} />Listar
                                                                                                </Button>
                                                                                            </div>
                                                                                            <br />
                                                                                            <Row>
                                                                                                <Col md={12}>
                                                                                                    <Card border='danger' text='danger' className='mt-2 mb-2'>
                                                                                                        <Card.Body>
                                                                                                            <Card.Title>
                                                                                                                <FontAwesomeIcon icon={faEyeSlash} /> Nivel 4 - No visibles
                                                                                                            </Card.Title>
                                                                                                            <center>
                                                                                                                <h1 className='display-1'>
                                                                                                                    <strong>{contadores.producto.visible.negativo.length}</strong>
                                                                                                                </h1>
                                                                                                            </center>
                                                                                                            <Card.Text>
                                                                                                                <FontAwesomeIcon icon={faFilter} /> Cantidad con filtro.
                                                                                                            </Card.Text>
                                                                                                            <ListGroup numbered>
                                                                                                                <ListGroup.Item variant='danger'>
                                                                                                                    <FontAwesomeIcon icon={faImage} /> No tiene imagen
                                                                                                                </ListGroup.Item>
                                                                                                                <ListGroup.Item variant='danger'>
                                                                                                                    <FontAwesomeIcon icon={faCoins} /> Precio mayor a 10000
                                                                                                                </ListGroup.Item>
                                                                                                                <ListGroup.Item variant='danger'>
                                                                                                                    <FontAwesomeIcon icon={fa0} /> Cantidad mayor a 0
                                                                                                                </ListGroup.Item>
                                                                                                                <ListGroup.Item variant='danger'>
                                                                                                                    <FontAwesomeIcon icon={faCheck} /> Habilitado
                                                                                                                </ListGroup.Item>
                                                                                                                <ListGroup.Item variant='danger'>
                                                                                                                    <FontAwesomeIcon icon={faFilter} />
                                                                                                                </ListGroup.Item>
                                                                                                            </ListGroup>
                                                                                                            <br />
                                                                                                            <div className="d-grid gap-2">
                                                                                                                <Button variant="outline-danger" size="lg" onClick={() => {
                                                                                                                    setNombre('Productos no Visibles');
                                                                                                                    interaccion(contadores.producto.visible.negativo.length, 'producto', 'visible', 'negativo');
                                                                                                                }}>
                                                                                                                    <FontAwesomeIcon icon={faList} />Listar
                                                                                                                </Button>
                                                                                                            </div>
                                                                                                        </Card.Body>
                                                                                                    </Card>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </Card.Body>
                                                                                    </Card>
                                                                                </Col>
                                                                                <Col md={12}>
                                                                                    <Card border='success' text='success' className='mt-2 mb-2'>
                                                                                        <Card.Body>
                                                                                            <Card.Title>
                                                                                                <FontAwesomeIcon icon={faImage} /> Nivel 3 - Con imagen
                                                                                            </Card.Title>
                                                                                            <center>
                                                                                                <h1 className='display-1'>
                                                                                                    <strong>{contadores.producto.imagen.con.length}</strong>
                                                                                                </h1>
                                                                                            </center>
                                                                                            <Card.Text>
                                                                                                <FontAwesomeIcon icon={faFilter} /> Cantidad con filtro.
                                                                                            </Card.Text>
                                                                                            <ListGroup numbered>
                                                                                                <ListGroup.Item variant='success'>
                                                                                                    <FontAwesomeIcon icon={faImage} /> Tiene imagen
                                                                                                </ListGroup.Item>
                                                                                                <ListGroup.Item variant='success'>
                                                                                                    <FontAwesomeIcon icon={faCheck} /> Habilitado
                                                                                                </ListGroup.Item>
                                                                                            </ListGroup>
                                                                                            <br />
                                                                                            <div className="d-grid gap-2">
                                                                                                <Button variant="outline-success" size="lg" onClick={() => {
                                                                                                    setNombre('Productos con imagen');
                                                                                                    interaccion(contadores.producto.imagen.con.length, 'producto', 'imagen', 'con');
                                                                                                }}>
                                                                                                    <FontAwesomeIcon icon={faList} />Listar
                                                                                                </Button>
                                                                                            </div>
                                                                                            <br />
                                                                                            <Row>
                                                                                                <Col md={3}>
                                                                                                    <Card border='secondary' text='secondary' className='mt-2 mb-2'>
                                                                                                        <Card.Body>
                                                                                                            <Card.Title>
                                                                                                                <FontAwesomeIcon icon={faMinus} /> Nivel 4 - Cantidad negativa
                                                                                                            </Card.Title>
                                                                                                            <center>
                                                                                                                <h1 className='display-1'>
                                                                                                                    <strong>{contadores.producto.cantidad.negativo.length}</strong>
                                                                                                                </h1>
                                                                                                            </center>
                                                                                                            <Card.Text>
                                                                                                                <FontAwesomeIcon icon={faFilter} /> Cantidad con filtro.
                                                                                                            </Card.Text>
                                                                                                            <ListGroup numbered>
                                                                                                                <ListGroup.Item variant='secondary'>
                                                                                                                    <FontAwesomeIcon icon={faImage} /> Tiene imagen
                                                                                                                </ListGroup.Item>
                                                                                                                <ListGroup.Item variant='secondary'>
                                                                                                                    <FontAwesomeIcon icon={faCoins} /> Precio mayor a 10000
                                                                                                                </ListGroup.Item>
                                                                                                                <ListGroup.Item variant='secondary'>
                                                                                                                    <FontAwesomeIcon icon={fa0} /> Cantidad menor a 0
                                                                                                                </ListGroup.Item>
                                                                                                                <ListGroup.Item variant='secondary'>
                                                                                                                    <FontAwesomeIcon icon={faCheck} /> Habilitado
                                                                                                                </ListGroup.Item>
                                                                                                                <ListGroup.Item variant='secondary'>
                                                                                                                    <FontAwesomeIcon icon={faFilter} />
                                                                                                                </ListGroup.Item>
                                                                                                            </ListGroup>
                                                                                                            <br />
                                                                                                            <div className="d-grid gap-2">
                                                                                                                <Button variant="outline-secondary" size="lg" onClick={() => {
                                                                                                                    setNombre('Productos cantidad negativa');
                                                                                                                    interaccion(contadores.producto.cantidad.negativo.length, 'producto', 'cantidad', 'negativo');
                                                                                                                }}>
                                                                                                                    <FontAwesomeIcon icon={faList} />Listar
                                                                                                                </Button>
                                                                                                            </div>
                                                                                                        </Card.Body>
                                                                                                    </Card>
                                                                                                </Col>

                                                                                                <Col md={3}>
                                                                                                    <Card border='danger' text='danger' className='mt-2 mb-2'>
                                                                                                        <Card.Body>
                                                                                                            <Card.Title>
                                                                                                                <FontAwesomeIcon icon={fa0} /> Nivel 4 - Cantidad cero
                                                                                                            </Card.Title>
                                                                                                            <center>
                                                                                                                <h1 className='display-1'>
                                                                                                                    <strong>{contadores.producto.cantidad.cero.length}</strong>
                                                                                                                </h1>
                                                                                                            </center>
                                                                                                            <Card.Text>
                                                                                                                <FontAwesomeIcon icon={faFilter} /> Cantidad con filtro.
                                                                                                            </Card.Text>
                                                                                                            <ListGroup numbered>
                                                                                                                <ListGroup.Item variant='danger'>
                                                                                                                    <FontAwesomeIcon icon={faImage} /> Tiene imagen
                                                                                                                </ListGroup.Item>
                                                                                                                <ListGroup.Item variant='danger'>
                                                                                                                    <FontAwesomeIcon icon={faCoins} /> Precio mayor a 10000
                                                                                                                </ListGroup.Item>
                                                                                                                <ListGroup.Item variant='danger'>
                                                                                                                    <FontAwesomeIcon icon={fa0} /> Cantidad igual a 0
                                                                                                                </ListGroup.Item>
                                                                                                                <ListGroup.Item variant='danger'>
                                                                                                                    <FontAwesomeIcon icon={faCheck} /> Habilitado
                                                                                                                </ListGroup.Item>
                                                                                                                <ListGroup.Item variant='danger'>
                                                                                                                    <FontAwesomeIcon icon={faFilter} />
                                                                                                                </ListGroup.Item>
                                                                                                            </ListGroup>
                                                                                                            <br />
                                                                                                            <div className="d-grid gap-2">
                                                                                                                <Button variant="outline-danger" size="lg" onClick={() => {
                                                                                                                    setNombre('Productos cantidad cero');
                                                                                                                    interaccion(contadores.producto.cantidad.cero.length, 'producto', 'cantidad', 'cero');
                                                                                                                }}>
                                                                                                                    <FontAwesomeIcon icon={faList} />Listar
                                                                                                                </Button>
                                                                                                            </div>
                                                                                                        </Card.Body>
                                                                                                    </Card>
                                                                                                </Col>

                                                                                                <Col md={3}>
                                                                                                    <Card border='secondary' text='secondary' className='mt-2 mb-2'>
                                                                                                        <Card.Body>
                                                                                                            <Card.Title>
                                                                                                                <FontAwesomeIcon icon={faCoins} /> Nivel 4 - Precio negativo
                                                                                                            </Card.Title>
                                                                                                            <center>
                                                                                                                <h1 className='display-1'>
                                                                                                                    <strong>{contadores.producto.precio.negativo.length}</strong>
                                                                                                                </h1>
                                                                                                            </center>
                                                                                                            <Card.Text>
                                                                                                                <FontAwesomeIcon icon={faFilter} /> Cantidad con filtro.
                                                                                                            </Card.Text>
                                                                                                            <ListGroup numbered>
                                                                                                                <ListGroup.Item variant='secondary'>
                                                                                                                    <FontAwesomeIcon icon={faImage} /> Tiene imagen
                                                                                                                </ListGroup.Item>
                                                                                                                <ListGroup.Item variant='secondary'>
                                                                                                                    <FontAwesomeIcon icon={faCoins} /> Precio menor a 0
                                                                                                                </ListGroup.Item>
                                                                                                                <ListGroup.Item variant='secondary'>
                                                                                                                    <FontAwesomeIcon icon={fa0} /> Cantidad mayor a 0
                                                                                                                </ListGroup.Item>
                                                                                                                <ListGroup.Item variant='secondary'>
                                                                                                                    <FontAwesomeIcon icon={faCheck} /> Habilitado
                                                                                                                </ListGroup.Item>
                                                                                                                <ListGroup.Item variant='secondary'>
                                                                                                                    <FontAwesomeIcon icon={faFilter} />
                                                                                                                </ListGroup.Item>
                                                                                                            </ListGroup>
                                                                                                            <br />
                                                                                                            <div className="d-grid gap-2">
                                                                                                                <Button variant="outline-secondary" size="lg" onClick={() => {
                                                                                                                    setNombre('Productos precio negativo');
                                                                                                                    interaccion(contadores.producto.precio.negativo.descripcion.length, 'producto', 'precio', 'negativo');
                                                                                                                }}>
                                                                                                                    <FontAwesomeIcon icon={faList} />Listar
                                                                                                                </Button>
                                                                                                            </div>
                                                                                                        </Card.Body>
                                                                                                    </Card>
                                                                                                </Col>

                                                                                                <Col md={3}>
                                                                                                    <Card border='danger' text='danger' className='mt-2 mb-2'>
                                                                                                        <Card.Body>
                                                                                                            <Card.Title>
                                                                                                                <FontAwesomeIcon icon={faCoins} /> Nivel 4 - Precio cero
                                                                                                            </Card.Title>
                                                                                                            <center>
                                                                                                                <h1 className='display-1'>
                                                                                                                    <strong>{contadores.producto.precio.cero.length}</strong>
                                                                                                                </h1>
                                                                                                            </center>
                                                                                                            <Card.Text>
                                                                                                                <FontAwesomeIcon icon={faFilter} /> Cantidad con filtro.
                                                                                                            </Card.Text>
                                                                                                            <ListGroup numbered>
                                                                                                                <ListGroup.Item variant='danger'>
                                                                                                                    <FontAwesomeIcon icon={faImage} /> Tiene imagen
                                                                                                                </ListGroup.Item>
                                                                                                                <ListGroup.Item variant='danger'>
                                                                                                                    <FontAwesomeIcon icon={faCoins} /> Precio igual a 0
                                                                                                                </ListGroup.Item>
                                                                                                                <ListGroup.Item variant='danger'>
                                                                                                                    <FontAwesomeIcon icon={fa0} /> Cantidad mayor a 0
                                                                                                                </ListGroup.Item>
                                                                                                                <ListGroup.Item variant='danger'>
                                                                                                                    <FontAwesomeIcon icon={faCheck} /> Habilitado
                                                                                                                </ListGroup.Item>
                                                                                                                <ListGroup.Item variant='danger'>
                                                                                                                    <FontAwesomeIcon icon={faFilter} />
                                                                                                                </ListGroup.Item>
                                                                                                            </ListGroup>
                                                                                                            <br />
                                                                                                            <div className="d-grid gap-2">
                                                                                                                <Button variant="outline-danger" size="lg" onClick={() => {
                                                                                                                    setNombre('Productos precio cero');
                                                                                                                    interaccion(contadores.producto.precio.cero.length, 'producto', 'precio', 'cero');
                                                                                                                }}>
                                                                                                                    <FontAwesomeIcon icon={faList} />Listar
                                                                                                                </Button>
                                                                                                            </div>
                                                                                                        </Card.Body>
                                                                                                    </Card>
                                                                                                </Col>
                                                                                                <Col md={12}>
                                                                                                    <Card border='success' text='success' className='mt-2 mb-2'>
                                                                                                        <Card.Body>
                                                                                                            <Card.Title>
                                                                                                                <FontAwesomeIcon icon={faEye} /> Nivel 4 - Visibles
                                                                                                            </Card.Title>
                                                                                                            <center>
                                                                                                                <h1 className='display-1'>
                                                                                                                    <strong>{contadores.producto.visible.positivo.length}</strong>
                                                                                                                </h1>
                                                                                                            </center>
                                                                                                            <Card.Text>
                                                                                                                <FontAwesomeIcon icon={faFilter} /> Cantidad con filtro.
                                                                                                            </Card.Text>
                                                                                                            <ListGroup numbered>
                                                                                                                <ListGroup.Item variant='success'>
                                                                                                                    <FontAwesomeIcon icon={faImage} /> Tiene imagen
                                                                                                                </ListGroup.Item>
                                                                                                                <ListGroup.Item variant='success'>
                                                                                                                    <FontAwesomeIcon icon={faCoins} /> Precio mayor a 10000
                                                                                                                </ListGroup.Item>
                                                                                                                <ListGroup.Item variant='success'>
                                                                                                                    <FontAwesomeIcon icon={fa0} /> Cantidad mayor a 0
                                                                                                                </ListGroup.Item>
                                                                                                                <ListGroup.Item variant='success'>
                                                                                                                    <FontAwesomeIcon icon={faCheck} /> Habilitado
                                                                                                                </ListGroup.Item>
                                                                                                                <ListGroup.Item variant='success'>
                                                                                                                    <FontAwesomeIcon icon={faFilter} />
                                                                                                                </ListGroup.Item>
                                                                                                            </ListGroup>
                                                                                                            <br />
                                                                                                            <div className="d-grid gap-2">
                                                                                                                <Button variant="outline-success" size="lg" onClick={() => {
                                                                                                                    setNombre('Productos Visibles');
                                                                                                                    interaccion(contadores.producto.visible.positivo.length, 'producto', 'visible', 'positivo');
                                                                                                                }}>
                                                                                                                    <FontAwesomeIcon icon={faList} />Listar
                                                                                                                </Button>
                                                                                                            </div>
                                                                                                            <br />
                                                                                                            <Row>

                                                                                                                <Col md={6}>
                                                                                                                    <Card border='primary' text='primary' className='mt-2 mb-2'>
                                                                                                                        <Card.Body>
                                                                                                                            <Card.Title>
                                                                                                                                <FontAwesomeIcon icon={faTag} /> Nivel 5 - Movimiento últimos 30 días
                                                                                                                            </Card.Title>
                                                                                                                            <center>
                                                                                                                                <h1 className='display-1'>
                                                                                                                                    <strong>{contadores.producto.movimiento.limite30.length}</strong>
                                                                                                                                </h1>
                                                                                                                            </center>
                                                                                                                            <Card.Text>
                                                                                                                                <FontAwesomeIcon icon={faFilter} /> Cantidad con filtro.
                                                                                                                            </Card.Text>
                                                                                                                            <ListGroup numbered>
                                                                                                                                <ListGroup.Item variant='primary'>
                                                                                                                                    <FontAwesomeIcon icon={faImage} /> Tiene imagen
                                                                                                                                </ListGroup.Item>
                                                                                                                                <ListGroup.Item variant='primary'>
                                                                                                                                    <FontAwesomeIcon icon={faCoins} /> Precio mayor a 10000
                                                                                                                                </ListGroup.Item>
                                                                                                                                <ListGroup.Item variant='primary'>
                                                                                                                                    <FontAwesomeIcon icon={fa0} /> Cantidad mayor a 0
                                                                                                                                </ListGroup.Item>
                                                                                                                                <ListGroup.Item variant='primary'>
                                                                                                                                    <FontAwesomeIcon icon={faCheck} /> Habilitado
                                                                                                                                </ListGroup.Item>
                                                                                                                                <ListGroup.Item variant='primary'>
                                                                                                                                    <FontAwesomeIcon icon={faFilter} /> Movimiento entre 0 a 30 días
                                                                                                                                </ListGroup.Item>
                                                                                                                            </ListGroup>
                                                                                                                            <br />
                                                                                                                            <div className="d-grid gap-2">
                                                                                                                                <Button variant="outline-primary" size="lg" onClick={() => {
                                                                                                                                    setNombre('Productos con movimiento los ultimos 30 dias');
                                                                                                                                    interaccion(contadores.producto.movimiento.limite30.length, 'producto', 'movimiento', 'limite30');
                                                                                                                                }}>
                                                                                                                                    <FontAwesomeIcon icon={faList} />Listar
                                                                                                                                </Button>
                                                                                                                            </div>
                                                                                                                        </Card.Body>
                                                                                                                    </Card>
                                                                                                                </Col>

                                                                                                                <Col md={6}>
                                                                                                                    <Card border='primary' text='primary' className='mt-2 mb-2'>
                                                                                                                        <Card.Body>
                                                                                                                            <Card.Title>
                                                                                                                                <FontAwesomeIcon icon={faFilter} /> Nivel 5 - Movimiento los últimos 90 días
                                                                                                                            </Card.Title>
                                                                                                                            <center>
                                                                                                                                <h1 className='display-1'>
                                                                                                                                    <strong>{contadores.producto.movimiento.limite90.length}</strong>
                                                                                                                                </h1>
                                                                                                                            </center>
                                                                                                                            <Card.Text>
                                                                                                                                <FontAwesomeIcon icon={faFilter} /> Cantidad con filtro.
                                                                                                                            </Card.Text>
                                                                                                                            <ListGroup numbered>
                                                                                                                                <ListGroup.Item variant='primary'>
                                                                                                                                    <FontAwesomeIcon icon={faImage} /> Tiene imagen
                                                                                                                                </ListGroup.Item>
                                                                                                                                <ListGroup.Item variant='primary'>
                                                                                                                                    <FontAwesomeIcon icon={faCoins} /> Precio mayor a 10000
                                                                                                                                </ListGroup.Item>
                                                                                                                                <ListGroup.Item variant='primary'>
                                                                                                                                    <FontAwesomeIcon icon={fa0} /> Cantidad mayor a 0
                                                                                                                                </ListGroup.Item>
                                                                                                                                <ListGroup.Item variant='primary'>
                                                                                                                                    <FontAwesomeIcon icon={faCheck} /> Habilitado
                                                                                                                                </ListGroup.Item>
                                                                                                                                <ListGroup.Item variant='primary'>
                                                                                                                                    <FontAwesomeIcon icon={faFilter} /> Movimiento entre 31 a 90 días
                                                                                                                                </ListGroup.Item>
                                                                                                                            </ListGroup>
                                                                                                                            <br />
                                                                                                                            <div className="d-grid gap-2">
                                                                                                                                <Button variant="outline-primary" size="lg" onClick={() => {
                                                                                                                                    setNombre('Productos con movimiento los ultimos 30 dias');
                                                                                                                                    interaccion(contadores.producto.movimiento.limite90.length, 'producto', 'movimiento', 'limite90');
                                                                                                                                }}>
                                                                                                                                    <FontAwesomeIcon icon={faList} />Listar
                                                                                                                                </Button>
                                                                                                                            </div>
                                                                                                                        </Card.Body>
                                                                                                                    </Card>
                                                                                                                </Col>

                                                                                                                <Col md={2}>
                                                                                                                    <Card border='success' text='success' className='mt-2 mb-2'>
                                                                                                                        <Card.Body>
                                                                                                                            <Card.Title>
                                                                                                                                <FontAwesomeIcon icon={faTag} /> Nivel 5 - Con características
                                                                                                                            </Card.Title>
                                                                                                                            <center>
                                                                                                                                <h1 className='display-1'>
                                                                                                                                    <strong>{contadores.producto.caracteristicas.con.length}</strong>
                                                                                                                                </h1>
                                                                                                                            </center>
                                                                                                                            <Card.Text>
                                                                                                                                <FontAwesomeIcon icon={faFilter} /> Cantidad con filtro.
                                                                                                                            </Card.Text>
                                                                                                                            <ListGroup numbered>
                                                                                                                                <ListGroup.Item variant='success'>
                                                                                                                                    <FontAwesomeIcon icon={faImage} /> Tiene imagen
                                                                                                                                </ListGroup.Item>
                                                                                                                                <ListGroup.Item variant='success'>
                                                                                                                                    <FontAwesomeIcon icon={faCoins} /> Precio mayor a 10000
                                                                                                                                </ListGroup.Item>
                                                                                                                                <ListGroup.Item variant='success'>
                                                                                                                                    <FontAwesomeIcon icon={fa0} /> Cantidad mayor a 0
                                                                                                                                </ListGroup.Item>
                                                                                                                                <ListGroup.Item variant='success'>
                                                                                                                                    <FontAwesomeIcon icon={faCheck} /> Habilitado
                                                                                                                                </ListGroup.Item>
                                                                                                                                <ListGroup.Item variant='success'>
                                                                                                                                    <FontAwesomeIcon icon={faFilter} /> Contiene características
                                                                                                                                </ListGroup.Item>
                                                                                                                            </ListGroup>
                                                                                                                            <br />
                                                                                                                            <div className="d-grid gap-2">
                                                                                                                                <Button variant="outline-success" size="lg" onClick={() => {
                                                                                                                                    setNombre('Productos con caracteristicas');
                                                                                                                                    interaccion(contadores.producto.caracteristicas.con.length, 'producto', 'caracteristicas', 'con');
                                                                                                                                }}>
                                                                                                                                    <FontAwesomeIcon icon={faList} />Listar
                                                                                                                                </Button>
                                                                                                                            </div>
                                                                                                                        </Card.Body>
                                                                                                                    </Card>
                                                                                                                </Col>

                                                                                                                <Col md={2}>
                                                                                                                    <Card border='danger' text='danger' className='mt-2 mb-2'>
                                                                                                                        <Card.Body>
                                                                                                                            <Card.Title>
                                                                                                                                <FontAwesomeIcon icon={faTag} /> Nivel 5 - Sin características
                                                                                                                            </Card.Title>
                                                                                                                            <center>
                                                                                                                                <h1 className='display-1'>
                                                                                                                                    <strong>{contadores.producto.caracteristicas.sin.length}</strong>
                                                                                                                                </h1>
                                                                                                                            </center>
                                                                                                                            <Card.Text>
                                                                                                                                <FontAwesomeIcon icon={faFilter} /> Cantidad con filtro.
                                                                                                                            </Card.Text>
                                                                                                                            <ListGroup numbered>
                                                                                                                                <ListGroup.Item variant='danger'>
                                                                                                                                    <FontAwesomeIcon icon={faImage} /> Tiene imagen
                                                                                                                                </ListGroup.Item>
                                                                                                                                <ListGroup.Item variant='danger'>
                                                                                                                                    <FontAwesomeIcon icon={faCoins} /> Precio mayor a 10000
                                                                                                                                </ListGroup.Item>
                                                                                                                                <ListGroup.Item variant='danger'>
                                                                                                                                    <FontAwesomeIcon icon={fa0} /> Cantidad mayor a 0
                                                                                                                                </ListGroup.Item>
                                                                                                                                <ListGroup.Item variant='danger'>
                                                                                                                                    <FontAwesomeIcon icon={faCheck} /> Habilitado
                                                                                                                                </ListGroup.Item>
                                                                                                                                <ListGroup.Item variant='danger'>
                                                                                                                                    <FontAwesomeIcon icon={faFilter} /> No contiene características
                                                                                                                                </ListGroup.Item>
                                                                                                                            </ListGroup>
                                                                                                                            <br />
                                                                                                                            <div className="d-grid gap-2">
                                                                                                                                <Button variant="outline-danger" size="lg" onClick={() => {
                                                                                                                                    setNombre('Productos sin caracteristicas');
                                                                                                                                    interaccion(contadores.producto.caracteristicas.sin.length, 'producto', 'caracteristicas', 'sin');
                                                                                                                                }}>
                                                                                                                                    <FontAwesomeIcon icon={faList} />Listar
                                                                                                                                </Button>
                                                                                                                            </div>
                                                                                                                        </Card.Body>
                                                                                                                    </Card>
                                                                                                                </Col>

                                                                                                                <Col md={2}>
                                                                                                                    <Card border='success' text='success' className='mt-2 mb-2'>
                                                                                                                        <Card.Body>
                                                                                                                            <Card.Title>
                                                                                                                                <FontAwesomeIcon icon={faTag} /> Nivel 5 - Con clasificación
                                                                                                                            </Card.Title>
                                                                                                                            <center>
                                                                                                                                <h1 className='display-1'>
                                                                                                                                    <strong>{contadores.producto.clasificacion.con.length}</strong>
                                                                                                                                </h1>
                                                                                                                            </center>
                                                                                                                            <Card.Text>
                                                                                                                                <FontAwesomeIcon icon={faFilter} /> Cantidad con filtro.
                                                                                                                            </Card.Text>
                                                                                                                            <ListGroup numbered>
                                                                                                                                <ListGroup.Item variant='success'>
                                                                                                                                    <FontAwesomeIcon icon={faImage} /> Tiene imagen
                                                                                                                                </ListGroup.Item>
                                                                                                                                <ListGroup.Item variant='success'>
                                                                                                                                    <FontAwesomeIcon icon={faCoins} /> Precio mayor a 10000
                                                                                                                                </ListGroup.Item>
                                                                                                                                <ListGroup.Item variant='success'>
                                                                                                                                    <FontAwesomeIcon icon={fa0} /> Cantidad mayor a 0
                                                                                                                                </ListGroup.Item>
                                                                                                                                <ListGroup.Item variant='success'>
                                                                                                                                    <FontAwesomeIcon icon={faCheck} /> Habilitado
                                                                                                                                </ListGroup.Item>
                                                                                                                                <ListGroup.Item variant='success'>
                                                                                                                                    <FontAwesomeIcon icon={faFilter} /> Contiene clasificación
                                                                                                                                </ListGroup.Item>
                                                                                                                            </ListGroup>
                                                                                                                            <br />
                                                                                                                            <div className="d-grid gap-2">
                                                                                                                                <Button variant="outline-success" size="lg" onClick={() => {
                                                                                                                                    setNombre('Productos con clasificacion');
                                                                                                                                    interaccion(contadores.producto.clasificacion.con.length, 'producto', 'clasificacion', 'con');
                                                                                                                                }}>
                                                                                                                                    <FontAwesomeIcon icon={faList} />Listar
                                                                                                                                </Button>
                                                                                                                            </div>
                                                                                                                        </Card.Body>
                                                                                                                    </Card>
                                                                                                                </Col>

                                                                                                                <Col md={2}>
                                                                                                                    <Card border='danger' text='danger' className='mt-2 mb-2'>
                                                                                                                        <Card.Body>
                                                                                                                            <Card.Title>
                                                                                                                                <FontAwesomeIcon icon={faTag} /> Nivel 5 - Sin clasificación
                                                                                                                            </Card.Title>
                                                                                                                            <center>
                                                                                                                                <h1 className='display-1'>
                                                                                                                                    <strong>{contadores.producto.clasificacion.sin.length}</strong>
                                                                                                                                </h1>
                                                                                                                            </center>
                                                                                                                            <Card.Text>
                                                                                                                                <FontAwesomeIcon icon={faFilter} /> Cantidad con filtro.
                                                                                                                            </Card.Text>
                                                                                                                            <ListGroup numbered>
                                                                                                                                <ListGroup.Item variant='danger'>
                                                                                                                                    <FontAwesomeIcon icon={faImage} /> Tiene imagen
                                                                                                                                </ListGroup.Item>
                                                                                                                                <ListGroup.Item variant='danger'>
                                                                                                                                    <FontAwesomeIcon icon={faCoins} /> Precio mayor a 10000
                                                                                                                                </ListGroup.Item>
                                                                                                                                <ListGroup.Item variant='danger'>
                                                                                                                                    <FontAwesomeIcon icon={fa0} /> Cantidad mayor a 0
                                                                                                                                </ListGroup.Item>
                                                                                                                                <ListGroup.Item variant='danger'>
                                                                                                                                    <FontAwesomeIcon icon={faCheck} /> Habilitado
                                                                                                                                </ListGroup.Item>
                                                                                                                                <ListGroup.Item variant='danger'>
                                                                                                                                    <FontAwesomeIcon icon={faFilter} /> No contiene clasificación
                                                                                                                                </ListGroup.Item>
                                                                                                                            </ListGroup>
                                                                                                                            <br />
                                                                                                                            <div className="d-grid gap-2">
                                                                                                                                <Button variant="outline-danger" size="lg" onClick={() => {
                                                                                                                                    setNombre('Productos sin clasificacion');
                                                                                                                                    interaccion(contadores.producto.clasificacion.sin.length, 'producto', 'clasificacion', 'sin');
                                                                                                                                }}>
                                                                                                                                    <FontAwesomeIcon icon={faList} />Listar
                                                                                                                                </Button>
                                                                                                                            </div>
                                                                                                                        </Card.Body>
                                                                                                                    </Card>
                                                                                                                </Col>

                                                                                                                <Col md={2}>
                                                                                                                    <Card border='success' text='success' className='mt-2 mb-2'>
                                                                                                                        <Card.Body>
                                                                                                                            <Card.Title>
                                                                                                                                <FontAwesomeIcon icon={faTag} /> Nivel 5 - Con promoción
                                                                                                                            </Card.Title>
                                                                                                                            <center>
                                                                                                                                <h1 className='display-1'>
                                                                                                                                    <strong>{contadores.producto.promocion.con.length}</strong>
                                                                                                                                </h1>
                                                                                                                            </center>
                                                                                                                            <Card.Text>
                                                                                                                                <FontAwesomeIcon icon={faFilter} /> Cantidad con filtro.
                                                                                                                            </Card.Text>
                                                                                                                            <ListGroup numbered>
                                                                                                                                <ListGroup.Item variant='success'>
                                                                                                                                    <FontAwesomeIcon icon={faImage} /> Tiene imagen
                                                                                                                                </ListGroup.Item>
                                                                                                                                <ListGroup.Item variant='success'>
                                                                                                                                    <FontAwesomeIcon icon={faCoins} /> Precio mayor a 10000
                                                                                                                                </ListGroup.Item>
                                                                                                                                <ListGroup.Item variant='success'>
                                                                                                                                    <FontAwesomeIcon icon={fa0} /> Cantidad mayor o igual a 0
                                                                                                                                </ListGroup.Item>
                                                                                                                                <ListGroup.Item variant='success'>
                                                                                                                                    <FontAwesomeIcon icon={faCheck} /> Habilitado
                                                                                                                                </ListGroup.Item>
                                                                                                                                <ListGroup.Item variant='success'>
                                                                                                                                    <FontAwesomeIcon icon={faFilter} /> Contiene promoción
                                                                                                                                </ListGroup.Item>
                                                                                                                            </ListGroup>
                                                                                                                            <br />
                                                                                                                            <div className="d-grid gap-2">
                                                                                                                                <Button variant="outline-success" size="lg" onClick={() => {
                                                                                                                                    setNombre('Productos con promocion');
                                                                                                                                    interaccion(contadores.producto.promocion.con.length, 'producto', 'promocion', 'con');
                                                                                                                                }}>
                                                                                                                                    <FontAwesomeIcon icon={faList} />Listar
                                                                                                                                </Button>
                                                                                                                            </div>
                                                                                                                        </Card.Body>
                                                                                                                    </Card>
                                                                                                                </Col>

                                                                                                                <Col md={2}>
                                                                                                                    <Card border='danger' text='danger' className='mt-2 mb-2'>
                                                                                                                        <Card.Body>
                                                                                                                            <Card.Title>
                                                                                                                                <FontAwesomeIcon icon={faTag} /> Nivel 5 - Sin promoción
                                                                                                                            </Card.Title>
                                                                                                                            <center>
                                                                                                                                <h1 className='display-1'>
                                                                                                                                    <strong>{contadores.producto.promocion.sin.length}</strong>
                                                                                                                                </h1>
                                                                                                                            </center>
                                                                                                                            <Card.Text>
                                                                                                                                <FontAwesomeIcon icon={faFilter} /> Cantidad con filtro.
                                                                                                                            </Card.Text>
                                                                                                                            <ListGroup numbered>
                                                                                                                                <ListGroup.Item variant='danger'>
                                                                                                                                    <FontAwesomeIcon icon={faImage} /> Tiene imagen
                                                                                                                                </ListGroup.Item>
                                                                                                                                <ListGroup.Item variant='danger'>
                                                                                                                                    <FontAwesomeIcon icon={faCoins} /> Precio mayor a 10000
                                                                                                                                </ListGroup.Item>
                                                                                                                                <ListGroup.Item variant='danger'>
                                                                                                                                    <FontAwesomeIcon icon={fa0} /> Cantidad mayor o igual a 0
                                                                                                                                </ListGroup.Item>
                                                                                                                                <ListGroup.Item variant='danger'>
                                                                                                                                    <FontAwesomeIcon icon={faCheck} /> Habilitado
                                                                                                                                </ListGroup.Item>
                                                                                                                                <ListGroup.Item variant='danger'>
                                                                                                                                    <FontAwesomeIcon icon={faFilter} /> No contiene promoción
                                                                                                                                </ListGroup.Item>
                                                                                                                            </ListGroup>
                                                                                                                            <br />
                                                                                                                            <div className="d-grid gap-2">
                                                                                                                                <Button variant="outline-danger" size="lg" onClick={() => {
                                                                                                                                    setNombre('Productos sin promocion');
                                                                                                                                    interaccion(contadores.producto.promocion.sin.length, 'producto', 'promocion', 'sin');
                                                                                                                                }}>
                                                                                                                                    <FontAwesomeIcon icon={faList} />Listar
                                                                                                                                </Button>
                                                                                                                            </div>
                                                                                                                        </Card.Body>
                                                                                                                    </Card>
                                                                                                                </Col>
                                                                                                            </Row>
                                                                                                        </Card.Body>
                                                                                                    </Card>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </Card.Body>
                                                                                    </Card>
                                                                                </Col>
                                                                            </Row>
                                                                        </Card.Body>
                                                                    </Card>
                                                                </Col>
                                                            </Row>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </Tab> : '')
                                    }
                                    {
                                        (obtenerPermiso(cuenta.interfaces, Interfaz, 'cuenta') ? <Tab eventKey="cuenta" title={`Cuenta: ${contadores.cuenta.general.total.length}`}>
                                            <Row>
                                                <Col md={12}>
                                                    <Card border='primary' text='primary' className='mt-2 mb-2'>
                                                        <Row>
                                                            <Col md={12}>
                                                                <Card.Body>
                                                                    <Card.Title>
                                                                        <FontAwesomeIcon icon={faUsers} /> Total de cuentas.
                                                                    </Card.Title>
                                                                    <center>
                                                                        <h1 className='display-1'>
                                                                            <strong>{contadores.cuenta.general.total.length}</strong>
                                                                        </h1>
                                                                    </center>
                                                                    <Card.Text>
                                                                        Total de cuentas.
                                                                    </Card.Text>
                                                                    <div className="d-grid gap-2">
                                                                        <Button variant="outline-primary" size="lg" onClick={() => {
                                                                            setNombre('Total');
                                                                            interaccion(contadores.cuenta.general.total.length, 'cuenta', 'general', 'total');
                                                                        }}>
                                                                            <FontAwesomeIcon icon={faList} />Listar
                                                                        </Button>
                                                                    </div>
                                                                </Card.Body>
                                                            </Col>
                                                        </Row>
                                                    </Card>
                                                </Col>
                                                <Col md={12}>

                                                </Col>
                                            </Row>
                                        </Tab> : '')
                                    }
                                    {
                                        (obtenerPermiso(cuenta.interfaces, Interfaz, 'carrito') ? <Tab eventKey="carrito" title={`Carrito: ${contadores.carrito.general.total.length}`}>
                                            <Row>
                                                <Col md={12}>
                                                    <Card border='primary' text='primary' className='mt-2 mb-2'>
                                                        <Card.Body>
                                                            <Card.Title>
                                                                <FontAwesomeIcon icon={faShoppingCart} /> Carritos: {contadores.carrito.general.total.length}
                                                            </Card.Title>
                                                            <br />
                                                            <Col md={12}>
                                                                <div className="d-grid gap-2 mb-2 mt-2">
                                                                    <Button variant="outline-primary" size="lg" onClick={() => {
                                                                        setNombre('Total');
                                                                        interaccion(contadores.carrito.general.total.length, 'carrito', 'general', 'total');
                                                                    }} disabled={(contadores.carrito.general.total.length <= 0)}>
                                                                        <FontAwesomeIcon icon={faList} />Listar
                                                                    </Button>
                                                                </div>
                                                            </Col>
                                                            <Row>
                                                                <Col md={4}>

                                                                </Col>
                                                                <Col md={4}>
                                                                    <center>
                                                                        <EstadisticaDona titulo='Carritos' titulos={['Pagados', 'Pendientes', 'Sin pagar']} datos={[contadores.carrito.estado.pagoConfirmado.length, contadores.carrito.estado.pagoSinConfirmar.length, contadores.carrito.estado.pagoSinRealizar.length]} colores={['6, 233, 6', '212, 233, 6', '236, 14, 14']} />
                                                                    </center>
                                                                </Col>
                                                                <Col md={4}>

                                                                </Col>
                                                                <Col md={4}>
                                                                    <div className="d-grid gap-2 mb-2 mt-2">
                                                                        <Button variant="outline-danger" size="lg" onClick={() => {
                                                                            setNombre('Total');
                                                                            interaccion(contadores.carrito.estado.pagoSinRealizar.length, 'carrito', 'estado', 'pagoSinRealizar');
                                                                        }} disabled={(contadores.carrito.estado.pagoSinRealizar.length <= 0)}>
                                                                            <FontAwesomeIcon icon={faList} />Listar
                                                                        </Button>
                                                                    </div>
                                                                </Col>
                                                                <Col md={4}>
                                                                    <div className="d-grid gap-2 mb-2 mt-2">
                                                                        <Button variant="outline-warning" size="lg" onClick={() => {
                                                                            setNombre('Total');
                                                                            interaccion(contadores.carrito.estado.pagoSinConfirmar.length, 'carrito', 'estado', 'pagoSinConfirmar');
                                                                        }} disabled={(contadores.carrito.estado.pagoSinConfirmar.length <= 0)}>
                                                                            <FontAwesomeIcon icon={faList} />Listar
                                                                        </Button>
                                                                    </div>
                                                                </Col>
                                                                <Col md={4}>
                                                                    <div className="d-grid gap-2 mb-2 mt-2">
                                                                        <Button variant="outline-success" size="lg" onClick={() => {
                                                                            setNombre('Total');
                                                                            interaccion(contadores.carrito.estado.pagoConfirmado.length, 'carrito', 'estado', 'pagoConfirmado');
                                                                        }} disabled={(contadores.carrito.estado.pagoConfirmado.length <= 0)}>
                                                                            <FontAwesomeIcon icon={faList} />Listar
                                                                        </Button>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </Tab> : '')
                                    }
                                    {
                                        (obtenerPermiso(cuenta.interfaces, Interfaz, 'publicidad') ? <Tab eventKey="publicidad" title={`Promociones: ${contadores.publicidad.general.total.length}`}>
                                            <Row>
                                                <Col md={12}>
                                                    <Card border='primary' text='primary' className='mt-2 mb-2'>
                                                        <Card.Body>
                                                            <Card.Title>
                                                                <FontAwesomeIcon icon={faTag} /> Promociones: {contadores.publicidad.general.total.length}
                                                            </Card.Title>
                                                            <Row>
                                                                <Col md={12}>
                                                                    <div className="d-grid gap-2 mb-3 mt-3">
                                                                        <Button variant="outline-primary" size="lg" onClick={() => {
                                                                            setNombre('Total promociones');
                                                                            interaccion(contadores.publicidad.general.total.length, 'publicidad', 'general', 'total');
                                                                        }} disabled={(contadores.publicidad.general.total.length <= 0)}>
                                                                            <FontAwesomeIcon icon={faList} />Listar
                                                                        </Button>
                                                                    </div>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <Card className='mb-3 mt-3'>
                                                                        <Card.Body>
                                                                            <Card.Title>Visibilidad Web</Card.Title>
                                                                            <Row>
                                                                                <Col md={3}>

                                                                                </Col>
                                                                                <Col md={6}>
                                                                                    <center>
                                                                                        <EstadisticaTorta titulo='Promoción' titulos={['Visible', 'No visible']} datos={[contadores.publicidad.visible.si.length, contadores.publicidad.visible.no.length]} colores={['6, 233, 6', '236, 14, 14']} />
                                                                                    </center>
                                                                                </Col>
                                                                                <Col md={3}>

                                                                                </Col>
                                                                                <Col md={6}>
                                                                                    <div className="d-grid gap-2 mb-2 mt-2">
                                                                                        <Button variant="outline-success" size="lg" onClick={() => {
                                                                                            setNombre('Promociones visibles');
                                                                                            interaccion(contadores.publicidad.visible.si.length, 'publicidad', 'visible', 'si');
                                                                                        }} disabled={(contadores.publicidad.visible.si.length <= 0)}>
                                                                                            <FontAwesomeIcon icon={faList} />Listar
                                                                                        </Button>
                                                                                    </div>
                                                                                </Col>
                                                                                <Col md={6}>
                                                                                    <div className="d-grid gap-2 mb-2 mt-2">
                                                                                        <Button variant="outline-danger" size="lg" onClick={() => {
                                                                                            setNombre('Promociones no visibles');
                                                                                            interaccion(contadores.publicidad.visible.no.length, 'publicidad', 'visible', 'no');
                                                                                        }} disabled={(contadores.publicidad.visible.no.length <= 0)}>
                                                                                            <FontAwesomeIcon icon={faList} />Listar
                                                                                        </Button>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </Card.Body>
                                                                    </Card>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <Card className='mb-3 mt-3'>
                                                                        <Card.Body>
                                                                            <Card.Title>Estados</Card.Title>
                                                                            <Row>
                                                                                <Col md={3}>

                                                                                </Col>
                                                                                <Col md={6}>
                                                                                    <center>
                                                                                        <EstadisticaTorta titulo='Promoción' titulos={['Próximamente', 'Vigentes', 'Vencidas']} datos={[contadores.publicidad.estado.proximamente.length, contadores.publicidad.estado.vigente.length, contadores.publicidad.estado.vencido.length]} colores={[' 14, 152, 236 ', '6, 233, 6', '236, 14, 14']} />
                                                                                    </center>
                                                                                </Col>
                                                                                <Col md={3}>

                                                                                </Col>
                                                                                <Col md={4}>
                                                                                    <div className="d-grid gap-2 mb-2 mt-2">
                                                                                        <Button variant="outline-primary" size="lg" onClick={() => {
                                                                                            setNombre('Promociones proximamente');
                                                                                            interaccion(contadores.publicidad.estado.proximamente.length, 'publicidad', 'estado', 'proximamente');
                                                                                        }} disabled={(contadores.publicidad.estado.proximamente.length <= 0)}>
                                                                                            <FontAwesomeIcon icon={faList} />Listar
                                                                                        </Button>
                                                                                    </div>
                                                                                </Col>
                                                                                <Col md={4}>
                                                                                    <div className="d-grid gap-2 mb-2 mt-2">
                                                                                        <Button variant="outline-success" size="lg" onClick={() => {
                                                                                            setNombre('Promociones vigentes');
                                                                                            interaccion(contadores.publicidad.estado.vigente.length, 'publicidad', 'estado', 'vigente');
                                                                                        }} disabled={(contadores.publicidad.estado.vigente.length <= 0)}>
                                                                                            <FontAwesomeIcon icon={faList} />Listar
                                                                                        </Button>
                                                                                    </div>
                                                                                </Col>
                                                                                <Col md={4}>
                                                                                    <div className="d-grid gap-2 mb-2 mt-2">
                                                                                        <Button variant="outline-danger" size="lg" onClick={() => {
                                                                                            setNombre('Promociones vencidas');
                                                                                            interaccion(contadores.publicidad.estado.vencido.length, 'publicidad', 'estado', 'vencido');
                                                                                        }} disabled={(contadores.publicidad.estado.vencido.length <= 0)}>
                                                                                            <FontAwesomeIcon icon={faList} />Listar
                                                                                        </Button>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </Card.Body>
                                                                    </Card>
                                                                </Col>
                                                            </Row>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </Tab> : '')
                                    }
                                    <Tab eventKey="ubicacion" title="Ubicación">
                                        <Row>
                                            <Col md={12}>
                                                <Card border='primary' text='primary' className='mt-2 mb-2'>
                                                    <Card.Body>
                                                        <Card.Title>
                                                            <FontAwesomeIcon icon={faMapMarker} /> Ubicación Actual
                                                        </Card.Title>
                                                        <center>
                                                            <Ubicacion latitud={-25.3092162} longitud={-57.5782501} />
                                                        </center>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </Tab>
                                </Tabs>
                            </Col>
                        </div>
                    </div>
                </div>
            </div>

            <Offcanvas show={panel} onHide={ocultarPanel} placement='end' className='bg-dark text-white' >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>
                        <FontAwesomeIcon icon={faClipboard} /> Interacción
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Exportar nombre={nombre} titulos={
                        [
                            {
                                label: 'Código',
                                key: 'codigo'
                            },
                            {
                                label: 'Marca',
                                key: 'marca'
                            },
                            {
                                label: 'Nombre',
                                key: 'nombre'
                            },
                            {
                                label: 'Cantidad',
                                key: 'cantidad'
                            }
                        ]
                    } datos={datos} />
                    <ListGroup>
                        {datos.map((dato) => (
                            <ListGroup.Item key={(dato.codigo === undefined ? dato.ruta : dato.codigo)} action onClick={() => {
                                dirigir((dato.codigo === undefined ? dato.ruta : dato.codigo));
                            }
                            }>
                                <FontAwesomeIcon icon={faChevronRight} /> {(dato.codigo === undefined ? dato.ruta : dato.codigo)} {(dato.cantidad === undefined ? '' : <Badge bg='primary'>{' Cantidad: ' + (dato.cantidad).toLocaleString('es-PY')}</Badge>)} <br /> {(dato.nombre === undefined ? '' : dato.nombre)}
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </Offcanvas.Body>
            </Offcanvas>

        </>
    );
}