import { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import Axios from 'axios';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark, faTrash, faCog, faPlus, faTimes, faCheck, faCogs, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { FloatingLabel, Button, Form, Modal, Table, Dropdown, Pagination } from 'react-bootstrap';
import { urlEcommerceBackend } from '../recursos/configuracion';
import { reportar } from '../recursos/funcion';
import { Cargador } from '../componentes/Cargador';
import { useUsuarioContexto } from '../proveedor/usuario';

export default function Caracteristica() {
    const parametro = useParams();
    const locacion = useLocation();
    const navegar = useNavigate();

    const cuenta = useUsuarioContexto();

    const [pagina, setPagina] = useState(0);
    const [intervalo, setIntevalo] = useState(0);

    const [datos, setDatos] = useState([]);
    const [paginas, setPaginas] = useState([]);
    const [paginado, setPaginado] = useState(
        {
            inicio: 0,
            fin: 0
        }
    );

    const [carga, setCarga] = useState(false);

    const [modal, setModal] = useState(false);

    const cerrarModal = () => setModal(false);
    const mostrarModal = () => setModal(true);

    useEffect(() => {

    }, []);

    useEffect(() => {
        setPagina(parseInt(parametro.pagina));
        setIntevalo(parseInt(parametro.intervalo));
    }, [locacion]);

    useEffect(() => {
        if (pagina !== 0 && intervalo !== 0) {
            recuperar();
        }
    }, [pagina, intervalo]);

    async function recuperar() {
        setCarga(true);
        try {
            let respuesta = await Axios.get(`${urlEcommerceBackend}/caracteristicas?pagina=${pagina}&intervalo=${intervalo}`, {
                headers: {
                    authorization: `Bearer ${cuenta.token}`
                }
            });
            setDatos(respuesta.data.arrayJson);
            setPaginas(respuesta.data.objetoJson.paginas);
            setPaginado(respuesta.data.objetoJson.paginado);
            setCarga(false);
        } catch (error) {
            setCarga(false);
            await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "caracteristica.jsx", "recuperar", `${error.toString()}`, {
                error
            }, 1);
        }
    }

    function dirigir(paginanueva) {
        if (paginanueva === 0) {
        } else {
            navegar(`/etiquetas/caracteristica/${paginanueva}/${intervalo}`);
        }
    }

    function actualizar() {
        recuperar();
    }

    function nuevo() {
        mostrarModal();
    }

    async function guardar() {
        let nombre = document.getElementById('txtNombre').value;
        if (nombre === '') {
            Swal.fire(
                'Atención',
                'El campo Nombre esta vacio',
                'info'
            )
        } else {
            try {
                let respuesta = await Axios.post(`${urlEcommerceBackend}/caracteristicas?nombre=${nombre.trim()}`, {

                }, {
                    headers: {
                        authorization: `Bearer ${cuenta.token}`
                    }
                });
                cerrarModal();
                actualizar();
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })

                Toast.fire({
                    icon: 'success',
                    title: respuesta.data.descripcion
                })
            } catch (error) {
                await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "caracteristica.jsx", "guardar", `${error.toString()}`, {
                    error
                }, 1);
            }
        }
    }

    function cerrar() {
        cerrarModal();
    }

    async function eliminar(nombre) {
        Swal.fire({
            title: 'Desea eliminar la etiqueta?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si',
            cancelButtonText: 'No'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    let respuesta = await Axios.delete(`${urlEcommerceBackend}/caracteristicas?nombre=${nombre}`, {
                        headers: {
                            authorization: `Bearer ${cuenta.token}`
                        }
                    });
                    actualizar();
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'bottom-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer)
                            toast.addEventListener('mouseleave', Swal.resumeTimer)
                        }
                    })

                    Toast.fire({
                        icon: 'success',
                        title: respuesta.data.descripcion
                    })
                } catch (error) {
                    await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "caracteristica.jsx", "eliminar", `${error.toString()}`, {
                        error
                    }, 1);
                }
            }
        })
    }

    const eventoEnter = (event) => {
        if (event.key === 'Enter') {
            guardar();
        }
    }

    return (
        <>
            <div className='container-fluid mt-3 mb-3'>
                <div className='row mb-3'>
                    <div className='col-md-12 mt-2 mb-2'>
                        <center>
                            <h1>
                                {(carga === true ? <Cargador estado={true} /> : <FontAwesomeIcon icon={faBookmark} />)} Caracteristicas
                            </h1>
                        </center>
                    </div>
                    <div className='col-md-6 mt-2 mb-2'>
                        <Dropdown>
                            <Dropdown.Toggle variant="outline-primary" id="cbOpciones">
                                <FontAwesomeIcon icon={faCogs} /> Opciones
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item href="#" onClick={nuevo}>
                                    <FontAwesomeIcon icon={faPlus} /> Nuevo
                                </Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item href="#" onClick={actualizar}>
                                    <FontAwesomeIcon icon={faRefresh} /> Actualizar
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div className='col-md-6 mt-2 mb-2'>
                        <div className="table-responsive">
                            <center>
                                {
                                    (paginas.length === 0 ? '' : <Pagination className='d-flex justify-content-end'>
                                        {(parseInt(pagina) === 1)}
                                        <Pagination.First onClick={() => {
                                            dirigir(paginado.inicio);
                                        }} disabled={parseInt(pagina) === 1} />
                                        <Pagination.Prev onClick={() => {
                                            dirigir(parseInt(pagina) - 1);
                                        }} disabled={parseInt(pagina) === 1} />
                                        {((Math.ceil(pagina / intervalo) * intervalo) === (Math.ceil(paginado.inicio / intervalo) * intervalo) ? '' : <><Pagination.Item onClick={() => {
                                            dirigir(paginado.inicio);
                                        }} disabled={(parseInt(pagina) === paginado.inicio)}>{paginado.inicio}</Pagination.Item><Pagination.Ellipsis onClick={() => {
                                            dirigir(parseInt(pagina) - 10)
                                        }} /></>)}

                                        {
                                            paginas.map((paginaOpcion, llave) => (
                                                <Pagination.Item key={llave} onClick={() => {
                                                    dirigir((paginaOpcion === parseInt(pagina) ? 0 : paginaOpcion))
                                                }} active={paginaOpcion === parseInt(pagina)}>{paginaOpcion}</Pagination.Item>
                                            ))
                                        }

                                        {((Math.ceil(pagina / intervalo) * intervalo) === (Math.ceil(paginado.fin / intervalo) * intervalo) ? '' : <><Pagination.Ellipsis onClick={() => {
                                            dirigir(parseInt(pagina) + 10)
                                        }} /><Pagination.Item onClick={() => {
                                            dirigir(paginado.fin);
                                        }} disabled={(parseInt(pagina) === paginado.fin)}>{paginado.fin}</Pagination.Item></>)}
                                        <Pagination.Next onClick={() => {
                                            dirigir(parseInt(pagina) + 1);
                                        }} disabled={parseInt(pagina) === paginado.fin} />
                                        <Pagination.Last onClick={() => {
                                            dirigir(paginado.fin);
                                        }} disabled={parseInt(pagina) === paginado.fin} />
                                    </Pagination>)
                                }
                            </center>
                        </div>
                    </div>
                    <div className='col-md-12'>
                        <div className='table-responsive'>
                            <Table bordered hover size='lg' className='table-dark'>
                                <thead>
                                    <tr>
                                        <th>Acción</th>
                                        <th>Nombre</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        datos.map((dato, key) => (
                                            <tr key={key}>
                                                <td>
                                                    <Dropdown>
                                                        <Dropdown.Toggle variant="outline-primary" id="cbOpcion">
                                                            <FontAwesomeIcon icon={faCog} />
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item href="#" onClick={() => {
                                                                eliminar(dato.nombre)
                                                            }}>
                                                                <FontAwesomeIcon icon={faTrash} /> Eliminar
                                                            </Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </td>
                                                <td>{dato.nombre}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </div>
                    <Modal show={modal} onHide={cerrarModal} backdrop="static" keyboard={false} >
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <FontAwesomeIcon icon={faBookmark} />Caracteristicas
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <FloatingLabel controlId="txtNombre" label="Nombre" className="mb-3" onKeyUp={eventoEnter} >
                                <Form.Control type="text" placeholder="Nombre" />
                            </FloatingLabel>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="outline-secondary" onClick={cerrar}>
                                <FontAwesomeIcon icon={faTimes} /> Cerrar
                            </Button>
                            <Button variant="outline-primary" onClick={guardar}>
                                <FontAwesomeIcon icon={faCheck} /> Guardar
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </div>
        </>
    );
}