import { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import Axios from 'axios';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCogs,
    faPlus,
    faRefresh,
    faTimes,
    faCheck,
    faPercent,
    faEye,
    faArrowUpRightFromSquare,
    faEyeSlash,
    faBoxes,
    faUpload
} from '@fortawesome/free-solid-svg-icons';
import { Image, Form, Button, Card, ButtonGroup, FloatingLabel, Modal, Dropdown } from 'react-bootstrap';
import { urlEcommerceBackend, urlEcommerceFrontend } from '../recursos/configuracion';
import { conseguirFormato, conseguirBase64, reportar, convertirFecha, diferencia } from '../recursos/funcion';
import { Cargador } from '../componentes/Cargador';
import { useUsuarioContexto } from '../proveedor/usuario';


export default function ConfiguracionCombo() {
    const cuenta = useUsuarioContexto();

    const [vital, setVital] = useState(false);

    const parametro = useParams();
    const locacion = useLocation();
    const navegar = useNavigate();

    const [pagina, setPagina] = useState(1);
    const [intervalo, setIntevalo] = useState(10);

    const [datos, setDatos] = useState([]);
    const [paginas, setPaginas] = useState([]);
    const [paginado, setPaginado] = useState({
        inicio: 0,
        fin: 0
    });
    const [carga, setCarga] = useState(false);
    const [modal, setModal] = useState(false);
    const mostrarModal = () => setModal(true);
    const cerrarModal = () => setModal(false);
    const [estadoSubir, setEstadoSubir] = useState(true);

    const [promociones, setPromociones] = useState([]);

    const [combos, setCombos] = useState([]);

    const [categorias, setCategorias] = useState([]);

    const [promocionSeleccionada, setPromocionSeleccionada] = useState('');

    const [promocionSeleccionadaId, setPromocionSeleccionadaId] = useState('');

    const [categoriaSeleccionada, setCategoriaSeleccionada] = useState('');

    const [comboObtenido, setCombo] = useState(false);

    const [imagen, setImagen] = useState("");

    const [estado, setEstado] = useState("");

    const [nombre, setNombre] = useState('');

    const [descripcion, setDescripcion] = useState('');

    const [valorVenta, setValorVenta] = useState('');


    useEffect(() => {
        if (cuenta.token === '') {
            setVital(true);
        } else {
            setVital(false);
            recuperar();
        }
    }, [cuenta]);

    useEffect(() => {
        setPagina(parseInt(parametro.pagina));
        setIntevalo(parseInt(parametro.intervalo));
    }, [locacion]);

    useEffect(() => {
        // if (pagina !== 0 && intervalo !== 0) {
            recuperar();
        // }
    }, []);

    async function recuperar() {

        setCarga(true);

        try {

            let respuesta = await Axios.get(
                `${urlEcommerceBackend}/promociones?pagina=${pagina}&intervalo=${intervalo}&estado=1`,
                {
                    headers: {
                        authorization: `Bearer ${cuenta.token}`
                    }
                }
            );

            setDatos(respuesta.data.arrayJson);

            // setPaginas(respuesta.data.objetoJson.paginas);

            // setPaginado(respuesta.data.objetoJson.paginado);

            setCarga(false);

            let promociones_activas = await Axios.get(
                `${urlEcommerceBackend}/promociones/listarActivas`,
                {
                    headers: {
                        authorization: `Bearer ${cuenta.token}`
                    }
                }
            );

            if (promociones_activas.data && promociones_activas.data.objetoJson) {
                setPromociones(promociones_activas.data.objetoJson);
            }

            // console.log('promociones_activas', promociones_activas);


            let combos = await Axios.get(
                `${urlEcommerceBackend}/productoCombo`,
                {
                    headers: {
                        authorization: `Bearer ${cuenta.token}`
                    }
                }
            );

            // console.log('combos', combos.data.objetoJson);

            if (combos.data && combos.data.objetoJson) {
                setCombos(combos.data.objetoJson);
            }


            let categorias = await Axios.get(
                `${urlEcommerceBackend}/categorias`,
                {
                    headers: {
                        authorization: `Bearer ${cuenta.token}`
                    }
                }
            );

            // console.log('categorias', categorias);

            setCategorias(categorias.data.arrayJson);


            let combo = await Axios.get(
                `${urlEcommerceBackend}/productoCombo/${parametro.id}`,
                {
                    headers: {
                        authorization: `Bearer ${cuenta.token}`
                    }
                });

            console.log('combo', combo.data.objetoJson[0]);

            setEstado(combo.data.objetoJson[0].estado);

            setCombo(combo.data.objetoJson[0]);

            // Datos del combo

            // console.log('comboObtenido', comboObtenido);

            // console.log('combo.data.objetoJson[0]', combo.data.objetoJson[0]);

            setNombre(combo.data.objetoJson[0].nombre);

            setDescripcion(combo.data.objetoJson[0].descripcion);

            // console.log('combo.data.objetoJson[0].promos[0]', typeof(combo.data.objetoJson[0].promos[0]));

            // console.log('combo.data.objetoJson[0].promos[0]', );

            // ACA SE ROMPE

            if (!(combo.data.objetoJson[0].promos[0] === '' || combo.data.objetoJson[0].promos[0] == undefined)){
                setPromocionSeleccionada(combo.data.objetoJson[0].promos[0]);

                // console.log('promociones_activas.data.objetoJson', promociones_activas.data.objetoJson);
    
                const promocionEncontrada = promociones_activas.data.objetoJson.find(promocion => promocion.ruta === combo.data.objetoJson[0].promos[0]);
    
                // console.log('combo.data.objetoJson[0].categorias[0]?.ruta', combo.data.objetoJson[0].categorias[0]?.ruta);
    
                // console.log('promocionEncontrada', promocionEncontrada);
    
                setPromocionSeleccionadaId(promocionEncontrada.codigo);                
            }


            const categoriaPreseleccionada = combo.data.objetoJson[0].categorias[0]?.ruta || '';

            setCategoriaSeleccionada(categoriaPreseleccionada);

            setValorVenta(combo.data.objetoJson[0].precio);

            setImagen("https://csdigitalizacion.nyc3.cdn.digitaloceanspaces.com/ecommerce/store/" + combo.data.objetoJson[0].imagenes[0].url["600"])


        } catch (error) {

            await reportar(
                `${cuenta.nombre} ${cuenta.apellido}`,
                'Ecommerce Admin',
                'Frontend',
                'promociones.jsx',
                'recuperar',
                `${error.toString()}`,
                {
                    error
                },
                1
            );

            setCarga(false);

        }
    }


    function dirigir(paginanueva) {

        if (paginanueva === 0) {

        } else {
            navegar(`/publicidad/promociones/${paginanueva}/${intervalo}`);
        }

    }


    function nuevo() {
        mostrarModal();
    }

    function actualizar() {
        recuperar();
    }



    //guardar generar una promocion nueva generada por el usuario.
    async function guardar() {

        let nombre_combo = document.getElementById('txtNombre').value;

        let descripcion_combo = document.getElementById('txtDescripcion').value;

        // let cantidadCliente = document.getElementById('txtCantidadCliente').value;
        if (nombre_combo === '') {
            Swal.fire('Promoción', 'El campo nombre esta vacio', 'info');
        } else {

            // console.log('promocionSeleccionada', promocionSeleccionada);

            let parametros = {
                usuario: `${cuenta.nombre} ${cuenta.apellido}`,
                nombre_combo: nombre_combo.trim(),
                categoria: categoriaSeleccionada,
                promo_asociada: promocionSeleccionada,
                descripcion: descripcion_combo.trim(),
                //cantidadCliente: cantidadCliente.trim(),
            }

            try {

                let respuesta = await Axios.post(`${urlEcommerceBackend}/productoCombo`, parametros, {
                    headers: {
                        authorization: `Bearer ${cuenta.token}`
                    }
                });

                // console.log('respuesta combo', respuesta);

                recuperar();
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                });

                Toast.fire({
                    icon: 'success',
                    title: respuesta.data.descripcion
                });

                cerrarModal();

                // SELECT ssss_emp1.insertar_combo_cabecera(:p_nombre_combo, :p_estado, :p_created_by, :p_promo_asociada);




                // let respuesta = await Axios.post(`${urlEcommerceBackend}/promocion`, parametros, {
                //   headers: {
                //     authorization: `Bearer ${cuenta.token}`
                //   }
                // });
                // recuperar();
                // cerrarModal();
                // const Toast = Swal.mixin({
                //   toast: true,
                //   position: 'bottom-end',
                //   showConfirmButton: false,
                //   timer: 3000,
                //   timerProgressBar: true,
                //   didOpen: (toast) => {
                //     toast.addEventListener('mouseenter', Swal.stopTimer);
                //     toast.addEventListener('mouseleave', Swal.resumeTimer);
                //   }
                // });

                // Toast.fire({
                //   icon: 'success',
                //   title: respuesta.data.descripcion
                // });


            } catch (error) {
                await reportar(
                    `${cuenta.nombre} ${cuenta.apellido}`,
                    'Ecommerce Admin',
                    'Frontend',
                    'promociones.jsx',
                    'guardar',
                    `${error.toString()}`,
                    {
                        parametros,
                        error
                    },
                    1
                );
            }
        }
    }

    function cancelar() {
        cerrarModal();
    }



    async function actualizarCombo() {
        let nombre_combo = document.getElementById('txtNombre').value;

        let descripcion_combo = document.getElementById('txtDescripcion').value;
    
        let valor_venta_combo = document.getElementById('valorVenta').value;
        console.log('promocionSeleccionadaId', promocionSeleccionadaId);

        let parametros = {
            usuario: `${cuenta.nombre} ${cuenta.apellido}`,
            estado: estado,
            nombre_combo: nombre_combo.trim(),
            categoria: categoriaSeleccionada,
            promo_asociada: promocionSeleccionada,
            promo_asociada_id: promocionSeleccionadaId,
            descripcion: descripcion_combo.trim(),
            valor_venta_combo: valor_venta_combo
          }

        console.log(parametro);

        let respuesta = await Axios.post(`${urlEcommerceBackend}/productoCombo/actualizar/${parametro.id}`, parametros, {
            headers: {
                authorization: `Bearer ${cuenta.token}`
            }
        });

        // console.log('respuesta combo', respuesta);

        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        });

        Toast.fire({
            icon: 'success',
            title: respuesta.data.descripcion
        });

        navegar(`/publicidad/combos/1/10`);

        
    }

    function setearIdPromo(ruta) {

        const promocionEncontrada = promociones.find(promocion => promocion.ruta === ruta);

        console.log('promocionEncontrada.codigo', promocionEncontrada?.codigo);

        setPromocionSeleccionadaId(promocionEncontrada?.codigo??'');

    }

    // async function duplicarCombo() {
    //     mostrarModal();
    //     // console.log('Duplicar combo');
    // }


    function elegirArchivo() {
        let informacion = document.getElementById('formImagen').files[0];
        let reader = new FileReader();
        reader.readAsDataURL(informacion);
        reader.onloadend = () => {
            setImagen(reader.result);
            setEstadoSubir(false);
            setDatos(
                {
                    formato: conseguirFormato(informacion.name),
                    codigo: comboObtenido.codigo,
                    file: conseguirBase64(reader.result),
                    id: comboObtenido._id
                }
            );
        };
    }

    async function subir() {
        if (document.getElementById('formImagen').value === '') {
            Swal.fire(
                'Subida de imágenes',
                'No se ha seleccionado imagen',
                'info'
            )
        } else {
            setEstadoSubir(true);
            try {
                let respuesta = await Axios.post(`${urlEcommerceBackend}/almacen/actualizarCombo`, datos, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        authorization: `Bearer ${cuenta.token}`
                    }
                });
                document.getElementById('formImagen').value = '';
                setImagen("https://csdigitalizacion.nyc3.cdn.digitaloceanspaces.com/ecommerce/logos/store/lcng/600.png");
                recuperar();
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })

                Toast.fire({
                    icon: 'success',
                    title: respuesta.data.descripcion
                })
            } catch (error) {
                await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "galeria.jsx", "subir", `${error.toString()}`, {
                    error
                }, 1);
            }
        }
    }

    const eventoEnter = (event) => {
        if (event.key === 'Enter') {
            guardar();
        }
    };
    { }
    return (
        <>
            <div className='container-fluid mt-3 mb-3'>
                <div className='row'>
                    <div className='col-md-12 mt-2 mb-2'>
                        <center>
                            <h1>{carga === true ? <Cargador estado={true} /> : <FontAwesomeIcon icon={faBoxes} />} Configuracion de: {comboObtenido.nombre}</h1>
                        </center>
                    </div>
                    {/* boton opciones */}
                    <div className='col-md-6 mt-2 mb-2'>
                        <Dropdown>
                            <Dropdown.Toggle variant='outline-primary' id='cbOpciones'>
                                <FontAwesomeIcon icon={faCogs} /> Opciones
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item href='#' onClick={nuevo} disabled={vital}>
                                    <FontAwesomeIcon icon={faPlus} /> Nuevo
                                </Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item href='#' onClick={actualizar} disabled={vital}>
                                    <FontAwesomeIcon icon={faRefresh} /> Actualizar
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <hr />
                    <div className='col-md-6 mb-3'>
                        <Card border="secondary">
                            <Card.Body>
                                <div className="row">
                                    <div className="col-md-12 mb-3">
                                        <center>
                                            <Image src={(imagen === "" ? "https://csdigitalizacion.nyc3.cdn.digitaloceanspaces.com/ecommerce/logos/store/lcng/600.png" : imagen)} height='600px' width='600px' fluid thumbnail alt="" />
                                        </center>
                                    </div>
                                    <div className="col-md-12">
                                        <center>
                                            <Form.Group controlId="formImagen">
                                                <Form.Control type="file" size="lg" disabled={comboObtenido.estado == 2} onChange={elegirArchivo} accept='image/*' />
                                            </Form.Group>
                                            <div className="d-grid gap-2 mt-3 mb-3">
                                                <Button variant="outline-success" size="lg" onClick={subir} disabled={estadoSubir}>
                                                    <FontAwesomeIcon icon={faUpload} />Subir
                                                </Button>
                                            </div>
                                        </center>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className="col-md-6 mb-3">
                        <Card border="secondary">
                            <Card.Body>
                                <br />
                                <div className='row justify-content-center align-items-center'>
                                    <Form.Group controlId="estadoSelect" className="col-md-10 mb-0">
                                        <Form.Label>Seleccione un estado</Form.Label>
                                        <Form.Control as="select" disabled={comboObtenido.estado == 2} value={estado} onChange={(e) => { setEstado(e.target.value) }}>
                                            <option disabled>Seleccionar</option>
                                            <option disabled={comboObtenido.estado == 1}value={0}>Inactivo</option>
                                            <option value={1}>Activo</option>
                                            <option value={2}>Vencido</option>
                                        </Form.Control>
                                    </Form.Group>
                                </div>
                                <br />
                                <div className='row justify-content-center align-items-center'>
                                    <Form.Group controlId='txtNombre' className="col-md-10 mb-0">
                                        <Form.Label>Nombre del combo</Form.Label>
                                        <Form.Control disabled={comboObtenido.estado == 2} type='text' placeholder='Nombre' value={nombre} onChange={(e) => setNombre(e.target.value)} />
                                    </Form.Group>
                                </div>
                                <br />
                                <div className='row justify-content-center align-items-center'>
                                    <Form.Group controlId='txtDescripcion' className="col-md-10 mb-0">
                                        <Form.Label>Descripción</Form.Label>
                                        <Form.Control
                                            disabled={comboObtenido.estado == 2}
                                            as="textarea"
                                            placeholder='Descripción'
                                            value={descripcion}
                                            onChange={(e) => setDescripcion(e.target.value)}
                                            rows={5}
                                        />
                                    </Form.Group>
                                </div>
                                <br />
                                <div className='row justify-content-center align-items-center'>
                                    <Form.Group controlId="selectPromocion" className="col-md-10 mb-0">
                                        <Form.Label>Promoción</Form.Label>
                                        <Form.Select
                                            // disabled={comboObtenido.estado == 2}
                                            disabled={true} // Siempre deshabilitado
                                            aria-label='Promoción'
                                            value={promocionSeleccionada}
                                            onChange={(e) => {
                                                setPromocionSeleccionada(e.target.value)
                                                setearIdPromo(e.target.value)
                                            }}
                                        >
                                            <option value='x' disabled>Seleccionar promoción</option>
                                            <option value=''>Sin promoción asignada</option>
                                            {promociones.map(promocion => (
                                                <option key={promocion.ruta} value={promocion.ruta}>
                                                    {promocion.nombre}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                </div>
                                <br />
                                <div className='row justify-content-center align-items-center'>
                                    <Form.Group controlId="selectCategoria" className="col-md-10 mb-0">
                                        <Form.Label>Categoría</Form.Label>
                                        <Form.Select
                                            disabled={comboObtenido.estado == 2}
                                            aria-label='Categoría'
                                            value={categoriaSeleccionada}
                                            onChange={(e) => setCategoriaSeleccionada(e.target.value)}
                                        >
                                            <option value=''>Seleccionar categoría</option>
                                            {categorias.map(categoria => (
                                                <option key={categoria.ruta} value={categoria.ruta}>
                                                    {categoria.nombre}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                </div>
                                <br />
                                <div className='row justify-content-center align-items-center'>
                                    <Form.Group controlId='valorVenta' className="col-md-10 mb-0">
                                        <Form.Label>Valor de venta</Form.Label>
                                        <Form.Control type='number' placeholder='Valor de venta' value={valorVenta} onChange={(e) => setValorVenta(e.target.value)} disabled />
                                    </Form.Group>
                                </div>
                                <br />
                                <div className='row justify-content-center'>
                                    <div className="col-md-10 text-center">
                                        <Button onClick={actualizarCombo} disabled={comboObtenido.estado == 2} variant="primary">
                                            Actualizar
                                        </Button>
                                    </div>
                                </div>
                                {/* <br />
                                <div className='row justify-content-center'>
                                    <div className="col-md-10 text-center">
                                        <Button onClick={duplicarCombo} variant="success">
                                            Duplicar
                                        </Button>
                                    </div>
                                </div> */}
                            </Card.Body>
                        </Card>
                    </div>


                </div>

            </div>



            <Modal show={modal} onHide={cancelar} backdrop='static' keyboard={false}>

                <Modal.Header closeButton>
                    <Modal.Title>
                        <FontAwesomeIcon icon={faPercent} /> Duplicar Combo
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <FloatingLabel controlId='txtNombre' label='Nombre' className='mb-3' onKeyUp={eventoEnter}>
                        <Form.Control type='text' placeholder='Nombre' defaultValue='' />
                    </FloatingLabel>

                    <FloatingLabel controlId='txtDescripcion' label='Descripción' className='mb-3' onKeyUp={eventoEnter}>
                        <Form.Control type='text' placeholder='Descripción' defaultValue='' />
                    </FloatingLabel>

                    <FloatingLabel controlId='selectPromocion' label='Promoción' className='mb-3'>
                        <Form.Select
                            aria-label='Promoción'
                            value={promocionSeleccionada}
                            onChange={(e) => setPromocionSeleccionada(e.target.value)}
                        >
                            <option value='' disabled>Seleccionar promoción</option>
                            <option>Sin promoción asignada</option>
                            {promociones.map(promocion => (
                                <option key={promocion.codigo} value={promocion.codigo}>
                                    {promocion.nombre}
                                </option>
                            ))}
                        </Form.Select>
                    </FloatingLabel>

                    <FloatingLabel controlId='selectCategoria' label='Categoría' className='mb-3'>
                        <Form.Select
                            aria-label='Categoría'
                            value={categoriaSeleccionada}
                            onChange={(e) => setCategoriaSeleccionada(e.target.value)}
                        >
                            <option value=''>Seleccionar categoría</option>
                            {categorias.map(categoria => (
                                <option key={categoria.ruta} value={categoria.ruta}>
                                    {categoria.nombre}
                                </option>
                            ))}
                        </Form.Select>
                    </FloatingLabel>

                </Modal.Body>

                <Modal.Footer>
                    <Button variant='outline-secondary' onClick={cancelar} size='lg'>
                        <FontAwesomeIcon icon={faTimes} /> Cancelar
                    </Button>
                    <Button variant='outline-primary' onClick={guardar} size='lg'>
                        <FontAwesomeIcon icon={faCheck} /> Guardar
                    </Button>
                </Modal.Footer>

            </Modal>

        </>
    );
}
