import { useState, useEffect } from 'react';
import Axios from 'axios';
import Swal from 'sweetalert2';
import { urlEcommerceBackend } from '../recursos/configuracion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FloatingLabel, Button, Form, ListGroup, Container, Row, Col } from 'react-bootstrap';
import { reportar } from '../recursos/funcion';
import { useUsuarioContexto } from '../proveedor/usuario';

export function Promocion(props) {

    const usuario = useUsuarioContexto();

    const [datos, setDatos] = useState([]);
    const [listas, setListas] = useState([]);

    useEffect(() => {
        actualizar(props.codigo);
        cargarLista();
    }, []);

    function agregar() {
        if (datos.length > 0) {
            Swal.fire(
                'Atención',
                'El producto ya figura en una promoción',
                'info'
            )
        } else {
            let valor = document.getElementById('idPromociones').value;
            if (valor === '') {
                Swal.fire(
                    'Atención',
                    'Promoción debe cargarse para continuar',
                    'info'
                )
            } else {
                Axios.post(urlEcommerceBackend + '/productopromociones/' + props.codigo + '?dato=' + valor)
                    .then(function (respuesta) {
                        actualizar(props.codigo);
                        const Toast = Swal.mixin({
                            toast: true,
                            position: 'bottom-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', Swal.stopTimer)
                                toast.addEventListener('mouseleave', Swal.resumeTimer)
                            }
                        })

                        Toast.fire({
                            icon: 'success',
                            title: respuesta.data.descripcion
                        })
                    })
                    .catch(async function (error) {
                        await reportar((usuario === null ? usuario.usuario : usuario.name), "Ecommerce Admin", "Frontend", "Promocion.jsx", "agregar", `${error.toString()}`, {
                            codigo: props.codigo,
                            dato: valor,
                            error: error.response
                        }, 1);
                    });
            }
        }
    }

    function eliminar(dato) {
        Swal.fire({
            title: 'Desea eliminar la etiqueta?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.isConfirmed) {
                Axios.delete(urlEcommerceBackend + '/productopromociones/' + props.codigo + '?dato=' + dato)
                    .then(function (respuesta) {
                        actualizar(props.codigo);
                        const Toast = Swal.mixin({
                            toast: true,
                            position: 'bottom-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', Swal.stopTimer)
                                toast.addEventListener('mouseleave', Swal.resumeTimer)
                            }
                        })

                        Toast.fire({
                            icon: 'success',
                            title: respuesta.data.descripcion
                        })
                    })
                    .catch(async function (error) {
                        await reportar((usuario === null ? usuario.usuario : usuario.name), "Ecommerce Admin", "Frontend", "Promocion.jsx", "eliminar", `${error.toString()}`, {
                            codigo: props.codigo,
                            dato: dato,
                            error: error.response.data
                        }, 1);
                    });
            }
        })
    }

    function actualizar(codigo) {
        Axios.get(urlEcommerceBackend + '/productopromociones/' + codigo)
            .then(function (respuesta) {
                setDatos(respuesta.data.arrayJson);
            })
            .catch(async function (error) {
                await reportar((usuario === null ? usuario.usuario : usuario.name), "Ecommerce Admin", "Frontend", "Promocion.jsx", "actualizar", `${error.toString()}`, {
                    codigo: codigo,
                    error: error.response.data
                }, 1);
            });
    }

    function cargarLista() {
        Axios.get(urlEcommerceBackend + '/consultas/promos')
            .then(function (respuesta) {
                setListas(respuesta.data.arrayJson);
            })
            .catch(async function (error) {
                await reportar((usuario === null ? usuario.usuario : usuario.name), "Ecommerce Admin", "Frontend", "Promocion.jsx", "cargarLista", `${error.toString()}`, {
                    error: error.response.data
                }, 1);
            });
    }

    return (
        <Container>
            <Row>
                <Col>
                    <FloatingLabel controlId="idPromociones" label="Promoción" className="mb-3">
                        <Form.Select aria-label="Floating label select example">
                            {
                                listas.map((lista, key) => (
                                    <option value={lista.ruta} key={key}>{lista.nombre}</option>
                                ))
                            }
                        </Form.Select>
                    </FloatingLabel>

                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="d-grid gap-2 mb-3">
                        <Button variant="outline-primary" size="lg" onClick={agregar}>
                            <FontAwesomeIcon icon={faPlus} />Agregar
                        </Button>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <ListGroup as="ol" numbered>
                        {
                            datos.map((dato, key) => (
                                <ListGroup.Item key={key} action onClick={() => { eliminar(dato) }}>{dato}</ListGroup.Item>
                            ))
                        }
                    </ListGroup>
                </Col>
            </Row>
        </Container>
    );
}