import { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import Axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCogs, faRefresh, faCartShopping, faArrowUpRightFromSquare, faCoins } from '@fortawesome/free-solid-svg-icons';
import { Table, Dropdown, Pagination, Button } from 'react-bootstrap';
import { BarraNavegacion } from '../componentes/BarraNavegacion';
import { urlEcommerceBackend } from '../recursos/configuracion';
import { obtenerFechaDeTiempo, obtenerHoraDeTiempo, obtenerInterfaz, obtenerPermiso, reportar } from '../recursos/funcion';
import { Cargador } from '../componentes/Cargador';
import { Estado } from '../componentes/Estado';
import { useUsuarioContexto } from '../proveedor/usuario';

export default function Carritos() {

    const cuenta = useUsuarioContexto();

    const parametro = useParams();
    const locacion = useLocation();
    const navegar = useNavigate();

    const [pagina, setPagina] = useState(1);
    const [intervalo, setIntevalo] = useState(10);

    const [datos, setDatos] = useState([]);
    const [paginas, setPaginas] = useState([]);
    const [paginado, setPaginado] = useState(
        {
            inicio: 0,
            fin: 0
        }
    );

    const [carga, setCarga] = useState(false);

    useEffect(() => {
        if (cuenta.token !== '') {
            recuperar();
        }
    }, [cuenta]);

    useEffect(() => {
        setPagina(parseInt(parametro.pagina));
        setIntevalo(parseInt(parametro.intervalo));
    }, [locacion]);

    useEffect(() => {
        if (pagina !== 0 && intervalo !== 0) {
            if (cuenta.token !== '') {
                recuperar();
            }
        }
    }, [pagina, intervalo]);

    async function recuperar() {
        setCarga(true);
        try {
            let respuesta = await Axios.get(`${urlEcommerceBackend}/carritos?pagina=${pagina}&intervalo=${intervalo}`, {
                headers: {
                    authorization: `Bearer ${cuenta.token}`
                }
            });
            setDatos(respuesta.data.arrayJson);
            setPaginas(respuesta.data.objetoJson.paginas);
            setPaginado(respuesta.data.objetoJson.paginado);
            setCarga(false);
        } catch (error) {
            setCarga(false);
            await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "carritos.jsx", "recuperar", `${error.toString()}`, {
                error
            }, 1);
        }
    }

    function dirigir(paginanueva) {
        if (paginanueva === 0) {

        } else {
            navegar(`/carritos/${paginanueva}/${intervalo}`);
        }
    }

    function actualizar() {
        recuperar();
    }

    function manjearEstado(estado) {
        let valor = {};
        if (estado === 0) {
            valor['descripcion'] = 'Pago confirmado';
            valor['estilo'] = 'success';
        } else if (estado === 1) {
            valor['descripcion'] = 'En proceso';
            valor['estilo'] = 'warning';
        } else if (estado === 2) {
            valor['descripcion'] = 'Cancelado';
            valor['estilo'] = 'danger';
        }
        return valor;
    }

    return (
        <>
            <div className='container-fluid mt-3 mb-3'>
                <div className='row mb-3'>
                    <div className='col-md-12'>
                        <h1>
                            {(carga === true ? <Cargador estado={true} /> : <FontAwesomeIcon icon={faCartShopping} />)}Carritos
                        </h1>
                    </div>
                    <div className='col-md-12 mt-2'>
                        <BarraNavegacion paginas={[
                            {
                                direccion: '/principal',
                                nombre: 'Principal'
                            },
                            {
                                direccion: `/carritos/${pagina}/${intervalo}`,
                                nombre: 'Carritos'
                            }
                        ]} />
                    </div>
                    <div className='col-md-6 mt-2 mb-2'>
                        <Dropdown>
                            <Dropdown.Toggle variant="outline-primary" id="cbOpciones">
                                <FontAwesomeIcon icon={faCogs} /> Opciones
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item href="#" onClick={actualizar}>
                                    <FontAwesomeIcon icon={faRefresh} /> Actualizar
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div className='col-md-6 mt-2 mb-2'>
                        <div className="table-responsive">
                            {
                                (paginas.length === 0 ? '' : <Pagination className='d-flex justify-content-end'>
                                    {(parseInt(pagina) === 1)}
                                    <Pagination.First onClick={() => {
                                        dirigir(paginado.inicio);
                                    }} disabled={parseInt(pagina) === 1} />
                                    <Pagination.Prev onClick={() => {
                                        dirigir(parseInt(pagina) - 1);
                                    }} disabled={parseInt(pagina) === 1} />
                                    {((Math.ceil(pagina / intervalo) * intervalo) === (Math.ceil(paginado.inicio / intervalo) * intervalo) ? '' : <><Pagination.Item onClick={() => {
                                        dirigir(paginado.inicio);
                                    }} disabled={(parseInt(pagina) === paginado.inicio)}>{paginado.inicio}</Pagination.Item><Pagination.Ellipsis onClick={() => {
                                        dirigir(parseInt(pagina) - 10)
                                    }} /></>)}

                                    {
                                        paginas.map((paginaOpcion, llave) => (
                                            <Pagination.Item key={llave} onClick={() => {
                                                dirigir((paginaOpcion === parseInt(pagina) ? 0 : paginaOpcion))
                                            }} active={paginaOpcion === parseInt(pagina)}>{paginaOpcion}</Pagination.Item>
                                        ))
                                    }

                                    {((Math.ceil(pagina / intervalo) * intervalo) === (Math.ceil(paginado.fin / intervalo) * intervalo) ? '' : <><Pagination.Ellipsis onClick={() => {
                                        dirigir(parseInt(pagina) + 10)
                                    }} /><Pagination.Item onClick={() => {
                                        dirigir(paginado.fin);
                                    }} disabled={(parseInt(pagina) === paginado.fin)}>{paginado.fin}</Pagination.Item></>)}
                                    <Pagination.Next onClick={() => {
                                        dirigir(parseInt(pagina) + 1);
                                    }} disabled={parseInt(pagina) === paginado.fin} />
                                    <Pagination.Last onClick={() => {
                                        dirigir(paginado.fin);
                                    }} disabled={parseInt(pagina) === paginado.fin} />
                                </Pagination>)
                            }
                        </div>
                    </div>
                    <div className='col-md-12'>
                        <div className='table-responsive'>
                            <Table bordered hover size="lg" className='table-dark'>
                                <thead>
                                    <tr>
                                        {
                                            (obtenerInterfaz(cuenta.interfaces, 'carrito') ? <th>Acción</th> : '')
                                        }
                                        <th>Número</th>
                                        <th>Cliente</th>
                                        <th>Documento</th>
                                        <th>Correo</th>
                                        <th>Teléfono</th>
                                        <th>Fecha</th>
                                        <th>Hora</th>
                                        <th>Pago</th>
                                        <th>Estado</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {datos.map((dato, key) => (
                                        <tr key={key} className={`text-${manjearEstado(dato.estado).estilo}`}>
                                            {
                                                (obtenerInterfaz(cuenta.interfaces, 'carrito') ? <td className='centro'>
                                                    <Button variant={`outline-${manjearEstado(dato.estado).estilo}`} onClick={() => {
                                                        if (dato.codigo !== undefined) {
                                                            navegar(`/carrito/${dato.codigo}`);
                                                        }
                                                    }}>
                                                        <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                                                    </Button>
                                                </td> : '')
                                            }
                                            <td className='derecha'>{parseInt(dato.codigo).toLocaleString('es-PY')}</td>
                                            <td>{(dato.cliente.razonsocial === undefined ? '' : dato.cliente.razonsocial)} {(dato.cliente.cuenta === undefined ? '' : dato.cliente.cuenta)}</td>
                                            <td className='derecha'>{(dato.cliente.documento === undefined || dato.cliente.documento === '' ? '' : parseInt(dato.cliente.documento).toLocaleString('es-PY'))}</td>
                                            <td>{dato.cliente.correo}</td>
                                            <td>{dato.cliente.telefono}</td>
                                            <td className='derecha'>{obtenerFechaDeTiempo(dato.tiempo)}</td>
                                            <td className='derecha'>{obtenerHoraDeTiempo(dato.tiempo)}</td>
                                            <td className='izquierda'>{dato.pago.tipo} {(dato.proceso === '' ? '' : <FontAwesomeIcon icon={faCoins} />)}</td>
                                            <td className='izquierda'><Estado descripcion={manjearEstado(dato.estado).descripcion} estado={dato.estado} tipo='0' formato='0' /></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}