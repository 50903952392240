import {
    useEffect,
    useState
} from 'react';
import {
    useNavigate,
    useLocation
} from 'react-router-dom';
import {
    FontAwesomeIcon
} from '@fortawesome/react-fontawesome';
import {
    faTag,
    faCubes,
    faPager,
    faCode,
    faChartPie,
    faRightToBracket,
    faRightFromBracket,
    faCartShopping,
    faListDots,
    faMicrochip,
    faMailBulk,
    faUsersGear,
    faMoneyCheck,
    faAward
} from '@fortawesome/free-solid-svg-icons';
import {
    Container,
    Nav,
    Navbar,
    NavDropdown
} from 'react-bootstrap';
import {
    LinkContainer
} from 'react-router-bootstrap';
import Swal from 'sweetalert2';
import {
    useUsuarioContexto,
    useSetUsuarioContexto
} from '../proveedor/usuario';
import {
    useSetContadoresContexto
} from '../proveedor/contadores';
import {
    useSetProcesosContexto
} from '../proveedor/procesos';
import {
    urlLoginBackend,
    urlProccessesBackend
} from '../recursos/configuracion';
import {
    obtenerCuenta,
    obtenerInterfaz,
    obtenerPermiso,
    reportar
} from '../recursos/funcion';
import Axios from 'axios';

export function BarraSuperior() {

    const localizacion = useLocation();
    const navegar = useNavigate();

    const cuenta = useUsuarioContexto();
    const establecerCuenta = useSetUsuarioContexto();

    const establecerContadores = useSetContadoresContexto();

    const establecerProcesos = useSetProcesosContexto();

    const [recuperacion, setRecuperacion] = useState(0);

    const [contadores, setContadores] = useState(
        {
            cuenta: {
                general: {
                    total: [],
                    hoy: []
                }
            },
            carrito: {
                general: {
                    total: [],
                    hoy: []
                },
                estado: {
                    pagoSinRealizar: [],
                    pagoSinConfirmar: [],
                    pagoConfirmado: []
                }
            },
            producto: { // Sin filtro
                general: {
                    total: [],
                    modelo: [],
                    descripcion: []
                },
                estado: {
                    positivo: [],
                    negativo: []
                },
                imagen: {
                    con: [],
                    sin: []
                },
                visible: { // Filtro base
                    positivo: [],
                    negativo: []
                },
                cantidad: {
                    negativo: [],
                    cero: [],
                    positivo: []
                },
                precio: {
                    negativo: [],
                    cero: [],
                    positivo: []
                },
                movimiento: {
                    limite30: [],
                    limite90: []
                },
                caracteristicas: {
                    con: [],
                    sin: []
                },
                clasificacion: {
                    con: [],
                    sin: []
                },
                promocion: {
                    con: [],
                    sin: []
                }
            },
            publicidad: {
                general: {
                    total: []
                },
                visible: {
                    si: [],
                    no: []
                },
                estado: {
                    proximamente: [],
                    vigente: [],
                    vencido: []
                }
            }
        }
    );

    const [procesos, setProcesos] = useState([]);

    useEffect(() => {
            verificarToken(1);
    }, []);

    useEffect(() => {
        verificarToken(0);
        //console.log(localizacion.pathname);
    }, [localizacion]);

    useEffect(() => {
        //console.log(cuenta);
        if (cuenta.token !== '') {
            if (obtenerPermiso(cuenta.interfaces, 'principal', 'contadores')) {
                recuperarContadores();
            }
            if (obtenerPermiso(cuenta.interfaces, 'panel', 'procesos')) {
                recuperarProcesos();
            }
        }
    }, [cuenta]);

    useEffect(() => {
        establecerContadores(contadores);
    }, [contadores]);

    useEffect(() => {
        establecerProcesos(procesos);
    }, [procesos]);

    async function recuperarContadores() {
        if (recuperacion === 0) {
            if (cuenta !== null) {
                setRecuperacion(1);
                try {
                    let respuesta = await Axios.get(`${urlProccessesBackend}/principales`);
                    setRecuperacion(0);
                    setContadores(respuesta.data.objetoJson);
                } catch (error) {
                    setRecuperacion(0);
                    await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "BarraSuperior.jsx", "recuperarContadores", `${error.toString()}`, {
                        error
                    }, 1);
                }
            }
        }
    }

    async function recuperarProcesos() {
        if (recuperacion === 0) {
            if (cuenta !== null) {
                setRecuperacion(1);
                try {
                    let respuesta = await Axios.get(`${urlProccessesBackend}/procesos`);
                    setRecuperacion(0);
                    setProcesos(respuesta.data.arrayJson);
                } catch (error) {
                    setRecuperacion(0);
                    await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "BarraSuperior.jsx", "recuperarProcesos", `${error.toString()}`, {
                        error
                    }, 1);
                }
            }
        }
    }

    async function verificarToken(tipo) {
        let token = sessionStorage.getItem('auth-token');
        if (token === null || token === "") {
            if (localizacion.pathname !== '/acceso') {
                navegar('/acceso');
            }
        } else {
            if (await validarToken(token)) {
                if (tipo === 0) {
                    if (localizacion.pathname === '/acceso') {
                        navegar('/principal');
                    }
                }
            } else {
                navegar('/acceso');
            }
        }
    }

    async function validarToken(token) {
        let envio = false;
        try {
            let respuesta = await Axios.post(`${urlLoginBackend}/comprobar`, {

            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            if (respuesta.data.codigo === 1) {
                envio = true;
                const cuentaVerificada = obtenerCuenta(token);
                establecerCuenta(
                    {
                        token: token,
                        nombre: cuentaVerificada.nombre,
                        apellido: cuentaVerificada.apellido,
                        rol: cuentaVerificada.rol.nombre,
                        interfaces: cuentaVerificada.rol.interfaz
                    }
                );
            } else {
                /*navegar('/acceso');
                sessionStorage.removeItem('auth-token');
                establecerCuenta({
                    token: '',
                    nombre: '',
                    apellido: '',
                    rol: '',
                    interfaces: []
                });
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })

                Toast.fire({
                    icon: 'info',
                    title: respuesta.data.descripcion
                })*/
                revocarToken();
                envio = false;
            }
            return envio;
        } catch (error) {
            await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "BarraSuperior.jsx", "validarToken", `${error.toString()}`, {
                error
            }, 1);
        }
    }

    async function revocarToken() {
        try {
            let token = sessionStorage.getItem('auth-token');
            let respuesta = await Axios.delete(`${urlLoginBackend}/cerrar`, {
                headers: {
                    authorization: `Bearer ${token}`
                }
            });
            if (respuesta.data.codigo === 0) {
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })

                Toast.fire({
                    icon: 'success',
                    title: respuesta.data.descripcion
                })
                navegar('/acceso');
                sessionStorage.removeItem('auth-token');
                localStorage.removeItem('ruta');
                establecerCuenta({
                    token: '',
                    nombre: '',
                    apellido: '',
                    rol: '',
                    interfaces: []
                });
            }

        } catch (error) {
            await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "BarraSuperior.jsx", "revocarToken", `${error.toString()}`, {
                error
            }, 1);
        }
    }

    return (
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
            <Container fluid>
                <LinkContainer to="/">
                    <Navbar.Brand>
                        <FontAwesomeIcon icon={faTag} /> Central Shop
                    </Navbar.Brand>
                </LinkContainer>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                        {obtenerInterfaz(cuenta.interfaces, 'principal') ? <LinkContainer to="/principal"><Nav.Link><FontAwesomeIcon icon={faChartPie} />Principal</Nav.Link></LinkContainer> : ''}
                        {obtenerInterfaz(cuenta.interfaces, 'etiquetas') ? <LinkContainer to="/etiquetas"><Nav.Link><FontAwesomeIcon icon={faTag} />Etiquetas</Nav.Link></LinkContainer> : ''}
                        {obtenerInterfaz(cuenta.interfaces, 'publicidad') ? <LinkContainer to="/publicidad"><Nav.Link><FontAwesomeIcon icon={faPager} />Publicidad</Nav.Link></LinkContainer> : ''}
                        {obtenerInterfaz(cuenta.interfaces, 'cuentas') ? <LinkContainer to="/cuentas"><Nav.Link><FontAwesomeIcon icon={faUsersGear} />Cuentas</Nav.Link></LinkContainer> : ''}
                        {obtenerInterfaz(cuenta.interfaces, 'carritos') ? <LinkContainer to="/carritos"><Nav.Link><FontAwesomeIcon icon={faCartShopping} />Carritos</Nav.Link></LinkContainer> : ''}
                        {obtenerInterfaz(cuenta.interfaces, 'solicitudes') ? <LinkContainer to="/solicitudes"><Nav.Link><FontAwesomeIcon icon={faMoneyCheck} />Solicitudes</Nav.Link></LinkContainer> : ''}
                        {obtenerInterfaz(cuenta.interfaces, 'productos') ? <LinkContainer to="/productos"><Nav.Link><FontAwesomeIcon icon={faCubes} />Productos</Nav.Link></LinkContainer> : ''}
                        {obtenerInterfaz(cuenta.interfaces, 'editor') ? <LinkContainer to="/editor"><Nav.Link><FontAwesomeIcon icon={faCode} />Editor</Nav.Link></LinkContainer> : ''}
                        {obtenerInterfaz(cuenta.interfaces, 'coleccion') ? <LinkContainer to="/masvendidos"><Nav.Link><FontAwesomeIcon icon={faAward} />Más Vendidos</Nav.Link></LinkContainer> : ''}
                        {obtenerInterfaz(cuenta.interfaces, 'coleccion') ? <LinkContainer to="/coleccion"><Nav.Link><FontAwesomeIcon icon={faListDots} />Colección</Nav.Link></LinkContainer> : ''}
                        {obtenerInterfaz(cuenta.interfaces, 'correo') ? <LinkContainer to="/correo"><Nav.Link><FontAwesomeIcon icon={faMailBulk} />Correo</Nav.Link></LinkContainer> : ''}
                        {obtenerInterfaz(cuenta.interfaces, 'panel') ? <LinkContainer to="/panel"><Nav.Link><FontAwesomeIcon icon={faMicrochip} />Panel</Nav.Link></LinkContainer> : ''}
                    </Nav>
                    <Nav>
                        {cuenta.token === '' ? <LinkContainer to="/acceso"><Nav.Link><FontAwesomeIcon icon={faRightToBracket} />Iniciar Sesion</Nav.Link></LinkContainer> : <NavDropdown title={`${`${cuenta.nombre} ${cuenta.apellido}`} (${cuenta.rol})`} id="cbOpciones" drop='start'><NavDropdown.Item onClick={revocarToken}><FontAwesomeIcon icon={faRightFromBracket} />Cerrar Sesión</NavDropdown.Item></NavDropdown>}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}