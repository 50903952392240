import { useState, useEffect } from 'react';
import Axios from 'axios';
import { urlEcommerceBackend } from '../recursos/configuracion';
import { Card, ListGroup, Dropdown, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbtack, faCogs, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { reportar } from '../recursos/funcion';
import { Cargador } from '../componentes/Cargador';
import { useUsuarioContexto } from '../proveedor/usuario';

export default function Marcas() {

    const cuenta = useUsuarioContexto();

    const [datos, setDatos] = useState([]);

    const [carga, setCarga] = useState(false);

    useEffect(() => {
        if (cuenta.token !== "") {
            recuperar();
        }
    }, [cuenta]);

    async function recuperar() {
        setCarga(true);
        try {
            let respuesta = await Axios.get(`${urlEcommerceBackend}/marcas`, {
                headers: {
                    authorization: `Bearer ${cuenta.token}`
                }
            });
            setCarga(false);
            //console.log(respuesta.data.arrayJson);
            setDatos(respuesta.data.arrayJson);
        } catch (error) {
            setCarga(false);
            await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "marcas.jsx", "recuperar", `${error.toString()}`, {
                error
            }, 1);
        }
    }

    async function control(id, estado) {
        setCarga(true);
        try {
            let respuesta = await Axios.put(`${urlEcommerceBackend}/marcas/${id}/${estado}`, {
                headers: {
                    authorization: `Bearer ${cuenta.token}`
                }
            });
            setCarga(false);
            //console.log(respuesta.data);
            await recuperar();
        } catch (error) {
            setCarga(false);
            await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "marcas.jsx", "control", `${error.toString()}`, {
                error
            }, 1);
        }
    }

    function actualizar() {
        recuperar();
    }

    return (
        <>
            <div className='container-fluid mt-3 mb-3'>
                <div className='row mb-3'>
                    <div className='col-md-12 mt-2 mb-2'>
                        <center>
                            <h1>
                                {(carga === true ? <Cargador estado={true} /> : <FontAwesomeIcon icon={faThumbtack} />)}Marcas
                            </h1>
                        </center>
                    </div>
                    <div className='col-md-12 mt-2 mb-2'>
                        <Dropdown>
                            <Dropdown.Toggle variant="outline-primary" id="cbOpciones">
                                <FontAwesomeIcon icon={faCogs} /> Opciones
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item href="#" onClick={actualizar}>
                                    <FontAwesomeIcon icon={faRefresh} /> Actualizar
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div className='col-md-12 mt-2 mb-2'>
                        <Card>
                            <Card.Body>
                                <ListGroup as="ol" numbered>
                                    {
                                        datos.map((dato, llave) => (
                                            <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start" action key={llave}>
                                                <div className="ms-2 me-auto">
                                                    <div className="fw-bold">{dato.nombre}</div>
                                                    Sin imagen
                                                </div>
                                                <Form.Check type="switch" id={llave} label="Control Precio" checked={(dato.configuracion.control === 1 ? true : false)} onChange={() => { control(dato._id, dato.configuracion.control) }} />
                                            </ListGroup.Item>
                                        ))
                                    }
                                </ListGroup>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className='col-md-12'>
                        <div className='row'></div>
                    </div>
                </div>
            </div>
        </>
    );
}