import { CSVLink } from "react-csv";

export function Exportar(props) {

    const csvReport = {
        data: props.datos,
        headers: props.titulos,
        filename: props.nombre + '.csv'
    };

    return (
        <div className="mb-3">
            <CSVLink {...csvReport}>Export to CSV</CSVLink>
        </div>
    );
}