import { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCircleExclamation, faCircleInfo, faExclamation, faExclamationTriangle, faInfo, faTimes } from '@fortawesome/free-solid-svg-icons';
import { ListGroup, Badge } from 'react-bootstrap';
import { obtenerFechaDeTiempo, obtenerHoraDeTiempo } from '../recursos/funcion';

export function Transaccion(props) {

    useEffect(() => {
        console.log(props.informacion.tipo);
    }, [props]);

    function mostrarRiesgo(indice) {
        let respuesta = '';
        if (indice === 0) {
            respuesta = 'No se puede generar el riesgo en tiempo real';
        } else if (indice === 1) {
            respuesta = 'Bajo';
        } else if (indice === 2) {
            respuesta = 'Bajo';
        } else if (indice === 3) {
            respuesta = 'Bajo';
        } else if (indice === 4) {
            respuesta = 'Medio';
        } else if (indice === 5) {
            respuesta = 'Medio';
        } else if (indice === 6) {
            respuesta = 'Medio';
        } else if (indice === 7) {
            respuesta = 'Alto';
        } else if (indice === 8) {
            respuesta = 'Alto';
        } else if (indice === 9) {
            respuesta = 'Alto';
        }
        return respuesta;
    }


    if (props.informacion.tipo === 'single_buy') {
        if (props.informacion.operacion.status === 'success') {
            return (
                <>
                    <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                            <div className="fw-bold">Inicio de transacción aprobado</div>
                            <b>Fecha:</b> {obtenerFechaDeTiempo(props.informacion.tiempo)}<br />
                            <b>Hora:</b> {obtenerHoraDeTiempo(props.informacion.tiempo)}<br />
                        </div>
                        <Badge bg="success" pill><FontAwesomeIcon icon={faCheck} /></Badge>
                    </ListGroup.Item>
                </>
            )
        } else if (props.informacion.operacion.status === '') {
            return (
                <>
                    <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                            <div className="fw-bold">Inicio de transacción reversado</div>
                            <b>Fecha:</b> {obtenerFechaDeTiempo(props.informacion.tiempo)}<br />
                            <b>Hora:</b> {obtenerHoraDeTiempo(props.informacion.tiempo)}<br />
                        </div>
                        <Badge bg="warning" pill><FontAwesomeIcon icon={faExclamationTriangle} /></Badge>
                    </ListGroup.Item>
                </>
            )
        } else {
            return (
                <>
                    <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                            <div className="fw-bold">Inicio de transacción rechazado</div>
                            <b>Fecha:</b> {obtenerFechaDeTiempo(props.informacion.tiempo)}<br />
                            <b>Hora:</b> {obtenerHoraDeTiempo(props.informacion.tiempo)}<br />
                        </div>
                        <Badge bg="danger" pill><FontAwesomeIcon icon={faTimes} /></Badge>
                    </ListGroup.Item>
                </>
            )
        }
    } else if (props.informacion.tipo === 'buy_single_confirm') {
        if (props.informacion.operacion.authorization_number === null) {
            return (
                <>
                    <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                            <div className="fw-bold">Confirmación de transacción rechazada</div>
                            {(props.informacion.operacion.response_description === null ? '' : <><b>Estado:</b> {props.informacion.operacion.response_description}<br /></>)}
                            {(props.informacion.operacion.extended_response_description === null ? '' : <><b>Motivo:</b> {props.informacion.operacion.extended_response_description}<br /></>)}
                            <b>Fecha:</b> {obtenerFechaDeTiempo(props.informacion.tiempo)}<br />
                            <b>Hora:</b> {obtenerHoraDeTiempo(props.informacion.tiempo)}<br />
                            {(props.informacion.operacion.authorization_number === null ? '' : <><b>Autorización:</b> {parseInt(props.informacion.operacion.authorization_number).toLocaleString('es-PY')}<br /></>)}
                            {(props.informacion.operacion.security_information.risk_index === null ? '' : <><b>Riesgo:</b> {mostrarRiesgo(parseInt(props.informacion.operacion.security_information.risk_index))}<br /></>)}
                        </div>
                        <Badge bg="danger" pill><FontAwesomeIcon icon={faTimes} /></Badge>
                    </ListGroup.Item>
                </>
            )
        } else {
            return (
                <>
                    <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                            <div className="fw-bold">Confirmación de transacción aprobada</div>
                            {(props.informacion.operacion.response_description === null ? '' : <><b>Estado:</b> {props.informacion.operacion.response_description}<br /></>)}
                            {(props.informacion.operacion.extended_response_description === null ? '' : <><b>Motivo:</b> {props.informacion.operacion.extended_response_description}<br /></>)}
                            <b>Fecha:</b> {obtenerFechaDeTiempo(props.informacion.tiempo)}<br />
                            <b>Hora:</b> {obtenerHoraDeTiempo(props.informacion.tiempo)}<br />
                            {(props.informacion.operacion.authorization_number === null ? '' : <><b>Autorización:</b> {parseInt(props.informacion.operacion.authorization_number).toLocaleString('es-PY')}<br /></>)}
                            {(props.informacion.operacion.security_information.risk_index === null ? '' : <><b>Riesgo:</b> {mostrarRiesgo(parseInt(props.informacion.operacion.security_information.risk_index))}<br /></>)}
                        </div>
                        <Badge bg="success" pill><FontAwesomeIcon icon={faCheck} /></Badge>
                    </ListGroup.Item>
                </>
            )
        }
    } else if (props.informacion.tipo === 'get_single_buy_confirmation') {
        if (props.informacion.operacion.confirmation !== undefined) {
            return (
                <>
                    <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                            <div className="fw-bold">Consulta de transacción {(props.informacion.operacion.authorization_number !== null ? 'Aprobada' : 'Rechazada')}</div>
                            <b>Fecha:</b> {obtenerFechaDeTiempo(props.informacion.tiempo)}<br />
                            <b>Hora:</b> {obtenerHoraDeTiempo(props.informacion.tiempo)}<br />
                            {(props.informacion.operacion.response_description !== null ? '' : <><b>Estado:</b> {props.informacion.operacion.response_description}</>)}
                            {(props.informacion.operacion.authorization_number !== null ? '' : <><b>Autorización:</b> {parseInt(props.informacion.operacion.authorization_number).toLocaleString('es-PY')}</>)}
                            {(props.informacion.operacion.risk_index !== null ? '' : <><b>Riesgo:</b> {mostrarRiesgo(parseInt(props.informacion.operacion.risk_index))}</>)}
                        </div>
                        {
                            (props.informacion.operacion.status === "success" ? <Badge bg="success" pill><FontAwesomeIcon icon={faCheck} /></Badge> : <Badge bg="danger" pill><FontAwesomeIcon icon={faTimes} /></Badge>)
                        }
                    </ListGroup.Item>
                </>
            )
        } else {
            if (props.informacion.operacion.messages[0].level === 'info') {
                return (
                    <>
                        <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                            <div className="ms-2 me-auto">
                                <div className="fw-bold">Consulta de transacción</div>
                                {<><b>Mensaje:</b> {props.informacion.operacion.messages[0].key}<br /></>}
                                {<><b>Descripción:</b> {props.informacion.operacion.messages[0].dsc}<br /></>}
                                <b>Fecha:</b> {obtenerFechaDeTiempo(props.informacion.tiempo)}<br />
                                <b>Hora:</b> {obtenerHoraDeTiempo(props.informacion.tiempo)}<br />
                            </div>
                            <Badge bg="info" pill><FontAwesomeIcon icon={faInfo} /></Badge>
                        </ListGroup.Item>
                    </>
                )
            } else if (props.informacion.operacion.messages[0].level === 'error') {
                return (
                    <>
                        <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                            <div className="ms-2 me-auto">
                                <div className="fw-bold">Consulta de transacción</div>
                                {<><b>Mensaje:</b> {props.informacion.operacion.messages[0].key}<br /></>}
                                {<><b>Descripción:</b> {props.informacion.operacion.messages[0].dsc}<br /></>}
                                <b>Fecha:</b> {obtenerFechaDeTiempo(props.informacion.tiempo)}<br />
                                <b>Hora:</b> {obtenerHoraDeTiempo(props.informacion.tiempo)}<br />
                            </div>
                            <Badge bg="danger" pill><FontAwesomeIcon icon={faTimes} /></Badge>
                        </ListGroup.Item>
                    </>
                )
            }
        }
    }

    /*if (props.informacion.operacion.status !== undefined) {
        if (props.informacion.operacion.process_id !== undefined) {             // single_buy
            return (
                <>
                    <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                            <div className="fw-bold">Inicio de transacción {(props.informacion.operacion.status === "success" ? 'Aprobada' : 'Rechazada')}</div>
                            <b>Fecha:</b> {obtenerFechaDeTiempo(props.informacion.tiempo)}<br />
                            <b>Hora:</b> {obtenerHoraDeTiempo(props.informacion.tiempo)}<br />
                        </div>
                        {
                            (props.informacion.operacion.status === "success" ? <Badge bg="success" pill><FontAwesomeIcon icon={faCheck} /></Badge> : <Badge bg="danger" pill><FontAwesomeIcon icon={faTimes} /></Badge>)
                        }
                    </ListGroup.Item>
                </>
            )
        } else if (props.informacion.operacion.messages !== undefined) {        // rollback
            return (
                <>
                    <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                            <div className="fw-bold">Reversión de transacción</div>
                            <b>Fecha:</b> {obtenerFechaDeTiempo(props.informacion.tiempo)}<br />
                            <b>Hora:</b> {obtenerHoraDeTiempo(props.informacion.tiempo)}<br />
                        </div>
                        <Badge bg="primary" pill><FontAwesomeIcon icon={faCircleInfo} /> {props.informacion.operacion.messages.length} </Badge>
                    </ListGroup.Item>
                </>
            )
        } else if (props.informacion.operacion.confirmation !== undefined) {    // get_single_buy_confirmation
            return (
                <>
                    <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                            <div className="fw-bold">Consulta de transacción {(props.informacion.operacion.authorization_number !== null ? 'Aprobada' : 'Rechazada')}</div>
                            <b>Fecha:</b> {obtenerFechaDeTiempo(props.informacion.tiempo)}<br />
                            <b>Hora:</b> {obtenerHoraDeTiempo(props.informacion.tiempo)}<br />
                            {(props.informacion.operacion.response_description !== null ? '' : <><b>Estado:</b> {props.informacion.operacion.response_description}</>)}
                            {(props.informacion.operacion.authorization_number !== null ? '' : <><b>Autorización:</b> {parseInt(props.informacion.operacion.authorization_number).toLocaleString('es-PY')}</>)}
                            {(props.informacion.operacion.risk_index !== null ? '' : <><b>Riesgo:</b> {mostrarRiesgo(parseInt(props.informacion.operacion.risk_index))}</>)}
                        </div>
                        {
                            (props.informacion.operacion.status === "success" ? <Badge bg="success" pill><FontAwesomeIcon icon={faCheck} /></Badge> : <Badge bg="danger" pill><FontAwesomeIcon icon={faTimes} /></Badge>)
                        }
                    </ListGroup.Item>
                </>
            )
        }
    } else if (props.informacion.operacion !== undefined) {                     // charge
        return (
            <>
                <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                    <div className="ms-2 me-auto">
                        <div className="fw-bold">Transacción de pago {(props.informacion.operacion.authorization_number !== null ? 'Aprobada' : 'Rechazada')}</div>
                        <b>Fecha:</b> {obtenerFechaDeTiempo(props.informacion.tiempo)}<br />
                        <b>Hora:</b> {obtenerHoraDeTiempo(props.informacion.tiempo)}<br />
                        {(props.informacion.operacion.response_description !== null ? <><b>Estado:</b> {props.informacion.operacion.response_description}<br /></> : '')}
                        {(props.informacion.operacion.authorization_number !== null ? <><b>Autorización:</b> {parseInt(props.informacion.operacion.authorization_number).toLocaleString('es-PY')}<br /></> : '')}
                        {(props.informacion.operacion.security_information.risk_index !== null ? <><b>Riesgo:</b> {mostrarRiesgo(parseInt(props.informacion.operacion.security_information.risk_index))}<br /></> : '')}
                    </div>
                    {
                        (props.informacion.operacion.authorization_number !== '' ? <Badge bg="success" pill><FontAwesomeIcon icon={faCheck} /></Badge> : <Badge bg="danger" pill><FontAwesomeIcon icon={faTimes} /></Badge>)
                    }
                </ListGroup.Item>
            </>
        )
    }*/
}