import { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import Axios from 'axios';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faTrash, faImages, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Image, Form, Button, Card, ButtonGroup } from 'react-bootstrap';
import { urlEcommerceBackend } from '../recursos/configuracion';
import { conseguirFormato, conseguirBase64, reportar } from '../recursos/funcion';
import { BarraNavegacion } from '../componentes/BarraNavegacion';
import { useUsuarioContexto } from '../proveedor/usuario';

export default function Imagen() {

    const cuenta = useUsuarioContexto();

    const parametro = useParams();

    const [codigo, setCodigo] = useState(0);

    const [vital, setVital] = useState(false);

    const [producto, setProducto] = useState({});
    const [datos, setDatos] = useState(
        {
            formato: '',
            codigo: '',
            file: '',
            id: ''
        }
    );
    const [imagen, setImagen] = useState("");
    const [imagenes, setImagenes] = useState([]);

    const [estadoSubir, setEstadoSubir] = useState(true);

    useEffect(() => {

    }, []);

    useEffect(() => {
        if (cuenta.token === '') {
            setVital(true);
        } else {
            setVital(false);
            setCodigo(parametro.codigo);
            recuperar();
        }
    }, [cuenta]);

    async function recuperar() {
        try {
            let respuesta = await Axios.get(`${urlEcommerceBackend}/productos/${parametro.codigo}?estado=1`, {
                headers: {
                    authorization: `Bearer ${cuenta.token}`
                }
            });
            setImagenes(respuesta.data.arrayJson[0].imagenes);
            setProducto(respuesta.data.arrayJson[0]);
        } catch (error) {
            await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "galeria.jsx", "recuperar", `${error.toString()}`, {
                error
            }, 1);
        }
    }

    function elegirArchivo() {
        let informacion = document.getElementById('formImagen').files[0];
        let reader = new FileReader();
        reader.readAsDataURL(informacion);
        reader.onloadend = () => {
            setImagen(reader.result);
            setEstadoSubir(false);
            setDatos(
                {
                    formato: conseguirFormato(informacion.name),
                    codigo: producto.codigo,
                    file: conseguirBase64(reader.result),
                    id: producto._id
                }
            );
        };
    }

    async function subir() {
        if (document.getElementById('formImagen').value === '') {
            Swal.fire(
                'Subida de imágenes',
                'No se ha seleccionado imagen',
                'info'
            )
        } else {
            setEstadoSubir(true);
            try {
                let respuesta = await Axios.post(`${urlEcommerceBackend}/almacen`, datos, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        authorization: `Bearer ${cuenta.token}`
                    }
                });
                document.getElementById('formImagen').value = '';
                setImagen("https://csdigitalizacion.nyc3.cdn.digitaloceanspaces.com/ecommerce/logos/store/lcng/600.png");
                recuperar();
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })

                Toast.fire({
                    icon: 'success',
                    title: respuesta.data.descripcion
                })
            } catch (error) {
                await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "galeria.jsx", "subir", `${error.toString()}`, {
                    error
                }, 1);
            }
        }
    }

    function eliminar(variante, formato) {
        Swal.fire({
            icon: 'question',
            title: 'Esta seguro/a de eliminar esta imagen?',
            showDenyButton: true,
            confirmButtonText: 'Si',
            denyButtonText: 'No',
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    let parametros = {
                        id: producto._id,
                        codigo: producto.codigo,
                        formato: formato,
                        variante: variante
                    };
                    let respuesta = await Axios.delete(`${urlEcommerceBackend}/almacen`, {
                        data: parametros,
                        headers: {
                            authorization: `Bearer ${cuenta.token}`
                        }
                    });
                    recuperar();
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'bottom-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer)
                            toast.addEventListener('mouseleave', Swal.resumeTimer)
                        }
                    })
                    Toast.fire({
                        icon: 'success',
                        title: respuesta.data.descripcion
                    })
                } catch (error) {
                    await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "galeria.jsx", "eliminar", `${error.toString()}`, {
                        error
                    }, 1);
                }
            }
        });
    }

    async function mover(lado, codigo, variante) {
        try {
            let parametros = {
                lado: lado,
                codigo: codigo,
                variante: variante
            }
            let respuesta = await Axios.put(`${urlEcommerceBackend}/almacen`, parametros, {
                headers: {
                    authorization: `Bearer ${cuenta.token}`
                }
            });
            recuperar();
            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })
            Toast.fire({
                icon: 'success',
                title: respuesta.data.descripcion
            })
        } catch (error) {
            await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "galeria.jsx", "mover", `${error.toString()}`, {
                error
            }, 1);
        }
    }

    return (
        <>
            <div className="container-fluid mt-3 mb-3">
                <div className="row">
                    <div className='col-md-12'>
                        <h1>
                            <FontAwesomeIcon icon={faImages} />Galeria (1000x1000)
                        </h1>
                    </div>
                    <div className='col-md-12 mt-2'>
                        <BarraNavegacion paginas={[
                            {
                                direccion: '/principal',
                                nombre: 'Principal'
                            },
                            {
                                direccion: '/productos/1/10/tm/tc',
                                nombre: 'Productos'
                            },
                            {
                                direccion: `/producto/${codigo}`,
                                nombre: 'Producto'
                            },
                            {
                                direccion: `/galeria/${codigo}`,
                                nombre: 'Galeria'
                            }
                        ]} />
                    </div>
                    <div className="col-md-12 mb-3">
                        <h5>{producto.nombre} ({producto.codigo})</h5>
                    </div>
                    <div className='col-md-4 mb-3'>
                        <Card border="secondary">
                            <Card.Body>
                                <div className="row">
                                    <div className="col-md-12 mb-3">
                                        <center>
                                            <Image src={(imagen === "" ? "https://csdigitalizacion.nyc3.cdn.digitaloceanspaces.com/ecommerce/logos/store/lcng/600.png" : imagen)} height='600px' width='600px' fluid thumbnail alt="" />
                                        </center>
                                    </div>
                                    <div className="col-md-12">
                                        <center>
                                            <Form.Group controlId="formImagen">
                                                <Form.Control type="file" size="lg" onChange={elegirArchivo} accept='image/*' />
                                            </Form.Group>
                                            <div className="d-grid gap-2 mt-3 mb-3">
                                                <Button variant="outline-success" size="lg" onClick={subir} disabled={estadoSubir}>
                                                    <FontAwesomeIcon icon={faUpload} />Subir
                                                </Button>
                                            </div>
                                        </center>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className="col-md-8 mb-3">
                        <Card border="secondary">
                            <Card.Body>
                                <div className='row'>
                                    {
                                        imagenes.map((imagen, key) => (
                                            <div className='col-md-3' key={key}>
                                                <Card border="secondary" className="mt-2 mb-2">
                                                    <Card.Img variant="top" src={imagen.url['600']} />
                                                    <Card.Footer>
                                                        <div className="d-grid gap-2">
                                                            <ButtonGroup size="lg" className="mb-2">
                                                                <Button variant="outline-primary" onClick={() => {
                                                                    mover(0, parametro.codigo, imagen.variante, imagen.formato);
                                                                }}>
                                                                    <FontAwesomeIcon icon={faChevronLeft} />
                                                                </Button>
                                                                <Button variant="outline-primary" onClick={() => {
                                                                    mover(1, parametro.codigo, imagen.variante, imagen.formato);
                                                                }}>
                                                                    <FontAwesomeIcon icon={faChevronRight} />
                                                                </Button>
                                                            </ButtonGroup>
                                                            <Button variant="outline-danger" onClick={() => {
                                                                eliminar(imagen.variante, imagen.formato)
                                                            }} size="lg">
                                                                <FontAwesomeIcon icon={faTrash} />
                                                            </Button>
                                                        </div>
                                                    </Card.Footer>
                                                </Card>
                                            </div>
                                        ))
                                    }
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </div>
        </>
    )
}
