import {
    createContext,
    useState,
    useContext
} from 'react';

const usuarioContexto = createContext();
const setUsuarioContexto = createContext();

export function useUsuarioContexto() {
    return useContext(usuarioContexto);
}

export function useSetUsuarioContexto() {
    return useContext(setUsuarioContexto);
}

export function UsuarioProveedor(props) {
    const [usuario, setUsuario] = useState(
        {
            token: '',
            nombre: '',
            apellido: '',
            rol: '',
            interfaces: []
        }
    );

    function establecerUsuario(usuarioActual) {
        setUsuario(usuarioActual);
    }

    return (
        <usuarioContexto.Provider value={usuario}>
            <setUsuarioContexto.Provider value={establecerUsuario}>
                {props.children}
            </setUsuarioContexto.Provider>
        </usuarioContexto.Provider>
    );
}