import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAward, faCogs, faCube, faEye, faEyeSlash, faLock, faPlus, faRefresh, faTrash, faUnlock } from '@fortawesome/free-solid-svg-icons';
import { Container, Row, Col, Dropdown, Modal, FloatingLabel, Form, Pagination, Card, Button, Badge } from 'react-bootstrap';
import { BarraNavegacion } from '../componentes/BarraNavegacion';
import { Cargador } from '../componentes/Cargador';
import { useUsuarioContexto } from '../proveedor/usuario';
import { urlEcommerceBackend } from '../recursos/configuracion';
import Axios from 'axios';
import { reportar } from '../recursos/funcion';
import Swal from 'sweetalert2';

export default function Recomendados() {

    const cuenta = useUsuarioContexto();

    const [carga, setCarga] = useState(false);

    const [intervalo, setIntervalo] = useState(12);
    const [dato, setDato] = useState('');
    const [marca, setMarca] = useState('sm');
    const [categoria, setCategoria] = useState('sc');
    const [subcategoria, setSubcategoria] = useState('ssc');

    const [datosMarca, setDatosMarca] = useState([]);
    const [datosCategoria, setDatosCategoria] = useState([]);
    const [posicionCategoria, setPosicionCategoria] = useState('sc');
    const [datosSubCategoria, setDatosSubCategoria] = useState([]);

    const [productos, setProductos] = useState([]);
    const [datos, setDatos] = useState([]);

    const [pagina, setPagina] = useState(1);
    const [paginas, setPaginas] = useState([]);
    const [paginado, setPaginado] = useState(
        {
            inicio: 0,
            fin: 0
        }
    );

    const [productosModal, setProductosModal] = useState(false);

    const ocultarProductosModal = () => setProductosModal(false);
    const mostrarProductosModal = () => setProductosModal(true);

    useEffect(() => {
        cargarListas();
        recuperar();
    }, []);

    useEffect(() => {
        //console.log(datos);
    }, [datos]);

    useEffect(() => {
        //console.log(productos);
    }, [productos]);

    useEffect(() => {
        if (cuenta.token !== '') {

        }
    }, [cuenta]);

    useEffect(() => {
        if (datosCategoria.length > 0) {
            if (posicionCategoria === 'sc') {
                setSubcategoria('ssc');
            } else {
                setDatosSubCategoria(datosCategoria[posicionCategoria].subcategoria);
            }
        }
    }, [datosCategoria]);

    useEffect(() => {
        if (datosCategoria.length > 0) {
            if (posicionCategoria === 'sc') {
                setSubcategoria('ssc');
            } else {
                setCategoria(datosCategoria[posicionCategoria].ruta);
                setDatosSubCategoria(datosCategoria[posicionCategoria].subcategoria);
            }
        }
    }, [posicionCategoria]);

    useEffect(() => {
        buscar();
    }, [categoria, subcategoria, marca, dato, pagina]);

    async function buscar() {
        try {
            let respuesta = await Axios.post(`${urlEcommerceBackend}/consultas/buscador?pagina=${pagina}&intervalo=${intervalo}&dato=${(dato === 'sd' ? '' : dato)}&marca=${(marca === 'sm' ? '' : marca)}&categoria=${(categoria === 'sc' ? '' : categoria)}&subcategoria=${(subcategoria === 'ssc' ? '' : subcategoria)}&clasificacion=`, {

            }, {
                headers: {
                    authorization: `Bearer ${cuenta.token}`
                }
            });
            setProductos(respuesta.data.arrayJson);
            setPaginas(respuesta.data.objetoJson.paginas);
            setPaginado(respuesta.data.objetoJson.paginado);
        } catch (error) {
            await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "nomina.jsx", "buscar", `${error.toString()}`, error, 1);
        }
    }

    function nuevo() {
        mostrarProductosModal();
    }

    async function actualizar() {
        await recuperar();
    }

    async function recuperar() {
        setCarga(true);
        try {
            let respuesta = await Axios.get(`${urlEcommerceBackend}/masvendidos?estado=0`);
            setDatos(respuesta.data.arrayJson);
            setCarga(false);
        } catch (error) {
            setCarga(false);
            await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "correo.jsx", "listar", `${error.toString()}`, {
                error
            }, 1);
        }
    }

    async function cargarListas() {
        try {
            let marca = await Axios.post(`${urlEcommerceBackend}/recursos/marcas?dato=&categoria=&subcategoria=`, {

            }, {
                headers: {
                    authorization: `Bearer ${cuenta.token}`
                }
            });
            setDatosMarca(marca.data.arrayJson);
            let categoria = await Axios.get(`${urlEcommerceBackend}/recursos/categorias?dato=&marca=&categoria=`, {
                headers: {
                    authorization: `Bearer ${cuenta.token}`
                }
            });
            setDatosCategoria(categoria.data.arrayJson);
        } catch (error) {
            await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "nomina.jsx", "cargarListas", `${error.toString()}`, {
                error: error.response.data
            }, 1);
        }
    }

    function cargarSubCategoria(posicion) {
        if (posicion === 'sc') {
            setCategoria('sc');
            setSubcategoria('ssc');
            setDatosSubCategoria([]);
        } else {
            setPosicionCategoria(posicion);
        }
    }

    function dirigir(paginanueva) {
        setPagina(paginanueva);
    }

    function manejarProducto(codigo) {
        console.log(codigo);
        /*if (comprobarEditable(codigo)) {
            let agregarProductos = [...productos];
            agregarProductos.push(
                {
                    codigo: codigo,
                    contado: precio,
                    credito: []
                }
            )
            setProductos(agregarProductos);
        } else {
            let quitarProductos = [...productos];
            setProductos(quitarProductos.filter((producto) => producto.codigo !== codigo));
        }*/
    }

    function comprobarProducto(codigo) {
        let comprobar = datos.find((producto) => producto.codigo === codigo);
        return comprobar === undefined;
    }

    async function agregar(codigo) {
        Swal.fire({
            title: 'Desea agregar el producto?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si',
            cancelButtonText: 'No'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    if (paginado.inicio === 0 || paginado.fin === 0) {
                        const Toast = Swal.mixin({
                            toast: true,
                            position: 'bottom-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', Swal.stopTimer)
                                toast.addEventListener('mouseleave', Swal.resumeTimer)
                            }
                        })

                        Toast.fire({
                            icon: 'info',
                            title: 'No se encuentran productos'
                        })
                    } else {
                        let parametros = {
                            carga: 0
                        }
                        await Axios.post(`${urlEcommerceBackend}/masvendidos/${codigo}`, parametros);
                        const Toast = Swal.mixin({
                            toast: true,
                            position: 'bottom-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', Swal.stopTimer)
                                toast.addEventListener('mouseleave', Swal.resumeTimer)
                            }
                        })
                        Toast.fire({
                            icon: 'info',
                            title: 'Proceso finalizado'
                        })
                        recuperar();
                    }
                } catch (error) {
                    console.log(error);
                }
            }
        })
    }

    async function modificacion(codigo, valor, tipo) {
        try {
            let parametros = {
                tipo: tipo,
                valor: (parseInt(valor) === 0 ? 1 : 0)
            }
            let respuesta = await Axios.put(`${urlEcommerceBackend}/masvendidos/${codigo}`, parametros);
            console.log(respuesta);
            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })
            Toast.fire({
                icon: (respuesta.data.codigo === 0 ? 'success' : 'error'),
                title: respuesta.data.descripcion
            })
            recuperar();
        } catch (error) {
            console.log(error);
        }
    }

    async function quitar(codigo) {
        Swal.fire({
            title: 'Desea quitar el producto?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si',
            cancelButtonText: 'No'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await Axios.delete(`${urlEcommerceBackend}/masvendidos/${codigo}`);
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'bottom-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer)
                            toast.addEventListener('mouseleave', Swal.resumeTimer)
                        }
                    })
                    Toast.fire({
                        icon: 'info',
                        title: 'Proceso finalizado'
                    })
                    recuperar();
                } catch (error) {
                    console.log(error);
                }
            }
        })
    }

    return (
        <>
            <Container fluid className='mt-3 mb-3'>
                <Row className='mb-3'>
                    <Col md={12} className='mt-2'>
                        <h1>
                            {(carga === true ? <Cargador estado={true} /> : <FontAwesomeIcon icon={faAward} />)}Más Vendidos
                        </h1>
                    </Col>
                    <Col md={12} className='mt-2'>
                        <BarraNavegacion paginas={[
                            {
                                direccion: '/principal',
                                nombre: 'Principal'
                            },
                            {
                                direccion: '/masvendidos',
                                nombre: 'Más vendidos'
                            }
                        ]} />
                    </Col>
                    <Col md={12} className='mt-2 mb-2'>
                        <Dropdown>
                            <Dropdown.Toggle variant="outline-primary" id="cbOpciones">
                                <FontAwesomeIcon icon={faCogs} /> Opciones
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item href="#" onClick={nuevo}>
                                    <FontAwesomeIcon icon={faPlus} /> Nuevo
                                </Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item href="#" onClick={actualizar}>
                                    <FontAwesomeIcon icon={faRefresh} /> Actualizar
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                    <Col md={12}>
                        <Card className='mt-3 mb-3'>
                            <Card.Body>
                                <Card.Title>Selección de productos como más vendidos</Card.Title>
                                <Row>
                                    {
                                        datos.map((dato, llave) => (
                                            <Col xxl={2} xl={3} lg={4} md={6} sm={12} xs={12} key={llave} className='mt-3 mb-3'>
                                                <Card bg={'dark'} text={'light'} onClick={() => { }}>
                                                    <Card.Img variant="top" src={dato.imagenes.length > 0 ? dato.imagenes[0].url['1000'] : "https://csdigitalizacion.nyc3.cdn.digitaloceanspaces.com/ecommerce/logos/store/lcng/1000.png"} />
                                                    <Card.Body>
                                                        <Card.Title>{dato.nombre} <Badge bg={(dato.carga === 0 ? 'primary' : 'secondary')}>{(dato.carga === 0 ? 'Manual' : 'Automático')}</Badge></Card.Title>
                                                        <Card.Text>
                                                            Código: {parseInt(dato.codigo).toLocaleString('es-PY')}<br />
                                                            Marca: {dato.marca.nombre}<br />
                                                            Modelo: {dato.modelo}<br />
                                                            Precio Gs: {parseInt(dato.precio).toLocaleString('es-PY')}<br />
                                                            Cantidad: {parseInt(dato.cantidad).toLocaleString('es-PY')}
                                                        </Card.Text>

                                                        <div className="d-grid gap-2">
                                                            <Button variant={(parseInt(dato.visible) === 0 ? 'danger' : 'success')} size="lg" onClick={async () => {
                                                                await modificacion(dato.codigo, dato.visible, 0);
                                                            }}>
                                                                Visible {(parseInt(dato.visible) === 0 ? <><FontAwesomeIcon icon={faEyeSlash} /></> : <><FontAwesomeIcon icon={faEye} /></>)}
                                                            </Button>
                                                            <Button variant={(parseInt(dato.persistencia) === 0 ? 'danger' : 'success')} size="lg" onClick={async () => {
                                                                await modificacion(dato.codigo, dato.persistencia, 1);
                                                            }}>
                                                                Persistencia {(parseInt(dato.persistencia) === 0 ? <><FontAwesomeIcon icon={faUnlock} /></> : <><FontAwesomeIcon icon={faLock} /></>)}
                                                            </Button>
                                                            <Button variant="danger" size="lg" onClick={async () => {
                                                                await quitar(dato.codigo);
                                                            }}>
                                                                <FontAwesomeIcon icon={faTrash} />
                                                            </Button>
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        ))
                                    }
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>

            <Modal show={productosModal} onHide={ocultarProductosModal} backdrop="static" keyboard={false} size='xl'>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <FontAwesomeIcon icon={faPlus} /> Agregar
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col md={6} className='mt-2 mb-2'>
                                    <FloatingLabel controlId="txtCategoria" label="Categoria">
                                        <Form.Select aria-label="Categoria" onChange={(event) => {
                                            cargarSubCategoria(event.target.value);
                                        }}>
                                            <option value="sc">Sin categoria</option>
                                            {
                                                datosCategoria.map((categoria, key) => (
                                                    <option value={key} key={key}>{categoria.nombre}</option>
                                                ))
                                            }
                                        </Form.Select>
                                    </FloatingLabel>
                                </Col>
                                <Col md={6} className='mt-2 mb-2'>
                                    <FloatingLabel controlId="txtSubCategoria" label="SubCategoria">
                                        <Form.Select aria-label="SubCategoria" onChange={(event) => {
                                            setSubcategoria(event.target.value);
                                        }}>
                                            <option value="ssc">Sin subcategoria</option>
                                            {
                                                datosSubCategoria.map((subcategoria, key) => (
                                                    <option value={subcategoria.ruta} key={key}>{subcategoria.nombre}</option>
                                                ))
                                            }
                                        </Form.Select>
                                    </FloatingLabel>
                                </Col>
                                <Col md={6} className='mt-2 mb-2'>
                                    <FloatingLabel controlId="txtMarca" label="Marca">
                                        <Form.Select aria-label="Marca" onChange={(event) => {
                                            setMarca(event.target.value);
                                        }}>
                                            <option value="sm">Sin marca</option>
                                            {
                                                datosMarca.map((marca, key) => (
                                                    <option value={marca.ruta} key={key}>{marca.nombre}</option>
                                                ))
                                            }
                                        </Form.Select>
                                    </FloatingLabel>
                                </Col>
                                <Col md={6} className='mt-2 mb-2'>
                                    <FloatingLabel controlId="txtDato" label="Código/Nombre" >
                                        <Form.Control type="text" placeholder="Código/Nombre" onKeyUp={(event) => { setDato(event.target.value) }} />
                                    </FloatingLabel>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Row className='mt-4'>
                        <Col md={12} className='table-responsive'>
                            {
                                (paginas.length === 0 ? '' : <Pagination className='d-flex justify-content-center'>
                                    {(parseInt(pagina) === 1)}
                                    <Pagination.First onClick={() => {
                                        dirigir(paginado.inicio);
                                    }} disabled={parseInt(pagina) === 1} />
                                    <Pagination.Prev onClick={() => {
                                        dirigir(parseInt(pagina) - 1);
                                    }} disabled={parseInt(pagina) === 1} />
                                    {((Math.ceil(pagina / intervalo) * intervalo) === (Math.ceil(paginado.inicio / intervalo) * intervalo) ? '' : <><Pagination.Item onClick={() => {
                                        dirigir(paginado.inicio);
                                    }} disabled={(parseInt(pagina) === paginado.inicio)}>{paginado.inicio}</Pagination.Item><Pagination.Ellipsis onClick={() => {
                                        dirigir(parseInt(pagina) - 10)
                                    }} /></>)}

                                    {
                                        paginas.map((paginaOpcion, llave) => (
                                            <Pagination.Item key={llave} onClick={() => {
                                                dirigir((paginaOpcion === parseInt(pagina) ? 0 : paginaOpcion))
                                            }} active={paginaOpcion === parseInt(pagina)}>{paginaOpcion}</Pagination.Item>
                                        ))
                                    }

                                    {((Math.ceil(pagina / intervalo) * intervalo) === (Math.ceil(paginado.fin / intervalo) * intervalo) ? '' : <><Pagination.Ellipsis onClick={() => {
                                        dirigir(parseInt(pagina) + 10)
                                    }} /><Pagination.Item onClick={() => {
                                        dirigir(paginado.fin);
                                    }} disabled={(parseInt(pagina) === paginado.fin)}>{paginado.fin}</Pagination.Item></>)}
                                    <Pagination.Next onClick={() => {
                                        dirigir(parseInt(pagina) + 1);
                                    }} disabled={parseInt(pagina) === paginado.fin} />
                                    <Pagination.Last onClick={() => {
                                        dirigir(paginado.fin);
                                    }} disabled={parseInt(pagina) === paginado.fin} />
                                </Pagination>)
                            }
                        </Col>
                        {
                            productos.map((dato, llave) => (
                                <Col md={3} key={llave} className='mt-3 mb-3'>
                                    <Card >
                                        <Card.Img variant="top" src={dato.imagenes.length > 0 ? dato.imagenes[0].url['1000'] : "https://csdigitalizacion.nyc3.cdn.digitaloceanspaces.com/ecommerce/logos/store/lcng/1000.png"} />
                                        <Card.Body>
                                            <Card.Title>{dato.nombre}</Card.Title>
                                            <Card.Text>
                                                Código: {parseInt(dato.codigo).toLocaleString('es-PY')}<br />
                                                Marca: {dato.marca.nombre}<br />
                                                Modelo: {dato.modelo}<br />
                                                Precio Gs: {parseInt(dato.precio).toLocaleString('es-PY')}<br />
                                                Cantidad: {parseInt(dato.cantidad).toLocaleString('es-PY')}
                                            </Card.Text>
                                            <div className="d-grid gap-2">
                                                {/* <Button variant='success' onClick={() => agregar(dato.codigo)} disabled={!comprobarProducto(dato.codigo)}><FontAwesomeIcon icon={faPlus} /> Agregar</Button>*/}
                                                <Button size="lg" variant={comprobarProducto(dato.codigo) ? "danger" : "success"} disabled={!comprobarProducto(dato.codigo)} onClick={() => agregar(dato.codigo)}>
                                                    {comprobarProducto(dato.codigo) ? 'Agregar' : 'Agregado'}
                                                </Button>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))
                        }
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={ocultarProductosModal}>Cerrar</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}