import {
    useState,
    useEffect
} from 'react';
import {
    useParams,
    useNavigate,
    useLocation
} from 'react-router-dom';
import Axios from 'axios';
import Swal from 'sweetalert2';
import {
    FontAwesomeIcon
} from '@fortawesome/react-fontawesome';
import {
    faTrash,
    faCog,
    faPlus,
    faCheck,
    faCogs,
    faRefresh,
    faCodeBranch,
    faTimes
} from '@fortawesome/free-solid-svg-icons';
import {
    Button,
    Form,
    Modal,
    Table,
    Dropdown,
    Pagination,
    Stack,
    FloatingLabel,
    ListGroup,
    Badge
} from 'react-bootstrap';
import {
    urlEcommerceBackend
} from '../recursos/configuracion';
import {
    obtenerInterfaz,
    reportar
} from '../recursos/funcion';
import {
    Cargador
} from '../componentes/Cargador';
import {
    useUsuarioContexto
} from '../proveedor/usuario';

export default function Relacion() {

    const cuenta = useUsuarioContexto();
    const Interfaz = 'relacion';

    const parametro = useParams();
    const locacion = useLocation();
    const navegar = useNavigate();

    const [pagina, setPagina] = useState(0);
    const [intervalo, setIntevalo] = useState(0);

    const [datos, setDatos] = useState([]);

    const [dato, setDato] = useState({
        subcategoria: {
            nombre: '',
            ruta: ''
        },
        miembro: []
    });

    const [rutaRelacion, setRutaRelacion] = useState("");

    const [paginas, setPaginas] = useState([]);
    const [paginado, setPaginado] = useState(
        {
            inicio: 0,
            fin: 0
        }
    );

    const [carga, setCarga] = useState(false);

    const [listas, setListas] = useState([]);
    const [sublistas, setSubListas] = useState([]);

    const [posicionl, setPosicionL] = useState(0);
    const [posicions, setPosicionS] = useState(0);

    const [modalNuevo, setModalNuevo] = useState(false);

    const cerrarModalNuevo = () => setModalNuevo(false);
    const mostrarModalNuevo = () => setModalNuevo(true);

    const [modalRelacion, setModalRelacion] = useState(false);

    const cerrarModalRelacion = () => setModalRelacion(false);
    const mostrarModalRelacion = () => setModalRelacion(true);

    useEffect(() => {
        setPagina(parseInt(parametro.pagina));
        setIntevalo(parseInt(parametro.intervalo));
    }, [locacion]);

    useEffect(() => {
        obtenerCategorias();
        if (pagina !== 0 && intervalo !== 0) {
            recuperar();
        }
    }, [cuenta, pagina, intervalo]);

    useEffect(() => {
        setSubListas(listas.length === 0 ? [] : listas[posicionl].subcategoria);
    }, [posicionl]);

    useEffect(() => {
        //console.log(datos);
    }, [datos]);

    useEffect(() => {
        if (sublistas.length === 0) {
            if (listas.length > 0) {
                setSubListas(listas[0].subcategoria);
            }
        }
    }, [listas]);

    useEffect(() => {
        //console.log(dato);
    }, [dato]);

    async function recuperar() {
        setCarga(true);
        try {
            let respuesta = await Axios.get(`${urlEcommerceBackend}/relaciones?pagina=${pagina}&intervalo=${intervalo}`, {
                headers: {
                    authorization: `Bearer ${cuenta.token}`
                }
            });
            setDatos(respuesta.data.arrayJson);
            setPaginas(respuesta.data.objetoJson.paginas);
            setPaginado(respuesta.data.objetoJson.paginado);
            setCarga(false);
        } catch (error) {
            setCarga(false);
            await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "relacion.jsx", "recuperar", `${error.toString()}`, {
                error
            }, 1);
        }
    }

    async function recuperarEspecifico(ruta) {
        try {
            let respuesta = await Axios.get(`${urlEcommerceBackend}/relacion/${ruta}`, {
                headers: {
                    authorization: `Bearer ${cuenta.token}`
                }
            });
            setDato(respuesta.data.objetoJson);
        } catch (error) {
            await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "relacion.jsx", "recuperarEspecifico", `${error.toString()}`, {
                error
            }, 1);
        }
    }

    function obtenerCategorias() {
        Axios.get(`${urlEcommerceBackend}/recursos/arbol`)
            .then(function (resultado) {
                setListas(resultado.data.arrayJson);
            })
            .catch(async function (error) {
                await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "relacion.jsx", "obtenerCategorias", `${error.toString()}`, {
                    error: error.response
                }, 1);
            });
    }

    function dirigir(paginanueva) {
        if (paginanueva === 0) {

        } else {
            navegar(`/etiquetas/relacion/${paginanueva}/${intervalo}`);
        }
    }

    function actualizar() {
        recuperar();
    }

    function nuevo() {
        mostrarModalNuevo();
    }

    async function guardar() {
        let nombre = document.getElementById('cbSubCategoria').value;
        if (nombre === '') {
            Swal.fire(
                'Atención',
                'Debe seleccionar una SubCategoria para continuar',
                'info'
            )
        } else {
            try {
                let parametros = {
                    nombre: sublistas[posicions].nombre,
                    ruta: sublistas[posicions].ruta
                }
                let respuesta = await Axios.post(`${urlEcommerceBackend}/relaciones`, parametros, {
                    headers: {
                        authorization: `Bearer ${cuenta.token}`
                    }
                });
                cerrarModalNuevo();
                actualizar();
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })

                Toast.fire({
                    icon: 'success',
                    title: respuesta.data.descripcion
                })
            } catch (error) {
                await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "relacion.jsx", "guardar", `${error.toString()}`, {
                    nombre: nombre.trim(),
                    error
                }, 1);
            }
        }
    }

    async function eliminar(ruta, cantidad) {
        if (cantidad > 0) {
            Swal.fire(
                'No se puede eliminar el registro',
                'Contiene miembros asignados',
                'info'
            )
        } else {
            Swal.fire({
                title: 'Desea eliminar la etiqueta?',
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si',
                cancelButtonText: 'No'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        let respuesta = await Axios.delete(`${urlEcommerceBackend}/relaciones/${ruta}`, {
                            headers: {
                                authorization: `Bearer ${cuenta.token}`
                            }
                        });
                        actualizar();
                        const Toast = Swal.mixin({
                            toast: true,
                            position: 'bottom-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', Swal.stopTimer)
                                toast.addEventListener('mouseleave', Swal.resumeTimer)
                            }
                        })
                        Toast.fire({
                            icon: 'success',
                            title: respuesta.data.descripcion
                        })
                    } catch (error) {
                        await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "relacion.jsx", "eliminar", `${error.toString()}`, {
                            ruta: ruta,
                            error: error.response.data
                        }, 1);
                    }
                }
            })
        }
    }

    function relacion(nombre, ruta, posicion) {
        recuperarEspecifico(ruta);
        setRutaRelacion(ruta);
        //console.log(nombre, ruta, posicion);
        mostrarModalRelacion();
    }

    async function agregar() {
        try {
            if (sublistas.length === 0) {
                Swal.fire(
                    'Atención',
                    'Debe seleccionar una subcategoria',
                    'info'
                )
            } else {
                let parametros = {
                    ruta: sublistas[document.getElementById('cbSubCategoriaR').value].ruta
                }
                let respuesta = await Axios.post(`${urlEcommerceBackend}/relacion/${rutaRelacion}`, parametros, {
                    headers: {
                        authorization: `Bearer ${cuenta.token}`
                    }
                });
                recuperar();
                recuperarEspecifico(rutaRelacion);
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })
                Toast.fire({
                    icon: 'success',
                    title: respuesta.data.descripcion
                })
            }
        } catch (error) {
            await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "relacion.jsx", "agregar", `${error.toString()}`, {
                error
            }, 1);
        }
    }

    async function quitar(ruta) {
        Swal.fire({
            title: 'Desea eliminar la etiqueta?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si',
            cancelButtonText: 'No'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    let respuesta = await Axios.delete(`${urlEcommerceBackend}/relacion/${rutaRelacion}`, {
                        headers: {
                            authorization: `Bearer ${cuenta.token}`
                        },
                        data: {
                            ruta: ruta
                        }
                    });
                    recuperar();
                    recuperarEspecifico(rutaRelacion);
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'bottom-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer)
                            toast.addEventListener('mouseleave', Swal.resumeTimer)
                        }
                    })
                    Toast.fire({
                        icon: 'success',
                        title: respuesta.data.descripcion
                    })
                } catch (error) {
                    await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "relacion.jsx", "quitar", `${error.toString()}`, {
                        error
                    }, 1);
                }
            }
        })
    }

    return (
        <>
            <div className='container-fluid mt-3 mb-3'>
                <div className='row mb-3'>
                    <div className='col-md-12 mt-2 mb-2'>
                        <center>
                            <h1>
                                {(carga === true ? <Cargador estado={carga} /> : <FontAwesomeIcon icon={faCodeBranch} />)} Relación
                            </h1>
                        </center>
                    </div>
                    <div className='col-md-6 mt-2 mb-2'>
                        <Dropdown>
                            <Dropdown.Toggle variant="outline-primary" id="cbOpciones">
                                <FontAwesomeIcon icon={faCogs} /> Opciones
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item href="#" onClick={nuevo}>
                                    <FontAwesomeIcon icon={faPlus} /> Nuevo
                                </Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item href="#" onClick={actualizar}>
                                    <FontAwesomeIcon icon={faRefresh} /> Actualizar
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div className='col-md-6 mt-2 mb-2'>
                        <div className="table-responsive">
                            <center>
                                {
                                    (paginas.length === 0 ? '' : <Pagination className='d-flex justify-content-end'>
                                        {(parseInt(pagina) === 1)}
                                        <Pagination.First onClick={() => {
                                            dirigir(paginado.inicio);
                                        }} disabled={parseInt(pagina) === 1} />
                                        <Pagination.Prev onClick={() => {
                                            dirigir(parseInt(pagina) - 1);
                                        }} disabled={parseInt(pagina) === 1} />
                                        {((Math.ceil(pagina / intervalo) * intervalo) === (Math.ceil(paginado.inicio / intervalo) * intervalo) ? '' : <><Pagination.Item onClick={() => {
                                            dirigir(paginado.inicio);
                                        }} disabled={(parseInt(pagina) === paginado.inicio)}>{paginado.inicio}</Pagination.Item><Pagination.Ellipsis onClick={() => {
                                            dirigir(parseInt(pagina) - 10)
                                        }} /></>)}

                                        {
                                            paginas.map((paginaOpcion, llave) => (
                                                <Pagination.Item key={llave} onClick={() => {
                                                    dirigir((paginaOpcion === parseInt(pagina) ? 0 : paginaOpcion))
                                                }} active={paginaOpcion === parseInt(pagina)}>{paginaOpcion}</Pagination.Item>
                                            ))
                                        }

                                        {((Math.ceil(pagina / intervalo) * intervalo) === (Math.ceil(paginado.fin / intervalo) * intervalo) ? '' : <><Pagination.Ellipsis onClick={() => {
                                            dirigir(parseInt(pagina) + 10)
                                        }} /><Pagination.Item onClick={() => {
                                            dirigir(paginado.fin);
                                        }} disabled={(parseInt(pagina) === paginado.fin)}>{paginado.fin}</Pagination.Item></>)}
                                        <Pagination.Next onClick={() => {
                                            dirigir(parseInt(pagina) + 1);
                                        }} disabled={parseInt(pagina) === paginado.fin} />
                                        <Pagination.Last onClick={() => {
                                            dirigir(paginado.fin);
                                        }} disabled={parseInt(pagina) === paginado.fin} />
                                    </Pagination>)
                                }
                            </center>
                        </div>
                    </div>
                    <div className='col-md-12'>
                        <div className='table-responsive'>
                            <Table bordered hover size='lg' className='table-dark'>
                                <thead>
                                    <tr>
                                        <th>Acción</th>
                                        <th>Subcategoria</th>
                                        <th>Relación</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        datos.map((dato, llave) => (
                                            <tr key={llave}>
                                                <td>
                                                    <Dropdown>
                                                        <Dropdown.Toggle variant="outline-primary" id="cbOpcion">
                                                            <FontAwesomeIcon icon={faCog} />
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            {
                                                                (obtenerInterfaz(cuenta.interfaces, 'clasificar') ? <><Dropdown.Item href="#" onClick={() => {
                                                                    relacion(dato.subcategoria.nombre, dato.subcategoria.ruta, llave)
                                                                }}>
                                                                    <FontAwesomeIcon icon={faCodeBranch} /> Relaciones
                                                                </Dropdown.Item>
                                                                    <Dropdown.Divider /></> : <></>)
                                                            }

                                                            <Dropdown.Item href="#" onClick={() => {
                                                                eliminar(dato.subcategoria.ruta, dato.miembro.length)
                                                            }}>
                                                                <FontAwesomeIcon icon={faTrash} /> Eliminar
                                                            </Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </td>
                                                <td>{dato.subcategoria.nombre}</td>
                                                <td>{dato.miembro.length}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </div>

                    <Modal show={modalNuevo} onHide={cerrarModalNuevo} backdrop="static" keyboard={false} >
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <FontAwesomeIcon icon={faCodeBranch} />Relación
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

                            <FloatingLabel controlId="cbCategoria" label="Categoria" className='mb-2'>
                                <Form.Select onChange={(event) => {
                                    setPosicionL(event.target.value);
                                }}>
                                    {
                                        listas.map((lista, llave) => (
                                            <option value={llave} key={llave}>{lista.nombre}</option>
                                        ))
                                    }
                                </Form.Select>
                            </FloatingLabel>

                            <FloatingLabel controlId="cbSubCategoria" label="SubCategoria">
                                <Form.Select onChange={(event) => {
                                    setPosicionS(event.target.value);
                                }}>
                                    {
                                        sublistas.map((lista, llave) => (
                                            <option value={llave} key={llave}>{lista.nombre}</option>
                                        ))
                                    }
                                </Form.Select>
                            </FloatingLabel>

                        </Modal.Body>
                        <Modal.Footer>
                            <Stack>
                                <Button variant="outline-primary" onClick={guardar} className='mb-2'>
                                    <FontAwesomeIcon icon={faCheck} /> Guardar
                                </Button>
                                <Button variant="outline-secondary" onClick={cerrarModalNuevo}>
                                    <FontAwesomeIcon icon={faTimes} /> Cancelar
                                </Button>
                            </Stack>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={modalRelacion} onHide={cerrarModalRelacion} backdrop="static" keyboard={false} >
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <FontAwesomeIcon icon={faCodeBranch} />Relaciones ({dato.subcategoria.nombre})
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

                            <FloatingLabel controlId="cbCategoriaR" label="Categoria" className='mb-2'>
                                <Form.Select onChange={(event) => {
                                    setPosicionL(event.target.value);
                                }}>
                                    {
                                        listas.map((lista, llave) => (
                                            <option value={llave} key={llave}>{lista.nombre}</option>
                                        ))
                                    }
                                </Form.Select>
                            </FloatingLabel>

                            <FloatingLabel controlId="cbSubCategoriaR" label="SubCategoria">
                                <Form.Select onChange={(event) => {
                                    setPosicionS(event.target.value);
                                }}>
                                    {
                                        sublistas.map((lista, llave) => (
                                            <option value={llave} key={llave}>{lista.nombre}</option>
                                        ))
                                    }
                                </Form.Select>
                            </FloatingLabel>
                            <Stack>
                            </Stack>
                        </Modal.Body>
                        <Modal.Footer>
                            <Stack>
                                <Button variant="outline-primary" onClick={() => {
                                    agregar();
                                }} className='mb-3'>
                                    <FontAwesomeIcon icon={faCheck} /> Agregar
                                </Button>
                                <ListGroup as="ol" numbered>
                                    {dato.miembro.map((miembro, llave) => (
                                        <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start" key={llave} onClick={() => {
                                            quitar(miembro);
                                        }} action>
                                            <div className="ms-2 me-auto">
                                                <div className="fw-bold">{miembro}</div>
                                                Click para eliminar
                                            </div>
                                            <Badge bg="danger" pill>
                                                <FontAwesomeIcon icon={faTrash} />
                                            </Badge>
                                        </ListGroup.Item>
                                    ))}
                                </ListGroup>
                            </Stack>
                        </Modal.Footer>
                    </Modal>

                </div>
            </div>
        </>
    );
}