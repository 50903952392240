import { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { urlEcommerceBackend } from '../recursos/configuracion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkedAlt, faUserGear } from '@fortawesome/free-solid-svg-icons'
import { Container, Row, Col, Card, FloatingLabel, Form, Table, Image, Button, Modal } from 'react-bootstrap';
import Axios from 'axios';
import { BarraNavegacion } from '../componentes/BarraNavegacion';
import Ubicacion from '../componentes/Ubicacion';
import { Cargador } from '../componentes/Cargador';
import { useUsuarioContexto } from '../proveedor/usuario';
import { obtenerFechaDeTiempo, obtenerHoraDeTiempo, reportar } from '../recursos/funcion';

export default function Cuenta() {

    const cuenta = useUsuarioContexto();

    const Interfaz = 'cuenta';

    const parametro = useParams();
    const locacion = useLocation();
    const navegar = useNavigate();

    const [carga, setCarga] = useState(false);

    const [codigo, setCodigo] = useState("");

    const [dato, setDato] = useState(
        {
            codigo: "",
            nombre: "",
            apellido: "",
            correo: "",
            documento: "",
            ruc: -1,
            foto: "",
            fnac: "",
            sexo: "",
            telefono: [],
            direccion: [],
            tiempo: "",
            tipo: 0,
            estado: 1
        }
    );

    const [modal, setModal] = useState(false);

    const mostrarModal = () => setModal(true);
    const cerrarModal = () => setModal(false);

    const [ubicacion, setUbicacion] = useState(
        {
            lat: 0,
            lng: 0
        }
    );

    useEffect(() => {
        //actualizar();
    }, [cuenta]);

    useEffect(() => {
        setCodigo(parametro.codigo);
    }, [locacion]);

    useEffect(() => {
        if (codigo !== "") {
            actualizar();
        }
    }, [codigo]);

    useEffect(() => {
        if (ubicacion.lat !== 0 && ubicacion.lng!==0) {
            mostrarModal();
        }
    }, [ubicacion]);

    async function actualizar() {
        setCarga(true);
        try {
            let respuesta = await Axios.get(`${urlEcommerceBackend}/cuenta/${codigo}`);
            setDato(respuesta.data.objetoJson);
            setCarga(false);
        } catch (error) {
            setCarga(false);
            await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "cuenta.jsx", "actualizar", `${error.toString()}`, {
                error
            }, 1);
        }
    }

    return (
        <>
            <Container fluid className='mt-3 mb-3'>
                <Row className='mb-3'>
                    <Col md={12}>
                        <h1>
                            {(carga === true ? <Cargador estado={true} /> : <FontAwesomeIcon icon={faUserGear} />)}Cuenta
                        </h1>
                    </Col>
                    <Col md={12} className='mt-2'>
                        <BarraNavegacion paginas={[
                            {
                                direccion: '/principal',
                                nombre: 'Principal'
                            },
                            {
                                direccion: '/cuentas/1/10',
                                nombre: 'Cuentas'
                            },
                            {
                                direccion: `/cuenta/${codigo}`,
                                nombre: 'Cuenta'
                            }
                        ]} />
                    </Col>
                    <Col md={12} className='mt-2'>
                        <Card border="secondary">
                            <Card.Body>
                                <Card.Title>Información de la cuenta</Card.Title>
                                <Row>
                                    <Col md={12}>
                                        <center>
                                            <Image src={dato.foto} roundedCircle className='mt-2 mb-2' />
                                        </center>
                                    </Col>
                                    {/*<Col md={12} className="mb-3">
                                        <center>
                                            <label><u>Fecha</u>: {obtenerFechaDeTiempo(dato.tiempo)} - <u>Hora</u>: {obtenerHoraDeTiempo(dato.tiempo)}</label>
                                        </center>
                    </Col>*/}
                                    <Col md={12} className="mb-2 mt-3">
                                        <center>
                                            <h2>Datos</h2>
                                        </center>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <FloatingLabel controlId="txtNombre" label="Nombre" >
                                            <Form.Control type="text" placeholder="Nombre" defaultValue={dato.nombre} disabled />
                                        </FloatingLabel>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <FloatingLabel controlId="txtApellido" label="Apellido" >
                                            <Form.Control type="text" placeholder="Apellido" defaultValue={dato.apellido} disabled />
                                        </FloatingLabel>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <FloatingLabel controlId="txtCorreo" label="Correo" >
                                            <Form.Control type="email" placeholder="Correo" defaultValue={dato.correo} disabled />
                                        </FloatingLabel>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <FloatingLabel controlId="txtDocumento" label="Documento" >
                                            <Form.Control type="text" placeholder="Documento" defaultValue={dato.documento} disabled />
                                        </FloatingLabel>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <FloatingLabel controlId="txtSexo" label="Sexo" >
                                            <Form.Control type="text" placeholder="Sexo" defaultValue={(dato.sexo === 1 ? "Masculino" : "Femenino")} disabled />
                                        </FloatingLabel>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <FloatingLabel controlId="txtFnac" label="Fecha de nacimiento" >
                                            <Form.Control type="text" placeholder="Fecha de nacimiento" defaultValue={dato.fnac} disabled />
                                        </FloatingLabel>
                                    </Col>
                                    <Col md={12} className="mb-2 mt-3">
                                        <center>
                                            <h2>Teléfono</h2>
                                        </center>
                                    </Col>
                                    <Col md={12} className="mb-3">
                                        <Table bordered hover>
                                            <thead className='bg-dark text-light'>
                                                <tr>
                                                    <th>Número</th>
                                                    <th>Tipo</th>
                                                    <th>Número</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    dato.telefono.map((telefono, llave) => (
                                                        <tr key={llave}>
                                                            <td>{llave + 1}</td>
                                                            <td>{telefono.tipo}</td>
                                                            <td>{telefono.numero}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </Table>
                                    </Col>
                                    <Col md={12} className="mb-2 mt-3">
                                        <center>
                                            <h2>Dirección</h2>
                                        </center>
                                    </Col>
                                    <Col md={12} className="mb-3">
                                        <Table bordered hover>
                                            <thead className='bg-dark text-light'>
                                                <tr>
                                                    <th>Número</th>
                                                    <th>Ciudad</th>
                                                    <th>Barrio</th>
                                                    <th>Dirección</th>
                                                    <th>Número de casa</th>
                                                    <th>Ubicación</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    dato.direccion.map((direccion, llave) => (
                                                        <tr key={llave}>
                                                            <td>{llave + 1}</td>
                                                            <td>{direccion.ciudad}</td>
                                                            <td>{direccion.ciudad}</td>
                                                            <td>{direccion.direccion}</td>
                                                            <td>{direccion.numeroDeCasa}</td>
                                                            <td>
                                                                <Button variant="outline-primary" onClick={() => setUbicacion(direccion.ubicacion)}>
                                                                    <FontAwesomeIcon icon={faMapMarkedAlt} />
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>

            <Modal show={modal} onHide={cerrarModal} backdrop="static" keyboard={false} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <FontAwesomeIcon icon={faMapMarkedAlt} /> Ubicación
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Ubicacion latitud={ubicacion.lat} longitud={ubicacion.lng} />
                </Modal.Body>
            </Modal>
        </>
    );
}