import { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import Axios from 'axios';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCogs,
  faPlus,
  faRefresh,
  faTimes,
  faCheck,
  faPercent,
  faEye,
  faArrowUpRightFromSquare,
  faClone,
  faEyeSlash,
  faBoxes
} from '@fortawesome/free-solid-svg-icons';
import { Dropdown, Table, Modal, Button, FloatingLabel, Form, Pagination } from 'react-bootstrap';
import { urlEcommerceBackend, urlEcommerceFrontend } from '../recursos/configuracion';
import { reportar, convertirFecha, diferencia } from '../recursos/funcion';
import { Cargador } from '../componentes/Cargador';
import { useUsuarioContexto } from '../proveedor/usuario';


export default function Combos() {
  const cuenta = useUsuarioContexto();

  const [vital, setVital] = useState(false);

  const parametro = useParams();
  const locacion = useLocation();
  const navegar = useNavigate();

  const [pagina, setPagina] = useState(1);
  const [intervalo, setIntevalo] = useState(10);

  const [datos, setDatos] = useState([]);
  const [paginas, setPaginas] = useState([]);
  const [paginado, setPaginado] = useState({
    inicio: 0,
    fin: 0
  });
  const [carga, setCarga] = useState(false);
  const [modal, setModal] = useState(false);
  const mostrarModal = () => setModal(true);
  const cerrarModal = () => setModal(false);

  const [promociones, setPromociones] = useState([]);

  const [combos, setCombos] = useState([]);

  const [categorias, setCategorias] = useState([]);

  const [promocionSeleccionada, setPromocionSeleccionada] = useState('');

  const [categoriaSeleccionada, setCategoriaSeleccionada] = useState('');

  const buscarRef = useRef(null);

  const [modalDuplicar, setModalDuplicar] = useState(false);

  const mostrarModalDuplicar = () => setModalDuplicar(true);
  const cerrarModalDuplicar = () => setModalDuplicar(false);

  const [comboDuplicar, setComboDuplicar] = useState(null);

  const [codigoCombo, setCodigoCombo] = useState('');

  const [comboObtenido, setCombo] = useState(false);

  const [paginacion, setPaginacionCombos] = useState()


  // Estado para manejar los valores del formulario
  const [formValues, setFormValues] = useState({
    nombre: '',
    descripcion: '',
    promocion: '',
    categoria: '',
    valorVenta: ''
  });

  const [valorVenta, setValorVenta] = useState(0);

  const handleValorVentaChange = (e) => {
    let value = e.target.value;

    // Remover cualquier caracter no numérico (excepto el punto decimal si necesitas incluir decimales)
    const numericValue = value.replace(/[^0-9]/g, '');

    // Si no hay valor, simplemente setear vacío
    if (!numericValue) {
      setValorVenta(0);
      return;
    }

    // Formatear con separador de miles
    const formattedValue = new Intl.NumberFormat().format(numericValue);

    setValorVenta(formattedValue);
  };

  const getValorVentaSinFormato = () => {
    // Remover las comas para obtener el valor real como entero
    return valorVenta.replace(/\./g, '');
  };

  useEffect(() => {
    if (cuenta.token === '') {
      setVital(true);
    } else {
      setVital(false);
      recuperar();
    }
  }, [cuenta]);

  useEffect(() => {
    setPagina(parseInt(parametro.pagina));
    setIntevalo(parseInt(parametro.intervalo));
  }, [locacion]);

  useEffect(() => {
    if (pagina !== 0 && intervalo !== 0) {
      recuperar();
    }
  }, [pagina, intervalo]);

  //paginacion
  let active = pagina;
  let items = [];
  for (let number = 1; number <= paginacion; number++) {
    items.push(
      <Pagination.Item key={number} active={number === active} onClick={() => manejarPaginacion(number)}>
        {number}
      </Pagination.Item>,
    );
  }

  const manejarPaginacion = (numero) => {
    navegar(`/publicidad/combos/${numero}/10`)
    recuperar()
  }

  async function recuperar(dato = '') {

    setCarga(true);

    

    try {

      let respuesta = await Axios.get(
        `${urlEcommerceBackend}/promociones?pagina=${pagina}&intervalo=${intervalo}&estado=1`,
        {
          headers: {
            authorization: `Bearer ${cuenta.token}`
          }
        }
      );

      setDatos(respuesta.data.arrayJson);

      setPaginas(respuesta.data.objetoJson.paginas);

      setPaginado(respuesta.data.objetoJson.paginado);

      setCarga(false);

      let promociones_activas = await Axios.get(
        `${urlEcommerceBackend}/promociones/listarActivas`,
        {
          headers: {
            authorization: `Bearer ${cuenta.token}`
          }
        }
      );

      if (promociones_activas.data && promociones_activas.data.objetoJson) {
        setPromociones(promociones_activas.data.objetoJson);
      }

      // console.log('promociones_activas', promociones_activas);


      let combos = await Axios.get(
        `${urlEcommerceBackend}/productoCombo/?page=${parametro.pagina}&limit=10&dato=${dato}`,
        {
          headers: {
            authorization: `Bearer ${cuenta.token}`
          }
        }
      );

      // console.log('combos', combos.data.objetoJson);

      if (combos.data && combos.data.objetoJson) {
        setCombos(combos.data.objetoJson.items);
        setPaginacionCombos(combos.data.objetoJson.totalPages)
      }

      let categorias = await Axios.get(
        `${urlEcommerceBackend}/categorias`,
        {
          headers: {
            authorization: `Bearer ${cuenta.token}`
          }
        }
      );

      // console.log('respuesta.data.arrayJson', categorias);

      if (categorias.data && categorias.data.arrayJson) {
        setCategorias(categorias.data.arrayJson);
      }
      let combo = await Axios.get(
        `${urlEcommerceBackend}/productoCombo/${parametro.id}`,
        {
          headers: {
            authorization: `Bearer ${cuenta.token}`
          }
        });

      setCombo(combo.data.objetoJson[0]);
    } catch (error) {

      await reportar(
        `${cuenta.nombre} ${cuenta.apellido}`,
        'Ecommerce Admin',
        'Frontend',
        'promociones.jsx',
        'recuperar',
        `${error.toString()}`,
        {
          error
        },
        1
      );

      setCarga(false);

    }
  }

  function dirigir(paginanueva) {

    if (paginanueva === 0) {

    } else {
      navegar(`/publicidad/promociones/${paginanueva}/${intervalo}`);
    }

  }

  function nuevo() {
    mostrarModal();
  }

  function actualizar() {
    recuperar();
  }

  //guardar generar una promocion nueva generada por el usuario.
  async function guardar() {

    let nombre_combo = document.getElementById('txtNombre').value;

    let descripcion_combo = document.getElementById('txtDescripcion').value;

    // let valor_venta_combo = document.getElementById('valorVenta').value;

    // let cantidadCliente = document.getElementById('txtCantidadCliente').value;

    if (nombre_combo === '') {

      Swal.fire('Promoción', 'El campo nombre esta vacio', 'info');

    } else {

      // console.log('promocionSeleccionada', promocionSeleccionada);

      let parametros = {
        usuario: `${cuenta.nombre} ${cuenta.apellido}`,
        nombre_combo: nombre_combo.trim(),
        categoria: categoriaSeleccionada,
        // Se envía vacío para cargar después desde el excel
        promo_asociada: "",
        // promo_asociada: retornarPromo(promocionSeleccionada),
        descripcion: descripcion_combo.trim(),
        valor_venta_combo: getValorVentaSinFormato(valorVenta)
        // valor_venta_combo: valor_venta_combo
        //cantidadCliente: cantidadCliente.trim(),
      }

      console.log(parametros);

      try {

        let respuesta = await Axios.post(`${urlEcommerceBackend}/productoCombo`, parametros, {
          headers: {
            authorization: `Bearer ${cuenta.token}`
          }
        });

        

        recuperar();
        const Toast = Swal.mixin({
          toast: true,
          position: 'bottom-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          }
        });

        Toast.fire({
          icon: 'success',
          title: respuesta.data.descripcion
        });

        cerrarModal();



      } catch (error) {
        await reportar(
          `${cuenta.nombre} ${cuenta.apellido}`,
          'Ecommerce Admin',
          'Frontend',
          'promociones.jsx',
          'guardar',
          `${error.toString()}`,
          {
            parametros,
            error
          },
          1
        );
      }

    }
  }

  function cancelar() {
    cerrarModal();
  }

  function retornarPromo(promo_id) {
    console.log(promo_id);
    const promocionEncontrada = promociones.find(promocion => promocion.codigo === promo_id);
    if (promocionEncontrada) {
      return {
        codigo: promo_id,
        ruta: promocionEncontrada.ruta
      };
    }
    return []; // Retorna null si no se encuentra la promoción
  }



  const handleBuscar = () => {
    const buscar = buscarRef.current.value;
    recuperar(buscar)
  }

  function cancelarDuplicar() {
    cerrarModalDuplicar();
  }

  async function duplicarCombo(codigo) {
    setCodigoCombo(codigo);
    let combo = await Axios.get(
      `${urlEcommerceBackend}/productoCombo/${codigo}`,
      {
        headers: {
          authorization: `Bearer ${cuenta.token}`
        }
      });
    setComboDuplicar(combo.data.objetoJson[0]);
    mostrarModalDuplicar();
  }

  async function confirmarDuplicar() {
    // Lógica para duplicar el combo usando los datos de comboDuplicar
    // por ejemplo, podrías hacer un post con los datos del combo duplicado
    console.log('comboDuplicar.productos', comboDuplicar.productos);
    try {
      let parametros = {
        usuario: `${cuenta.nombre} ${cuenta.apellido}`,
        nombre_combo: comboDuplicar.nombre,
        categoria: comboDuplicar.categorias[0]?.ruta || '',
        // Se envía vacío para cargar después desde el excel
        promo_asociada: "",
        // promo_asociada: retornarPromo(promocionSeleccionada),
        descripcion: comboDuplicar.descripcion,
        productos: comboDuplicar.productos,
        valor_venta_combo: comboDuplicar.precio
      }

      console.log(parametros);

      let respuesta = await Axios.post(`${urlEcommerceBackend}/productoCombo/duplicar`, parametros, {
        headers: {
          authorization: `Bearer ${cuenta.token}`
        }
      });

      console.log('respuesta combo duplicado', respuesta);

      // // Desactivar la promoción duplicada

      console.log('codigoCombo', codigoCombo);

      let parametroInhabilitar = {
        estado: 2
      }

      console.log(parametro);

      let respuesta2 = await Axios.post(`${urlEcommerceBackend}/productoCombo/deshabilitar/${codigoCombo}`, parametroInhabilitar, {
        headers: {
          authorization: `Bearer ${cuenta.token}`
        }
      });


      recuperar();
      Swal.fire('Éxito', 'Combo duplicado correctamente', 'success');
      cerrarModalDuplicar();

    } catch (error) {
      await reportar(
        `${cuenta.nombre} ${cuenta.apellido}`,
        'Ecommerce Admin',
        'Frontend',
        'combos.jsx',
        'duplicarCombo',
        `${error.toString()}`,
        {
          error
        },
        1
      );
      Swal.fire('Error', 'Hubo un error al duplicar el combo', 'error');
    }
  }


  return (
    <>
      <div className='container-fluid mt-3 mb-3'>
        <div className='row mb-3'>
          <div className='col-md-12 mt-2 mb-2'>
            <center>
              <h1>{carga === true ? <Cargador estado={true} /> : <FontAwesomeIcon icon={faBoxes} />} Combos</h1>
            </center>
          </div>
          {/* botón opciones */}
          <div className='col-md-6 mt-2 mb-2'>
            <Dropdown>
              <Dropdown.Toggle variant='outline-primary' id='cbOpciones'>
                <FontAwesomeIcon icon={faCogs} /> Opciones
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item href='#' onClick={nuevo} disabled={vital}>
                  <FontAwesomeIcon icon={faPlus} /> Nuevo
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item href='#' onClick={actualizar} disabled={vital}>
                  <FontAwesomeIcon icon={faRefresh} /> Actualizar
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>

          <div className='col-md-6 mb-3 d-flex justify-content-end gap-2'>
            <Form.Control
              type="text"
              placeholder="Buscar"
              ref={buscarRef}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleBuscar();
                }
              }}
            />
            <Button onClick={handleBuscar}>Buscar</Button>
          </div>

          <div className='col-md-12'>
            <div className='table-responsive'>
              <Table bordered hover size='lg' className='table-dark'>
                <thead>
                  <tr>
                    <th>Código</th>
                    <th>Nombre</th>
                    <th>Promoción</th>
                    <th>Precio</th>
                    <th>Estado</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {combos
                    .map((combo, key) => (
                      <tr
                        key={key}
                        className={`align-middle ${combo.estado === 1 ? 'text-success' : combo.estado === 0 ? 'text-danger' : 'text-muted'}`}
                      >
                        <td>{combo.codigo}</td>
                        <td>{combo.nombre}</td>
                        <td>{combo.promos[0]}</td>
                        <td className='text-end'>{Intl.NumberFormat().format(combo.precio)}</td>
                        <td className='text-end'> {combo.estado === 1 ? 'Activo' : combo.estado === 2 ? 'Vencido' : 'Inactivo'}</td>
                        <td className='text-center'>
                          <Button
                            variant={`${combo.estado == 1 ? 'outline-success' : 'outline-danger'}`}
                            onClick={() => {
                              navegar(`/combos/${combo.codigo}`);
                            }}
                          >
                            <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                          </Button>
                          <Button
                            variant="outline-warning"
                            className="ms-4" // Clase de Bootstrap para margen izquierdo
                            onClick={() => {
                              duplicarCombo(combo.codigo); // Función que manejará la duplicación
                            }}
                          >
                            <FontAwesomeIcon icon={faClone} /> {/* Icono de duplicar */}
                          </Button>
                        </td>
                      </tr>
                    ))}
                </tbody>

              </Table>
            </div>
          </div>
        </div>

        {/* Paginación centrada */}
        <div className="d-flex justify-content-center">
          <Pagination>{items}</Pagination>
        </div>
      </div>


      <Modal show={modal} onHide={cancelar} backdrop='static' keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faPercent} /> Nuevo Combo
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <FloatingLabel controlId='txtNombre' label='Nombre' className='mb-3'>
            <Form.Control type='text' placeholder='Nombre' defaultValue='' />
          </FloatingLabel>

          <FloatingLabel controlId='txtDescripcion' label='Descripción' className='mb-3'>
            <Form.Control as="textarea" placeholder='Descripción' defaultValue='' />
          </FloatingLabel>

          <FloatingLabel controlId='selectCategoria' label='Categoría' className='mb-3'>
            <Form.Select
              aria-label='Categoría'
              value={categoriaSeleccionada}
              onChange={(e) => setCategoriaSeleccionada(e.target.value)}
            >
              <option value=''>Seleccionar categoría</option>
              {categorias.map((categoria) => (
                <option key={categoria.ruta} value={categoria.ruta}>
                  {categoria.nombre}
                </option>
              ))}
            </Form.Select>
          </FloatingLabel>

          <FloatingLabel controlId='valorVenta' label='Valor de venta' className='mb-3'>
            <Form.Control
              type='text'
              placeholder='Valor de venta'
              value={valorVenta}
              onChange={handleValorVentaChange}
              onBlur={() => {
                const valorSinFormato = getValorVentaSinFormato();
                console.log('Valor entero:', valorSinFormato); // Aquí puedes guardar el valor sin formato si es necesario
              }}
            />
          </FloatingLabel>
        </Modal.Body>

        <Modal.Footer>
          <Button variant='outline-secondary' onClick={cancelar} size='lg'>
            <FontAwesomeIcon icon={faTimes} /> Cancelar
          </Button>
          <Button variant='outline-primary' onClick={guardar} size='lg'>
            <FontAwesomeIcon icon={faCheck} /> Guardar
          </Button>
        </Modal.Footer>
      </Modal>


      <Modal show={modalDuplicar} onHide={cancelarDuplicar} backdrop='static' keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faClone} /> Duplicar Combo
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {comboDuplicar && (
            <>
              <FloatingLabel controlId='txtNombre' label='Nombre' className='mb-3'>
                <Form.Control
                  type='text'
                  placeholder='Nombre'
                  value={comboDuplicar.nombre}
                  onChange={(e) => setComboDuplicar({ ...comboDuplicar, nombre: e.target.value })}
                />
              </FloatingLabel>

              <FloatingLabel controlId='txtDescripcion' label='Descripción' className='mb-3'>
                <Form.Control
                  type='text'
                  placeholder='Descripción'
                  value={comboDuplicar.descripcion}
                  onChange={(e) => setComboDuplicar({ ...comboDuplicar, descripcion: e.target.value })}
                />
              </FloatingLabel>

              {/* <FloatingLabel controlId='selectPromocion' label='Promoción' className='mb-3'>
                <Form.Select
                  aria-label='Promoción'
                  value={comboDuplicar.promos[0] || ''}
                  onChange={(e) => {
                    const newPromos = [e.target.value];
                    setComboDuplicar({ ...comboDuplicar, promos: newPromos });
                  }}
                >
                  <option value='' disabled>Seleccionar promoción</option>
                  <option>Sin promoción asignada</option>
                  {promociones.map(promocion => (
                    <option key={promocion.codigo} value={promocion.codigo}>
                      {promocion.nombre}
                    </option>
                  ))}
                </Form.Select>
              </FloatingLabel> */}

              <FloatingLabel controlId='selectCategoria' label='Categoría' className='mb-3'>
                <Form.Select
                  aria-label='Categoría'
                  value={comboDuplicar.categorias.length > 0 ? comboDuplicar.categorias[0]?.ruta : ''}
                  onChange={(e) => {
                    setComboDuplicar({
                      ...comboDuplicar,
                      categorias: [{ nombre: e.target.options[e.target.selectedIndex].text, ruta: e.target.value }]
                    });
                  }}
                >
                  <option value=''>Seleccionar categoría</option>
                  {categorias.map(categoria => (
                    <option key={categoria.ruta} value={categoria.ruta}>
                      {categoria.nombre}
                    </option>
                  ))}
                </Form.Select>
              </FloatingLabel>

              <FloatingLabel controlId='valorVenta' label='Valor de venta' className='mb-3'>
                <Form.Control
                  type='number'
                  placeholder='Valor de venta'
                  value={comboDuplicar.precio}
                  onChange={(e) => setComboDuplicar({ ...comboDuplicar, precio: e.target.value })}
                />
              </FloatingLabel>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='outline-secondary' onClick={cancelarDuplicar} size='lg'>
            <FontAwesomeIcon icon={faTimes} /> Cancelar
          </Button>
          <Button variant='outline-primary' onClick={confirmarDuplicar} size='lg'>
            <FontAwesomeIcon icon={faCheck} /> Confirmar Duplicación
          </Button>
        </Modal.Footer>
      </Modal>


    </>
  );
}
