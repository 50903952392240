import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faRefresh,
  faTimes,
  faToolbox,
  faIcons,
  faScrewdriverWrench
} from '@fortawesome/free-solid-svg-icons';
import { Card, Modal, Button, Form, Row, Col, InputGroup } from 'react-bootstrap';
import {
  configDefecto,
  listaAnimacionComportamiento,
  listaAnimacionEntrada,
  listaAnimacionSalida,
  listaDuracion,
  listaRepeticion,
  listaRetraso
} from './constantes';

export default function ConfiguracionModal({ show, onHide: setShow, onSave,configuracion }) {

  function guardarConfig() {
   
    const configuracion = {
      elemento: {
        titulo: {
          tamano: parseInt(document.getElementById('tituloTamanoElemento').value),
          color: document.getElementById('tituloColorElemento').value
        },
        fondo: {
          color: document.getElementById('fondoColorElemento').value
        },
        borde: {
          tamano: parseInt(document.getElementById('bordeTamanoElemento').value),
          redondeo: parseInt(document.getElementById('bordeRedondeoElemento').value),
          color: document.getElementById('bordeColorElemento').value
        },
        efecto: {
          comportamiento: {
            animacion: document.getElementById('efectoAnimacionComportamientoElemento').value,
            repeticion: document.getElementById('efectoRepeticionComportamientoElemento').value,
            duracion: document.getElementById('efectoDuracionComportamientoElemento').value,
            retraso: document.getElementById('efectoRetrasoComportamientoElemento').value
          },
          entrada: {
            animacion: document.getElementById('efectoAnimacionEntradaElemento').value,
            repeticion: document.getElementById('efectoRepeticionEntradaElemento').value,
            duracion: document.getElementById('efectoDuracionEntradaElemento').value,
            retraso: document.getElementById('efectoDuracionEntradaElemento').value
          },
          salida: {
            animacion: document.getElementById('efectoAnimacionSalidaElemento').value,
            repeticion: document.getElementById('efectoRepeticionSalidaElemento').value,
            duracion: document.getElementById('efectoDuracionSalidaElemento').value,
            retraso: document.getElementById('efectoRetrasoSalidaElemento').value
          }
        }
      },
      contenido: {
        fondo: {
          color: document.getElementById('fondoColorContenido').value
        },
        borde: {
          tamano: parseInt(document.getElementById('bordeTamanoContenido').value),
          redondeo: parseInt(document.getElementById('bordeRedondeoContenido').value),
          color: document.getElementById('bordeColorContenido').value
        },
        texto: {
          tamano: parseInt(document.getElementById('textoTamanoContenido').value),
          color: document.getElementById('textoColorContenido').value
        },
        icono: {
          tamano: parseInt(document.getElementById('iconoTamanoContenido').value),
          color: document.getElementById('iconoColorContenido').value
        },
        sello: {
          tamano: parseInt(document.getElementById('selloTamanoContenido').value)
        },
        efecto: {
          comportamiento: {
            animacion: document.getElementById('efectoAnimacionComportamientoContenido').value,
            repeticion: document.getElementById('efectoRepeticionComportamientoContenido').value,
            duracion: document.getElementById('efectoDuracionComportamientoContenido').value,
            retraso: document.getElementById('efectoRetrasoComportamientoContenido').value
          },
          entrada: {
            animacion: document.getElementById('efectoAnimacionEntradaContenido').value,
            repeticion: document.getElementById('efectoRepeticionEntradaContenido').value,
            duracion: document.getElementById('efectoDuracionEntradaContenido').value,
            retraso: document.getElementById('efectoRetrasoEntradaContenido').value
          },
          salida: {
            animacion: document.getElementById('efectoAnimacionSalidaContenido').value,
            repeticion: document.getElementById('efectoRepeticionSalidaContenido').value,
            duracion: document.getElementById('efectoDuracionSalidaContenido').value,
            retraso: document.getElementById('efectoRetrasoSalidaContenido').value
          }
        }
      }
    };
    onSave(configuracion)
    setShow(false);
  }

  return (
    <Modal show={show} onHide={setShow} backdrop='static' keyboard={false} size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>
          <FontAwesomeIcon icon={faScrewdriverWrench} />
          Configuración
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={12}>
            <Card border='dark' className='mt-2 mb-2'>
              <Card.Body>
                <Card.Title>
                  <FontAwesomeIcon icon={faToolbox} />
                  Elemento
                </Card.Title>
                <Row>
                  <Col md={12}>
                    <Card className='mt-2 mb-2'>
                      <Card.Body>
                        <Card.Title>
                          <center>Titulo</center>
                        </Card.Title>
                        <InputGroup size='sm' className='mb-3'>
                          <InputGroup.Text>Tamaño</InputGroup.Text>
                          <Form.Select id='tituloTamanoElemento' defaultValue={configuracion.elemento.titulo.tamano}>
                            <option value='1'>1</option>
                            <option value='2'>2</option>
                            <option value='3'>3</option>
                            <option value='4'>4</option>
                            <option value='5'>5</option>
                            <option value='6'>6</option>
                            <option value='7'>7</option>
                            <option value='8'>8</option>
                            <option value='9'>9</option>
                            <option value='10'>10</option>
                            <option value='11'>11</option>
                            <option value='12'>12</option>
                            <option value='13'>13</option>
                            <option value='14'>14</option>
                            <option value='15'>15</option>
                            <option value='16'>16</option>
                            <option value='17'>17</option>
                            <option value='18'>18</option>
                            <option value='19'>19</option>
                            <option value='20'>20</option>
                            <option value='21'>21</option>
                            <option value='22'>22</option>
                            <option value='23'>23</option>
                            <option value='24'>24</option>
                            <option value='25'>25</option>
                            <option value='26'>26</option>
                            <option value='27'>27</option>
                            <option value='28'>28</option>
                            <option value='29'>29</option>
                            <option value='30'>30</option>
                          </Form.Select>
                          <Button
                            variant='outline-secondary'
                            onClick={() => {
                              document.getElementById('tituloTamanoElemento').value =
                                configDefecto.elemento.titulo.tamano;
                            }}
                          >
                            <FontAwesomeIcon icon={faRefresh} />
                            Reestablecer
                          </Button>
                        </InputGroup>
                        <InputGroup size='sm'>
                          <InputGroup.Text>Color</InputGroup.Text>
                          <Form.Control
                            type='color'
                            id='tituloColorElemento'
                            defaultValue={
                              configuracion.elemento.titulo.color === ''
                                ? '#EC650A'
                                : configuracion.elemento.titulo.color
                            }
                            title='Selector de color'
                          />
                          <Button
                            variant='outline-secondary'
                            onClick={() => {
                              document.getElementById('tituloColorElemento').value =
                                configDefecto.elemento.titulo.color;
                            }}
                          >
                            <FontAwesomeIcon icon={faRefresh} />
                            Reestablecer
                          </Button>
                        </InputGroup>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md={12}>
                    <Card className='mt-2 mb-2'>
                      <Card.Body>
                        <Card.Title>
                          <center>Fondo</center>
                        </Card.Title>
                        <InputGroup size='sm'>
                          <InputGroup.Text>Color</InputGroup.Text>
                          <Form.Control
                            type='color'
                            id='fondoColorElemento'
                            defaultValue={
                              configuracion.elemento.fondo.color === '' ? '#EC650A' : configuracion.elemento.fondo.color
                            }
                            title='Selector de color'
                          />
                          <Button
                            variant='outline-secondary'
                            onClick={() => {
                              document.getElementById('fondoColorElemento').value = configDefecto.elemento.fondo.color;
                            }}
                          >
                            <FontAwesomeIcon icon={faRefresh} />
                            Reestablecer
                          </Button>
                        </InputGroup>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md={12}>
                    <Card className='mt-2 mb-2'>
                      <Card.Body>
                        <Card.Title>
                          <center>Borde</center>
                        </Card.Title>
                        <InputGroup size='sm' className='mb-3'>
                          <InputGroup.Text>Grosor</InputGroup.Text>
                          <Form.Select id='bordeTamanoElemento' defaultValue={configuracion.elemento.borde.tamano}>
                            <option value='0'>0</option>
                            <option value='1'>1</option>
                            <option value='2'>2</option>
                            <option value='3'>3</option>
                            <option value='4'>4</option>
                            <option value='5'>5</option>
                            <option value='6'>6</option>
                            <option value='7'>7</option>
                            <option value='8'>8</option>
                            <option value='9'>9</option>
                            <option value='10'>10</option>
                            <option value='11'>11</option>
                            <option value='12'>12</option>
                            <option value='13'>13</option>
                            <option value='14'>14</option>
                            <option value='15'>15</option>
                          </Form.Select>
                          <Button
                            variant='outline-secondary'
                            onClick={() => {
                              document.getElementById('bordeTamanoElemento').value =
                                configDefecto.elemento.borde.tamano;
                            }}
                          >
                            <FontAwesomeIcon icon={faRefresh} />
                            Reestablecer
                          </Button>
                        </InputGroup>
                        <InputGroup size='sm' className='mb-3'>
                          <InputGroup.Text>Redondeo</InputGroup.Text>
                          <Form.Select id='bordeRedondeoElemento' defaultValue={configuracion.elemento.borde.redondeo}>
                            <option value='0'>0</option>
                            <option value='1'>1</option>
                            <option value='2'>2</option>
                            <option value='3'>3</option>
                            <option value='4'>4</option>
                            <option value='5'>5</option>
                          </Form.Select>
                          <Button
                            variant='outline-secondary'
                            onClick={() => {
                              document.getElementById('bordeRedondeoElemento').value =
                                configDefecto.elemento.borde.redondeo;
                            }}
                          >
                            <FontAwesomeIcon icon={faRefresh} />
                            Reestablecer
                          </Button>
                        </InputGroup>
                        <InputGroup size='sm' className='mb-3'>
                          <InputGroup.Text>Color</InputGroup.Text>
                          <Form.Control
                            type='color'
                            id='bordeColorElemento'
                            defaultValue={
                              configuracion.elemento.borde.color === '' ? '#EC650A' : configuracion.elemento.borde.color
                            }
                            title='Selector de color'
                          />
                          <Button
                            variant='outline-secondary'
                            onClick={() => {
                              document.getElementById('bordeColorElemento').value = configDefecto.elemento.fondo.color;
                            }}
                          >
                            <FontAwesomeIcon icon={faRefresh} />
                            Reestablecer
                          </Button>
                        </InputGroup>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md={12}>
                    <Card className='mt-2 mb-2'>
                      <Card.Body>
                        <Card.Title>
                          <center>Efectos</center>
                        </Card.Title>
                        <Row>
                          <Col md={12}>
                            <Card className='mt-2 mb-2'>
                              <Card.Body>
                                <Card.Title>
                                  <center>Comportamiento</center>
                                </Card.Title>
                                <Row>
                                  <Col md={12}>
                                    <InputGroup size='sm' className='mb-3'>
                                      <InputGroup.Text>Animación</InputGroup.Text>
                                      <Form.Select
                                        id='efectoAnimacionComportamientoElemento'
                                        defaultValue={configuracion.elemento.efecto.comportamiento.animacion}
                                      >
                                        <option value=''>Sin definir</option>
                                        {listaAnimacionComportamiento.map((animacion, llave) => (
                                          <option value={animacion.clase} key={llave}>
                                            {animacion.nombre}
                                          </option>
                                        ))}
                                      </Form.Select>
                                      <Button
                                        variant='outline-secondary'
                                        onClick={() => {
                                          document.getElementById('efectoAnimacionComportamientoElemento').value =
                                            configDefecto.elemento.efecto.comportamiento.animacion;
                                        }}
                                      >
                                        <FontAwesomeIcon icon={faRefresh} />
                                        Reestablecer
                                      </Button>
                                    </InputGroup>
                                  </Col>
                                  <Col md={12}>
                                    <InputGroup size='sm' className='mb-3'>
                                      <InputGroup.Text>Repetición</InputGroup.Text>
                                      <Form.Select
                                        id='efectoRepeticionComportamientoElemento'
                                        defaultValue={configuracion.elemento.efecto.comportamiento.repeticion}
                                      >
                                        <option value=''>Sin definir</option>
                                        {listaRepeticion.map((repeticion, llave) => (
                                          <option value={repeticion.clase} key={llave}>
                                            {repeticion.nombre}
                                          </option>
                                        ))}
                                      </Form.Select>
                                      <Button
                                        variant='outline-secondary'
                                        onClick={() => {
                                          document.getElementById('efectoRepeticionComportamientoElemento').value =
                                            configDefecto.elemento.efecto.comportamiento.repeticion;
                                        }}
                                      >
                                        <FontAwesomeIcon icon={faRefresh} />
                                        Reestablecer
                                      </Button>
                                    </InputGroup>
                                  </Col>
                                  <Col md={12}>
                                    <InputGroup size='sm' className='mb-3'>
                                      <InputGroup.Text>Duración</InputGroup.Text>
                                      <Form.Select
                                        id='efectoDuracionComportamientoElemento'
                                        defaultValue={configuracion.elemento.efecto.comportamiento.duracion}
                                      >
                                        <option value=''>Sin definir</option>
                                        {listaDuracion.map((duracion, llave) => (
                                          <option value={duracion.clase} key={llave}>
                                            {duracion.nombre}
                                          </option>
                                        ))}
                                      </Form.Select>
                                      <Button
                                        variant='outline-secondary'
                                        onClick={() => {
                                          document.getElementById('efectoDuracionComportamientoElemento').value =
                                            configDefecto.elemento.efecto.comportamiento.duracion;
                                        }}
                                      >
                                        <FontAwesomeIcon icon={faRefresh} />
                                        Reestablecer
                                      </Button>
                                    </InputGroup>
                                  </Col>
                                  <Col md={12}>
                                    <InputGroup size='sm' className='mb-3'>
                                      <InputGroup.Text>Retraso</InputGroup.Text>
                                      <Form.Select
                                        id='efectoRetrasoComportamientoElemento'
                                        defaultValue={configuracion.elemento.efecto.comportamiento.retraso}
                                      >
                                        <option value=''>Sin definir</option>
                                        {listaRetraso.map((retraso, llave) => (
                                          <option value={retraso.clase} key={llave}>
                                            {retraso.nombre}
                                          </option>
                                        ))}
                                      </Form.Select>
                                      <Button
                                        variant='outline-secondary'
                                        onClick={() => {
                                          document.getElementById('efectoRetrasoComportamientoElemento').value =
                                            configDefecto.elemento.efecto.comportamiento.retraso;
                                        }}
                                      >
                                        <FontAwesomeIcon icon={faRefresh} />
                                        Reestablecer
                                      </Button>
                                    </InputGroup>
                                  </Col>
                                </Row>
                              </Card.Body>
                            </Card>
                          </Col>
                          <Col md={12}>
                            <Card className='mt-2 mb-2'>
                              <Card.Body>
                                <Card.Title>
                                  <center>Entrada</center>
                                </Card.Title>
                                <Row>
                                  <Col md={12}>
                                    <InputGroup size='sm' className='mb-3'>
                                      <InputGroup.Text>Animación</InputGroup.Text>
                                      <Form.Select
                                        id='efectoAnimacionEntradaElemento'
                                        defaultValue={configuracion.elemento.efecto.entrada.animacion}
                                      >
                                        <option value=''>Sin definir</option>
                                        {listaAnimacionEntrada.map((animacion, llave) => (
                                          <option value={animacion.clase} key={llave}>
                                            {animacion.nombre}
                                          </option>
                                        ))}
                                      </Form.Select>
                                      <Button
                                        variant='outline-secondary'
                                        onClick={() => {
                                          document.getElementById('efectoAnimacionEntradaElemento').value =
                                            configDefecto.elemento.efecto.entrada.animacion;
                                        }}
                                      >
                                        <FontAwesomeIcon icon={faRefresh} />
                                        Reestablecer
                                      </Button>
                                    </InputGroup>
                                  </Col>
                                  <Col md={12}>
                                    <InputGroup size='sm' className='mb-3'>
                                      <InputGroup.Text>Repetición</InputGroup.Text>
                                      <Form.Select
                                        id='efectoRepeticionEntradaElemento'
                                        defaultValue={configuracion.elemento.efecto.entrada.repeticion}
                                      >
                                        <option value=''>Sin definir</option>
                                        {listaRepeticion.map((repeticion, llave) => (
                                          <option value={repeticion.clase} key={llave}>
                                            {repeticion.nombre}
                                          </option>
                                        ))}
                                      </Form.Select>
                                      <Button
                                        variant='outline-secondary'
                                        onClick={() => {
                                          document.getElementById('efectoRepeticionEntradaElemento').value =
                                            configDefecto.elemento.efecto.entrada.repeticion;
                                        }}
                                      >
                                        <FontAwesomeIcon icon={faRefresh} />
                                        Reestablecer
                                      </Button>
                                    </InputGroup>
                                  </Col>
                                  <Col md={12}>
                                    <InputGroup size='sm' className='mb-3'>
                                      <InputGroup.Text>Duración</InputGroup.Text>
                                      <Form.Select
                                        id='efectoDuracionEntradaElemento'
                                        defaultValue={configuracion.elemento.efecto.entrada.duracion}
                                      >
                                        <option value=''>Sin definir</option>
                                        {listaDuracion.map((duracion, llave) => (
                                          <option value={duracion.clase} key={llave}>
                                            {duracion.nombre}
                                          </option>
                                        ))}
                                      </Form.Select>
                                      <Button
                                        variant='outline-secondary'
                                        onClick={() => {
                                          document.getElementById('efectoDuracionEntradaElemento').value =
                                            configDefecto.elemento.efecto.entrada.duracion;
                                        }}
                                      >
                                        <FontAwesomeIcon icon={faRefresh} />
                                        Reestablecer
                                      </Button>
                                    </InputGroup>
                                  </Col>
                                  <Col md={12}>
                                    <InputGroup size='sm' className='mb-3'>
                                      <InputGroup.Text>Retraso</InputGroup.Text>
                                      <Form.Select
                                        id='efectoRetrasoEntradaElemento'
                                        defaultValue={configuracion.elemento.efecto.entrada.retraso}
                                      >
                                        <option value=''>Sin definir</option>
                                        {listaRetraso.map((retraso, llave) => (
                                          <option value={retraso.clase} key={llave}>
                                            {retraso.nombre}
                                          </option>
                                        ))}
                                      </Form.Select>
                                      <Button
                                        variant='outline-secondary'
                                        onClick={() => {
                                          document.getElementById('efectoRetrasoEntradaElemento').value =
                                            configDefecto.elemento.efecto.entrada.retraso;
                                        }}
                                      >
                                        <FontAwesomeIcon icon={faRefresh} />
                                        Reestablecer
                                      </Button>
                                    </InputGroup>
                                  </Col>
                                </Row>
                              </Card.Body>
                            </Card>
                          </Col>
                          <Col md={12}>
                            <Card className='mt-2 mb-2'>
                              <Card.Body>
                                <Card.Title>
                                  <center>Salida</center>
                                </Card.Title>
                                <Row>
                                  <Col md={12}>
                                    <InputGroup size='sm' className='mb-3'>
                                      <InputGroup.Text>Animación</InputGroup.Text>
                                      <Form.Select
                                        id='efectoAnimacionSalidaElemento'
                                        defaultValue={configuracion.elemento.efecto.salida.animacion}
                                      >
                                        <option value=''>Sin definir</option>
                                        {listaAnimacionSalida.map((animacion, llave) => (
                                          <option value={animacion.clase} key={llave}>
                                            {animacion.nombre}
                                          </option>
                                        ))}
                                      </Form.Select>
                                      <Button
                                        variant='outline-secondary'
                                        onClick={() => {
                                          document.getElementById('efectoAnimacionSalidaElemento').value =
                                            configDefecto.elemento.efecto.salida.animacion;
                                        }}
                                      >
                                        <FontAwesomeIcon icon={faRefresh} />
                                        Reestablecer
                                      </Button>
                                    </InputGroup>
                                  </Col>
                                  <Col md={12}>
                                    <InputGroup size='sm' className='mb-3'>
                                      <InputGroup.Text>Repetición</InputGroup.Text>
                                      <Form.Select
                                        id='efectoRepeticionSalidaElemento'
                                        defaultValue={configuracion.elemento.efecto.salida.repeticion}
                                      >
                                        <option value=''>Sin definir</option>
                                        {listaRepeticion.map((repeticion, llave) => (
                                          <option value={repeticion.clase} key={llave}>
                                            {repeticion.nombre}
                                          </option>
                                        ))}
                                      </Form.Select>
                                      <Button
                                        variant='outline-secondary'
                                        onClick={() => {
                                          document.getElementById('efectoRepeticionSalidaElemento').value =
                                            configDefecto.elemento.efecto.salida.repeticion;
                                        }}
                                      >
                                        <FontAwesomeIcon icon={faRefresh} />
                                        Reestablecer
                                      </Button>
                                    </InputGroup>
                                  </Col>
                                  <Col md={12}>
                                    <InputGroup size='sm' className='mb-3'>
                                      <InputGroup.Text>Duración</InputGroup.Text>
                                      <Form.Select
                                        id='efectoDuracionSalidaElemento'
                                        defaultValue={configuracion.elemento.efecto.salida.duracion}
                                      >
                                        <option value=''>Sin definir</option>
                                        {listaDuracion.map((duracion, llave) => (
                                          <option value={duracion.clase} key={llave}>
                                            {duracion.nombre}
                                          </option>
                                        ))}
                                      </Form.Select>
                                      <Button
                                        variant='outline-secondary'
                                        onClick={() => {
                                          document.getElementById('efectoDuracionSalidaElemento').value =
                                            configDefecto.elemento.efecto.salida.duracion;
                                        }}
                                      >
                                        <FontAwesomeIcon icon={faRefresh} />
                                        Reestablecer
                                      </Button>
                                    </InputGroup>
                                  </Col>
                                  <Col md={12}>
                                    <InputGroup size='sm' className='mb-3'>
                                      <InputGroup.Text>Retraso</InputGroup.Text>
                                      <Form.Select
                                        id='efectoRetrasoSalidaElemento'
                                        defaultValue={configuracion.elemento.efecto.salida.retraso}
                                      >
                                        <option value=''>Sin definir</option>
                                        {listaRetraso.map((retraso, llave) => (
                                          <option value={retraso.clase} key={llave}>
                                            {retraso.nombre}
                                          </option>
                                        ))}
                                      </Form.Select>
                                      <Button
                                        variant='outline-secondary'
                                        onClick={() => {
                                          document.getElementById('efectoRetrasoSalidaElemento').value =
                                            configDefecto.elemento.efecto.salida.retraso;
                                        }}
                                      >
                                        <FontAwesomeIcon icon={faRefresh} />
                                        Reestablecer
                                      </Button>
                                    </InputGroup>
                                  </Col>
                                </Row>
                              </Card.Body>
                            </Card>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>

          <Col md={12}>
            <Card border='dark' className='mt-2 mb-2'>
              <Card.Body>
                <Card.Title>
                  <FontAwesomeIcon icon={faIcons} />
                  Contenido
                </Card.Title>
                <Row>
                  <Col md={12}>
                    <Card className='mt-2 mb-2'>
                      <Card.Body>
                        <Card.Title>
                          <center>Fondo</center>
                        </Card.Title>
                        <InputGroup size='sm'>
                          <InputGroup.Text>Color</InputGroup.Text>
                          <Form.Control
                            type='color'
                            id='fondoColorContenido'
                            defaultValue={
                              configuracion.contenido.fondo.color === ''
                                ? '#EC650A'
                                : configuracion.contenido.fondo.color
                            }
                            title='Selector de color'
                          />
                          <Button
                            variant='outline-secondary'
                            onClick={() => {
                              document.getElementById('fondoColorContenido').value =
                                configDefecto.contenido.fondo.color;
                            }}
                          >
                            <FontAwesomeIcon icon={faRefresh} />
                            Reestablecer
                          </Button>
                        </InputGroup>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md={12}>
                    <Card className='mt-2 mb-2'>
                      <Card.Body>
                        <Card.Title>
                          <center>Borde</center>
                        </Card.Title>
                        <InputGroup size='sm' className='mb-3'>
                          <InputGroup.Text>Grosor</InputGroup.Text>
                          <Form.Select id='bordeTamanoContenido' defaultValue={configuracion.contenido.borde.tamano}>
                            <option value='0'>0</option>
                            <option value='1'>1</option>
                            <option value='2'>2</option>
                            <option value='3'>3</option>
                            <option value='4'>4</option>
                            <option value='5'>5</option>
                            <option value='6'>6</option>
                            <option value='7'>7</option>
                            <option value='8'>8</option>
                            <option value='9'>9</option>
                            <option value='10'>10</option>
                            <option value='11'>11</option>
                            <option value='12'>12</option>
                            <option value='13'>13</option>
                            <option value='14'>14</option>
                            <option value='15'>15</option>
                          </Form.Select>
                          <Button
                            variant='outline-secondary'
                            onClick={() => {
                              document.getElementById('bordeTamanoContenido').value =
                                configDefecto.contenido.borde.tamano;
                            }}
                          >
                            <FontAwesomeIcon icon={faRefresh} />
                            Reestablecer
                          </Button>
                        </InputGroup>
                        <InputGroup size='sm' className='mb-3'>
                          <InputGroup.Text>Redondeo</InputGroup.Text>
                          <Form.Select
                            id='bordeRedondeoContenido'
                            defaultValue={configuracion.contenido.borde.redondeo}
                          >
                            <option value='0'>0</option>
                            <option value='1'>1</option>
                            <option value='2'>2</option>
                            <option value='3'>3</option>
                            <option value='4'>4</option>
                            <option value='5'>5</option>
                          </Form.Select>
                          <Button
                            variant='outline-secondary'
                            onClick={() => {
                              document.getElementById('bordeRedondeoContenido').value =
                                configDefecto.contenido.borde.redondeo;
                            }}
                          >
                            <FontAwesomeIcon icon={faRefresh} />
                            Reestablecer
                          </Button>
                        </InputGroup>
                        <InputGroup size='sm' className='mb-3'>
                          <InputGroup.Text>Color</InputGroup.Text>
                          <Form.Control
                            type='color'
                            id='bordeColorContenido'
                            defaultValue={
                              configuracion.contenido.borde.color === ''
                                ? '#EC650A'
                                : configuracion.contenido.borde.color
                            }
                            title='Selector de color'
                          />
                          <Button
                            variant='outline-secondary'
                            onClick={() => {
                              document.getElementById('bordeColorContenido').value =
                                configDefecto.contenido.borde.color;
                            }}
                          >
                            <FontAwesomeIcon icon={faRefresh} />
                            Reestablecer
                          </Button>
                        </InputGroup>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md={12}>
                    <Card className='mt-2 mb-2'>
                      <Card.Body>
                        <Card.Title>
                          <center>Texto</center>
                        </Card.Title>
                        <InputGroup size='sm' className='mb-3'>
                          <InputGroup.Text>Tamaño</InputGroup.Text>
                          <Form.Select id='textoTamanoContenido' defaultValue={configuracion.contenido.texto.tamano}>
                            <option value='1'>1</option>
                            <option value='2'>2</option>
                            <option value='3'>3</option>
                            <option value='4'>4</option>
                            <option value='5'>5</option>
                            <option value='6'>6</option>
                            <option value='7'>7</option>
                            <option value='8'>8</option>
                            <option value='9'>9</option>
                            <option value='10'>10</option>
                            <option value='11'>11</option>
                            <option value='12'>12</option>
                            <option value='13'>13</option>
                            <option value='14'>14</option>
                            <option value='15'>15</option>
                            <option value='16'>16</option>
                            <option value='17'>17</option>
                            <option value='18'>18</option>
                            <option value='19'>19</option>
                            <option value='20'>20</option>
                            <option value='21'>21</option>
                            <option value='22'>22</option>
                            <option value='23'>23</option>
                            <option value='24'>24</option>
                            <option value='25'>25</option>
                            <option value='26'>26</option>
                            <option value='27'>27</option>
                            <option value='28'>28</option>
                            <option value='29'>29</option>
                            <option value='30'>30</option>
                          </Form.Select>
                          <Button
                            variant='outline-secondary'
                            onClick={() => {
                              document.getElementById('textoTamanoContenido').value =
                                configDefecto.contenido.texto.tamano;
                            }}
                          >
                            <FontAwesomeIcon icon={faRefresh} />
                            Reestablecer
                          </Button>
                        </InputGroup>
                        <InputGroup size='sm'>
                          <InputGroup.Text>Color</InputGroup.Text>
                          <Form.Control
                            type='color'
                            id='textoColorContenido'
                            defaultValue={
                              configuracion.contenido.texto.color === ''
                                ? '#EC650A'
                                : configuracion.contenido.texto.color
                            }
                            title='Selector de color'
                          />
                          <Button
                            variant='outline-secondary'
                            onClick={() => {
                              document.getElementById('textoColorContenido').value =
                                configDefecto.contenido.texto.color;
                            }}
                          >
                            <FontAwesomeIcon icon={faRefresh} />
                            Reestablecer
                          </Button>
                        </InputGroup>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md={12}>
                    <Card className='mt-2 mb-2'>
                      <Card.Body>
                        <Card.Title>
                          <center>Icono</center>
                        </Card.Title>
                        <InputGroup size='sm' className='mb-3'>
                          <InputGroup.Text>Tamaño</InputGroup.Text>
                          <Form.Select id='iconoTamanoContenido' defaultValue={configuracion.contenido.icono.tamano}>
                            <option value='1'>1</option>
                            <option value='2'>2</option>
                            <option value='3'>3</option>
                            <option value='4'>4</option>
                            <option value='5'>5</option>
                            <option value='6'>6</option>
                            <option value='7'>7</option>
                            <option value='8'>8</option>
                            <option value='9'>9</option>
                            <option value='10'>10</option>
                            <option value='11'>11</option>
                            <option value='12'>12</option>
                            <option value='13'>13</option>
                            <option value='14'>14</option>
                            <option value='15'>15</option>
                            <option value='16'>16</option>
                            <option value='17'>17</option>
                            <option value='18'>18</option>
                            <option value='19'>19</option>
                            <option value='20'>20</option>
                            <option value='21'>21</option>
                            <option value='22'>22</option>
                            <option value='23'>23</option>
                            <option value='24'>24</option>
                            <option value='25'>25</option>
                            <option value='26'>26</option>
                            <option value='27'>27</option>
                            <option value='28'>28</option>
                            <option value='29'>29</option>
                            <option value='30'>30</option>
                          </Form.Select>
                          <Button
                            variant='outline-secondary'
                            onClick={() => {
                              document.getElementById('iconoTamanoContenido').value =
                                configDefecto.contenido.icono.tamano;
                            }}
                          >
                            <FontAwesomeIcon icon={faRefresh} />
                            Reestablecer
                          </Button>
                        </InputGroup>
                        <InputGroup size='sm'>
                          <InputGroup.Text>Color</InputGroup.Text>
                          <Form.Control
                            type='color'
                            id='iconoColorContenido'
                            defaultValue={
                              configuracion.contenido.icono.color === ''
                                ? '#EC650A'
                                : configuracion.contenido.icono.color
                            }
                            title='Selector de color'
                          />
                          <Button
                            variant='outline-secondary'
                            onClick={() => {
                              document.getElementById('iconoColorContenido').value =
                                configDefecto.contenido.icono.color;
                            }}
                          >
                            <FontAwesomeIcon icon={faRefresh} />
                            Reestablecer
                          </Button>
                        </InputGroup>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md={12}>
                    <Card className='mt-2 mb-2'>
                      <Card.Body>
                        <Card.Title>
                          <center>Sello</center>
                        </Card.Title>
                        <InputGroup size='sm' className='mb-3'>
                          <InputGroup.Text>Tamaño</InputGroup.Text>
                          <Form.Select id='selloTamanoContenido' defaultValue={configuracion.contenido.sello.tamano}>
                            <option value='1'>1</option>
                            <option value='2'>2</option>
                            <option value='3'>3</option>
                            <option value='4'>4</option>
                            <option value='5'>5</option>
                            <option value='6'>6</option>
                            <option value='7'>7</option>
                            <option value='8'>8</option>
                            <option value='9'>9</option>
                            <option value='10'>10</option>
                            <option value='11'>11</option>
                            <option value='12'>12</option>
                            <option value='13'>13</option>
                            <option value='14'>14</option>
                            <option value='15'>15</option>
                            <option value='16'>16</option>
                            <option value='17'>17</option>
                            <option value='18'>18</option>
                            <option value='19'>19</option>
                            <option value='20'>20</option>
                            <option value='21'>21</option>
                            <option value='22'>22</option>
                            <option value='23'>23</option>
                            <option value='24'>24</option>
                            <option value='25'>25</option>
                            <option value='26'>26</option>
                            <option value='27'>27</option>
                            <option value='28'>28</option>
                            <option value='29'>29</option>
                            <option value='30'>30</option>
                          </Form.Select>
                          <Button
                            variant='outline-secondary'
                            onClick={() => {
                              document.getElementById('selloTamanoContenido').value =
                                configDefecto.contenido.sello.tamano;
                            }}
                          >
                            <FontAwesomeIcon icon={faRefresh} />
                            Reestablecer
                          </Button>
                        </InputGroup>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md={12}>
                    <Card className='mt-2 mb-2'>
                      <Card.Body>
                        <Card.Title>
                          <center>Efectos</center>
                        </Card.Title>
                        <Row>
                          <Col md={12}>
                            <Card className='mt-2 mb-2'>
                              <Card.Body>
                                <Card.Title>
                                  <center>Comportamiento</center>
                                </Card.Title>
                                <Row>
                                  <Col md={12}>
                                    <InputGroup size='sm' className='mb-3'>
                                      <InputGroup.Text>Animación</InputGroup.Text>
                                      <Form.Select
                                        id='efectoAnimacionComportamientoContenido'
                                        defaultValue={configuracion.contenido.efecto.comportamiento.animacion}
                                      >
                                        <option value=''>Sin definir</option>
                                        {listaAnimacionComportamiento.map((animacion, llave) => (
                                          <option value={animacion.clase} key={llave}>
                                            {animacion.nombre}
                                          </option>
                                        ))}
                                      </Form.Select>
                                      <Button
                                        variant='outline-secondary'
                                        onClick={() => {
                                          document.getElementById('efectoAnimacionComportamientoContenido').value =
                                            configDefecto.contenido.efecto.comportamiento.animacion;
                                        }}
                                      >
                                        <FontAwesomeIcon icon={faRefresh} />
                                        Reestablecer
                                      </Button>
                                    </InputGroup>
                                  </Col>
                                  <Col md={12}>
                                    <InputGroup size='sm' className='mb-3'>
                                      <InputGroup.Text>Repetición</InputGroup.Text>
                                      <Form.Select
                                        id='efectoRepeticionComportamientoContenido'
                                        defaultValue={configuracion.contenido.efecto.comportamiento.repeticion}
                                      >
                                        <option value=''>Sin definir</option>
                                        {listaRepeticion.map((repeticion, llave) => (
                                          <option value={repeticion.clase} key={llave}>
                                            {repeticion.nombre}
                                          </option>
                                        ))}
                                      </Form.Select>
                                      <Button
                                        variant='outline-secondary'
                                        onClick={() => {
                                          document.getElementById('efectoRepeticionComportamientoContenido').value =
                                            configDefecto.contenido.efecto.comportamiento.repeticion;
                                        }}
                                      >
                                        <FontAwesomeIcon icon={faRefresh} />
                                        Reestablecer
                                      </Button>
                                    </InputGroup>
                                  </Col>
                                  <Col md={12}>
                                    <InputGroup size='sm' className='mb-3'>
                                      <InputGroup.Text>Duración</InputGroup.Text>
                                      <Form.Select
                                        id='efectoDuracionComportamientoContenido'
                                        defaultValue={configuracion.contenido.efecto.comportamiento.duracion}
                                      >
                                        <option value=''>Sin definir</option>
                                        {listaDuracion.map((duracion, llave) => (
                                          <option value={duracion.clase} key={llave}>
                                            {duracion.nombre}
                                          </option>
                                        ))}
                                      </Form.Select>
                                      <Button
                                        variant='outline-secondary'
                                        onClick={() => {
                                          document.getElementById('efectoDuracionComportamientoContenido').value =
                                            configDefecto.contenido.efecto.comportamiento.duracion;
                                        }}
                                      >
                                        <FontAwesomeIcon icon={faRefresh} />
                                        Reestablecer
                                      </Button>
                                    </InputGroup>
                                  </Col>
                                  <Col md={12}>
                                    <InputGroup size='sm' className='mb-3'>
                                      <InputGroup.Text>Retraso</InputGroup.Text>
                                      <Form.Select
                                        id='efectoRetrasoComportamientoContenido'
                                        defaultValue={configuracion.contenido.efecto.comportamiento.retraso}
                                      >
                                        <option value=''>Sin definir</option>
                                        {listaRetraso.map((retraso, llave) => (
                                          <option value={retraso.clase} key={llave}>
                                            {retraso.nombre}
                                          </option>
                                        ))}
                                      </Form.Select>
                                      <Button
                                        variant='outline-secondary'
                                        onClick={() => {
                                          document.getElementById('efectoRetrasoComportamientoContenido').value =
                                            configDefecto.contenido.efecto.comportamiento.retraso;
                                        }}
                                      >
                                        <FontAwesomeIcon icon={faRefresh} />
                                        Reestablecer
                                      </Button>
                                    </InputGroup>
                                  </Col>
                                </Row>
                              </Card.Body>
                            </Card>
                          </Col>
                          <Col md={12}>
                            <Card className='mt-2 mb-2'>
                              <Card.Body>
                                <Card.Title>
                                  <center>Entrada</center>
                                </Card.Title>
                                <Row>
                                  <Col md={12}>
                                    <InputGroup size='sm' className='mb-3'>
                                      <InputGroup.Text>Animación</InputGroup.Text>
                                      <Form.Select
                                        id='efectoAnimacionEntradaContenido'
                                        defaultValue={configuracion.contenido.efecto.entrada.animacion}
                                      >
                                        <option value=''>Sin definir</option>
                                        {listaAnimacionEntrada.map((animacion, llave) => (
                                          <option value={animacion.clase} key={llave}>
                                            {animacion.nombre}
                                          </option>
                                        ))}
                                      </Form.Select>
                                      <Button
                                        variant='outline-secondary'
                                        onClick={() => {
                                          document.getElementById('efectoAnimacionEntradaContenido').value =
                                            configDefecto.contenido.efecto.entrada.animacion;
                                        }}
                                      >
                                        <FontAwesomeIcon icon={faRefresh} />
                                        Reestablecer
                                      </Button>
                                    </InputGroup>
                                  </Col>
                                  <Col md={12}>
                                    <InputGroup size='sm' className='mb-3'>
                                      <InputGroup.Text>Repetición</InputGroup.Text>
                                      <Form.Select
                                        id='efectoRepeticionEntradaContenido'
                                        defaultValue={configuracion.contenido.efecto.entrada.repeticion}
                                      >
                                        <option value=''>Sin definir</option>
                                        {listaRepeticion.map((repeticion, llave) => (
                                          <option value={repeticion.clase} key={llave}>
                                            {repeticion.nombre}
                                          </option>
                                        ))}
                                      </Form.Select>
                                      <Button
                                        variant='outline-secondary'
                                        onClick={() => {
                                          document.getElementById('efectoRepeticionEntradaContenido').value =
                                            configDefecto.contenido.efecto.entrada.repeticion;
                                        }}
                                      >
                                        <FontAwesomeIcon icon={faRefresh} />
                                        Reestablecer
                                      </Button>
                                    </InputGroup>
                                  </Col>
                                  <Col md={12}>
                                    <InputGroup size='sm' className='mb-3'>
                                      <InputGroup.Text>Duración</InputGroup.Text>
                                      <Form.Select
                                        id='efectoDuracionEntradaContenido'
                                        defaultValue={configuracion.contenido.efecto.entrada.duracion}
                                      >
                                        <option value=''>Sin definir</option>
                                        {listaDuracion.map((duracion, llave) => (
                                          <option value={duracion.clase} key={llave}>
                                            {duracion.nombre}
                                          </option>
                                        ))}
                                      </Form.Select>
                                      <Button
                                        variant='outline-secondary'
                                        onClick={() => {
                                          document.getElementById('efectoDuracionEntradaContenido').value =
                                            configDefecto.contenido.efecto.entrada.duracion;
                                        }}
                                      >
                                        <FontAwesomeIcon icon={faRefresh} />
                                        Reestablecer
                                      </Button>
                                    </InputGroup>
                                  </Col>
                                  <Col md={12}>
                                    <InputGroup size='sm' className='mb-3'>
                                      <InputGroup.Text>Retraso</InputGroup.Text>
                                      <Form.Select
                                        id='efectoRetrasoEntradaContenido'
                                        defaultValue={configuracion.contenido.efecto.entrada.retraso}
                                      >
                                        <option value=''>Sin definir</option>
                                        {listaRetraso.map((retraso, llave) => (
                                          <option value={retraso.clase} key={llave}>
                                            {retraso.nombre}
                                          </option>
                                        ))}
                                      </Form.Select>
                                      <Button
                                        variant='outline-secondary'
                                        onClick={() => {
                                          document.getElementById('efectoRetrasoEntradaContenido').value =
                                            configDefecto.contenido.efecto.entrada.retraso;
                                        }}
                                      >
                                        <FontAwesomeIcon icon={faRefresh} />
                                        Reestablecer
                                      </Button>
                                    </InputGroup>
                                  </Col>
                                </Row>
                              </Card.Body>
                            </Card>
                          </Col>
                          <Col md={12}>
                            <Card className='mt-2 mb-2'>
                              <Card.Body>
                                <Card.Title>
                                  <center>Salida</center>
                                </Card.Title>
                                <Row>
                                  <Col md={12}>
                                    <InputGroup size='sm' className='mb-3'>
                                      <InputGroup.Text>Animación</InputGroup.Text>
                                      <Form.Select
                                        id='efectoAnimacionSalidaContenido'
                                        defaultValue={configuracion.contenido.efecto.salida.animacion}
                                      >
                                        <option value=''>Sin definir</option>
                                        {listaAnimacionSalida.map((animacion, llave) => (
                                          <option value={animacion.clase} key={llave}>
                                            {animacion.nombre}
                                          </option>
                                        ))}
                                      </Form.Select>
                                      <Button
                                        variant='outline-secondary'
                                        onClick={() => {
                                          document.getElementById('efectoAnimacionSalidaContenido').value =
                                            configDefecto.contenido.efecto.salida.animacion;
                                        }}
                                      >
                                        <FontAwesomeIcon icon={faRefresh} />
                                        Reestablecer
                                      </Button>
                                    </InputGroup>
                                  </Col>
                                  <Col md={12}>
                                    <InputGroup size='sm' className='mb-3'>
                                      <InputGroup.Text>Repetición</InputGroup.Text>
                                      <Form.Select
                                        id='efectoRepeticionSalidaContenido'
                                        defaultValue={configuracion.contenido.efecto.salida.repeticion}
                                      >
                                        <option value=''>Sin definir</option>
                                        {listaRepeticion.map((repeticion, llave) => (
                                          <option value={repeticion.clase} key={llave}>
                                            {repeticion.nombre}
                                          </option>
                                        ))}
                                      </Form.Select>
                                      <Button
                                        variant='outline-secondary'
                                        onClick={() => {
                                          document.getElementById('efectoRepeticionSalidaContenido').value =
                                            configDefecto.contenido.efecto.salida.repeticion;
                                        }}
                                      >
                                        <FontAwesomeIcon icon={faRefresh} />
                                        Reestablecer
                                      </Button>
                                    </InputGroup>
                                  </Col>
                                  <Col md={12}>
                                    <InputGroup size='sm' className='mb-3'>
                                      <InputGroup.Text>Duración</InputGroup.Text>
                                      <Form.Select
                                        id='efectoDuracionSalidaContenido'
                                        defaultValue={configuracion.contenido.efecto.salida.duracion}
                                      >
                                        <option value=''>Sin definir</option>
                                        {listaDuracion.map((duracion, llave) => (
                                          <option value={duracion.clase} key={llave}>
                                            {duracion.nombre}
                                          </option>
                                        ))}
                                      </Form.Select>
                                      <Button
                                        variant='outline-secondary'
                                        onClick={() => {
                                          document.getElementById('efectoDuracionSalidaContenido').value =
                                            configDefecto.contenido.efecto.salida.duracion;
                                        }}
                                      >
                                        <FontAwesomeIcon icon={faRefresh} />
                                        Reestablecer
                                      </Button>
                                    </InputGroup>
                                  </Col>
                                  <Col md={12}>
                                    <InputGroup size='sm' className='mb-3'>
                                      <InputGroup.Text>Retraso</InputGroup.Text>
                                      <Form.Select
                                        id='efectoRetrasoSalidaContenido'
                                        defaultValue={configuracion.contenido.efecto.salida.retraso}
                                      >
                                        <option value=''>Sin definir</option>
                                        {listaRetraso.map((retraso, llave) => (
                                          <option value={retraso.clase} key={llave}>
                                            {retraso.nombre}
                                          </option>
                                        ))}
                                      </Form.Select>
                                      <Button
                                        variant='outline-secondary'
                                        onClick={() => {
                                          document.getElementById('efectoRetrasoSalidaContenido').value =
                                            configDefecto.contenido.efecto.salida.retraso;
                                        }}
                                      >
                                        <FontAwesomeIcon icon={faRefresh} />
                                        Reestablecer
                                      </Button>
                                    </InputGroup>
                                  </Col>
                                </Row>
                              </Card.Body>
                            </Card>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <div className='d-grid gap-2'>
          <Button variant='outline-primary' onClick={guardarConfig} size='lg'>
            <FontAwesomeIcon icon={faCheck} />
            Guardar
          </Button>
          <Button variant='outline-secondary' onClick={() => setShow(false)} size='lg'>
            <FontAwesomeIcon icon={faTimes} />
            Cerrar
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
