import {
    createContext,
    useState,
    useContext
} from 'react';

const procesosContexto = createContext();
const setProcesosContexto = createContext();

export function useProcesosContexto() {
    return useContext(procesosContexto);
}

export function useSetProcesosContexto() {
    return useContext(setProcesosContexto);
}

export function ProcesosProveedor(props) {
    const [procesos, setProcesos] = useState([]);

    function establecerProcesos(procesosActual) {
        setProcesos(procesosActual);
    }

    return (
        <procesosContexto.Provider value={procesos}>
            <setProcesosContexto.Provider value={establecerProcesos}>
                {props.children}
            </setProcesosContexto.Provider>
        </procesosContexto.Provider>
    );
}