
//------------------------------------------------------------------Login Backend

var urlLoginBackend = process.env.REACT_APP_BACKEND_LOGIN_URL;

//------------------------------------------------------------------Ecommerce Backend

var urlEcommerceBackend = process.env.REACT_APP_BACKEND_ECOMMERCE_URL;

//------------------------------------------------------------------Syslog Api

var urlSyslogBackend = process.env.REACT_APP_BACKEND_SYSLOG_URL;

//------------------------------------------------------------------Proccesses Api

var urlProccessesBackend = process.env.REACT_APP_BACKEND_PROCCESSES_URL;

//------------------------------------------------------------------Ecommerce Frontend

var urlEcommerceFrontend = process.env.REACT_APP_FRONTEND_ECOMMERCE_URL;

//------------------------------------------------------------------Google Maps

var urlGoogleMapsAPIKEY = process.env.REACT_APP_GOOGLE_MAPS_KEY;

//------------------------------------------------------------------Syslog Api

var ambienteAdmin = process.env.REACT_APP_AMBIENTE_ADMIN;

module.exports = {
    urlLoginBackend,
    urlEcommerceBackend,
    urlSyslogBackend,
    urlProccessesBackend,
    urlEcommerceFrontend,
    urlGoogleMapsAPIKEY,
    ambienteAdmin
};