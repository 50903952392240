import { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import Axios from 'axios';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCogs, faPlus, faRefresh, faTimes, faCheck, faPercent, faEye, faArrowUpRightFromSquare, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { Dropdown, Table, Modal, Button, FloatingLabel, Form, Pagination } from 'react-bootstrap';
import { urlEcommerceBackend, urlEcommerceFrontend } from '../recursos/configuracion';
import { reportar, convertirFecha, diferencia } from '../recursos/funcion';
import { Cargador } from '../componentes/Cargador';
import { useUsuarioContexto } from '../proveedor/usuario';

export default function Promociones() {

    const cuenta = useUsuarioContexto();

    const [vital, setVital] = useState(false);

    const parametro = useParams();
    const locacion = useLocation();
    const navegar = useNavigate();

    const [pagina, setPagina] = useState(1);
    const [intervalo, setIntevalo] = useState(10);

    const [datos, setDatos] = useState([]);
    console.log("DATOS PROMOCION ", datos);
    const [paginas, setPaginas] = useState([]);
    const [paginado, setPaginado] = useState(
        {
            inicio: 0,
            fin: 0
        }
    );

    const [carga, setCarga] = useState(false);

    const [modal, setModal] = useState(false);

    const mostrarModal = () => setModal(true);
    const cerrarModal = () => setModal(false);

    useEffect(() => {

    }, []);

    useEffect(() => {
        if (cuenta.token === '') {
            setVital(true);
        } else {
            setVital(false);
            recuperar();
        }
    }, [cuenta]);

    useEffect(() => {
        setPagina(parseInt(parametro.pagina));
        setIntevalo(parseInt(parametro.intervalo));
    }, [locacion]);

    useEffect(() => {
        if (pagina !== 0 && intervalo !== 0) {
            recuperar();
        }
    }, [pagina, intervalo]);

    async function recuperar() {
        setCarga(true);
        try {
            let respuesta = await Axios.get(`${urlEcommerceBackend}/promociones?pagina=${pagina}&intervalo=${intervalo}&estado=1`, {
                headers: {
                    authorization: `Bearer ${cuenta.token}`
                }
            });
            //console.log("respuesta get: ", respuesta.data.arrayJson);
            setDatos(respuesta.data.arrayJson);
            setPaginas(respuesta.data.objetoJson.paginas);
            setPaginado(respuesta.data.objetoJson.paginado);
            setCarga(false);
        } catch (error) {
            await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "promociones.jsx", "recuperar", `${error.toString()}`, {
                error
            }, 1);
            setCarga(false);
        }
    }

    function dirigir(paginanueva) {
        if (paginanueva === 0) {

        } else {
            navegar(`/publicidad/promociones/${paginanueva}/${intervalo}`);
        }
    }

    function nuevo() {
        mostrarModal();
    }

    function actualizar() {
        recuperar();
    }
    //guardar generar una promocion nueva generada por el usuario.
    async function guardar() {
        let nombre = document.getElementById('txtNombre').value;
        // let cantidadCliente = document.getElementById('txtCantidadCliente').value;
        if (nombre === '') {
            Swal.fire(
                'Promoción',
                'El campo nombre o cantidad cliente esta vacio',
                'info'
            )
        } else {
            let parametros = {
                usuario: `${cuenta.nombre} ${cuenta.apellido}`,
                nombre: nombre.trim(),
                //cantidadCliente: cantidadCliente.trim(),
            }
            try {
                let respuesta = await Axios.post(`${urlEcommerceBackend}/promocion`, parametros, {
                    headers: {
                        authorization: `Bearer ${cuenta.token}`
                    }
                });
                recuperar();
                cerrarModal();
                console.log(respuesta);
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })

                Toast.fire({
                    icon: 'success',
                    title: respuesta.data.descripcion
                })
            } catch (error) {
                await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "promociones.jsx", "guardar", `${error.toString()}`, {
                    parametros,
                    error
                }, 1);
            }
        }
    }

    function cancelar() {
        cerrarModal();
    }

    const eventoEnter = (event) => {
        if (event.key === 'Enter') {
            guardar();
        }
    }

    return (
        <>
            <div className='container-fluid mt-3 mb-3'>
                <div className='row mb-3'>
                    <div className='col-md-12 mt-2 mb-2'>
                        <center>
                            <h1>
                                {(carga === true ? <Cargador estado={true} /> : <FontAwesomeIcon icon={faPercent} />)} Promoción
                            </h1>
                        </center>
                    </div>
                    <div className='col-md-6 mt-2 mb-2'>
                        <Dropdown>
                            <Dropdown.Toggle variant="outline-primary" id="cbOpciones">
                                <FontAwesomeIcon icon={faCogs} /> Opciones
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item href="#" onClick={nuevo} disabled={vital}>
                                    <FontAwesomeIcon icon={faPlus} /> Nuevo
                                </Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item href="#" onClick={actualizar} disabled={vital}>
                                    <FontAwesomeIcon icon={faRefresh} /> Actualizar
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div className='col-md-6 mt-2 mb-2'>
                        <div className="table-responsive">
                            <center>
                                {
                                    (paginas.length === 0 ? '' : <Pagination className='d-flex justify-content-end'>
                                        {(parseInt(pagina) === 1)}
                                        <Pagination.First onClick={() => {
                                            dirigir(paginado.inicio);
                                        }} disabled={parseInt(pagina) === 1} />
                                        <Pagination.Prev onClick={() => {
                                            dirigir(parseInt(pagina) - 1);
                                        }} disabled={parseInt(pagina) === 1} />
                                        {((Math.ceil(pagina / intervalo) * intervalo) === (Math.ceil(paginado.inicio / intervalo) * intervalo) ? '' : <><Pagination.Item onClick={() => {
                                            dirigir(paginado.inicio);
                                        }} disabled={(parseInt(pagina) === paginado.inicio)}>{paginado.inicio}</Pagination.Item><Pagination.Ellipsis onClick={() => {
                                            dirigir(parseInt(pagina) - 10)
                                        }} /></>)}

                                        {
                                            paginas.map((paginaOpcion, llave) => (
                                                <Pagination.Item key={llave} onClick={() => {
                                                    dirigir((paginaOpcion === parseInt(pagina) ? 0 : paginaOpcion))
                                                }} active={paginaOpcion === parseInt(pagina)}>{paginaOpcion}</Pagination.Item>
                                            ))
                                        }

                                        {((Math.ceil(pagina / intervalo) * intervalo) === (Math.ceil(paginado.fin / intervalo) * intervalo) ? '' : <><Pagination.Ellipsis onClick={() => {
                                            dirigir(parseInt(pagina) + 10)
                                        }} /><Pagination.Item onClick={() => {
                                            dirigir(paginado.fin);
                                        }} disabled={(parseInt(pagina) === paginado.fin)}>{paginado.fin}</Pagination.Item></>)}
                                        <Pagination.Next onClick={() => {
                                            dirigir(parseInt(pagina) + 1);
                                        }} disabled={parseInt(pagina) === paginado.fin} />
                                        <Pagination.Last onClick={() => {
                                            dirigir(paginado.fin);
                                        }} disabled={parseInt(pagina) === paginado.fin} />
                                    </Pagination>)
                                }
                            </center>
                        </div>
                    </div>
                    <div className='col-md-12'>
                        <div className='table-responsive'>
                            <Table bordered hover size="lg" className='table-dark'>
                                <thead>
                                    <tr>
                                        <th>Acción</th>
                                        <th>Nombre</th>
                                        <th>Ruta</th>
                                        <th>Fecha</th>
                                        <th>Hora</th>
                                        <th>Teaser</th>
                                        <th>Inicio</th>
                                        <th>Fin</th>
                                        <th>Vigencia</th>
                                        <th>Visibilidad</th>
                                        <th>Estado</th>
                                      {/*  <th>Cantidad</th>*/}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        datos.map((dato, key) => (
                                            <tr key={key} className={`align-middle ${(dato.estado.codigo === 1 ? 'text-success' : 'text-danger')}`}>
                                                <td className='text-center'>
                                                    <Button variant={`${(dato.estado.codigo === 1 ? 'outline-success' : 'outline-danger')}`} onClick={() => window.open(urlEcommerceFrontend + '/promos/' + dato.ruta, "_blank")}>
                                                        <FontAwesomeIcon icon={faEye} />
                                                    </Button>
                                                    <Button variant={`${(dato.estado.codigo === 1 ? 'outline-success' : 'outline-danger')}`} onClick={() => {
                                                        navegar(`/promocion/${dato.ruta}`);
                                                    }}>
                                                        <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                                                    </Button>
                                                </td>
                                                <td>{dato.nombre}</td>
                                                <td>{dato.ruta}</td>
                                                <td className='text-end'>{convertirFecha(dato.fecha)}</td>
                                                <td className='text-end'>{dato.hora}</td>
                                                <td className='text-end'>{convertirFecha(dato.tiempo.teaser)}</td>
                                                <td className='text-end'>{convertirFecha(dato.tiempo.inicio)}</td>
                                                <td className='text-end'>{convertirFecha(dato.tiempo.fin)}</td>
                                                <td className='text-end'>{(diferencia(dato.tiempo.fin, dato.tiempo.inicio) + 1)} dias</td>
                                                <td className='text-center'>{(dato.visibilidad.codigo === 1 ? <><FontAwesomeIcon icon={faEye} size='xl' /></> : <><FontAwesomeIcon icon={faEyeSlash} size='xl' /></>)}</td>
                                                <td className='text-center'>{(dato.estado.codigo === 1 ? <><FontAwesomeIcon icon={faCheck} size='xl' /></> : <><FontAwesomeIcon icon={faTimes} size='xl' /></>)}</td>
                                                {/*<td className='text-center'>{dato.cantidadCliente}</td>*/}
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={modal} onHide={cancelar} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <FontAwesomeIcon icon={faPercent} /> Promoción
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FloatingLabel controlId="txtNombre" label="Nombre" className="mb-3" onKeyUp={eventoEnter}>
                        <Form.Control type="text" placeholder="Nombre" defaultValue="" />
                    </FloatingLabel>
                    {/* <FloatingLabel controlId="txtCantidadCliente" label="Cantidad permitida por cliente" className="mb-3" onKeyUp={eventoEnter}>
                        <Form.Control type="text" placeholder="Cantidad permitida por cliente" defaultValue="0" />
                    </FloatingLabel>*/}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-secondary" onClick={cancelar} size="lg">
                        <FontAwesomeIcon icon={faTimes} /> Cancelar
                    </Button>
                    <Button variant="outline-primary" onClick={guardar} size="lg">
                        <FontAwesomeIcon icon={faCheck} /> Guardar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}